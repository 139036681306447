import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
function ClientOnly({ className, children, ...delegated }) {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }
  return (
    <div className={className} style={{ width: '100%' }} {...delegated}>
      {children}
    </div>
  );
}

ClientOnly.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ClientOnly;
