import React, { useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { motion } from 'framer-motion';
import Button from '@components/atoms/button';
import Counter from '@components/atoms/character-counter';
import { Textarea } from '../../styles';
import * as styles from '../../create-channel.module.scss';

const ChannelDescription = ({ current, send }) => {
  const textAreaRef = useRef();
  const [length, setLength] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const { description } = current.context;

  const emptySpaces = description.trim().length;

  const toggleChanges = (val) => {
    const values = description.trim().length;
    if (values > 0) {
      setDisabled(false);
    }
    setLength(emptySpaces);
    send({ type: 'ON_CHANGE', description: val });
  };

  useEffect(() => {
    textAreaRef.current.focus();
  }, [current.value]);

  const onKeyDownPreventEnter = (evt) => {
    if (evt.keyCode === 13 && evt.shiftKey === false) {
      evt.preventDefault();
    }
  };

  useEffect(() => {
    if (emptySpaces > 0) {
      setLength(description.length);
      setDisabled(false);
    }

    if (emptySpaces === 0) {
      setDisabled(true);
    }

    if (textAreaRef) {
      textAreaRef.current.setSelectionRange(description.length, description.length);
    }
  }, []);

  useEffect(() => {
    setLength(description.length);
    if (emptySpaces > 0) {
      setDisabled(false);
    }

    if (emptySpaces === 0) {
      setDisabled(true);
    }
  }, [description]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
        className={styles.motionContainer}
      >
        <div className={styles.stepperWrapper}>
          <div className={styles.titleWrapper}>
            <h2>Channel Description</h2>
          </div>
          <div className={styles.contentWrapper}>
            <Counter totalValue={160} changedValue={length} startValue={0} />
            <Textarea
              ref={textAreaRef}
              placeholder="Channel description up to 160 characters"
              fontSize={['3rem', '4rem']}
              fontWeight="bold"
              padding="0"
              border="none"
              width={['100%', '61.5rem']}
              name="name"
              value={description}
              maxLength={160}
              style={
                current.matches({ enterDescription: 'invalid' })
                  ? {
                      borderRight: '2px solid red',
                    }
                  : null
              }
              onChange={(evt) => {
                toggleChanges(evt.target.value);
              }}
              onKeyDown={onKeyDownPreventEnter}
            />
          </div>
          <div className={styles.footerWrapper}>
            <Button btnClass="primary" backBtn onClick={() => send('BACK')}>
              Back
            </Button>
            <Button btnClass="primary" onClick={() => send('NEXT')}>
              Next
            </Button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

ChannelDescription.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default ChannelDescription;
