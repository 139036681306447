import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { contentWrapper, statusButtons, relativePositioning } from '@helpers/main.module.scss';
import Button from '@components/atoms/button';
import MediaItem from './components/media-item';
import { validateYouTubeUrl } from '@helpers';
import { mediaSource } from './media.module.scss';

const MediaSourceInformation = ({ current, send }) => {
  const { content, isExiting, files, media } = current.context;
  const [items, setItems] = useState([]);

  const mediaTypes = ['media', 'image', 'carousel', 'video'];

  const configureMediaInfo = () => {
    const obj = [];

    if (files.length > 0) {
      files.map((i, idx) => {
        const item = {
          index: idx,
          ...i,
          area: 'cover',
          type: 'media',
        };
        return obj.push(item);
      });
    }
    if (content.length > 0) {
      content.map((item, cId) => {
        if (item && mediaTypes.includes(item?.type)) {
          if (item?.type === 'media' && item.items[0].type === 'video') {
            if (validateYouTubeUrl(item?.items[0]?.asset?.defaultUrl)) {
              return obj;
            }
            const yItx = {
              ...item.items[0].asset,
              caption: item.items[0].caption,
              type: 'video',
            };
            return obj.push(yItx);
          }
          if (item?.type === 'media') {
            return item?.items.map((cItem, x) => {
              const citx = {
                ...cItem,
                area: 'content',
                type: 'carousel-img',
                index: x,
                pIndex: cId,
              };
              return obj.push(citx);
            });
          }
          const itx = {
            ...item,
            type: item.type,
            area: 'content',
            index: cId,
          };
          return obj.push(itx);
        }
        return obj;
      });
    }
    setItems(obj);
  };

  const updateMeta = (pId, values, area, type, parentIndex) => {
    if (area === 'cover' && type === 'media') {
      send({
        type: 'SET_COVER_METADATA',
        index: pId,
        sourceInformation: values,
      });
    }

    if (area === 'content' && type === 'carousel-img') {
      const updatedCarousel = [];
      let newContentItem = {
        type: content[parentIndex].type,
      };

      content[parentIndex].items.map((item, idx) => {
        let newItem = {
          ...item,
        };

        if (idx === pId) {
          newItem = {
            ...newItem,
            sourceInformation: values,
          };
        }

        return updatedCarousel.push(newItem);
      });

      newContentItem = {
        ...newContentItem,
        items: updatedCarousel,
      };

      send({
        type: 'SET_CAROUSEL_METADATA',
        index: pId,
        parentIndex,
        updatedCarousel: newContentItem,
      });
    }

    if (area === 'content' && type === 'image') {
      send({
        type: 'SET_IMAGE_METADATA',
        index: pId,
        parentIndex,
        sourceInformation: values,
      });
    }
  };

  const goBack = () => {
    send({ type: 'BACK' });
    if (isExiting) {
      send({ type: 'IS_EXITING', exit: false });
    }
  };

  useEffect(() => {
    configureMediaInfo();
  }, []);

  return (
    <div className={contentWrapper}>
      <div className={mediaSource}>
        <h2>Media Source Information</h2>
        {items.map((m) => (
          <>
            <MediaItem
              onMetaDataUpdate={(values) => updateMeta(m.index, values, m.area, m.type, m.pIndex)}
              current={current}
              data={m}
              id={m.index}
              key={m.index}
            />
          </>
        ))}
      </div>
      <div className={`${statusButtons} ${relativePositioning}`}>
        <Button btnClass="editorBtn" onClick={() => goBack()}>
          Back
        </Button>
        <Button btnClass="editorBtn" primaryBtn onClick={() => send({ type: 'NEXT' })}>
          Next
        </Button>
      </div>
    </div>
  );
};

MediaSourceInformation.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default MediaSourceInformation;
