import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import {
  isLoggedIn,
  logout,
  setFreshToken,
  getToken,
  getTenant,
  getRefreshToken,
  setUserData,
} from '@helpers';
import cookie from 'js-cookie';
import Api from '@api';

const handleRefreshToken = async () => {
  const response = await Api.refreshToken(getRefreshToken());
  const { IdToken } = response;
  setFreshToken(IdToken);
};

const handleUserCheck = async () => {
  const userId = cookie.get('userId');
  const userData = await Api.getCurrentUser(getToken(), getTenant(), userId);
  setUserData(userData);
};

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  // TODO IdToken expiration needs to aleart changes.
  // FIXME Refactor the logic of checking for the userId and token
  const token = getToken();
  // const userId = cookie.get('userId');

  const checkLoggin = () => {
    if (isLoggedIn()) {
      handleRefreshToken();
    } else {
      logout(() => {
        // eslint-disable-next-line no-console
        console.log('You have been logged out!');
      });
    }

    if (!isLoggedIn()) {
      if (location.pathname !== `/app` || location.pathname === '/') {
        navigate('/');
      }
      return () => null;
    }

    return null;
  };

  const checkCurrentUser = () => {
    if (isLoggedIn()) {
      handleUserCheck();
    }
  };

  const performAlertedInfo = () => {
    handleRefreshToken();
  };

  useEffect(() => {
    setInterval(() => performAlertedInfo(), 3540000);
  }, []);

  useEffect(() => {
    // console.log('Token has changed', token);
  }, [token]);

  useEffect(() => {
    checkLoggin();
    checkCurrentUser();
  }, [location.pathname]);

  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.shape({
    hash: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
    protocol: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({}),
  }),
};

export default PrivateRoute;
