import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import SEO from '@components/seo.js';
import Icon from '@components/atoms/icon';
import Header from '@components/organisms/header';
import { Link } from '@reach/router';

import { cardsWrapper, whiteCardLayout, titleWrap } from './create.module.scss';

export default function SelectCreate() {
  return (
    <motion.div>
      <SEO title="Create" />
      <Header />
      <div className={cardsWrapper}>
        <Link to="/app/create-card" className={whiteCardLayout}>
          <Icon iconClass="full" fSize={4.6} />
          <h3>New Card</h3>
        </Link>
        <Link to="/app/create-channel" className={whiteCardLayout}>
          <Icon iconClass="channel" fSize={4.6} />
          <h3>New Channel</h3>
        </Link>
      </div>
    </motion.div>
  );
}
