import React from 'react';

import { motion } from 'framer-motion';

import Founders from '@images/felix-anatol-yann.png';
import Deniz from '@images/deniz.png';
import Icon from '@components/atoms/icon';
import Ballon from '@images/balloon.svg';

import {
  aboutWrapper,
  containerWrapper,
  closeDialog,
  imageWrapper,
  imageCaption,
  outsideImage,
  aboutDescription,
} from '../../profile.module.scss';

function AboutUs({ send }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: '-3rem' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '-3rem' }}
      className={aboutWrapper}
    >
      <div className={containerWrapper}>
        <div
          className={closeDialog}
          role="button"
          onClick={() => send({ type: 'VIEW_ABOUT', toggleAbout: false })}
        >
          <Icon iconClass="close-thin" fSize="2" />
        </div>
        <div className={imageWrapper}>
          <img src={Founders} width={626} alt="Justt Founders" />
          <div className={outsideImage}>
            <Ballon />
            <img src={Deniz} width={120} alt="Virtual Deniz" />
          </div>
          <div className={imageCaption}>
            <h6>
              <Icon iconClass="camera" />
              Photo taken during one of our founders workshops. Due to COVID-19 Deniz joined
              virtually for that one. Münich, 2020.
            </h6>
          </div>
        </div>
        <div className={aboutDescription}>
          <h2>The Story of Justt</h2>
          <hr />
          <p>
            Justt is the result of several bottles of red wine on a balcony in Port d'Andratx on
            Mallorca. There, Yann and Anatol - friends from school days who took different paths
            after graduating from high school - exchange views about their frustrating online media
            consumption. The next morning, they find scribbles about a platform on the table.
          </p>
          <p>
            The idea won't let them go. They decide to invest in a prototype and sink a lot of money
            into having the thing built cheaply in India.
          </p>
          <p>
            A colleague at work put Yann in touch with Deniz, who had set up the digital agency
            RollingRabbits in Pristina, Kosovo. Intro: "When Deniz delivers something, everyone's
            jaw drops." We talk to Deniz on the phone, tell him about the idea. And then hear:
            nothing.
          </p>
          <p>
            A week passes. Another. In the third week, Deniz sends a download link: 285 MB of data.
            We open the files. And see: Justt. And it looks amazing. The guy designed half the app,
            unpaid. "The idea is great. It inspired me. I worked through a few nights," is his
            answer.
          </p>
          <p>
            Six months later - by which time several coders from Pristina and Skopje were already
            working on Justt - we fly to Kosovo for the first time. Deniz becomes a partner.
          </p>
          <p>
            Yann is a techie. Anatol a journalist. Deniz a designer. We still need someone for the
            business. The next tip comes from BR host Christoph Süß, whom Anatol knows: "Call Felix.
            He's part of the family and does something with start-ups." Anatol calls. Turns out that
            Felix is the former strategy director at Sky and, as a former investment banker,
            evaluates start-up portfolios. Felix immediately believes in the idea. A short time
            later, Felix becomes a partner. The founding team is in place.
          </p>
          <p>
            Since then, our talented coder-team has been growing in Kosovo. We founded Justt AG with
            a top-class supervisory board. Our MVP is in the Appstore. And we recently added a
            top-notch saleswoman, Anne Hufnagel, who is developing the business and knocking down
            open doors.
          </p>
          <p>
            But the essence of this story is this: that scribble on the balcony in Port d'Andratx
            was not utopia. Because we are actually building it now, the platform that makes many
            things different and many things better. Because we are creators ourselves and
            understand what you are missing out there. And we are users ourselves who understand
            what annoys you out there: trash, clickbait, manipulation.
          </p>
          <p>
            Justt. <br />
            <br />
            Join us - and be part of the better story.
          </p>
        </div>
      </div>
    </motion.div>
  );
}

export default AboutUs;
