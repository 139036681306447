import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

import SEO from '@components/seo';
import Api from '@api';
import CardService from '@services/cards';

import Modal from '@components/molecules/modal';
import useSWR from 'swr';
import { navigate } from '@reach/router';

import { getToken, getUserId, getTenant } from '@helpers';
import Loading from '@components/atoms/loading';
import Card from '@components/organisms/card';
import CardSuccessIcon from '@images/card-moved.svg';

import {
  cardContent,
  allCentered,
  channelWrap,
  fullHeight,
  selectCard,
  cardsWrapper,
  titleWrapper,
  cardSuccessBox,
  iconWrapper,
} from '@components/templates/create-card/create-new-card.module.scss';

const SelectChannel = ({ id }) => {
  const [emptyData, setEmptyData] = useState(false);
  // Modal Toggle
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const [onDone, setOnDone] = useState(false);
  const [onExit, setOnExit] = useState(false);

  const { data, error } = useSWR(['/listContentCreationStreams'], () =>
    Api.listContentCreationStreams('all', 'DESC', 0)
  );

  const setModalValues = (mTitle, mDesc) => {
    setShowModal(true);
    setModalTitle(mTitle);
    setModalDesc(mDesc);
  };

  const moveToChannel = async (streamId) => {
    try {
      const response = await CardService.moveCardToChannel(id, streamId);

      if (response) {
        if (!response.success) {
          return setModalValues(
            'The card is already in this channel',
            'Please select another channel'
          );
        }
        return setOnDone(true);
      }
    } catch (e) {
      console.log(e);
      if (e) {
        setShowModal(true);
        setModalValues(response.message);
      }
    }
  };

  const cancelModal = () => {
    setShowModal(false);
    setModalTitle('');
    setModalDesc('');
  };

  const handleModalAction = () => {
    setShowModal(false);
  };

  const animateVariants = {
    initial: {
      y: '75vh',
      opacity: 0,
      transition: {
        delay: 1,
      },
    },
    onEnter: {
      y: '15vh',
      opacity: 1,
      transition: {
        duration: 1,
        ease: [0.38, 1.34, 0.91, 1.07],
      },
    },
    exitAnimation: {
      y: '-75vh',
      opacity: 0,
      transition: {
        delay: 1,
        ease: [0.46, -0.85, 0.27, 0.88],
        duration: 1.5,
      },
    },
  };

  // const cardVariants = {
  //   initial: {
  //     opacity: 1,
  //     y: 0,
  //   },
  //   onEnter: {
  //     y: '50vh',
  //   },
  // };

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        setEmptyData(true);
      }
    }
  }, [data, error]);

  useEffect(() => {
    if (onDone) {
      setTimeout(() => {
        setOnExit(true);
      }, 2500);
    }
  }, [onDone]);

  useEffect(() => {
    if (onExit) {
      setTimeout(() => {
        navigate('/app/cards');
      }, 2500);
    }
  }, [onExit]);

  return (
    <>
      <SEO title="Select Channel to Move Card | Justt CCD" />
      {onDone ? (
        <div className={`${cardContent} ${allCentered}`}>
          <motion.div
            className={cardSuccessBox}
            variants={animateVariants}
            initial="initial"
            animate={onExit ? 'exitAnimation' : 'onEnter'}
            exit="initial"
            style={{ alignItems: 'center' }}
          >
            <div>
              <CardSuccessIcon />
            </div>
            <h3>
              Card Moved <br />
              Successfully
            </h3>
          </motion.div>
        </div>
      ) : (
        <motion.div>
          {showModal && (
            <Modal
              title={modalTitle}
              desc={modalDesc}
              type="cant-move"
              showModal={showModal}
              onConfirm={() => handleModalAction()}
              onCancel={() => cancelModal()}
            />
          )}
          <div className={cardContent}>
            <div className={titleWrapper}>
              <h2>Select which Channel to move to</h2>
            </div>
            {!data && <Loading />}
            <div className={`${cardsWrapper} ${selectCard} ${emptyData && fullHeight}`}>
              {data &&
                data.map(({ streamId, created, published, coverMedia, name, description }) => (
                  <div
                    role="button"
                    key={streamId}
                    tabIndex="-1"
                    onClick={() => moveToChannel(streamId)}
                  >
                    <Card
                      isChannel
                      date={created}
                      status={published}
                      src={coverMedia?.items ? coverMedia?.items[0] : null}
                      id={streamId}
                      title={name}
                      description={description}
                    />
                  </div>
                ))}
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

SelectChannel.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default SelectChannel;
