import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

import SEO from '@components/seo';
import ContainerWrapper from '@components/molecules/container-wrapper';
import Button from '@components/atoms/button';

// In Depth Card Types
import Card from '@components/organisms/card';
import CardsData from '@helpers/placeholderData';

// Utils
import * as styles from '@components/organisms/select-create/create.module.scss';

const InDepthCards = ({ current, send }) => {
  const { format, subFormat } = current.context;
  const formatTypes = CardsData.cardTypes.indepth.items;

  //   const [activeIndex, setActiveIndex] = useState(0);

  const [active, setActive] = useState('Image');
  const [phase, setPhase] = useState('initial');

  const setActiveCard = (val, idx) => {
    setPhase(phase);
    // setActiveIndex(idx);
    setActive(val);
    send({ type: 'SELECT_SUBFORMAT', subformat: val });
  };

  const startCardCreation = () => {
    send({ type: 'START_INDEPTH_EDITOR' });
  };

  //   const getCardClass = (cardIndex) => {
  //     let cardClass = `${styles.cardHolder}`;
  //     if (cardIndex === 0) {
  //       if (activeIndex === 0) {
  //         cardClass += ` ${styles.activeCard}`;
  //       }
  //       if (activeIndex === 1) {
  //         cardClass += ` ${styles.secondStack} ${styles.leftPulled}`;
  //       }
  //       if (activeIndex === 2) {
  //         cardClass += ` ${styles.thirdStack} ${styles.furtherLeftPulled}`;
  //       }
  //       if (activeIndex === 3) {
  //         cardClass += ` ${styles.hiddenCard}`;
  //       }
  //     }
  //     if (cardIndex === 1) {
  //       if (activeIndex === 0) {
  //         cardClass += ` ${styles.secondStack} ${styles.rightPulled}`;
  //       }
  //       if (activeIndex === 1) {
  //         cardClass += ` ${styles.activeCard}`;
  //       }
  //       if (activeIndex === 2) {
  //         cardClass += ` ${styles.secondStack} ${styles.leftPulled}`;
  //       }
  //       if (activeIndex === 3) {
  //         cardClass += ` ${styles.thirdStack} ${styles.furtherLeftPulled}`;
  //       }
  //     }
  //     if (cardIndex === 2) {
  //       if (activeIndex === 0) {
  //         cardClass += ` ${styles.thirdStack} ${styles.furtherRightPulled}`;
  //       }
  //       if (activeIndex === 1) {
  //         cardClass += ` ${styles.secondStack} ${styles.rightPulled}`;
  //       }
  //       if (activeIndex === 2) {
  //         cardClass += ` ${styles.activeCard}`;
  //       }
  //       if (activeIndex === 3) {
  //         cardClass += ` ${styles.secondStack} ${styles.leftPulled}`;
  //       }
  //     }
  //     if (cardIndex === 3) {
  //       if (activeIndex === 0) {
  //         cardClass += ` ${styles.hiddenCard}`;
  //       }
  //       if (activeIndex === 1) {
  //         cardClass += ` ${styles.thirdStack} ${styles.furtherRightPulled}`;
  //       }
  //       if (activeIndex === 2) {
  //         cardClass += ` ${styles.secondStack} ${styles.rightPulled}`;
  //       }
  //       if (activeIndex === 3) {
  //         cardClass += ` ${styles.activeCard}`;
  //       }
  //     }
  //     return cardClass;
  //   };

  const slideAnimate = {
    initial: {
      transform: 'translateX(0)',
    },
    secondPull: {
      transform: 'translateX(-20rem)',
    },
    thirdPull: {
      transform: 'translateX(-40rem)',
    },
    end: {
      transform: 'translateX(-60rem)',
    },
  };

  const variants = {
    initial: {
      transform: 'scale(1)',
    },
    active: {
      transform: 'scale(1.15)',
    },
  };

  const checkCurrentType = ['Image', 'Blur', 'Solid', 'Text'];

  useEffect(() => {
    if (!format || format !== 'Indepth') {
      send({ type: 'SELECT_FORMAT', format: 'Indepth' });
    }
    if (!checkCurrentType.includes(subFormat)) {
      send({ type: 'SELECT_SUBFORMAT', subformat: 'Image' });
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ height: '100%' }}
    >
      <SEO title="Create Card" />
      <ContainerWrapper>
        <div className={styles.cardContent}>
          <div className={styles.cardTypes}>
            {formatTypes.map((item) => (
              <Button
                btnClass={active === item.id ? 'activeBtn' : ''}
                key={item.id}
                onClick={() => setActiveCard(item.id)}
              >
                {item.name}
              </Button>
            ))}
          </div>
          <div className={`${styles.cardsFormats}`}>
            {formatTypes.map((item) => (
              <motion.div
                className={styles.cardHolder}
                initial="initial"
                variants={variants}
                animate={active === item.id ? 'active' : 'initial'}
                onClick={() => setActiveCard(item.id)}
                key={item.id}
                aria-hidden="true"
              >
                <Card placeholder type={item.class} cardClass={item.class} />
              </motion.div>
            ))}
          </div>
          <Button btnClass="primaryButton" onClick={() => startCardCreation()}>
            Next
          </Button>
        </div>
      </ContainerWrapper>
    </motion.div>
  );
};

InDepthCards.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default InDepthCards;
