import styled from 'styled-components';
import { motion } from 'framer-motion';
import { flexbox, space, layout, color, typography, shadow, position, border } from 'styled-system';
import { Link as CustomLink } from 'gatsby';
import TextareaAutosize from 'react-autosize-textarea';
import rangeIcon from '@images/range-icon.svg';

export const Div = styled(motion.div)`
  ${color}
  ${flexbox}
  ${layout}
  ${space}
  ${shadow}
  ${position}
  ${border}
`;

export const Title = styled.h1`
  ${color}
  ${typography}
  ${space}
`;

export const Text = styled.div`
  ${color}
  ${typography}
  ${space}
  ${position}
`;

export const Textarea = styled(TextareaAutosize)`
  outline: none;
  resize: vertical;
  background-color: transparent;
  ${color}
  ${border}
  ${typography}
  ${layout}
  ::-webkit-input-placeholder {
    color: #cacaca;
  }
  ::-moz-placeholder {
    color: #cacaca;
  }
  :-ms-input-placeholder {
    color: #cacaca;
  }
  :-moz-placeholder {
    color: #cacaca;
  }
`;

export const Error = styled.div`
  ${color}
  ${typography}
  ${space}
`;

export const Range = styled.input`
  -webkit-appearance: none;
  width: 100%;
  background-color: transparent;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    width: 38px;
    height: 38px;
    border-radius: 19px;
    background: #ffffff;
    cursor: ew-resize;
    margin-top: -18px;
    background: url(${rangeIcon}) #fff;
    background-size: 43%;
    background-position: center;
    background-repeat: no-repeat;
    border: 0 solid #e9e9e9;
    box-shadow: 0 0 7px 0 rgba(175, 175, 175, 0.5);
  }

  &:focus {
    outline: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: ew-resize;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #d3d3d3;
    box-shadow: inset 0px 10px 1px #d3d3d3, 0px 0px 1px #d3d3d3;
    -webkit-appearance: none;
  }

  &::-moz-range-progress {
    background-color: #d3d3d3;
  }

  &::-moz-range-track {
    width: 100%;
    height: 2px;
    background: #d3d3d3;
  }

  &::-ms-track {
    width: 100%;
    height: 2px;
    background: #d3d3d3;
  }

  &::-ms-fill-lower {
    width: 100%;
    height: 2px;
    background: #d3d3d3;
  }

  &::-ms-fill-upper {
    width: 100%;
    height: 2px;
    background: #d3d3d3;
  }
`;

export const SelectFile = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007aff;
  color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export const Link = styled(CustomLink)`
  outline: none;
  text-decoration: none;
  ${flexbox}
  ${color}
  ${border}
  ${shadow}
  ${space}
  ${layout}
  ${typography}
  ${position}
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
