import React, { useEffect, useState, useRef } from 'react';

import { motion } from 'framer-motion';
import { useSWRConfig } from 'swr';
import useDynamicRefs from 'use-dynamic-refs';

import SEO from '@components/seo';
import ClientOnly from '@components/molecules/clientOnly';
import Header from '@components/organisms/header';

import { getTenant, isLoggedIn, getTenants, setTenant, getUserInitials } from '@helpers';

import {
  contentWrapper,
  detailsWrapper,
  pulledTitle,
  userPlaygrounds,
  buttonWrapper,
  activeBtn,
  buttonSlider,
  buttonIcon,
  playgroundWrapper,
  playgroundItem,
  activePlayground,
  iconWrapper,
  initial,
  sectionWrapper,
  firstItem,
  secondItem,
  disabledItem,
  fullGridWrapper,
} from './spaces.module.scss';

const Spaces = () => {
  const [spaces, setSpaces] = useState([]);
  const [playgrounds, setPlayGrounds] = useState([]);

  const { cache, mutate } = useSWRConfig();

  const [active, setActive] = useState(null);
  const [playgroundsActive, setPlayGroundsActive] = useState(0);
  const [leftPos, setLeftPos] = useState(0);
  const [activeWidth, setWidth] = useState(180);

  const currentTenantId = getTenant();
  const tenants = JSON.parse(getTenants());
  const parentRef = useRef();
  const [getRef, setRef] = useDynamicRefs();
  const [hasRefs, setHasRefs] = useState(false);

  const mainSpaces = ['Justt', 'Justt Business'];

  const detectActiveItem = () => {
    const refId = getRef(currentTenantId);

    // Check if we have the predefined tenant
    const filteredSpaces = spaces.filter((item) => item.tenantId === currentTenantId);
    const spaceLength = spaces.length;
    if (filteredSpaces.length > 0) {
      const parentPos = parentRef.current;
      const actualLeft =
        refId?.current?.getBoundingClientRect().left - parentPos.getBoundingClientRect().left;

      setLeftPos(actualLeft);
      setWidth(refId?.current?.getBoundingClientRect().width);
    } else if (spaceLength == 1 && currentTenantId !== null) {
      setActive(spaces[0].tenantId);
    } else {
      setPlayGroundsActive(currentTenantId);
    }
  };

  //

  const tenantSetting = () => {
    if (tenants && tenants.length > 0) {
      const accessableTenants = tenants.filter(
        (item) => item.update && mainSpaces.includes(item.name)
      );
      const accessablePlaygrounds = tenants.filter(
        (item) => item.update && !mainSpaces.includes(item.name)
      );

      const activeTenant = accessableTenants.filter((item) => item.tenantId === currentTenantId);

      if (activeTenant.length > 0) {
        setActive(activeTenant[0].tenantId);
      }

      if (accessableTenants.length > 0) {
        const firstItem = 'Justt';

        const sorter = (a, b) => {
          if (a.name === firstItem) {
            return -1;
          }
          if (b.name === firstItem) {
            return 1;
          }
          return a.name < b.name ? -1 : 1;
        };
        const sortedTenants = accessableTenants.sort(sorter);
        setSpaces(sortedTenants || accessableTenants);
      }

      setPlayGrounds(accessablePlaygrounds);
    }
  };

  // TODO Function to be called on render
  // TODO Find the number of spaces/playgrounds
  // TODO See if we have an active item. If not, preselect the first space available
  // TODO If no spaces available, preselect the first playground

  // TODO Save this tenant selection to localStorage, to keep the active tenant whenever entering the spaces view

  useEffect(() => {
    tenantSetting();
    setHasRefs(true);
    // checkForActive();

    return () => {
      setActive(null);
      setSpaces([]);
      setPlayGrounds([]);
    };
  }, []);

  useEffect(() => {
    if (hasRefs && spaces.length > 0) {
      detectActiveItem();
    } else if (playgrounds && playgrounds?.length > 0) {
      setPlayGroundsActive(playgrounds[0]?.tenantId);
      setTenant(playgrounds[0]?.tenantId);
    } else {
      ////
    }
  }, [hasRefs]);

  const toggleClick = (val, name) => {
    setTenant(val);
    cache.delete('/listContentCreationStreams');

    if (mainSpaces.includes(name)) {
      setActive(val);
      setPlayGroundsActive(null);
      const parentPos = parentRef.current;
      const activeChild = getRef(val);

      const actualLeft =
        activeChild.current.getBoundingClientRect().left - parentPos.getBoundingClientRect().left;

      setLeftPos(actualLeft);
      setWidth(activeChild.current.getBoundingClientRect().width);
    } else {
      setActive(null);
      setPlayGroundsActive(val);
    }
  };

  const detectClass = (val) => {
    if (val === 'Justt') {
      return firstItem;
    }
    if (val === 'Justt Business') {
      return secondItem;
    }
    return disabledItem;
  };

  return (
    <motion.div className={contentWrapper}>
      <SEO title="User Spaces" />
      <Header isLoggedIn={isLoggedIn()} isAccount />
      <ClientOnly>
        <div className={detailsWrapper}>
          {/* <h1 className={pulledTitle}>Main Platform</h1> */}
          <div className={`${sectionWrapper}`}>
            <h2>Main Platform</h2>
            {spaces && spaces.length > 0 ? (
              <div className={buttonWrapper} ref={parentRef}>
                {spaces.map((space) => (
                  <button
                    type="button"
                    key={space.name}
                    ref={setRef(space.tenantId)}
                    className={`
                    ${active === space.tenantId ? activeBtn : ''} 
                    ${detectClass(space.name)}
                  `}
                    onClick={() => toggleClick(space.tenantId, space.name)}
                  >
                    <span className={buttonIcon} />
                    {space.name}
                  </button>
                ))}

                {active && (
                  <span
                    className={`${buttonSlider}`}
                    style={{ left: leftPos, width: activeWidth }}
                  />
                )}
              </div>
            ) : (
              <p style={{ fontSize: '1.4rem', textAlign: 'center' }}>
                You haven't been accredited to the main platform yet. <br />
                Head out to the Justt team if you want to publish on the main platform.
              </p>
            )}
          </div>
          <div className={`${userPlaygrounds} ${sectionWrapper}`}>
            <h2>Spaces</h2>
            <div
              className={`${playgroundWrapper} ${
                playgrounds.length === 0 ? `${fullGridWrapper}` : null
              }`}
            >
              {playgrounds && playgrounds.length > 0 ? (
                playgrounds.map((env) => (
                  <button
                    type="button"
                    className={`${playgroundItem} ${
                      playgroundsActive === env.tenantId && activePlayground
                    }`}
                    key={env.name}
                    onClick={() => toggleClick(env.tenantId, env.name)}
                  >
                    <span className={initial}>{getUserInitials(env.name)}</span>
                    {env.name}
                    <span className={iconWrapper}>{/* <i>I</i> */}</span>
                  </button>
                ))
              ) : (
                <p style={{ fontSize: '1.4rem', textAlign: 'center' }}>
                  You don't have additional Spaces available at this time.
                </p>
              )}
            </div>
          </div>
        </div>
      </ClientOnly>
    </motion.div>
  );
};

export default Spaces;
