import React, { useEffect, useRef, useState } from 'react';

import { useSWRConfig } from 'swr';
import { navigate } from '@reach/router';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';

import useOutsideClick from '@helpers/useOutsideClick';
import { cleanUpHtml, getUserInitials, logout } from '@helpers';
import Verified from '@images/verified-profile.svg';
import Icon from '@components/atoms/icon';
import Button from '@components/atoms/button';
import ImpressumModal from '../../../../molecules/modal/components/impressum';

import { socialIcon, subMenuAnimate, variants } from '../../shared';
import * as styles from '@components/organisms/header/header.module.scss';

import {
  userDetailsWrapper,
  imgWrapper,
  initialAvatar,
  userProfileInfo,
  actionButtons,
  externalLinksWrapper,
  externalImpressumWrapper,
  linkWrap,
  impressumWrap,
  settingsDropdown,
  dropDownTypes,
  dropDownContainer,
  dropDownMenuItem,
  logOut,
  logOutLabel,
  impressumText,
} from '../../profile.module.scss';

function ViewProfile({ send, context }) {
  const { userData, edit } = context;
  const [isOpen, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const ref = useRef();
  const { cache } = useSWRConfig();

  useOutsideClick(ref, () => {
    if (isOpen) {
      setOpen(false);
    }
  });

  const handeLogOut = () => {
    cache.delete();
    logout(() => {
      Cookies.remove('env');
      navigate('/');
    });

    // cache.delete('/listContentCreationStreams');
    // cache.delete('/listContentCreationCards');
    // cache.delete('/getUserProfileInfo');
  };

  const triggerModal = () => {
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const description = (children) => {
    return <div>{(dangerouslySetInnerHTML = { __html: children })}</div>;
  };

  return (
    <motion.div
      variants={edit ? variants : ''}
      animate={edit ? 'editing' : 'initial'}
      className={userDetailsWrapper}
    >
      <div className={actionButtons}>
        <button type="button" className={styles.defaultBtn} onClick={() => setOpen(!isOpen)}>
          <Icon iconClass="settings" fSize={2.2} />
        </button>

        <motion.div
          className={settingsDropdown}
          ref={ref}
          initial="exit"
          animate={isOpen ? 'enter' : 'exit'}
          variants={subMenuAnimate}
        >
          <div className={dropDownTypes}>
            <div className={dropDownContainer}>
              <div role="button" tabIndex="0" className={dropDownMenuItem}>
                <h4 style={{ opacity: '0.5' }}>Settings</h4>
                <span>Version 1.0.1</span>
              </div>
              <div
                role="button"
                tabIndex="0"
                className={dropDownMenuItem}
                onClick={() => {
                  send({ type: 'VIEW_ABOUT', toggleABout: true });
                  setOpen(false);
                }}
              >
                <h4>About</h4>
                <Icon iconClass="arrow-right" fSize={1.4} />
              </div>
            </div>
          </div>
          <div className={`${dropDownTypes} ${logOut}`}>
            <div className={`${dropDownContainer}`}>
              <div
                role="button"
                tabIndex="0"
                className={dropDownMenuItem}
                onClick={() => handeLogOut()}
              >
                <span className={logOutLabel}>Logout</span>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className={imgWrapper}>
        {userData?.avatar ? (
          <>
            <img srcSet={userData.avatar} width="100" height="100" alt={userData?.displayName} />
            <Verified />
          </>
        ) : (
          <div className={initialAvatar}>
            <span>{userData?.displayName ? getUserInitials(userData?.displayName) : 'J'}</span>
          </div>
        )}
      </div>
      <h4>{userData?.displayName}</h4>
      <span>{userData?.profile?.occupation}</span>
      <Button btnClass="editProfile" onClick={() => send({ type: 'TOGGLE_EDITING', edit: true })}>
        Edit Profile
        <Icon iconClass="edit" />
      </Button>
      {userData?.profile ? (
        <div className={userProfileInfo}>
          {userData?.profile?.html ? (
            <div dangerouslySetInnerHTML={{ __html: userData?.profile?.html }}></div>
          ) : (
            ''
          )}
          <div className={externalLinksWrapper}>
            {userData && userData?.profile?.externalLinks
              ? userData?.profile?.externalLinks?.map(
                  (link, index) =>
                    link.value && (
                      <div className={linkWrap} key={index}>
                        {socialIcon(link.key)}
                        <p title={link.value}>{link.value}</p>
                      </div>
                    )
                )
              : ''}
          </div>
          {userData?.profile?.impressum !== '<p></p>' ? (
            <div className={externalImpressumWrapper}>
              <div className={impressumWrap}>
                <button onClick={() => triggerModal()}>
                  <p className={impressumText}>Impressum/Imprint</p>
                </button>
                {showModal && (
                  <ImpressumModal
                    modalTitle={'Impressum/Imprint'}
                    desc={userData?.profile?.impressum}
                    showModal={showModal}
                    onCancel={() => onCloseModal()}
                  />
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </motion.div>
  );
}

export default ViewProfile;
