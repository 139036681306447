import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { isURL, getTwitterId } from '@helpers';
import { flow } from 'lodash';

import { useSnackbar } from 'react-simple-snackbar';

import Icon from '@components/atoms/icon';

import '@draft-js-plugins/anchor/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';

// TODO fix package below for heavy memory usages
import {
  promptLinkAppend,
  appendLinkToSelection,
  htmlParsedContent,
  removeInlineStyles,
  removeEntities,
  removeBlockTypes,
} from '@components/organisms/my-editor/models';

import {
  myBlockRenderMap,
  styleMap,
  findLinkEntities,
  Link,
  IGNORED_TYPES,
  getPlatform,
} from '@components/organisms/my-editor/helpers';

import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';

import {
  EditorState,
  ContentState,
  Modifier,
  RichUtils,
  CompositeDecorator,
  Entity,
} from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import Editor from '@draft-js-plugins/editor';
// import { getSelectionEntity, getEntityRange } from 'draftjs-utils';
// import { removeLinkOverlay } from './inline-editor.module.scss';

const linkDetectionPlugin = createLinkDetectionPlugin();
const plugins = [linkDetectionPlugin];

const InlineEditor = ({ data, id, onDelete, current, send }) => {
  const {
    title,
    media,
    content,
    editing,
    editorIndex,
    toggleIndex,
    toggleMediaIndex,
    inlineStyle,
    caretData,
    blockTypeStyle,
    urlValue,
  } = current.context;

  const [editorPlaceholder, setPlaceholder] = useState('Start writing ...');
  const [initialized, setInitialized] = useState(false);
  const [hasRemoved, setHasRemoved] = useState(false);

  const [open, close] = useSnackbar({
    position: 'bottom-left',
  });

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  let paste = false;
  let pastedValue = '';
  const [linkFormated, setLinkFormated] = useState(false);
  const [withoutLinkContent, setWithoutLinkContent] = useState(null);

  const characterLength = editorState.getCurrentContent().getPlainText('').length;
  const editor = useRef(null);

  const editorCursorTracker = () => {
    const currentBlockKey = editorState.getSelection().getStartKey();

    const currentBlockIndex = editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq()
      .findIndex((k) => k === currentBlockKey);

    const anchorOffset = editorState.getSelection().getAnchorOffset();
    const caretData = {
      currentKey: currentBlockKey,
      currentEntityKey: linkMeta.entityKey,
      currentId: currentBlockIndex,
      editorIndex: id,
      caret: anchorOffset,
    };

    send({ type: 'SET_CARET_DATA', caretData });
  };

  // The content blocks, will be parsed into html here
  // and later be used for updating the source property of editors
  const convertedEditorState = (contentBlocks) => {
    if (contentBlocks.length > 0) {
      const entityMap = editorState.getCurrentContent().getEntityMap();
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      const parsedData = htmlParsedContent(newEditorState);
      return parsedData;
    }
    return null;
  };

  const splitBlocks = (item) => {
    // We are mapping the entity of the keyes here, meaning
    // we are finding all the blocks, and then by the item that we are trying to split
    // we insert a line to where the caret is

    const entityMap = editorState.getCurrentContent().getEntityMap();
    const contentState = ContentState.createFromBlockArray(item, entityMap);
    const selection = editorState.getSelection();

    // endBlock
    const blockMap = contentState.getBlockMap();
    const endKey = selection.getEndKey();
    const endBlock = blockMap.get(endKey);

    if (!endBlock) {
      return null;
    }

    // After collecting the content state and selection state, we push it to the newly updated
    // editor state, specifically for the splitting item, not all the other items.

    const newLineBlock = Modifier.splitBlock(contentState, selection);
    const newState = EditorState.push(editorState, newLineBlock, 'split-block');

    // As a result of this split, now we will have two blocks, one from before the cursor,
    // the other after the cursor. Thats why below we are going with the same approach
    // of collecting now the splittedBlocks, because on the function above, these blocks will
    // then be converted to HTML to be added on the content json

    const splittedBlocks = newState.getCurrentContent().getBlocksAsArray();
    return splittedBlocks;
  };

  const getBlockValues = () => {
    const itemIndex = caretData.currentId;
    const caretPosition = caretData.caret;

    // Overall record
    let leftItems = [];
    let splittingItem = [];
    let rightItems = [];

    // fetch all editorState blocks
    const blocksArray = editorState.getCurrentContent().getBlocksAsArray();

    if (caretData.editorIndex === id) {
      blocksArray.map((item, idx) => {
        const characterSize = item.getCharacterList().size;

        // Two conditions: if the index of the iterated block is lower than the index
        // of the block where the caret is, then add it to the left side but also
        // if indexes match and the cursor is at the very end of the line of that block
        if (item === undefined) return;

        if (idx < itemIndex) {
          return leftItems.push(item);
        }

        if (idx === itemIndex && characterSize === caretPosition) {
          return leftItems.push(item);
        }

        // If we are at the start of the editor, then add all blocks to the next editor
        // and append the media items before this editor
        if (idx === 0 && itemIndex === 0 && caretPosition === 0) {
          return rightItems.push(item);
        }

        if (idx > itemIndex) {
          return rightItems.push(item);
        }

        // In the event that the conditions above aren't met that means we go with
        // splitting a certain block. Meaning: the cursor position is between the lines of one block
        // and that block will require splitting where one part will go left,
        // the other part will right

        return splittingItem.push(item);
      });

      if (splittingItem.length > 0) {
        // If we have a splitting block, then that block will be added to the
        // already existing left and right editors.
        const additionalBlocks = splitBlocks(splittingItem);

        if (additionalBlocks) {
          leftItems.push(additionalBlocks[0]);

          // we are creating a copy of the rightItems while adding the second item
          // at the very first of the rightItems array and then spread that array to this one
          // only for this one to do a reassign to the right items
          const newRighItems = [additionalBlocks[1], ...rightItems];
          rightItems = newRighItems;
          // We are pushing the first one to the left after the other existing items
        }
      }

      // For better reuse between components, we will save it into the State Machines
      send({
        type: 'SET_EDITOR_BLOCKS',
        editorBlocks: {
          leftItems: convertedEditorState(leftItems),
          rightItems: convertedEditorState(rightItems),
        },
      });
    }
  };

  const focusEditor = () => {
    editor.current.focus();
    send({ type: 'SET_EDITOR_INDEX', index: id });
    editorCursorTracker();
  };

  // Process of spliting the editor content and inserting the image will be as follow
  const disableButtons = (len) => {
    if (id === 0 && content && content.length === 1) {
      if (len === 0) {
        setTimeout(() => send({ type: 'SET_DISABLED', disabled: true }), 150);
      }
    }
  };

  const onChange = (message, changedState) => {
    if (!initialized) return;
    if (!editing) {
      const currentLength = changedState.getCurrentContent().getPlainText().length;
      if (title.length > 0 && media.length > 0 && content.length <= 1) {
        disableButtons(currentLength);
      }
    }

    const stateContent = changedState.getCurrentContent().getPlainText();

    if (paste) {
      if (stateContent.indexOf('https://twitter.com') !== -1) {
        paste = false;
        const twitterId = getTwitterId(pastedValue);

        const embedValues = {
          type: 'externalContent',
          platform: 'twitter',
          source: twitterId,
        };

        send({ type: 'SET_BLOCKTYPE', blockTypeStyle: 'externalContent' });
        send({ type: 'SET_EMBED_VALUES', embedValues });
        return null;
      } else if (stateContent.indexOf('https://www.facebook.com') !== -1) {
        const encodeUrl = encodeURIComponent(stateContent);
        return null;
      } else if (
        stateContent.indexOf('https://soundcloud.com') !== -1 ||
        stateContent.indexOf('https://vimeo.com') !== -1 ||
        stateContent.indexOf('https://www.youtube.com') !== -1
      ) {
        const platform = getPlatform(stateContent);
        const videoBlock = {
          type: 'externalContent',
          source: stateContent,
          platform,
          key: '',
          embed: true,
        };

        send({ type: 'SET_BLOCKTYPE', blockTypeStyle: 'externalContent' });
        send({ type: 'SET_EMBED_VALUES', embedValues: videoBlock });
        return null;
      } else {
        // you pasted a text that is not a link
      }
    }

    setEditorState(changedState);

    // We are checking if there is any selected text/keys/entities
    // inside of our state
    const selected = promptLinkAppend(changedState);
    if (selected) {
      send({ type: 'SET_SELECTED_ENTITY', selectedEntity: true });
    } else {
      send({ type: 'SET_SELECTED_ENTITY', selectedEntity: false });
    }

    // In order to parse Custom Inline Styles
    // we use the convertToHtml HOC from Draft.
    // This logic below, maps our inlinestyles with the overall output
    // and how it will be displayed and mapped on iOS

    const changedData = convertToHTML({
      styleToHTML: (style) => {
        if (style === 'HIGHLIGHT') {
          return <span style={{ backgroundColor: '#AFFFAA' }} />;
        }
        if (style === 'STRIKETHROUGH') {
          return <span style={{ textDecoration: 'line-through' }} />;
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={entity.data.url}>{originalText}</a>;
        }
        return originalText;
      },
    })(changedState.getCurrentContent());

    const components = [...content];
    const item = {
      ...components[id],
      source: changedData,
    };
    components[id] = item;

    send({ type: 'SET_TOGGLE_INDEX', index: toggleIndex + 1 });

    send({
      type: 'ONCHANGE_EDITOR_CONTENT',
      updatedContent: components,
    });
  };

  const resetToDefault = () => {
    const stateWithoutEditing = flow([removeInlineStyles, removeEntities, removeBlockTypes])(
      editorState
    );
    onChange('state without editing', stateWithoutEditing);
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (command === 'backspace') {
      if (characterLength === 0 && id !== 0) {
        onDelete();
      }
    }

    if (newState) {
      onChange('handle key command', newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const checkPlaceholder = () => {
    if (id !== 0) {
      setPlaceholder(' ');
    } else {
      setPlaceholder('Start writing ...');
    }
  };

  const [hoveredLink, setHoveredLink] = useState(false);
  const [linkMeta, setLinkMeta] = useState({});
  const [onRemove, setOnRemove] = useState(false);
  const [hoverOut, setHoverOut] = useState(false);

  const onHoverIn = (val) => {
    setLinkMeta(val.linkMeta);
    setHoveredLink(true);
  };

  const clearHover = () => {
    setLinkMeta({});
    setHoverOut(false);
    setHoveredLink(false);
  };

  const onHoverOut = () => setHoverOut(true);

  const onRemoveHover = () => {
    setHoverOut(false);
    setOnRemove(true);
  };

  const onRemoveHoverOut = () => {
    setOnRemove(false);
    if (!hoveredLink) {
      clearHover();
    } else {
      setHoveredLink(false);
      setHoverOut(false);
    }
  };

  useEffect(() => {
    if (!onRemove) {
      clearHover();
    }
  }, [hoverOut]);

  const removingLink = () => {
    const { blockKey, start, entityKey, end } = linkMeta;

    const currentEntity = entityKey;
    let selection = editorState.getSelection();

    if (currentEntity) {
      const entityRange = { start, end };
      const isBackward = selection.getIsBackward();

      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start,
        });
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end,
        });
      }

      const contentState = editorState.getCurrentContent();
      const contentWithoutEntities = Modifier.applyEntity(contentState, selection, null);

      const newEditorState = EditorState.push(editorState, contentWithoutEntities, 'apply-entity');

      onChange('removingLink', newEditorState);

      setTimeout(() => {
        if (hasRemoved) {
          setHasRemoved(false);
        }
      }, 2000);
    }
  };

  useEffect(() => {
    if (hasRemoved) {
      removingLink();
    }
    return () => setHasRemoved(false);
  }, [hasRemoved]);

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
      props: {
        hoverIngItem: (val) => onHoverIn(val),
        hoveringOut: () => onHoverOut(),
      },
    },
  ]);

  // The need for toggleIndex, stems solely on the principle that
  // to revert the styling of a specific element/block type one
  // needs to pass the exact same prop to revert it. useEffect doesnt
  // re-render on those instances, and thus we can't perform reverting logic

  useEffect(() => {
    if (id === editorIndex && !IGNORED_TYPES.includes(inlineStyle)) {
      if (inlineStyle && inlineStyle !== 'LINK') {
        onChange(
          'inline style, toggle index useEffect',
          RichUtils.toggleInlineStyle(editorState, inlineStyle)
        );
        send({ type: 'SET_INLINE', inlineStyle: null });
      }
    }
  }, [inlineStyle, toggleIndex]);

  useEffect(() => {
    if (linkFormated) {
      send({ type: 'SET_TOGGLE_INDEX', index: toggleIndex + 1 });
      send({
        type: 'ONCHANGE_EDITOR_CONTENT',
        updatedContent: withoutLinkContent,
      });
      setLinkFormated(false);
      setWithoutLinkContent(null);
    }
  }, [linkFormated]);

  useEffect(() => {
    if (id === editorIndex && !IGNORED_TYPES.includes(blockTypeStyle)) {
      if (blockTypeStyle) {
        send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null });
        if (blockTypeStyle === 'unstyled') {
          return resetToDefault();
        }
        onChange(
          'blockTypeStyle toggleIndex useEffect',
          RichUtils.toggleBlockType(editorState, blockTypeStyle)
        );
      }
    }
  }, [blockTypeStyle, toggleIndex]);

  useEffect(() => {
    if (caretData) {
      getBlockValues();
    }
  }, [caretData]);

  const convertHTMLtoEditorContent = (val) => {
    const renderingState = EditorState.createWithContent(
      convertFromHTML({
        htmlToStyle: (nodeName, node, currentStyle) => {
          if (nodeName === 'span' && node.style.backgroundColor === 'rgb(175, 255, 170)') {
            return currentStyle.add('HIGHLIGHT');
          }
          if (nodeName === 'u') {
          }
          if (nodeName === 'span' && node.style.textDecoration === 'line-through') {
            return currentStyle.add('STRIKETHROUGH');
          }
          return currentStyle;
        },
        htmlToEntity: (nodeName, node, entity) => {
          if (nodeName === 'a') {
            return Entity.create('LINK', 'MUTABLE', { url: node.href });
          }
        },
      })(val),
      decorator
    );
    setEditorState(renderingState);
  };

  const onEnterCommand = (e) => {
    if (e.shiftKey) {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }
    return 'not-handled';
  };

  useEffect(() => {
    if (urlValue) {
      const stateToUpdate = appendLinkToSelection(urlValue, editorState);
      onChange('urlValue useEffect', stateToUpdate);
      setTimeout(() => {
        send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null });
        send({ type: 'SET_URL_VALUE', blockTypeStyle: '' });
      }, 100);
    }
  }, [urlValue]);

  const getLinkUrl = (key) => {};

  const onEditLink = (meta) => {
    const data = {
      currentLinkData: {
        blockKey: meta.blockKey,
        url: linkMeta.url,
        entityKey: meta.entityKey,
      },
    };

    send({ type: 'ON_LINK_EDITING', data });
  };

  const handlePaste = (text, v) => {
    if (isURL(text)) {
      paste = true;
      pastedValue = text;
    }
  };

  useEffect(() => {
    if (data?.source) {
      convertHTMLtoEditorContent(data?.source);
    }
  }, [toggleMediaIndex]);

  useEffect(() => {
    checkPlaceholder();
    if (data?.source) {
      convertHTMLtoEditorContent(data?.source);
    }

    if (data?.focused) {
      setTimeout(() => {
        focusEditor();
      }, 100);
    }

    setInitialized(true);
  }, []);

  return (
    <div
      style={{
        margin: '0 2.2rem 1rem',
        width: '100%',
        minHeight: id === 0 ? '3.4rem' : '1.6rem',
        cursor: 'text',
        display: 'inline-block',
      }}
      onClick={() => focusEditor()} // on click
      onBlur={() => editorCursorTracker()}
      onKeyUp={(value) => editorCursorTracker(value)} // after releasing key press
      // onKeyDown={() => focusEditor()} // on key press, before releasing
      className="RichEditor-editor"
      role="textbox"
      tabIndex="0"
    >
      <Editor
        ref={editor}
        editorState={editorState}
        plugins={plugins}
        customStyleMap={styleMap}
        handleKeyCommand={handleKeyCommand}
        handlePastedText={handlePaste}
        handleReturn={onEnterCommand}
        placeholder={editorPlaceholder}
        onChange={(changedValue) => (!hasRemoved ? onChange('editor', changedValue) : '')}
        blockRendererFn={myBlockRenderMap}
      />
      <div
        className={hoveredLink ? 'nonHoveredItem hoveredItem' : 'nonHoveredItem'}
        style={{
          left: linkMeta?.left ? linkMeta?.left + linkMeta?.width - 20 : 0,
          top: linkMeta?.top ? linkMeta?.top - 17 : 0,
        }}
        onMouseEnter={() => onRemoveHover()}
        onMouseLeave={() => onRemoveHoverOut()}
      >
        <div onClick={() => onEditLink(linkMeta)}>
          <Icon iconClass="edit" />
          <span>Edit Link</span>
        </div>
        <div onClick={() => setHasRemoved(true)}>
          <Icon iconClass="close" />
          <span>Remove Link</span>
        </div>
      </div>
      {/* <div
        style={{
          position: 'fixed',
          right: '10%',
          top: '40%',
          padding: '1rem',
          borderRadius: '1rem',
          width: '200px',
          height: '200px',
          backgroundColor: '#ccc',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <p style={{ fontSize: '2rem', fontWeight: '500' }}>
          hoveredLink: <strong>{hoveredLink ? 'true' : 'false'}</strong>
        </p>
        <p style={{ fontSize: '2rem', fontWeight: '500' }}>
          onRemove: <strong>{onRemove ? 'true' : 'false'}</strong>
        </p>
        <p style={{ fontSize: '2rem', fontWeight: '500' }}>
          hoverOut: <strong>{hoverOut ? 'true' : 'false'}</strong>
        </p>
      </div> */}
    </div>
  );
};

InlineEditor.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
  id: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  onDelete: PropTypes.func,
};
export default InlineEditor;
