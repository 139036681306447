import React, { useState } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
// import Card from '@components/organisms/card';

import IphoneMask from '@components/molecules/iphone-mask';
import Button from '@components/atoms/button';
import Api from '@api';
import waterfall from 'async/waterfall';
import ThreeDots from '@components/atoms/loaders/three-dots';
import { getToken, getTenant, getUserData } from '@helpers';

import Modal from '@components/molecules/modal';
import * as styles from '../../create-channel.module.scss';

const ChannelPreview = ({ current, send }) => {
  const { userId, name, streamId, description, category, tempImage, editing, channelState, image } =
    current.context;

  const tenantId = getTenant();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [onDone, setOnDone] = useState(false);

  const userDetails = getUserData();

  const variants = {
    start: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.2,
      },
    },
    done: {
      y: '-50%',
      transition: {
        duration: 1.2,
      },
    },
  };

  const handleCreateStream = async (cb) => {
    const response = await Api.createChannel(getToken(), {
      userId,
      name,
      description,
      streamState: 'unpublished',
      category,
      tempImage,
      tenantId,
      coverMedia: {
        items: image,
      },
    });

    cb(null, response);
  };

  const editStream = async (cb) => {
    const response = await Api.editChannelData(getToken(), {
      streamId,
      userId,
      tenantId,
      name,
      description,
      category,
      coverMedia: {
        items: image,
      },
    });

    cb(null, response);
  };

  const goToPublished = () => {
    send({ type: 'FINISH' });
  };

  const handleSaveStream = async () => {
    waterfall(
      [
        function (cb) {
          handleCreateStream(cb);
        },
      ],
      (err, result) => {
        if (!result.success || result.code) {
          setShowModal(true);
          setDisabled(false);
          setModalTitle('Can not create the channel');
          setDisabled(false);
          if (result.message) {
            setModalDesc(result.message);
          } else {
            setModalDesc('Something went wrong!');
          }
        }

        if (result.stream.streamId) {
          send({ type: 'SET_STREAM_ID', streamId: result.streamId });
          setOnDone(true);
          setTimeout(() => {
            goToPublished();
          }, 2000);
        }
      }
    );
  };

  const handleEditStream = async () => {
    waterfall(
      [
        function (cb) {
          editStream(cb);
        },
      ],
      (err, response) => {
        if (!response.success) {
          setShowModal(true);
          setOnDone(false);
          setDisabled(false);
          setModalTitle('Can not edit the channel');
          setModalDesc('Something went wrong!');
          return null;
        }
        setOnDone(true);
        setTimeout(() => {
          navigate('/app');
        }, 2500);
        return null;
      }
    );
  };

  const cancelModal = () => {
    setShowModal(false);
    setModalTitle('');
    setModalDesc('');
  };

  const handleModalAction = () => {
    setShowModal(false);
  };

  const onChannelDone = () => {
    setDisabled(true);
    if (editing && channelState !== 'autosaved') {
      handleEditStream();
    } else {
      handleSaveStream();
    }
  };

  const renderFinishButton = () =>
    !disabled ? (
      <Button btnClass="primary" disabled={disabled} onClick={() => onChannelDone()}>
        {editing && channelState !== 'autosaved' ? 'Save Edit' : 'Save'}
      </Button>
    ) : (
      <ThreeDots />
    );

  return (
    <>
      <motion.div
        initial="start"
        variants={variants}
        animate={onDone ? 'done' : 'start'}
        className={styles.motionContainer}
      >
        {showModal && (
          <Modal
            title={modalTitle}
            desc={modalDesc}
            type="error"
            showModal={showModal}
            onConfirm={() => handleModalAction()}
            onCancel={() => cancelModal()}
          />
        )}
        <div className={styles.stepperWrapper}>
          <div className={`${styles.titleWrapper} ${styles.pulledUpTitle} ${styles.centered}`}>
            <Button type="button" iconBtn>
              Channel Preview
            </Button>
          </div>
          <div className={`${styles.contentWrapper} ${styles.previewChannelWrapper}`}>
            <IphoneMask preview="channel">
              <div className={styles.channelPreview}>
                <h2 className={styles.previewTitle}>Justt</h2>
                <div className={styles.channels}>
                  <div className={styles.channelStack} />
                  <div className={styles.currentChannel}>
                    <div className={styles.author}>
                      <div className={styles.authorImg}>
                        <img
                          src={userDetails.avatar}
                          alt={userDetails.name}
                          width={30}
                          height={30}
                        />
                      </div>
                      <p>{userDetails.displayName}</p>
                    </div>
                    <img src={image[0]?.asset?.defaultUrl} alt={name} />
                    <div className={styles.channelDesc}>
                      <p>{description}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.channelInfo}>
                  <h3>{name}</h3>
                </div>
              </div>
            </IphoneMask>
          </div>
          <div className={styles.footerWrapper}>
            {!onDone ? renderFinishButton() : <h2 className={styles.doneTitle}>Done</h2>}
          </div>
        </div>
      </motion.div>
    </>
  );
};

ChannelPreview.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default ChannelPreview;
