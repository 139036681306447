// extracted by mini-css-extract-plugin
export var cardFormats = "card-format-module--cardFormats--1439b";
export var justtAccount = "card-format-module--justt-account--fc54e";
export var justtAdd = "card-format-module--justt-add--dc370";
export var justtAddVideo = "card-format-module--justt-add-video--f257c";
export var justtArrowLeft = "card-format-module--justt-arrow-left--03e20";
export var justtArrowRight = "card-format-module--justt-arrow-right--172f8";
export var justtArticleLink = "card-format-module--justt-article-link--daabc";
export var justtAspectRatio = "card-format-module--justt-aspect-ratio--4db38";
export var justtAutosave = "card-format-module--justt-autosave--38883";
export var justtBlur = "card-format-module--justt-blur--6b97c";
export var justtCalendar = "card-format-module--justt-calendar--a03bf";
export var justtCamera = "card-format-module--justt-camera--91205";
export var justtCards = "card-format-module--justt-cards--2f988";
export var justtChannel = "card-format-module--justt-channel--2d225";
export var justtChannels = "card-format-module--justt-channels--3aa5e";
export var justtCharacters = "card-format-module--justt-characters--bd611";
export var justtCircularAdd = "card-format-module--justt-circular-add--44a97";
export var justtClose = "card-format-module--justt-close--6b236";
export var justtCloseThin = "card-format-module--justt-close-thin--cd1ed";
export var justtComments = "card-format-module--justt-comments--d3b6b";
export var justtCreate = "card-format-module--justt-create--86a8b";
export var justtEdit = "card-format-module--justt-edit--71605";
export var justtFull = "card-format-module--justt-full--38a4c";
export var justtFullCardIcon = "card-format-module--justt-full-card-icon--5da9c";
export var justtFullscreen = "card-format-module--justt-fullscreen--1af06";
export var justtGlobe = "card-format-module--justt-globe--c33f8";
export var justtHidePassword = "card-format-module--justt-hide-password--250b1";
export var justtInfo = "card-format-module--justt-info--7a65e";
export var justtLink = "card-format-module--justt-link--caf90";
export var justtLinkArrow = "card-format-module--justt-link-arrow--13a0a";
export var justtLinkImage = "card-format-module--justt-link-image--ac42d";
export var justtLists = "card-format-module--justt-lists--d14f4";
export var justtLogout = "card-format-module--justt-logout--547af";
export var justtLoop = "card-format-module--justt-loop--e0765";
export var justtMedia = "card-format-module--justt-media--60b36";
export var justtMove = "card-format-module--justt-move--116de";
export var justtParagraphs = "card-format-module--justt-paragraphs--9df74";
export var justtPlay = "card-format-module--justt-play--e4071";
export var justtPreviewCard = "card-format-module--justt-preview-card--a5a3c";
export var justtPublished = "card-format-module--justt-published--9957c";
export var justtReposition = "card-format-module--justt-reposition--41ed7";
export var justtReverse = "card-format-module--justt-reverse--7568f";
export var justtSaved = "card-format-module--justt-saved--40845";
export var justtSeen = "card-format-module--justt-seen--a88a0";
export var justtSelected = "card-format-module--justt-selected--51eb8";
export var justtSettings = "card-format-module--justt-settings--fcc7f";
export var justtShare = "card-format-module--justt-share--d49a1";
export var justtShowPassword = "card-format-module--justt-show-password--cadb0";
export var justtSixteenNine = "card-format-module--justt-sixteen-nine--89ce7";
export var justtSolid = "card-format-module--justt-solid--0e812";
export var justtSortColored = "card-format-module--justt-sort-colored--f0c0c";
export var justtSpaces = "card-format-module--justt-spaces--b13e8";
export var justtStacked = "card-format-module--justt-stacked--01cc0";
export var justtText = "card-format-module--justt-text--330a0";
export var justtTheme = "card-format-module--justt-theme--9bd33";
export var justtTrash = "card-format-module--justt-trash--bcd18";
export var justtTrashLined = "card-format-module--justt-trash-lined--b0a88";
export var justtUnpublish = "card-format-module--justt-unpublish--3df4a";
export var justtVideo = "card-format-module--justt-video--23835";
export var justtVideoLink = "card-format-module--justt-video-link--bbf8f";
export var justtVideoMessage = "card-format-module--justt-video-message--f73fd";
export var path1 = "card-format-module--path1--18c7a";
export var path2 = "card-format-module--path2--0ab81";