/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useRef, Profiler } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'react-simple-snackbar';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import waterfall from 'async/waterfall';

// Image methods to functionalize
import Api from '@api';
import isLoadingHoc from '@components/hoc/isLoading';
import { uuidv4, getToken } from '@helpers';
import { readFile } from '@services/image';

// Custom Block Components are here
import EditorCarousel from '@components/organisms/editor-carousel';
import EditorImage from '@components/organisms/editor-image';
import EditorVideo from '@components/organisms/editor-video';
import ArticlePreview from '@components/molecules/article-preview';
import FacebookEmbed from '@components/molecules/embeds/facebook';
import SpotifyEmbed from '@components/molecules/embeds/spotify';
import InstagramEmbed from '@components/molecules/embeds/instagram';

import { Tweet } from 'react-twitter-widgets';

import InlineEditor from '@components/organisms/my-editor/components/inline-editor';
import InlineOptions from '@components/organisms/my-editor/components/inline-options';
import MediaButton from '@components/atoms/media-button';

import { InputTypes } from './helpers';
import Icon from '@components/atoms/icon';

// DraftJS Styling comes here
import 'draft-js/dist/Draft.css';
import './draft-editor.scss';
// import '@draft-js-plugins/side-toolbar/lib/plugin.css';

import * as style from './my-editor.module.scss';

const MyEditor = ({
  send,
  videoType,
  current,
  setLoading,
  endAnimation,
  setShowModal,
  setModalType,
  startLoader,
}) => {
  const {
    editing,
    blockTypeStyle,
    inlineStyle,
    currentLinkData,
    caretData,
    editorBlocks,
    toggleIndex,
    content,
    toggleMediaIndex,
    sequence,
    sequenceIndex,
    loadingUpload,
    selectedEntity,
    embedValues,
  } = current.context;

  const [shown, setShown] = useState(true);
  const [duplicate, setDuplicate] = useState(false);
  const [addingId, setAddingId] = useState(null);
  const [visible, setVisible] = useState(false);

  const singleImageUpload = useRef(null);
  const carouselImageUpload = useRef(null);
  const [open, close] = useSnackbar({
    position: 'bottom-left',
  });

  const changeContent = (obj) => {
    send({
      type: 'ONCHANGE_CONTENT',
      source: obj,
    });
  };

  const mediaInsertion = (type, source) => {
    const { editorIndex } = caretData;

    let components = [...content];
    let updatedComponent = { ...components[editorIndex] };

    const component = [
      {
        type,
        ...source,
      },
    ];

    let data = null;
    let firstEditor = null;
    let secondEditor = null;
    const emptyEditor = [
      {
        type: 'text',
        source: '<p></p>',
      },
    ];

    // If cursor at the very top, the media item will be inserted here
    // and the content will be moved down

    if (editorBlocks) {
      const { leftItems, rightItems } = editorBlocks;
      if (!leftItems) {
        firstEditor = {
          type: 'text',
          source: rightItems,
        };

        if (content[editorIndex - 1] && content[editorIndex - 1].type !== 'text') {
          data = [...emptyEditor, ...component, firstEditor];
        } else {
          data = [...component, firstEditor];
        }
      }

      // If the cursor at the very end, the media will be added after the editor
      // and thus will be pushed below

      if (!rightItems) {
        firstEditor = {
          type: 'text',
          source: leftItems,
        };

        if (
          (content[editorIndex + 1] && content[editorIndex + 1].type !== 'text') ||
          (content[editorIndex] && content[editorIndex].type === 'text')
        ) {
          data = [firstEditor, ...component, ...emptyEditor];
        } else {
          data = [firstEditor, ...component];
        }
      }

      // If we are having both leftItems and rightItems, then we do the split
      // while also adding the media item between these two
      if (leftItems && rightItems) {
        firstEditor = {
          type: 'text',
          source: leftItems,
        };
        secondEditor = {
          type: 'text',
          source: rightItems,
        };
        data = [firstEditor, ...component, secondEditor];
      }

      updatedComponent = data;
      // This logic needs to be refactored and we will be able to finalize the
      // overall construct of the modified components
      components = [
        ...components.slice(0, editorIndex),
        ...data,
        ...components.slice(editorIndex + 1),
      ];
    }

    if (!editorBlocks) {
      data = [
        {
          type,
          ...source,
        },
        {
          type: 'text',
          source: '<p></p>',
        },
      ];

      components = [...content, ...data];
    }

    return components;
  };

  const parseComponentData = (type, src) => {
    const newComponent = mediaInsertion(type, src);

    send({
      type: 'ADD_COMPONENT',
      newContent: newComponent,
    });

    setTimeout(() => {
      send({ type: 'ON_MEDIA_TOGGLE', index: toggleMediaIndex + 1 });
    }, 150);
  };

  const overOptions = (val) => {
    if (shown === false) {
      if (val === true) {
        setShown(true);
      } else {
        setTimeout(() => {
          setShown(false);
        }, 2000);
      }
    }
  };

  const hideInlineOptions = () => {
    if (!overOptions) {
      setTimeout(() => {
        setShown(false);
      }, 1500);
    }
  };

  const changeComponentData = (id, data) => {
    // const { type } = data;
    const components = [...content];
    let updatedComponent = { ...components[id] };
    updatedComponent = { ...components[id], items: [...data] };
    components[id] = updatedComponent;

    changeContent(components);
  };

  const addMediaToExistingItem = (id, obj) => {
    const components = [...content];
    const carouselData = content[id].items;
    let newData = [];

    if (obj.type === 'multi') {
      newData = [...obj.carousel, ...carouselData];
    } else {
      newData = [obj.block, ...carouselData];
    }

    const updatingComponent = { ...content[id] };
    updatingComponent.items = newData;
    components[id] = updatingComponent;

    changeContent(components);
    setAddingId(null);
  };

  const addPictures = (id) => {
    send({ type: 'SET_TOGGLE_INDEX', index: toggleIndex + 1 });
    send({ type: 'SET_BLOCKTYPE', blockTypeStyle: 'carousel' });
    setAddingId(id);
  };

  const getFilesFromEvent = (e) =>
    new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then(async (chosenFiles) => {
        const images = [];
        let mappedItems = null;

        const file = chosenFiles[0].fileObject;
        const type = file.type.split('/')[0];

        if (type === 'video') {
          const videoUrl = await readFile(file);
          mappedItems = {
            videoUrl,
          };
        } else {
          // eslint-disable-next-line no-lonely-if
          if (chosenFiles.length > 1) {
            for (const { fileObject } of chosenFiles) {
              const imageUrl = await readFile(fileObject);
              const imageObj = {
                originalFile: fileObject,
                originalUrlBlob: imageUrl,
                croppedFile: fileObject,
                croppedUrlBlob: imageUrl,
              };
              images.push(imageObj);
            }

            mappedItems = [...images];
          } else {
            const imageDataUrl = await readFile(file);
            mappedItems = {
              originalFile: file,
              originalUrlBlob: imageDataUrl,
              croppedFile: file,
              croppedUrlBlob: imageDataUrl,
            };
          }
        }
        resolve(mappedItems);
      });
    });

  const handleContentMediaUpload = (batch, multiple) => {
    setLoading(true);
    if (!multiple) {
      const { croppedFile } = batch;
      const ext = croppedFile.name.substr(croppedFile.name.lastIndexOf('.') + 1);

      const filename = `${uuidv4()}.${ext}`;
      const type = croppedFile.type.split('/')[0];

      // TODO refactor, move to image service
      const getSingleSignedUrl = async (cb) => {
        const response = await Api.getImageUploadUrl(getToken(), [{ key: filename }]);
        cb(null, response);
      };
      // TODO refactor, move to image service
      const addMediaToAws = async (storage, cb) => {
        try {
          await fetch(storage[0].url, {
            method: 'PUT',
            body: croppedFile,
          });
          cb(null, storage);
        } catch (error) {
          cb(error);
        }
      };
      // TODO refactor, move to image service
      const transformMediaContent = async (storage, cb) => {
        try {
          const result = await Api.transformMedia(getToken(), type, storage[0].key);
          cb(null, result);
        } catch (err) {
          cb(err);
        }
      };

      waterfall(
        [
          function (callback) {
            getSingleSignedUrl(callback);
          },
          function (storage, callback) {
            addMediaToAws(storage, callback);
          },
          function (storage, callback) {
            transformMediaContent(storage, callback);
          },
        ],
        (err, res) => {
          if (!err && res) {
            if (type === 'video') {
              const videoBlock = {
                type: 'media',
                items: [
                  {
                    type: 'video',
                    caption: '',
                    asset: {
                      defaultUrl: res.source,
                    },
                  },
                ],
                // key: res.key,
              };
              parseComponentData('media', videoBlock);
            } else {
              const imageBlock = {
                type: 'media',
                items: [
                  {
                    type: 'image',
                    caption: '',
                    asset: {
                      fullSizeUrl: res.original,
                      defaultUrl: res.source,
                      lowResUrl: res.previewSource,
                    },
                  },
                ],
              };

              if (!addingId) {
                parseComponentData('media', imageBlock);
              } else {
                const obj = {
                  type: 'single',
                  block: imageBlock,
                };
                addMediaToExistingItem(addingId, obj);
              }
            }
            send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null });
            endAnimation(true);
          }
        }
      );
    } else {
      const keys = batch.map((file, i) => {
        const { croppedFile } = file;
        const ext = croppedFile.name.substr(croppedFile.name.lastIndexOf('.') + 1);
        const key = `${uuidv4()}.${ext}`;
        return { key };
      });

      const getImageUploadUrl = async (cb) => {
        const response = await Api.getImageUploadUrl(getToken(), keys);
        cb(null, response);
      };

      const addFilesToAws = async (storage, cb) => {
        const promises = batch.map(async (file, i) => {
          try {
            const response = await fetch(storage[i].url, {
              method: 'PUT',
              body: file.croppedFile,
            });

            return response;
          } catch (err) {
            cb(err);
          }
        });

        await Promise.all(promises);
        cb(null, storage);
      };

      const transformAllMedia = async (storage, cb) => {
        const promises = storage.map(async (info, i) => {
          const type = batch[i].croppedFile.type.split('/')[0];
          try {
            const result = await Api.transformMedia(getToken(), type, storage[i].key);

            return result;
          } catch (err) {
            cb(err);
          }
        });

        let response = null;
        await Promise.all(promises).then((res) => {
          response = res;
        });
        cb(null, response);
      };

      waterfall(
        [
          function (callback) {
            getImageUploadUrl(callback);
          },
          function (storage, callback) {
            addFilesToAws(storage, callback);
          },
          function (storage, callback) {
            transformAllMedia(storage, callback);
          },
        ],
        (err, res) => {
          if (res.length > 1) {
            const carouselImages = {
              type: 'media',
              items: [],
            };
            res.map((image) => {
              carouselImages.items.push({
                type: 'image',
                asset: {
                  defaultUrl: image.source,
                  fullSizeUrl: image.original,
                  lowResUrl: image.previewSource,
                },
                caption: '',
              });

              return carouselImages;
            });

            if (!addingId) {
              parseComponentData('media', carouselImages);
            } else {
              const obj = {
                type: 'multi',
                carousel: carouselImages.items,
              };
              addMediaToExistingItem(addingId, obj);
            }

            send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null });
            endAnimation(true);
          }
        }
      );
    }
  };

  // TODO see the possibility of refactoring this to helper functions
  const handleFileUpload = (files) => {
    getFilesFromEvent(files).then((res) => {
      handleContentMediaUpload(res, res.length > 1);
    });
  };

  const mergeEditorData = (index, type) => {
    let prevItem = null;
    let nextItem = null;
    let arrayOfData = [...content];

    let newType = null;
    if (arrayOfData) {
      if (arrayOfData.length > index) {
        nextItem = arrayOfData[index + 1] ? arrayOfData[index + 1] : null;
      }

      if (index !== 0 && arrayOfData[index - 1]) {
        prevItem = arrayOfData[index - 1] ? arrayOfData[index - 1] : null;
      }
    }

    if (type === 'text') {
      let currentItem = arrayOfData[index];
      let beforePrevItem = arrayOfData[index - 2];

      if (currentItem.type === 'text' && beforePrevItem.type === 'text') {
        let editorSource = null;

        if (beforePrevItem.source === '<p></p>' && currentItem.source === '<p></p>') {
          editorSource = '<p></p>';
        }

        if (beforePrevItem.source !== '<p></p>' && currentItem.source === '<p></p>') {
          editorSource = beforePrevItem.source;
        }

        if (beforePrevItem.source === '<p></p>' && currentItem.source !== '<p></p>') {
          editorSource = currentItem.source;
        }

        if (beforePrevItem.source !== '<p></p>' && currentItem.source !== '<p></p>') {
          editorSource = beforePrevItem.source + currentItem.source;
        }

        newType = {
          source: editorSource,
          type: 'text',
        };

        return newType;
      }
    }

    if (prevItem && nextItem) {
      if (prevItem.type === 'text' && nextItem.type === 'text') {
        let editorSource = null;

        if (prevItem.source === '<p></p>' && nextItem.source === '<p></p>') {
          editorSource = '<p></p>';
        }

        if (prevItem.source !== '<p></p>' && nextItem.source === '<p></p>') {
          editorSource = prevItem.source;
        }

        if (prevItem.source === '<p></p>' && nextItem.source !== '<p></p>') {
          editorSource = nextItem.source;
        }

        if (prevItem.source !== '<p></p>' && nextItem.source !== '<p></p>') {
          editorSource = prevItem.source + nextItem.source;
        }

        newType = {
          source: editorSource,
          type: 'text',
        };

        return newType;
      }

      if (prevItem.type !== 'text') {
        let beforePrev = arrayOfData[index - 2];

        if (beforePrev.type === 'text') {
          if (beforePrev.source === '<p></p>') {
          }
        }
      }
    }

    return null;
  };

  const deleteItem = (idx, type) => {
    // FIXME name - in the event of using name value, move this as parameter of the function

    // Current logic is as follows: if the delete function gets called from the editor
    // due to empty backspace, it will simply delete one item, but if the item being deleted
    // is part of the media items: carousel, video, image then also delete the editor after that image.

    const arr = [...content];

    if (arr[idx]) {
      const slicedArray = [...arr.slice(0, idx), ...arr.slice(idx + 1)];
      const mergedEditors = mergeEditorData(idx, type);

      if (slicedArray.length >= 3) {
        let mergedArray = [];

        if (mergedEditors && mergedEditors.source) {
          if (type === 'text') {
            mergedArray = [
              ...slicedArray.slice(0, idx - 2),
              {
                ...mergedEditors,
              },
              ...slicedArray.slice(idx - 1),
            ];
          } else {
            mergedArray = [
              ...slicedArray.slice(0, idx - 1),
              {
                ...mergedEditors,
              },
              ...slicedArray.slice(idx + 1),
            ];
          }
          changeContent(mergedArray);
        } else {
          changeContent(slicedArray);
        }

        // if (mergedArray)
      } else if (slicedArray.length === 2) {
        if (slicedArray[0].type === 'text' && slicedArray[1].type === 'text') {
          changeContent([mergedEditors]);
        }

        if (slicedArray[0].type === 'text' && slicedArray[1].type !== 'text') {
          changeContent([mergedEditors]);
        }
      } else {
        arr.splice(idx, 1);
        changeContent(arr);
      }

      send({ type: 'ON_MEDIA_TOGGLE', index: toggleMediaIndex + 1 });
    }
  };

  const onTabEvent = (idx) => {
    const nextId = idx + 1;
    const newEditorComponent = [
      {
        type: 'text',
        focused: true,
      },
    ];

    if (!content[nextId]) {
      const newContent = [...content, ...newEditorComponent];
      changeContent(newContent);
    }
  };

  const isImageFile = (type) => {
    if (type === 'image') {
      return (
        <label htmlFor="singleFileUpload">
          Single Upload
          <input
            type="file"
            tabIndex="-1"
            accept="image/png,image/jpg,image/jpeg,image/webp"
            id="singleFileUpload"
            ref={singleImageUpload}
            onChange={(value) => handleFileUpload(value)}
          />
        </label>
      );
    }
    if (type === 'carousel') {
      return (
        <label htmlFor="multipleFileUpload">
          Multi Upload
          <input
            type="file"
            tabIndex="-1"
            accept="image/png,image/jpg,image/jpeg,image/webp"
            id="multipleFileUpload"
            multiple
            min={2}
            ref={carouselImageUpload}
            onChange={(value) => handleFileUpload(value)}
          />
        </label>
      );
    }
    return null;
  };

  const togglePictureSequence = (id) => {
    if (!sequence) {
      send({ type: 'VIEW_SEQUENCE', sequence: true });
    } else if (sequenceIndex === id) {
      send({ type: 'VIEW_SEQUENCE', sequence: false });
      setTimeout(() => send({ type: 'SET_SEQUENCE', index: null }), 100);
    }

    if (!sequenceIndex || sequenceIndex !== id) {
      send({ type: 'SET_SEQUENCE', index: id });
    }
  };

  const checkBlockType = (val) => {
    if (InputTypes.includes(val)) {
      return val === 'image'
        ? singleImageUpload.current.click()
        : carouselImageUpload.current.click();
    }

    if (val === 'video' || val === 'externalContent') {
      if (val === 'video') {
        setModalType('editor-video');
      } else {
        setModalType('editor-tweet-link');
      }
      return setShowModal(true);
    }

    if (val === 'editingLink' || (val === 'externalLink' && selectedEntity)) {
      setModalType('editor-link');
      return setShowModal(true);
    }

    return null;
  };

  const renderMediaComponent = (data, idx) => {
    const length = data.length;
    if (length === 1) {
      const type = data[0].type;
      if (type === 'image') {
        return (
          <EditorImage
            data={data}
            id={idx}
            send={send}
            editing={editing}
            setData={(val) => changeComponentData(idx, val)}
            tabEvent={() => onTabEvent(idx)}
            onDelete={() => deleteItem(idx, 'image')}
          />
        );
      }

      if (type === 'video') {
        return (
          <EditorVideo
            data={data}
            id={idx}
            setData={(val) => changeComponentData(idx, val)}
            tabEvent={() => onTabEvent(idx)}
            onDelete={() => deleteItem(idx, 'video')}
          />
        );
      }

      if (type === 'audio') {
        return <p>'audio-component'</p>;
      }

      if (type === 'link') {
        return <p>'link-component'</p>;
      }
    }

    if (length > 1) {
      return (
        <EditorCarousel
          data={data}
          id={idx}
          editing={editing}
          send={send}
          setData={(val) => changeComponentData(idx, val, 'media')}
          onAddImages={() => addPictures(idx)}
          onToggleOrder={() => togglePictureSequence(idx)}
          tabEvent={() => onTabEvent(idx)}
          onDelete={() => deleteItem(idx, 'carousel')}
        />
      );
    }
  };

  const renderEditorContent = (type, componentData, idx) => {
    switch (type) {
      case 'media':
        return renderMediaComponent(componentData.items, idx);
      case 'text':
        return (
          <InlineEditor
            data={componentData}
            id={idx}
            current={current}
            send={send}
            duplicateItems={duplicate}
            inlineStyle={inlineStyle}
            setFocused
            onDelete={() => deleteItem(idx, 'text')}
          />
        );
      case 'articlePreview':
        return <ArticlePreview data={componentData.articlePreview} editorView />;
      case 'spotifyEmbed':
        return <SpotifyEmbed data={componentData.spotifyEmbed} />;
      case 'facebookEmbed':
        return <FacebookEmbed data={componentData.facebookEmbed} />;
      case 'InstagramEmbed':
        return <InstagramEmbed data={componentData.instagramEmbed} />;
      case 'externalContent':
        return (
          <div className={style.twitterWrapper}>
            <MediaButton icon="trash" onBtnClick={() => deleteItem(idx, 'externalContent')} />
            <Tweet tweetId={componentData.source} />
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (videoType && blockTypeStyle) {
      const videoData = {
        type: 'media',
        items: [
          {
            type: 'video',
            caption: '',
            asset: {
              highResThumbnailUrl: videoType.thumbnail || '',
              defaultUrl: videoType.source,
            },
          },
        ],
      };

      if (blockTypeStyle === 'externalLink' && selectedEntity) {
        send({ type: 'SET_URL_VALUE', urlValue: videoType });
      } else {
        if (blockTypeStyle === 'externalContent') {
          parseComponentData('externalContent', videoType);
        } else {
          if (blockTypeStyle !== 'externalLink') {
            parseComponentData('media', videoData);
          }
        }
      }
    }
  }, [toggleIndex, videoType, startLoader]);

  useEffect(() => {
    if (blockTypeStyle && !embedValues) {
      if (blockTypeStyle === 'externalLink' && !selectedEntity) {
        send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null });
        setTimeout(() => {
          close();
        }, 3000);
        return open('You need to select text, to add a link!');
      }
      checkBlockType(blockTypeStyle);
    }

    if (blockTypeStyle === 'externalContent') {
      if (embedValues && embedValues?.type) {
        setShowModal(false);
        if (embedValues && embedValues?.embed) {
          // FIXME adapt this type of media content according to the new contents of media
          const valuesFetched = {
            platform: embedValues.platform,
            source: embedValues.source,
          };
          parseComponentData('video', valuesFetched);
        } else {
          parseComponentData('externalContent', embedValues);
        }

        send({ type: 'SET_EMBED_VALUES', embedValues: null });
        send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null });
      }
    }
  }, [blockTypeStyle, embedValues]);

  useEffect(() => {
    if (currentLinkData?.entityKey) {
      checkBlockType('editingLink');
    }
  }, [currentLinkData?.entityKey]);

  const createNewEditor = (currentIndex) => {
    const arr = [...content];
    const mutatedArray = [
      ...arr.slice(0, currentIndex + 1),
      {
        type: 'text',
        source: '<p></p>',
      },
      ...arr.slice(currentIndex + 1),
    ];
    changeContent(mutatedArray);
  };

  useEffect(() => {
    if (loadingUpload) {
      setLoading(true);
    } else {
      endAnimation(true);
    }
  }, [loadingUpload]);

  const renderBubbleItem = (array, idx) => {
    let showBubble = false;
    const currentItem = array[idx];
    const arrayLength = array.length;
    const lastItem = arrayLength - 1;

    const currentItemType = currentItem.type;
    const nextItem = array.length > idx + 1 ? array[idx + 1] : null;
    const nextItemType = nextItem ? nextItem.type : null;

    if (
      (currentItemType !== 'text' && nextItemType !== 'text') ||
      (currentItemType !== 'text' && lastItem === idx)
    ) {
      showBubble = true;
    }

    return showBubble ? (
      <div
        className={`${style.additionalSpace} ${visible && style.visibleButton}`}
        onMouseOver={() => setVisible(true)}
        onMouseOut={() => setVisible(false)}
      >
        <div className={style.overlayBtn} onClick={() => createNewEditor(idx)}>
          <Icon iconClass="add" />
          <p>Add Space</p>
        </div>
      </div>
    ) : (
      ''
    );
  };
  // function onRenderCallback(
  //   id, // the "id" prop of the Profiler tree that has just committed
  //   phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  //   actualDuration, // time spent rendering the committed update
  //   baseDuration, // estimated time to render the entire subtree without memoization
  //   startTime, // when React began rendering this update
  //   commitTime, // when React committed this update
  //   interactions // the Set of interactions belonging to this update
  // ) {
  //   // Aggregate or log render timings...
  // }

  useEffect(() => {
    let hideTimer;
    if (!shown) {
      setTimeout(() => setShown(false), 5000);
    }
    return () => clearTimeout(hideTimer);
  }, []);

  return (
    <>
      {/* <Profiler id="editorProfile" onRender={onRenderCallback}> */}
      <div className={style.editorContent}>
        {blockTypeStyle && (
          <div className={style.hiddenInputs} tabIndex="-1">
            {isImageFile(blockTypeStyle)}
          </div>
        )}
        <div className={style.componentsWrapper}>
          {content.map((item, idx) => (
            <div
              className={`${style.childComponent} editorArea`}
              key={idx}
              style={{ width: '100%' }}
              name=""
            >
              {renderEditorContent(item.type, JSON.parse(JSON.stringify(item)), idx)}
              {renderBubbleItem(content, idx)}
            </div>
          ))}
        </div>
      </div>
      <div
        className={style.parentWrap}
        onMouseEnter={() => overOptions(true)}
        onMouseLeave={() => {
          overOptions(false);
          hideInlineOptions();
        }}
      >
        <InlineOptions
          isShown={shown}
          send={send}
          current={current}
          onToggleBlocks={(val) => setDuplicate(val)}
        />
      </div>
      {/* </Profiler> */}
    </>
  );
};

MyEditor.propTypes = {
  current: PropTypes.shape(),
  videoType: PropTypes.shape(),
  send: PropTypes.func,
  setShowModal: PropTypes.func,
  setLoading: PropTypes.func,
  setModalType: PropTypes.func,
  startLoader: PropTypes.bool,
  endAnimation: PropTypes.func,
};

export default isLoadingHoc(MyEditor);
