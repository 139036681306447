import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import Counter from '@components/atoms/character-counter';

import * as styles from './input.module.scss';

const Input = ({
  customClass,
  maxLength = 100,
  maxRows = 3,
  minRows,
  placeholder,
  name,
  value,
  valueChange,
  hasVideo,
  onKeyDownChange,
  hasIndicator,
}) => {
  const inputRef = useRef(null);

  return (
    <div className={styles.inputWrapper}>
      <TextareaAutosize
        className={`${styles[customClass]} ${styles.input} ${hasVideo && styles.videoOver}`}
        minRows={minRows || '1'}
        maxRows={maxRows}
        name={name}
        ref={inputRef}
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={() => valueChange(inputRef.current.value)}
        onKeyDown={onKeyDownChange}
      />
      {hasIndicator ? (
        <div className={styles.counterWrapper}>
          <Counter totalValue={maxLength} changedValue={value.length} startValue={0} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

Input.propTypes = {
  valueChange: PropTypes.func,
  value: PropTypes.string,
  hasVideo: PropTypes.bool,
  customClass: PropTypes.string,
  maxRows: PropTypes.number,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  onKeyDownChange: PropTypes.func,
};

export default Input;
