import React from 'react';

import { Router } from '@reach/router';
import { AnimatePresence } from 'framer-motion';

// Authentication, Layout and Private Routes
import Layout from '@components/hoc/layout';
import PrivateRoute from '@components/molecules/private-routes';

// Channel Routes
import Channels from '@components/templates/channel-listings';
import NoChannels from '@components/templates/no-channel';
import CreateChannel from '@components/templates/create-channel';
import ChannelCards from '@components/templates/channel-cards';
import Trash from '@components/templates/trash';
import Autosaved from '@components/templates/autosaved';
// Card Routes
import Cards from '@components/templates/card-listings';
import CreateCard from '@components/templates/create-card';
import SelectCreate from '@components/organisms/select-create';
import MoveToChannel from '@components/templates/move-to-channel';

import Spaces from '@components/templates/spaces';
import Profile from '@components/templates/profile';
// Not Found Page
import NotFoundPage from '../404';

const App = () => (
  <Layout>
    <AnimatePresence exitBeforeEnter>
      <Router primary={false} basepath="/app">
        <NotFoundPage default />
        <PrivateRoute path="/" component={Channels} />
        <PrivateRoute path="/cards" component={Cards} />
        <PrivateRoute path="/channel-cards/:id" component={ChannelCards} />
        <PrivateRoute path="/move-to-channel/:id" component={MoveToChannel} />
        <PrivateRoute path="/create" component={SelectCreate} />
        <PrivateRoute path="/no-channels" component={NoChannels} />
        <PrivateRoute path="/create-card" component={CreateCard} />
        <PrivateRoute path="/trash/:pageType" component={Trash} />
        <PrivateRoute path="/autosaved/" component={Autosaved} />
        <PrivateRoute path="/edit-card/:subformat/:id" component={CreateCard} />
        <PrivateRoute path="/create-channel" component={CreateChannel} />
        <PrivateRoute path="/edit-channel/:channelId" component={CreateChannel} />
        <PrivateRoute path="/spaces" component={Spaces} />
        <PrivateRoute path="/profile" component={Profile} />
      </Router>
    </AnimatePresence>
  </Layout>
);

export default App;
