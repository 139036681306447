import React from 'react';
import PropTypes from 'prop-types';

const FacebookEmbed = ({ source }) => {
  return (
    <iframe
      src={`https://www.facebook.com/plugins/post.php?href=${source}&show_text=true&width=500`}
      width="500"
      height="590"
      style="border:none;overflow:hidden"
      scrolling="no"
      frameborder="0"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    ></iframe>
  );
};

FacebookEmbed.propTypes = {
  source: PropTypes.string.isRequired,
};

export default FacebookEmbed;
