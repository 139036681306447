import React from 'react';
import PropTypes from 'prop-types';

import {
  mediaItemWrapper,
  mediaOptions,
  mediaItem,
  captionWrapper,
} from '@helpers/main.module.scss';

import ReactPlayer from 'react-player';
import Input from '@components/atoms/input';
import MediaButton from '@components/atoms/media-button';

const EditorVideo = ({ data, setData, onDelete, tabEvent }) => {
  const onCaptionChange = (property) => {
    const items = [...data];
    const item = {
      ...items[0],
      ...property,
    };

    items[0] = item;
    setData(items);
  };

  const handleKeyChange = (val) => {
    if (val.keyCode === 13 && val.shiftKey === false) {
      val.preventDefault();
    }

    if (val.keyCode === 9) {
      tabEvent();
    }
  };

  return (
    <div className={mediaItemWrapper}>
      <div className={mediaItem}>
        <div className={mediaOptions}>
          <MediaButton icon="trash" onBtnClick={() => onDelete()} />
        </div>
        <ReactPlayer
          url={data[0].asset.defaultUrl}
          controls
          config={{
            youtube: {
              playerVars: { showInfo: 1 },
              height: '220px',
            },
            file: {
              attributes: {
                className: 'video-player',
              },
            },
            vimeo: {
              playerVars: {
                portrait: false,
                width: '375px',
              },
            },
          }}
          width={375}
          height={220}
        />
      </div>
      <div className={captionWrapper}>
        <Input
          placeholder="Caption"
          customClass="captionInput"
          maxRows={2}
          value={data.caption}
          valueChange={(val) => onCaptionChange({ caption: val })}
          onKeyDownChange={(evt) => handleKeyChange(evt)}
        />
      </div>
    </div>
  );
};

EditorVideo.propTypes = {
  data: PropTypes.shape(),
  tabEvent: PropTypes.func,
  onDelete: PropTypes.func,
  setData: PropTypes.func,
  // id: PropTypes.number,
};

export default EditorVideo;
