import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { loadingWrapper, greenColor, failedColor } from './isloading.module.scss';

const loadingCircleVariants = {
  start: {
    width: '0%',
  },
  second: {
    width: '95%',
    transition: {
      duration: 10,
    },
  },
  end: {
    width: '100%',
    transition: {
      duration: 1.5,
    },
  },
};

export const isLoadingHoc = (WrappedComponent) => {
  function HOC(props) {
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);
    const [endAnimation, setEndAnimation] = useState(false);

    const setLoadingState = (isComponentLoading) => {
      setLoading(isComponentLoading);
    };

    const setFailedState = (failedEnding) => {
      setFailed(failedEnding);
    };

    const onEndAnimation = (endingAnimation) => {
      setEndAnimation(endingAnimation);
      setTimeout(() => {
        setLoading(false);
        setEndAnimation(false);
      }, 1600);
    };

    return (
      <>
        {loading && (
          <div className={loadingWrapper}>
            <motion.div
              className={`${greenColor} ${failed && failedColor}`}
              initial="start"
              variants={loadingCircleVariants}
              animate={endAnimation ? 'end' : 'second'}
            />
          </div>
        )}
        <WrappedComponent
          {...props}
          setLoading={setLoadingState}
          endAnimation={onEndAnimation}
          onFailed={setFailedState}
        />
      </>
    );
  }

  return HOC;
};

export default isLoadingHoc;
