/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import Api from '@api';
import Modal from '@components/molecules/modal';
import SEO from '@components/seo';
import useSWR, { mutate } from 'swr';
import { Link } from '@reach/router';
import { getToken, getTenant, getUserId, placeholders } from '@helpers';
import { waterfall } from 'async';
import { checkState } from '@components/templates/create-card/helpers';
import Loading from '@components/atoms/loading';
import ThreeDots from '@components/atoms/loaders/three-dots';
import Placeholder from '@components/atoms/placeholder';
import Card from '@components/organisms/card';
import Button from '@components/atoms/button';
import * as styles from '../../create-new-card.module.scss';

import { useBottomScrollListener } from 'react-bottom-scroll-listener';

// TODO have a look at the possibility of editing a card
// to either include a media, or not depending on the
// changed information of the media object // TODO

const SelectChannel = ({ current, send }) => {
  const [emptyData, setEmptyData] = useState(false);
  const [prevStatus, setPrevStatus] = useState('');
  const [cardFilter, setCardFilter] = useState('all');
  const [order, setOrder] = useState('DESC');
  const [cards, setCards] = useState([]);
  const [initialScroll, setInitialScroll] = useState(false);

  // page indexes
  const [prevPage, setPrevPage] = useState(1);
  const [pageIndex, setPageIndex] = useState(1);

  // fetching mechanism
  const [fetching, setFetching] = useState(false);
  const [noFetching, setNoFetching] = useState(false);

  // Modal Toggle
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');

  const {
    isForSaving,
    isForPublishing,
    userId,
    cardId,
    cardState,
    streamId,
    format,
    subFormat,
    title,
    description,
    media,
    content,
  } = current.context;

  const tenantId = getTenant();
  const { data, error } = useSWR(
    ['/listContentCreationStreams', cardFilter, order, pageIndex],
    () => Api.listContentCreationStreams(cardFilter, order, pageIndex)
  );

  const setModalValues = (mTitle, mDesc) => {
    setShowModal(true);
    setModalTitle(mTitle);
    setModalDesc(mDesc);
  };

  const handlePublish = async (storage, cb) => {
    try {
      const response = await Api.publishChannel(getToken(), {
        streamId,
        userId: getUserId(),
        tenantId,
      });

      if (response && response.code) {
        throw new Error(response.message);
      }

      mutate('/listContentCreationStreams');
      mutate('/listContentCreationCards');

      cb(null, storage ? storage : response);
    } catch (err) {
      cb(err);
    }
  };

  const createCard = async (cb) => {
    try {
      const response = await Api.createCard(getToken(), {
        userId,
        tenantId,
        streamId,
        title,
        description,
        format,
        subFormat,
        content_v2: content,
        coverMedia: {
          items: media,
        },
        cardState: isForSaving ? 'unpublished' : 'published',
      });

      cb(null, response);
    } catch (err) {
      cb(err);
    }
  };

  const updateCard = async (cb) => {
    try {
      const cardData = {
        cardId,
        userId,
        cardState: 'published',
        title,
        tenantId,
        streamId,
        description,
        format,
        subFormat,
        content_v2: content,
        coverMedia: {
          items: media,
        },
      };

      const response = await Api.updateEditedCard(getToken(), cardData);
      cb(null, response);
    } catch (err) {
      cb(err);
    }
  };

  const handleCreateCard = () => {
    if (isForPublishing) {
      if (cardId && cardState === 'autosaved') {
        return waterfall(
          [
            function (callback) {
              updateCard(callback);
            },
            function (storage, callback) {
              handlePublish(storage, callback);
            },
          ],
          (err, res) => {
            if (err || !res?.success) {
              return setModalValues(
                'Can not publish the autosaved card',
                err?.message || 'Something went wrong'
              );
            }
            send('PUBLISH');
            return send('NEXT');
          }
        );
      }
      return waterfall(
        [
          function (callback) {
            createCard(callback);
          },
          function (storage, callback) {
            handlePublish(storage, callback);
          },
        ],
        (err, res) => {
          if (err || !res?.success) {
            return setModalValues(
              'Can not create the card',
              err?.message || 'Something went wrong'
            );
          }
          send('PUBLISH');
          return send('NEXT');
        }
      );
    }

    if (isForSaving) {
      return waterfall(
        [
          function (callback) {
            createCard(callback);
          },
        ],
        (err, res) => {
          if (err || !res?.success) {
            return setModalValues('Can not save the card', err?.message || 'Something went wrong');
          }

          send('SAVE');
          return send('NEXT');
        }
      );
    }
  };

  const cancelModal = () => {
    setShowModal(false);
    setModalTitle('');
    setModalDesc('');
  };

  const handleModalAction = () => {
    setShowModal(false);
  };

  const onScrollEnd = () => {
    if (!noFetching) {
      setFetching(true);
      if (!fetching) {
        setPageIndex(pageIndex + 1);
      }
    }
  };

  useBottomScrollListener(onScrollEnd);

  useEffect(() => {
    if (!initialScroll) {
      window.scrollTo(0, 0);
      setInitialScroll(true);
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        setEmptyData(true);
        if (cards.length > 0) {
          setNoFetching(true);
        }

        if (prevPage === pageIndex && data.length === 0) {
          setCards([]);
        }
      } else {
        setNoFetching(false);
        if (pageIndex === prevPage) {
          setCards(data);
          setFetching(false);
        } else {
          const newUpdatedValues = [...cards, ...data];
          setCards(newUpdatedValues);
          setPrevPage(pageIndex);
          setFetching(false);
        }
      }
    }
  }, [data, error]);

  const selectChannel = (id) => {
    setDisabled(true);
    if (!disabled) {
      send({
        type: 'SET_STREAM_ID',
        streamId: id,
      });
    }
  };

  const handleExitOperation = () => {
    setModalValues(
      'Incomplete Operation',
      'You need to select a channel, to continue the operation'
    );
  };

  useEffect(() => {
    if (streamId && streamId !== 'autosaved') {
      handleCreateCard();
    }
    // eslint-disable-next-line
  }, [streamId]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <SEO title="Select Channel to Publish Card | Justt CCD" />
      {showModal && (
        <Modal
          title={modalTitle}
          desc={modalDesc}
          type="error"
          showModal={showModal}
          onConfirm={() => handleModalAction()}
          onCancel={() => cancelModal()}
        />
      )}
      <div className={styles.cardContent}>
        <div className={styles.titleWrapper}>
          <h2>Select a Channel</h2>
        </div>
        <div
          className={`
          ${styles.cardsWrapper} 
          ${styles.selectChannel} 
          ${styles.selectCard} 
          ${emptyData ? styles.fullHeight : ''}
          `}
        >
          {cards &&
            cards.length > 0 &&
            cards.map((card) => (
              <div
                role="button"
                key={card.streamId}
                tabIndex="-1"
                onClick={() => selectChannel(card.streamId)}
              >
                <Card
                  isChannel
                  date={card.created}
                  status={card.streamState}
                  src={card?.coverMedia?.items ? card?.coverMedia?.items[0] : null}
                  id={card.streamId}
                  title={card.name}
                  description={card.description}
                />
              </div>
            ))}

          {data && !noFetching && cards.length >= 10 ? (
            <div className={styles.loadMoreCards}>
              <Button btnClass="primary" onClick={() => setPageIndex(pageIndex + 1)}>
                View More
              </Button>
            </div>
          ) : null}

          {!data && cards.length > 0 && (
            <div className={styles.incomingCards}>
              <h5>Loading New Channels</h5>
              <ThreeDots />
            </div>
          )}

          {!data && cards.length === 0 && placeholders.map((item) => <Placeholder key={item.id} />)}

          {data && cards.length === 0 && data.length === 0 ? (
            <div className={styles.noData}>
              <h3>Currently you don't have any channels</h3>
              <p>Try creating one and they will appear here</p>
              <Link to="/app/create-channel">
                <Button btnClass="primary">Create Channel</Button>
              </Link>
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.deactivatedRoutes} onClick={() => handleExitOperation()} />
    </motion.div>
  );
};

SelectChannel.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default SelectChannel;
