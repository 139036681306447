/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Input from '@components/atoms/input';
import MyEditor from '@components/organisms/my-editor';
import Modal from '@components/molecules/modal';

import { useHotkeys } from 'react-hotkeys-hook';
import { formatDateLabels, getUserData, truncateString } from '@helpers';
import GalleryLightbox from '@components/organisms/lightbox';

import * as styles from './editor-content.module.scss';

const EditorContent = ({ current, send }) => {
  const {
    title,
    description,
    content,
    lightbox,
    gallery,
    currentLinkData,
    media,
    subFormat,
    toggleIndex,
    editing,
    author,
    created,
  } = current.context;

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('editor-video');
  const [videoTypeVal, setVideoValues] = useState(null);
  const [startLoader, setStartLoader] = useState(false);
  const [showGallery, setShowGallery] = useState(false);

  const currentUser = getUserData();
  const [refresh, setRefresh] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);

  const createDate = new Date();

  const titleChange = (value) => {
    send({ type: 'ONCHANGE_TITLE', title: value });

    if (value.length > 0) {
      send('NEXT_STATE');
    } else {
      send({ type: 'SET_DISABLED', disabled: true });
    }

    send({ type: 'SET_TOGGLE_INDEX', index: toggleIndex + 1 });
  };

  const descChange = (value) => {
    send({ type: 'ONCHANGE_DESCRIPTION', description: value });
    if (value.length > 0) {
      send('NEXT_STATE');
    }

    send({ type: 'SET_TOGGLE_INDEX', index: toggleIndex + 1 });
  };

  const preventEnterOnKeyDown = (evt) => {
    if (evt.keyCode === 13 && evt.shiftKey === false) {
      evt.preventDefault();
    }
  };

  const onClosingModal = () => {
    setShowModal(false);
    if (modalType === 'editor-link' || modalType === 'editor-video') {
      setTimeout(() => send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null }), 500);
    }
  };

  const updateLinkData = () => {};

  const uploadVideoValues = (val) => {
    if (val.type !== 'file') {
      setVideoValues(val);
    }

    setTimeout(() => {
      setShowModal(false);
      setVideoValues(null);
      send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null });
    }, 300);
  };

  useEffect(() => {
    if (lightbox) {
      setShowGallery(true);
    } else {
      setShowGallery(false);
    }
  }, [lightbox, gallery, modalType]);

  const onCloseModal = () => {
    setShowModal(false);
    send({ type: 'IS_EXITING', exit: false });
  };

  useHotkeys('f5', (evt) => {
    evt.preventDefault();
    setRefresh(true);
  });

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  useEffect(() => {
    if (
      refresh &&
      (media.length > 0 ||
        title.length ||
        (content.length === 1 && content[0].source !== '<p></p>'))
    ) {
      setShowModal(true);
      setRefreshModal(true);
    }
  }, [refresh]);

  return (
    <div className={`${styles.editorWrapper} ${subFormat === 'Text' && styles.roundedBorders}`}>
      {showModal && !refreshModal ? (
        <Modal
          type={modalType}
          showModal={showModal}
          linkData={currentLinkData}
          onStartLoader={() => setStartLoader(true)}
          onConfirm={(val) =>
            currentLinkData?.entityKey ? updateLinkData(val) : uploadVideoValues(val)
          }
          onCancel={() => onClosingModal()}
        />
      ) : (
        ''
      )}

      {refreshModal ? (
        <Modal
          type="save-work"
          title="Cancel Card Creation"
          isEditing={editing}
          showModal={showModal}
          onCancel={() => navigate(-1)}
          onConfirm={() => setShowModal(false)}
          onClose={() => onCloseModal()}
        />
      ) : (
        ''
      )}

      {showGallery && <GalleryLightbox current={current} send={send} />}
      <div
        className={`${styles.cardHeaderInputs} ${subFormat === 'Text' ? styles.textLayout : null}`}
      >
        <Input
          customClass="titleInput"
          placeholder="Title"
          value={title}
          maxLength={75}
          maxRows={6}
          valueChange={(val) => titleChange(val)}
          onKeyDownChange={preventEnterOnKeyDown}
          hasIndicator
        />
        <Input
          customClass="descriptionInput"
          placeholder="Subtitle"
          maxLength={110}
          maxRows={5}
          value={description}
          hasIndicator
          valueChange={(val) => descChange(val)}
          onKeyDownChange={(evt) => preventEnterOnKeyDown(evt)}
        />
        {!editing ? (
          <span tabIndex="-1" className={styles.contentCreator}>
            {truncateString(
              currentUser.displayName || currentUser.fullName || currentUser.username,
              40
            )}{' '}
            • {formatDateLabels(createDate)}
          </span>
        ) : (
          <span tabIndex="-1" className={styles.contentCreator}>
            {author.name} • {formatDateLabels(created)}
          </span>
        )}
      </div>

      <MyEditor
        videoType={videoTypeVal}
        setShowModal={setShowModal}
        setModalType={setModalType}
        current={current}
        startLoader={startLoader}
        send={send}
      />
    </div>
  );
};

EditorContent.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
  // parentClassName: PropTypes.string,
};

export default EditorContent;
