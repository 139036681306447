import DeepDiff from 'deep-diff';

// import { waterfall } from 'async';
// import { getToken } from '@helpers';
import { getToken } from '@helpers';
import Api from '@api';

export const windowOffsetHeight = (elClass) => {
  let elementHeight = 0;
  let windowHeight = 0;
  const headerHeight = 100;
  const bottomPadding = 20;
  const editorPadding = 30;
  const inlineOptionsHeight = 86;

  elementHeight = document.getElementsByClassName(elClass)[0].offsetHeight;
  windowHeight = window.outerHeight;

  const calculateDistance = () =>
    windowHeight -
    elementHeight -
    headerHeight -
    bottomPadding -
    editorPadding -
    inlineOptionsHeight;

  return calculateDistance();
};

export const readFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

export const ArticleLinkSteps = [
  {
    id: '1',
    fieldValue: 'original',
    name: 'Original Link',
  },
  {
    id: '2',
    fieldValue: 'renamed',
    name: 'Renamed Link',
  },
  {
    id: '3',
    fieldValue: 'preview',
    name: 'Article Preview',
  },
];

export const VideoRecommendationSteps = [
  {
    id: '1',
    name: 'Link',
    fieldValue: 'original',
  },
  {
    id: '2',
    name: 'Renamed Link',
    fieldValue: 'renamed',
  },
];

export const VideoMessageSteps = [
  {
    id: '1',
    name: 'Default',
  },
  {
    id: '2',
    name: 'Headline',
  },
];

export const hasCurationInfo = ['VideoLink', 'ArticleLink'];

export const hasCurationLink = ['ArticleLink', 'VideoRecommendation'];

export const onScrollDirection = () => {
  let scrollPosition = 0;
  let direction = 'none';
  if (document.body.getBoundingClientRect().top > scrollPosition) {
    direction = 'UP';
  } else {
    direction = 'DOWN';
  }
  // saves the new position for iteration.
  scrollPosition = document.body.getBoundingClientRect().top;
  return direction;
};

export const compareObjectChanges = (a, b) => {
  const deepChange = DeepDiff(a, b);
  if (deepChange === undefined) return [];
  return deepChange;
};

export const dataFetchingObject = (cardData) => {
  const fetchedData = {
    cardId: cardData.cardId,
    userId: cardData.userId,
    tenantId: cardData.tenantId,
    title: cardData.title,
    content: cardData.content,
    subFormat: cardData.subFormat,
    format: cardData.format,
    media: [
      {
        previewSource: cardData.media[0].previewSource,
        previewThumbnail: cardData.media[0].previewThumbnail,
        thumbnail: cardData.media[0].thumbnail,
        source: cardData.media[0].source,
        type: cardData.media[0].type,
      },
    ],
    files: cardData.media,
    author: cardData.author,
    created: cardData.created,
    streamId: cardData.streamId,
  };

  return fetchedData;
};

export const populateMediaObject = (item, filteredMedia) => {
  let itemData = {
    type: item.type,
  };

  if (item.source) {
    itemData = {
      ...itemData,
      source: item.source,
      previewSource: item.previewSource,
      original: item.previewSource,
    };
  }

  if (item.aspectRatio) {
    itemData = {
      ...itemData,
      aspectRatio: item.aspectRatio,
    };
  }

  if (item.mediaId) {
    itemData = {
      ...itemData,
      mediaId: item.mediaId,
    };
  }

  if (item.metadata) {
    itemData = {
      ...itemData,
      metadata: item.metadata,
    };
  }

  if (item.cropped && item.croppedKey) {
    // FIXME need to get rid of this
    itemData = {
      ...itemData,
      original: {
        key: item.key,
      },
      cropped: {
        key: item.cropped.key,
      },
    };
  } else if (!item.previewSource) {
    itemData = {
      ...itemData,
      original: {
        key: item.original.key,
      },
      cropped: {
        key: item.cropped.key,
      },
    };
  } else {
    itemData = {
      ...itemData,
      original: {
        key: item.key,
      },
      cropped: {
        key: item.croppedKey,
      },
    };
  }

  filteredMedia.push(itemData);
  return filteredMedia;
};

export const populateVideoMediaObject = (item) => {
  let itemData = {};

  if (item) {
    itemData = {
      type: item.type,
      source: item.source,
      thumbnail: item.thumbnail,
    };

    return itemData;
  }

  return null;
};

export const checkFields = (obj) => {
  let validation = false;

  const { title, description, content, media } = obj;

  if (media.length > 0) {
    validation = true;
    return validation;
  }

  if (title !== '') {
    validation = true;
    return validation;
  }

  if (description !== '') {
    validation = true;
    return validation;
  }

  if (content && content.length === 0) {
    if (content[0].source !== '<p></p>') {
      validation === true;
      return validation;
    }
    if (content.length > 0) {
      validation === true;
      return validation;
    }
  }

  return validation;
};

export const createCard = async (data) => {
  try {
    const response = await Api.createCard(getToken(), data);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateCard = async (data) => {
  try {
    const response = await Api.updateEditedCard(getToken(), data);
    return response;
  } catch (err) {
    return err;
  }
};

export const checkState = (state) => {
  if (type === 'trash-delete' && currentState === 'autosaved') {
    return 'trashed_autosaved';
  }
  return state;
};

export const allowedStates = [
  'editor',
  'videoMessageEditor',
  'curationEditor',
  'mediaSourceInformation',
];

// isForPublishing, isForSaving,
export const performAutosave = (send, cleanTimer) => {
  var timerID = setInterval(function () {
    send({ type: 'ON_AUTOSAVE' });
  }, 30 * 1000);

  if (cleanTimer) {
    clearInterval(timerID);
  }
};
