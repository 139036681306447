import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const CharacterCounter = ({ changedValue, startvalue = 0, totalValue = 100 }) => {
  const [value, setValue] = useState(startvalue);
  const [descendingValue, setDescending] = useState(totalValue - startvalue);

  useEffect(() => {
    setValue(changedValue);
    setDescending(totalValue - changedValue);
  }, [changedValue, totalValue]);
  return (
    <div
      style={{
        width: '5.4rem',
        height: '5.4rem',
        position: 'relative',
        marginLeft: '-8.9rem',
        marginRight: '3.5rem',
      }}
    >
      <CircularProgressbar
        value={value}
        maxValue={totalValue}
        styles={buildStyles({
          rotation: 0,
          strokeLinecap: 'butt',
          pathTransitionDuration: 0.5,
          pathColor: `#75fa54`,
          trailColor: '#e2e2e2',
        })}
      />
      <span
        style={{
          position: 'absolute',
          width: '100%',
          left: 0,
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          fontWeight: '600',
          top: 0,
          justifyContent: 'center',
        }}
      >
        {descendingValue}
      </span>
    </div>
  );
};
CharacterCounter.propTypes = {
  changedValue: PropTypes.number,
  totalValue: PropTypes.number,
  startvalue: PropTypes.func,
};

export default CharacterCounter;
