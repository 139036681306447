import React from 'react';
import { navigate } from '@reach/router';
import { motion } from 'framer-motion';
import Icon from '@components/atoms/icon';
import * as styles from '../../create-channel.module.scss';

const FinalChannelCreationStep = ({ current }) => {
  const { name } = current.context;

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
        className={styles.motionContainer}
      >
        <div className={`${styles.titleWrapper} ${styles.unpublished} ${styles.pulledUpTitle}`}>
          <h2>{name}</h2>
          <span>Unpublished | 0 Cards</span>
        </div>
        <div className={`${styles.contentWrapper} ${styles.fullHeight}`}>
          <div
            className={styles.createCardContainer}
            role="link"
            tabIndex="0"
            onKeyDown={() => {}}
            onClick={() => navigate('/app/create-card')}
          >
            <div className={styles.IconWrapper}>
              <Icon iconClass="add" fSize={2.6} />
            </div>
            <h5>Create Card</h5>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default FinalChannelCreationStep;
