import Api from '@api';
import { getToken, getTenant } from '@helpers';

// eslint-disable-next-line import/prefer-default-export
export const readFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

export const performAutosave = (send, cleanTimer) => {
  var timerID = setInterval(function () {
    send({ type: 'ON_AUTOSAVE' });
  }, 10 * 1000);

  if (cleanTimer) {
    clearInterval(timerID);
  }
};

export const createChannel = async (createData, cb) => {
  const response = await Api.createChannel(getToken(), createData);
  return response;
};
export const updateChannel = async (updateData, cb) => {
  const response = await Api.editChannelData(getToken(), updateData);
  return response;
};

export const handleAutosaving = async (context, send) => {
  const { userId, name, description, autoSaving, image, autoSaveId, category, editing } = context;

  const createData = {
    userId,
    tenantId: getTenant(),
    name,
    streamState: 'autosaved',
    description,
    category,
    coverMedia: {
      items: image?.length > 0 ? image : [],
    },
  };

  const updateData = {
    streamId: autoSaveId,
    ...createData,
  };

  send({
    type: 'START_AUTOSAVE',
  });

  const response = autoSaveId ? await updateChannel(updateData) : await createChannel(createData);

  if (response.success) {
    const { streamId, streamState, coverMedia } = response.stream;
    const parsedMedia = JSON.parse(coverMedia);

    const autosaveData = {
      name,
      description,
      image: parsedMedia.items.length > 0 ? parsedMedia.items : [],
    };

    if (editing) {
      send({
        type: 'AUTOSAVE_EXISTING',
        data: {
          autoSaveId: streamId,
          autosaveData,
        },
      });
    } else {
      send({
        type: 'NEW_AUTOSAVE',
        data: {
          streamId,
          streamState,
          autoSaveId: streamId,
          autosaveData,
        },
      });
    }
  }
};
