import React, { useEffect, useMemo, useState } from 'react';
// On Scroll Event
import useSWR, { useSWRConfig } from 'swr';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
// API Requests and Mutations
import Api from '@api';
import Button from '@components/atoms/button';
import ThreeDots from '@components/atoms/loaders/three-dots';
import Placeholder from '@components/atoms/placeholder';
import Modal from '@components/molecules/modal';
import Card from '@components/organisms/card';
import Header from '@components/organisms/header';
import SEO from '@components/seo';
// import SearchContextProvider from '@components/hoc/searchComponent';
// Helper Functions
import {
  getTenant,
  getToken,
  getUserId,
  hasSocials,
  isCurator,
  placeholders,
  errorCodes,
} from '@helpers';
import { Link, navigate } from '@reach/router';
import waterfall from 'async/waterfall';
// Styles and animations
import { motion } from 'framer-motion';
import PlaceholderImage from '@images/placeholder-image.png';
import * as styles from './cards.module.scss';

const Cards = () => {
  const { cache } = useSWRConfig();

  const tenantId = getTenant();
  const [emptyData, setEmptyData] = useState(false);
  const [cId, setcId] = useState(null);
  const [tId, setTenantId] = useState(null);

  // status
  const [prevStatus, setPrevStatus] = useState();
  const [cardFilter, setCardFilter] = useState('all');

  const [order, setOrder] = useState('DESC');
  const [cards, setCards] = useState([]);
  const [prevStream, setPrevStream] = useState(null);
  const [streamId, setStream] = useState(null);

  // page indexes
  const [prevPage, setPrevPage] = useState(1);
  const [pageIndex, setPageIndex] = useState(1);

  // fetching mechanism
  const [fetching, setFetching] = useState(false);
  const [noFetching, setNoFetching] = useState(false);

  // Modal values
  const [type, setType] = useState('');
  const [currentState, setCurrentState] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');

  const { data, error, mutate } = useSWR(
    ['/listContentCreationCards', cardFilter, order, tenantId, streamId, pageIndex],
    () => Api.listContentCreationCards(streamId, cardFilter, order, pageIndex)
  );

  const setModalValues = (modalType, title, desc) => {
    setType(modalType);
    setModalTitle(title);
    setModalDesc(desc);
  };

  const checkCardState = (state) => {
    if (type === 'trash-delete' && currentState === 'autosaved') {
      return 'trashed_autosaved';
    }
    return state;
  };

  const updateCardState = async (cardState, cb) => {
    const cardId = cId;
    try {
      const response = await Api.changeCardState({
        cardId,
        tenantId,
        cardState: checkCardState(cardState),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const publishCard = async (cb) => {
    const cardId = cId;
    try {
      const response = await Api.publishCard(getToken(), {
        cardId,
        userId: getUserId(),
        tenantId,
      });

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCardSharing = (id, tId) => {
    setType('sharing');
    setModalTitle('Share Card');
    setModalDesc(null);
    setcId(id);
    setTenantId(tId);
    setTimeout(() => {
      setShowModal(true);
    }, 200);
  };

  const handleCardStateAction = (id, type, cardState) => {
    setType(type);
    setcId(id);
    setCurrentState(cardState);
    switch (type) {
      case 'publish':
        setModalTitle('Publish Card');
        setModalDesc('Are you sure you want to publish your card?');
        break;
      case 'unpublish':
        setModalTitle('Unpublish Card');
        setModalDesc('Are you sure you want to unpublish your card?');
        break;
      case 'trash-delete':
        setModalTitle(
          <>
            Are you sure you want to delete <br />
            this card?
          </>
        );
        setModalDesc(
          <>
            The card will be deleted accordingly and <br />
            disappear for your users!
          </>
        );
        break;
      default:
        break;
    }
    setTimeout(() => {
      setShowModal(true);
    }, 200);
  };

  const handleErrorModal = (type) => {
    switch (type) {
      case 'unpublished':
        setModalValues(
          'error',
          'Card can’t be unpublished',
          'Something went wrong! Card can not be unpublished!'
        );
        break;
      case 'trashed':
        setModalValues(
          'error',
          'Card can not be deleted',
          'Something went wrong! Card can not be deleted!'
        );
        break;
      default:
        break;
    }
  };

  const handleSuccessModal = (type) => {
    switch (type) {
      case 'unpublished':
        setModalValues(
          'successfully-deleted',
          'Unpublished card',
          'Card was unpublished successfully'
        );
        mutate();
        cache.delete();
        break;
      case 'trashed':
        setModalValues('successfully-deleted', 'Deleted card', 'Card was deleted successfully');
        // const mutatedCards = cards.filter((card) => card.cardId !== cId);
        // setCards(mutatedCards);
        mutate();
        cache.delete();
        break;
      default:
        break;
    }
  };

  const changeCardStateAction = (state) => {
    setShowModal(false);
    waterfall(
      [
        function (cb) {
          updateCardState(state, cb);
        },
      ],
      (err, res) => {
        setShowModal(true);
        if (!res?.success || res.code) {
          handleErrorModal(state);
        } else {
          handleSuccessModal(state);
        }
      }
    );
  };

  const publishCardAction = () => {
    waterfall(
      [
        function (callback) {
          publishCard(callback);
        },
      ],
      (err, res) => {
        setShowModal(true);
        if (errorCodes.includes(res?.code)) {
          setModalValues(
            'cant-publish',
            'Card can’t be published',
            res?.message ? res.message : 'Something went wrong! Card can not be published!'
          );
        } else {
          setModalValues(
            'successfully-deleted',
            'Published card',
            'Card was published successfully'
          );
          mutate();
          cache.delete();
        }
      }
    );
  };

  const clearModal = () => {
    setType('');
    setModalTitle('');
    setModalDesc('');
  };

  const handleModalAction = (modalType) => {
    if (modalType === 'publish') {
      publishCardAction();
    }

    if (modalType === 'unpublish') {
      changeCardStateAction('unpublished');
    }

    if (modalType === 'trash-delete') {
      changeCardStateAction('trashed');
    }

    if (modalType === 'error') {
      setShowModal(false);
    }

    clearModal();
  };

  const cancelModal = () => {
    setShowModal(false);
    setModalTitle('');
    setModalDesc('');
    setType('');
  };

  const handleAspectRatio = (ratio, format) => {
    if (ratio && ratio.length > 0) {
      if (format !== 'Text' && ratio[0]) {
        return ratio[0].intendedAspectRatio || 'Original';
      }
    }
    return null;
  };

  const onScrollEnd = () => {
    if (!noFetching) {
      setFetching(true);
      if (!fetching) {
        setPageIndex(pageIndex + 1);
      }
    }
  };

  const changeCardStatus = (val) => {
    setPrevStatus(cardFilter);
    setTimeout(() => {
      setCardFilter(val);
    }, 300);
    setPrevPage(1);
    setPageIndex(1);
    window.scrollTo(0, 0);
  };

  const changeOrder = (val) => {
    setOrder(val);
  };

  useBottomScrollListener(onScrollEnd);

  useEffect(() => {
    if (data) {
      if (data.cards.length === 0) {
        if (cards.length === 0) {
          setEmptyData(true);
        }

        if (cards.length > 0) {
          setNoFetching(true);
        }

        if (cardFilter !== prevStatus) {
          setCards([]);
        }

        if (prevStream !== null && data.cards.length === 0) {
          setCards([]);
        }

        setPrevStatus(cardFilter);
      } else {
        setNoFetching(false);
        if (pageIndex === prevPage || cardFilter !== prevStatus) {
          setCards(data.cards);
          setFetching(false);
          setPrevStatus(cardFilter);
        } else {
          const newUpdatedValues = [...cards, ...data.cards];
          setCards(newUpdatedValues);
          setPrevPage(pageIndex);
          setFetching(false);
        }
      }
    }

    // return () => {
    //   setCards([]);
    //   setFetching(false);
    //   setNoFetching(false);
    // };
  }, [data, error]);

  useEffect(
    () => () => {
      setCards([]);
      setFetching(false);
      setNoFetching(false);
    },
    []
  );

  const changeStream = (val) => {
    setPrevStream(streamId);
    setStream(val);
  };

  const CardProps = (card) => {
    const cardObject = {
      key: card.cardId,
      id: card.cardId,
      title: card.title,
      description: card.description,
      src: card?.coverMedia?.items?.length
        ? card?.coverMedia?.items[0]
        : { source: PlaceholderImage },
      channelId: card.streamId,
      channel: card.stream,
      status: card.cardState || card.published,
      curator: isCurator.includes(card.subFormat),
      cardClass: card.subFormat,
      coverAuthor: card.coverAuthor,
      category: card.category,
      type: card.subFormat,
      date: card.created,
      author: card.author,
      isPiqd: card.contentSourceProvider && card.contentSourceProvider === 'piqd',
      isList: true,
      hasLink: true,
      socials: hasSocials.includes(card.subFormat) ? card.counters : null,
      intendedAspectRatio: handleAspectRatio(card?.media, card?.subFormat),
      deleteCard: () => handleCardStateAction(card.cardId, 'trash-delete', card.cardState),
      publishCard: () => handleCardStateAction(card.cardId, 'publish'),
      unpublishCard: () => handleCardStateAction(card.cardId, 'unpublish'),
      shareCard: () => handleCardSharing(card.cardId, card.tenantId),
      moveCard: () => navigate(`/app/move-to-channel/${card.cardId}`),
    };

    return cardObject;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.contentWrapper}
    >
      <Header
        setCardFilter={(val) => changeCardStatus(val)}
        setCardOrder={(val) => changeOrder(val)}
        onStreamChange={(val) => changeStream(val)}
        order={order}
      />
      <SEO title="Cards" lang="de" />
      {showModal && (
        <Modal
          title={modalTitle}
          desc={modalDesc}
          type={type}
          showModal={showModal}
          hasCardId={cId}
          hasTenantId={tId}
          onConfirm={() => handleModalAction(type)}
          onCancel={() => cancelModal()}
        />
      )}
      <div
        className={`
          ${styles.cardsWrapper} 
          ${emptyData && cards.length === 0 && styles.fullHeight}`}
        id="cardList"
      >
        {cards && cards.length > 0 && cards.map((card) => <Card {...CardProps(card)} />)}

        {data && !noFetching && cards.length >= 20 ? (
          <div className={styles.loadMoreCards}>
            <Button btnClass="primary" onClick={() => setPageIndex(pageIndex + 1)}>
              View More
            </Button>
          </div>
        ) : null}

        {!data && cards.length > 0 && (
          <div className={styles.incomingCards}>
            <h5>Loading New Cards</h5>
            <ThreeDots />
          </div>
        )}

        {!data && cards.length === 0 && placeholders.map((item) => <Placeholder key={item.id} />)}
        {data && cards.length === 0 && data.cards.length === 0 ? (
          <div className={styles.noData}>
            <h3>Currently you don't have any cards</h3>
            <p>Try creating one and they will appear here</p>
            <Link to="/app/create-card">
              <Button btnClass="primary">Create Cards</Button>
            </Link>
          </div>
        ) : null}
      </div>
    </motion.div>
  );
};

export default Cards;
