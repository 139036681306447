/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import PlaceholderImage from '@images/placeholder-image.png';

import * as styles from './order-media.module.scss';

const SortableItem = SortableElement(({ file, index, identifier }) => {
  const type = () => file.originalFile.type.split('/')[0] || file.type;

  if (type !== 'video') {
    return (
      <div className={`${styles.imageWrap} ${identifier === index && styles.activeItem}`}>
        <img
          key={index}
          width={62}
          height={72}
          className={styles.arrayImage}
          src={file?.asset?.fullSizeUrl || file?.originalUrlBlob || file?.source}
          alt=""
        />
      </div>
    );
  }
  return (
    <div className={`${styles.imageWrap} ${identifier === index && styles.activeItem}`}>
      <img
        key={index}
        className={styles.arrayImage}
        width={62}
        height={72}
        src={PlaceholderImage}
        alt=""
      />
    </div>
  );
});

export default SortableItem;
