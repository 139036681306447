import React, { useRef, useState, useEffect } from 'react';
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/anchor/lib/plugin.css';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState, RichUtils, CompositeDecorator, Entity } from 'draft-js';
import { flow } from 'lodash';
import {
  removeInlineStyles,
  removeEntities,
  removeBlockTypes,
} from '@components/organisms/my-editor/models';

import {
  myBlockRenderMap,
  styleMap,
  findLinkEntities,
  Link,
} from '@components/organisms/my-editor/helpers';
import { IGNORED_TYPES } from '@components/organisms/my-editor/helpers';
import InlineOptions from '@components/organisms/my-editor/components/inline-options';

import { profileEditor } from './light-editor.module.scss';
// import { setIn } from 'formik';

function LightEditorComponent({
  parsedValue,
  updateValue,
  name,
  current = null,
  send = null,
  hasInlineOptions,
}) {
  const [editorPlaceholder, setPlaceholder] = useState('Start writing ...');
  const [initialized, setInitialized] = useState(false);
  const editor = useRef(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const focusEditor = () => {
    editor.current.focus();
  };

  const onChange = (changedState) => {
    if (!initialized) return;
    setEditorState(changedState);

    const changedData = convertToHTML({
      styleToHTML: (style) => {
        if (style === 'HIGHLIGHT') {
          return <span style={{ backgroundColor: '#AFFFAA' }} />;
        }
        if (style === 'STRIKETHROUGH') {
          return <span style={{ textDecoration: 'line-through' }} />;
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={entity.data.url}>{originalText}</a>;
        }
        return originalText;
      },
    })(changedState.getCurrentContent());

    updateValue(changedData);
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  const resetToDefault = () => {
    const stateWithoutEditing = flow([removeInlineStyles, removeEntities, removeBlockTypes])(
      editorState
    );
    onChange(stateWithoutEditing);
  };

  const convertHTMLtoEditorContent = (val) => {
    const renderingState = EditorState.createWithContent(
      convertFromHTML({
        htmlToStyle: (nodeName, node, currentStyle) => {
          if (nodeName === 'span' && node.style.backgroundColor === 'rgb(140, 255, 134)') {
            return currentStyle.add('HIGHLIGHT');
          }
          if (nodeName === 'span' && node.style.textDecoration === 'line-through') {
            return currentStyle.add('STRIKETHROUGH');
          }
          return currentStyle;
        },
        htmlToEntity: (nodeName, node, entity) => {
          if (nodeName === 'a') {
            return Entity.create('LINK', 'MUTABLE', { url: node.href });
          }
        },
      })(val),
      decorator
    );
    setEditorState(renderingState);
  };

  const checkPlaceholder = () =>
    !parsedValue ? setPlaceholder('Start writing ...') : setPlaceholder('');

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const onEnterCommand = (e) => {
    if (e.shiftKey) {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }
    return 'not-handled';
  };

  useEffect(() => {
    checkPlaceholder();
    if (parsedValue) {
      convertHTMLtoEditorContent(parsedValue);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (current?.context?.inlineStyle && !IGNORED_TYPES.includes(current?.context?.inlineStyle)) {
      if (current?.context?.inlineStyle && current?.context?.inlineStyle !== 'LINK') {
        onChange(RichUtils.toggleInlineStyle(editorState, current?.context?.inlineStyle));
        send({ type: 'SET_INLINE', inlineStyle: null });
      }
    }
  }, [current?.context?.inlineStyle]);

  useEffect(() => {
    if (!IGNORED_TYPES.includes(current?.context?.blockTypeStyle)) {
      if (current?.context?.blockTypeStyle) {
        send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null });
        if (current?.context?.blockTypeStyle === 'unstyled') {
          return resetToDefault();
        }
        onChange(RichUtils.toggleBlockType(editorState, current?.context?.blockTypeStyle));
      }
    }
  }, [current?.context?.blockTypeStyle]);

  return (
    <>
      <div className={profileEditor} onClick={() => focusEditor()}>
        <Editor
          ref={editor}
          editorState={editorState}
          name={name}
          customStyleMap={styleMap}
          handleKeyCommand={handleKeyCommand}
          placeholder={editorPlaceholder}
          handleReturn={onEnterCommand}
          onChange={(changedValue) => onChange(changedValue)}
          blockRendererFn={myBlockRenderMap}
        />
      </div>
      {hasInlineOptions && (
        <InlineOptions isShown={true} send={send} current={current} impressum={hasInlineOptions} />
      )}
    </>
  );
}

export default LightEditorComponent;
