import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import SortableList from './sortableList';
import * as styles from './order-media.module.scss';

const OrderMedia = ({ current, send }) => {
  const { files, content, media, editing, loadingUpload, sequenceIndex } = current.context;
  const [focused, setFocused] = useState(0);

  const onSort = ({ oldIndex, newIndex }) => {
    if (sequenceIndex) {
      setFocused(newIndex);
      send({ type: 'ORDER_CAROUSEL', cId: sequenceIndex, oldIndex, newIndex });
    } else {
      send({ type: 'ORDER_FILES', files, oldIndex, newIndex });
      send({ type: 'ORDER_MEDIA', media, oldIndex, newIndex });
      if (newIndex === 0) {
        send({
          type: 'SET_COVER',
          coverMedia: media[oldIndex]?.asset?.defaultUrl,
        });
      }
    }
    send({ type: 'SET_SEQUENCE_INDEX' });
  };

  const itemsCounter = () => {
    if (sequenceIndex) {
      return `${focused + 1}/${content[sequenceIndex]?.items?.length}`;
    }
    return `${focused + 1}/${media.length}`;
  };

  const calculateHeight = () => {
    if (!sequenceIndex && files.length > 3) {
      return 100;
    }
    if (sequenceIndex && content[sequenceIndex]?.items?.length > 3) {
      return 100;
    }
    return 90;
  };

  // TODO Find a solution to fix this one below
  useEffect(() => {
    if (media.length > 0 && media[0]?.asset?.defaultUrl) {
      send({ type: 'SET_COVER', coverMedia: media[0]?.asset?.defaultUrl });
    } else {
      send({ type: 'SET_COVER', coverMedia: files[0]?.croppedUrlBlob });
    }
  }, [files, media]);

  useEffect(() => {
    if (sequenceIndex && !content[sequenceIndex]?.items) {
      send({ type: 'VIEW_SEQUENCE', sequence: false });
    }

    if (!sequenceIndex && media.length === 0) {
      send({ type: 'VIEW_SEQUENCE', sequence: false });
    }
  }, [content, media]);

  return (
    <div className={`${styles.orderMedia} ${sequenceIndex !== null && styles.noPadding}`}>
      {sequenceIndex && content[sequenceIndex]?.items ? (
        <div className={styles.fullFormat}>
          <img
            src={content[sequenceIndex]?.items[focused]?.asset?.defaultUrl}
            width={342}
            alt="First item"
          />
        </div>
      ) : (
        ''
      )}
      <div className={styles.sectionTitle}>
        {!sequenceIndex ? <h5>Cover Order</h5> : <h5>Picture sequence carousel</h5>}
        <span>{itemsCounter()}</span>
      </div>

      <Scrollbars style={{ width: 352, height: calculateHeight() }}>
        {files ? (
          <SortableList
            axis="xy"
            onSortEnd={onSort}
            focused={focused}
            editing={editing}
            hasCarousel={sequenceIndex !== null}
            files={sequenceIndex ? content[sequenceIndex]?.items : files}
            hasMedia={!sequenceIndex ? files?.length > 0 : true}
            isLoading={loadingUpload}
          />
        ) : (
          ''
        )}
      </Scrollbars>
    </div>
  );
};

OrderMedia.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.shape(),
  files: PropTypes.shape(),
};
export default OrderMedia;
