import React, { useEffect, useState, useContext } from 'react';

import useSWR, { useSWRConfig } from 'swr';
import waterfall from 'async/waterfall';
import { motion } from 'framer-motion';

import Api from '@api';
import SEO from '@components/seo';
import { Link, navigate } from '@reach/router';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import SearchContext from '@components/hoc/searchComponent';

import Card from '@components/organisms/card';
import Placeholder from '@components/atoms/placeholder';
import Modal from '@components/molecules/modal';
import Button from '@components/atoms/button';
import Icon from '@components/atoms/icon';
import ThreeDots from '@components/atoms/loaders/three-dots';
import NavLink from '@components/molecules/nav-link';
import Tooltip from '@reach/tooltip';
import { toolTipStyle } from '@helpers';
import arrowLeft from '@components/organisms/header/header.module.scss';

import {
  getTenant,
  getToken,
  getUserId,
  handleAspectRatio,
  hasSocials,
  isCurator,
  placeholders,
  errorCodes,
} from '@helpers';

import PlaceholderImage from '@images/placeholder-image.png';

import * as styles from './channel-cards.module.scss';
import { defaultBtn } from '@components/organisms/header/header.module.scss';

const ChannelCards = ({ id }) => {
  const tenantId = getTenant();
  const [tId, setTenantId] = useState(null);
  const [cId, setcId] = useState(null);
  const { cache } = useSWRConfig();

  // Channel Info
  const [channelTitle, setTitle] = useState(null);
  const [cardCount, setCardCount] = useState(null);
  const [channelDetails, setChannelDetails] = useState({});

  // Status
  const [active, setActive] = useState(false);
  const [order, setOrder] = useState('DESC');

  // Page Indexes
  const [prevPage, setPrevPage] = useState(1);
  const [pageIndex, setPageIndex] = useState(1);

  // fetching mechanism
  const [fetching, setFetching] = useState(false);
  const [noFetching, setNoFetching] = useState(false);
  const [stopFetching, setStopFetching] = useState(false);

  // Modal state permutations
  const [type, setType] = useState('');
  const [currentState, setCurrentState] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const [cardList, setCardList] = useState([]);

  const { showSearch } = useContext(SearchContext);

  const { data, error, mutate } = useSWR(['/getStreamCards', id, order, pageIndex], () =>
    Api.listContentCreationCards(id, 'all', order, pageIndex)
  );

  const fetchChannelDetails = async () => {
    try {
      const response = await Api.getChannelDetails(getToken(), id, getUserId(), getTenant());
      if (response) {
        // setCardCount(response.cardNo);
        // setTitle(response.name);
        setChannelDetails(response);
      }
    } catch (err) {
      setStopFetching(true);
    }
  };

  const setModalValues = (modalType, title, desc) => {
    setType(modalType);
    setModalTitle(title);
    setModalDesc(desc);
    setTimeout(() => {
      setShowModal(true);
    }, [100]);
  };

  const reverseCardOrder = (value) => {
    setActive(!active);
    if (value === 'ASC') {
      setOrder('DESC');
    } else {
      setOrder('ASC');
    }
  };

  const checkCardState = (state) => {
    if (type === 'trash-delete' && currentState === 'autosaved') {
      return 'trashed_autosaved';
    }
    return state;
  };

  const updateCardState = async (cardState, cb) => {
    const cardId = cId;
    try {
      const response = await Api.changeCardState({
        cardId,
        tenantId,
        cardState: checkCardState(cardState),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const publishCard = async (cb) => {
    const cardId = cId;
    try {
      const response = await Api.publishCard(getToken(), {
        cardId,
        userId: getUserId(),
        tenantId,
      });

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCardSharing = (id, tId) => {
    setType('sharing');
    setModalTitle('Share Card');
    setModalDesc(null);
    setcId(id);
    setTenantId(tId);
    setTimeout(() => {
      setShowModal(true);
    }, 200);
  };

  const handleCardStateAction = (id, type, cardState) => {
    setType(type);
    setcId(id);
    setCurrentState(cardState);
    switch (type) {
      case 'publish':
        setModalTitle('Publish Card');
        setModalDesc('Are you sure you want to publish your card?');
        break;
      case 'unpublish':
        setModalTitle('Unpublish Card');
        setModalDesc('Are you sure you want to unpublish your card?');
        break;
      case 'trash-delete':
        setModalTitle(
          <>
            Are you sure you want to delete <br />
            this card?
          </>
        );
        setModalDesc(
          <>
            The card will be deleted accordingly and <br />
            disappear for your users!
          </>
        );
        break;
      default:
        break;
    }
    setTimeout(() => {
      setShowModal(true);
    }, 200);
  };

  const handleErrorModal = (type) => {
    switch (type) {
      case 'unpublished':
        setModalValues(
          'error',
          'Card can’t be unpublished',
          'Something went wrong! Card can not be unpublished!'
        );
        break;
      case 'trashed':
        setModalValues(
          'error',
          'Card can not be deleted',
          'Something went wrong! Card can not be deleted!'
        );
        break;
      default:
        break;
    }
  };

  const handleSuccessModal = (type) => {
    switch (type) {
      case 'unpublished':
        setModalValues(
          'successfully-deleted',
          'Unpublished card',
          'Card was unpublished successfully'
        );
        mutate();
        cache.delete();
        break;
      case 'trashed':
        setModalValues('successfully-deleted', 'Deleted card', 'Card was deleted successfully');
        mutate();
        cache.delete();
        break;
      default:
        break;
    }
  };

  const changeCardStateAction = (state) => {
    setShowModal(false);
    waterfall(
      [
        function (cb) {
          updateCardState(state, cb);
        },
      ],
      (err, res) => {
        setShowModal(true);
        if (!res?.success) {
          handleErrorModal(state);
        } else {
          handleSuccessModal(state);
        }
      }
    );
  };

  const publishCardAction = () => {
    waterfall(
      [
        function (callback) {
          publishCard(callback);
        },
      ],
      (err, res) => {
        setShowModal(true);
        if (errorCodes.includes(res?.code)) {
          setModalValues(
            'cant-publish',
            'Card can’t be published',
            res?.message ? res.message : 'Something went wrong! Card can not be published!'
          );
        } else {
          setModalValues(
            'successfully-deleted',
            'Published card',
            'Card was published successfully'
          );
          mutate();
          cache.delete();
        }
      }
    );
  };

  const cancelModal = () => {
    setShowModal(false);
    setModalTitle('');
    setModalDesc('');
    setType('');
  };

  const clearModal = () => {
    setType('');
    setModalTitle('');
    setModalDesc('');
  };

  const handleModalAction = (modalType) => {
    if (modalType === 'publish') {
      publishCardAction();
    }

    if (modalType === 'unpublish') {
      changeCardStateAction('unpublished');
    }

    if (modalType === 'trash-delete') {
      changeCardStateAction('trashed');
    }

    if (modalType === 'error') {
      setShowModal(false);
    }

    clearModal();
  };

  const onScrollEnd = () => {
    if (!noFetching) {
      setFetching(true);
      if (!fetching) {
        setPageIndex(pageIndex + 1);
      }
    }
  };

  const CardProps = (card) => {
    const cardObject = {
      key: card.cardId,
      id: card.cardId,
      title: card.title,
      description: card.description,
      src: card?.coverMedia?.items?.length
        ? card?.coverMedia?.items[0]
        : { source: PlaceholderImage },
      channelId: card.streamId,
      channel: card.stream,
      status: card.cardState || card.published,
      curator: isCurator.includes(card.subFormat),
      coverAuthor: card.coverAuthor,
      cardClass: card.subFormat,
      category: card.category,
      type: card.subFormat,
      date: card.created,
      author: card.author,
      isPiqd: card.contentSourceProvider && card.contentSourceProvider === 'piqd',
      isList: true,
      hasLink: true,
      socials: hasSocials.includes(card.subFormat) ? card.counters : null,
      intendedAspectRatio: handleAspectRatio(card?.media, card?.subFormat),
      deleteCard: () => handleCardStateAction(card.cardId, 'trash-delete', card.cardState),
      publishCard: () => handleCardStateAction(card.cardId, 'publish'),
      unpublishCard: () => handleCardStateAction(card.cardId, 'unpublish'),
      shareCard: () => handleCardSharing(card.cardId, card.tenantId),
      moveCard: () => navigate(`/app/move-to-channel/${card.cardId}`),
    };

    return cardObject;
  };

  useBottomScrollListener(onScrollEnd);

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        if (cardList.length > 0) {
          setNoFetching(true);
        }
      } else {
        setNoFetching(false);

        if (pageIndex === prevPage) {
          setCardList(data.cards);
          setFetching(false);
        } else {
          const newUpdatedValues = [...cardList, ...data.cards];
          setCardList(newUpdatedValues);
          setPrevPage(pageIndex);
          setFetching(false);
        }
      }
    }
  }, [data, error]);

  useEffect(() => {
    if (id) {
      fetchChannelDetails();
    }
  }, [id]);

  const storeChannelId = () => localStorage.setItem('streamId', id);

  useEffect(() => {
    storeChannelId();

    () => {
      setCardList([]);
      cache.delete();
    };
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        // className={styles.contentWrapper}
      >
        <SEO title="Channel Cards" />
        {showModal && (
          <Modal
            title={modalTitle}
            desc={modalDesc}
            type={type}
            showModal={showModal}
            hasCardId={cId}
            hasTenantId={tId}
            onConfirm={() => handleModalAction(type)}
            onCancel={() => cancelModal()}
          />
        )}

        <div className={styles.leftNavIcons}>
          {
            (channelDetails.streamState = 'autosaved' ? (
              <Tooltip label="Back" style={toolTipStyle}>
                <button className={defaultBtn} type="button" onClick={() => navigate(-1)}>
                  <Icon iconClass="arrow-left" />
                </button>
              </Tooltip>
            ) : null)
          }
          <Button type="button" iconBtn onClick={() => showSearch()}>
            <Icon iconClass="loop" fSize={2.2} />
          </Button>
        </div>
        <div className={styles.rightNavIcons}>
          <Button type="button" iconBtn active={active} onClick={() => reverseCardOrder(order)}>
            <i className="justt justt-sort-colored">
              <span className="path1" style={{ fontSize: '2.2rem' }} />
              <span className="path2" style={{ fontSize: '2.2rem' }} />
            </i>
          </Button>
          <NavLink to={`/app/edit-channel/${id}`}>
            <Button type="button" iconBtn editBtn>
              <Icon iconClass="edit" fSize={1.1} />
              <span className={styles.editLabel}>Edit</span>
            </Button>
          </NavLink>
        </div>
        <SEO title="Channel Cards" />
        <div className={styles.cardContent}>
          <div className={styles.channelCardTitle}>
            {data && (
              <>
                <h2>
                  {channelDetails.name}
                  <p>{channelDetails.cardNo}</p>
                </h2>
                <div className={styles.breadCrumbs}>
                  <ul>
                    <li>
                      <NavLink to="/app">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/app">Channels</NavLink>
                    </li>
                    <li>
                      <p>{channelDetails.name}</p>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <div className={`${styles.cardsWrapper} ${cardList.length === 0 && styles.fullHeight}`}>
            {cardList && cardList.map((card) => <Card {...CardProps(card)} />)}

            {data && !noFetching && cardList.length >= 20 ? (
              <div className={styles.loadMoreCards}>
                <Button btnClass="primary" onClick={() => setPageIndex(pageIndex + 1)}>
                  View More
                </Button>
              </div>
            ) : null}

            {!data && cardList.length > 0 && (
              <div className={styles.incomingCards}>
                <h5>Loading New Cards</h5>
                <ThreeDots />
              </div>
            )}

            {!data &&
              cardList.length === 0 &&
              placeholders.map((item) => <Placeholder key={item.id} />)}

            {data && cardList.length === 0 ? (
              <div className={styles.noData}>
                <h3>This channel doesn't have any cards</h3>
                <p>Try creating one and they will appear here</p>
                <Link to="/app/create-card">
                  <Button btnClass="primary">Create Card</Button>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ChannelCards;
