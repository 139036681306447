/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Map } from 'immutable';

import { EditorState, SelectionState, Modifier, ContentBlock, genKey, RichUtils } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { inlineStyles, getSelectedBlocks } from '@components/organisms/my-editor/helpers';
/*
Used from [react-rte](https://github.com/brijeshb42/medium-draft)
by [brijeshb42](https://github.com/brijeshb42/medium-draft)
*/

/*
Returns default block-level metadata for various block type. Empty object otherwise.
*/
export const getDefaultBlockData = (blockType, initialData = {}) => {
  switch (blockType) {
    // case Block.TODO: return { checked: false };
    default:
      return initialData;
  }
};

export const getNode = (root = window) => {
  let t = null;
  if (root.getSelection) {
    t = root.getSelection();
  } else if (root.document.getSelection) {
    t = root.document.getSelection();
  } else if (root.document.selection) {
    t = root.document.selection.createRange().text;
  }
  return t;
};

/*
Get currentBlock in the editorState.
*/
export const getCurrentBlock = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selectionState.getStartKey());
  return block;
};

/*
Adds a new block (currently replaces an empty block) at the current cursor position
of the given `newType`.
*/
export const addNewBlock = (editorState, newType = 'unstyled', initialData = {}) => {
  const selectionState = editorState.getSelection();
  if (!selectionState.isCollapsed()) {
    return editorState;
  }
  const contentState = editorState.getCurrentContent();
  const key = selectionState.getStartKey();
  const blockMap = contentState.getBlockMap();
  const currentBlock = getCurrentBlock(editorState);
  if (!currentBlock) {
    return editorState;
  }
  if (currentBlock.getLength() === 0) {
    if (currentBlock.getType() === newType) {
      return editorState;
    }
    const newBlock = currentBlock.merge({
      type: newType,
      data: getDefaultBlockData(newType, initialData),
    });
    const newContentState = contentState.merge({
      blockMap: blockMap.set(key, newBlock),
      selectionAfter: selectionState,
    });
    return EditorState.push(editorState, newContentState, 'change-block-type');
  }
  return editorState;
};

/*
Changes the block type of the current block.
*/
export const resetBlockWithType = (editorState, newType = 'unstyled', data = {}) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const key = selectionState.getStartKey();
  const blockMap = contentState.getBlockMap();
  const block = blockMap.get(key);

  const text = block.getText();

  const newBlock = block.merge({
    text,
    type: newType,
    data: getDefaultBlockData(newType, data),
  });
  const newContentState = contentState.merge({
    blockMap: blockMap.set(key, newBlock),
    selectionAfter: selectionState.merge({
      anchorOffset: 0,
      focusOffset: 0,
    }),
  });
  return EditorState.push(editorState, newContentState, 'change-block-type');
};

/*
Update block-level metadata of the given `block` to the `newData`/
*/
export const updateDataOfBlock = (editorState, block, newData) => {
  const contentState = editorState.getCurrentContent();
  const newBlock = block.merge({
    data: newData,
  });
  const newContentState = contentState.merge({
    blockMap: contentState.getBlockMap().set(block.getKey(), newBlock),
  });
  return EditorState.push(editorState, newContentState, 'change-block-type');
  // return editorState;
};

export const updateTextOfBlock = (editorState, block, text) => {
  const contentState = editorState.getCurrentContent();
  const newBlock = block.merge({
    text,
  });
  const newContentState = contentState.merge({
    blockMap: contentState.getBlockMap().set(block.getKey(), newBlock),
  });

  return EditorState.push(editorState, newContentState, 'replace-text');
  // return editorState;
};

export const updateCharacterListOfBlock = (editorState, block, text, charList) => {
  const contentState = editorState.getCurrentContent();

  const newBlock = block.merge({
    text,
    characterList: charList,
  });

  const newContentState = contentState.merge({
    blockMap: contentState.getBlockMap().set(block.getKey(), newBlock),
  });

  return EditorState.push(editorState, newContentState, 'replace-text');
  // return editorState;
};

// const BEFORE = -1;
// const AFTER = 1;

/*
Used from [react-rte](https://github.com/sstur/react-rte/blob/master/src/lib/insertBlockAfter.js)
by [sstur](https://github.com/sstur)
*/
export const addNewBlockAt = (
  editorState,
  pivotBlockKey,
  newBlockType = 'unstyled',
  initialData = {}
) => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const block = blockMap.get(pivotBlockKey);
  const blocksBefore = blockMap.toSeq().takeUntil((v) => v === block);
  const blocksAfter = blockMap
    .toSeq()
    .skipUntil((v) => v === block)
    .rest();
  const newBlockKey = genKey();

  const newBlock = new ContentBlock({
    key: newBlockKey,
    type: newBlockType,
    text: '',
    characterList: block.getCharacterList().slice(0, 0),
    depth: 0,
    data: Map(getDefaultBlockData(newBlockType, initialData)),
  });

  const newBlockMap = blocksBefore
    .concat(
      [
        [pivotBlockKey, block],
        [newBlockKey, newBlock],
      ],
      blocksAfter
    )
    .toOrderedMap();

  const selection = editorState.getSelection();

  const newContent = content.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection.merge({
      anchorKey: newBlockKey,
      anchorOffset: 0,
      focusKey: newBlockKey,
      focusOffset: 0,
      isBackward: false,
    }),
  });
  return EditorState.push(editorState, newContent, 'split-block');
};

export const promptLinkAppend = (editorState) => {
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

    let url = '';
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url;
    }

    return true;
  }

  return false;
};

export const appendLinkToSelection = (urlValue, editorState) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url: urlValue,
  });

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });

  const withLinkEditorState = RichUtils.toggleLink(
    newEditorState,
    newEditorState.getSelection(),
    entityKey
  );

  return withLinkEditorState;
};

export const htmlParsedContent = (state) =>
  convertToHTML({
    styleToHTML: (style) => {
      if (style === 'HIGHLIGHT') {
        return <span style={{ backgroundColor: '#AFFFAA' }} />;
      }
      if (style === 'STRIKETHROUGH') {
        return <span style={{ textDecoration: 'line-through' }} />;
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        return <a href={entity.data.url}>{originalText}</a>;
      }
      return originalText;
    },
  })(state.getCurrentContent());

export const removeInlineStyles = (eState, editorState) => {
  const contentState = eState.getCurrentContent();
  const contentWithoutStyles = inlineStyles.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(newContentState, eState.getSelection(), style),
    contentState
  );

  const newEditorState = EditorState.push(eState, contentWithoutStyles, 'change-inline-style');

  // onChange(newEditorState);
  return newEditorState;
};

export const removeEntities = (eState, entityKey = null) => {
  const contentState = eState.getCurrentContent();
  const contentWithoutEntities = Modifier.applyEntity(
    contentState,
    eState.getSelection(),
    entityKey
  );

  const newEditorState = EditorState.push(eState, contentWithoutEntities, 'apply-entity');

  return newEditorState;
};

export const removeBlockTypes = (eState) => {
  const contentState = eState.getCurrentContent();
  const currentSelection = eState.getSelection();

  const startKey = currentSelection.getStartKey();
  const endKey = currentSelection.getEndKey();

  const blocksMap = getSelectedBlocks(contentState, startKey, endKey);
  const contentWithoutBlocks = blocksMap.reduce((newContentState, block) => {
    const blockType = block.getType();
    if (blockType !== 'unstyled') {
      const selectionState = SelectionState.createEmpty(block.getKey());
      const updatedSelection = selectionState.merge({
        focusOffset: 0,
        anchorOffset: block.getText().length,
      });

      return Modifier.setBlockType(newContentState, updatedSelection, 'unstyled');
    }

    return newContentState;
  }, contentState);

  const newEditorState = EditorState.push(eState, contentWithoutBlocks, 'change-block-type');

  return newEditorState;
};
