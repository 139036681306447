import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  getFilesFromEvent,
  getCleanUploadedFiles,
  validateMediaUrl,
  readFile,
  mediaHandlingService,
  aspectRatios,
} from '@services/image';
import Cropper from 'react-easy-crop';
import Dropzone from 'react-dropzone-uploader';
import CoverDropzoneInput from '@components/molecules/cover-dropzone-input';
import ImageCarousel from '@components/organisms/image-carousel';
import ThreeDots from '@components/atoms/loaders/three-dots';
import Slider from 'rc-slider';
import waterfall from 'async/waterfall';

import { rangeWrap, aspectRatiosStyle, btnWrapper, activeItem } from '@helpers/main.module.scss';
import MediaButton from '@components/atoms/media-button';
import getCroppedImg from '@helpers/cropImage';
import * as styles from './media-cover.module.scss';

const MediaCover = ({ current, send }) => {
  const { context, event } = current;
  const {
    files,
    media,
    currentFileIndex,
    // sequence,
    // sequenceFileIndex,
    // sequenceIndex,
    // changed,
    coverMedia,
    editing,
    isCropping,
    changedMedia,
  } = context;

  const carouselImageUpload = useRef(null);

  const acceptedFileTypes = [
    `image/jpg`,
    `image/png`,
    `image/jpeg`,
    `image/webp`,
    `video/mp4`,
    `video/x-m4v`,
    `video/avi`,
  ];
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState([1]);
  const [aspect, setAspect] = useState(4 / 5);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [video, setVideo] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [filesAdded, setFilesAdded] = useState(null);

  // Aspect Ration feature
  const [onAspect, setOnAspect] = useState(false);
  const [active, setActive] = useState(false);
  const [originalAspect, setOriginal] = useState(null);

  const onCropChange = (cropValue) => {
    setCrop(cropValue);
  };

  const onCropComplete = (croppedArea, cropAreaPixles) => {
    setCroppedAreaPixels(cropAreaPixles);
  };

  // FIXME convert this to a helper function
  const handleCrop = async (cb = null) => {
    let croppingImage = null;
    let fileMediaParsed = false;

    fileMediaParsed = 'originalUrlBlob' in files[0];

    if (fileMediaParsed) {
      croppingImage = files[currentFileIndex].originalUrlBlob;
    } else {
      croppingImage = files[currentFileIndex]?.asset?.fullSizeUrl;
    }

    const { blobUrl, binaryFile } = await getCroppedImg(croppingImage, croppedAreaPixels);

    send({
      type: 'HANDLE_CROP',
      index: currentFileIndex,
      croppedFile: binaryFile,
      croppedUrlBlob: blobUrl,
    });

    send({ type: 'SET_COVER', coverMedia: blobUrl });

    if (!isCropping) {
      send({
        type: 'ONCROPPING',
        isCropping: true,
      });
    }

    send({ type: 'CHANGED_MEDIA', changedMedia: true });
    send({ type: 'SET_CHANGED', changed: true });
    if (cb) {
      const croppedFile = {
        ...files[currentFileIndex],
        croppedFile: binaryFile,
        croppedUrlBlob: blobUrl,
      };
      cb(null, croppedFile);
    }
  };

  const onZoomChange = (zoomValue) => {
    setZoom([zoomValue]);
  };

  const justCrop = () => {
    handleCrop();
  };

  const checkFileToEdit = () => {
    if (!changedMedia && editing && media.length > 0) {
      return send({
        type: 'SET_COVER',
        coverMedia: files[currentFileIndex].source,
      });
    }

    return send({
      type: 'SET_COVER',
      coverMedia: files[0].videoCover || files[0].source,
    });
  };

  const onAddImages = () => {
    setAddMore(true);
    carouselImageUpload.current.click();
  };

  const mediaTransformation = async (uploadedFiles = files, onResponse) => {
    try {
      await mediaHandlingService(uploadedFiles, onResponse, false, null, false);
    } catch (error) {
      throw error;
    }
  };

  const onSuccess = (msg) => {
    const { success, response } = msg;
    if (editing) {
      send({ type: 'CHANGED_MEDIA', changedMedia: true });
    }

    if (success) {
      send({ type: 'SET_PARSED_MEDIA', parsed: false });

      response.forEach((file, i) => {
        const type = response[i].type;

        // defaultUrl = the one to be shown / cropped
        // fullSizeUrl = the original image
        // lowRezUrl = reduced picture size

        if (isCropping) {
          send({
            type: 'CROP_MEDIA',
            index: currentFileIndex,
            croppedData: {
              type: media[currentFileIndex].type,
              asset: {
                fullSizeUrl: media[currentFileIndex].asset.fullSizeUrl,
                defaultUrl: response[0].source,
                lowResUrl: response[0].previewSource,
              },
            },
          });

          send({
            type: 'ONCROPPING',
            isCropping: false,
          });

          setTimeout(() => {
            send({ type: 'SET_CHANGED', changed: true });
            send({ type: 'CHANGED_MEDIA', changedMedia: true });
          }, 200);
        } else {
          const media =
            type === 'image'
              ? {
                  type: 'image',
                  asset: {
                    fullSizeUrl: response[i].source,
                    defaultUrl: response[i].source,
                  },
                }
              : {
                  type: 'media',
                  asset: {
                    type: 'video',
                    defaultUrl: response[i].source,
                    lowResThumbnailUrl: response[i].previewThumbnail,
                    highResThumbnailUrl: response[i].thumbnail,
                  },
                };

          send({ type: 'SET_MEDIA', media: media });
        }
      });

      validateMediaUrl(
        response[0].type === 'video' ? response[0].uncachedThumbnail : response[0].source,
        coverMedia,
        send,
        response[0].type === 'video' ? response[0].type : null
      );

      if (filesAdded) {
        setFilesAdded(null);
      }

      if (addMore) {
        setAddMore(false);
      }

      send({ type: 'SET_LOADING_UPLOAD', loading: false });
    }

    if (currentFileIndex === 0 && !video) {
      checkFileToEdit();
    }
  };

  const handleInitialUploadMediaFiles = async (storage, cb = null) => {
    let scenario = null;

    if (files?.length > 0 && media.length === 0 && !isCropping) {
      scenario = 'initialUpload';
    } else if (isCropping && media.length > 0 && !addMore) {
      scenario = 'cropping';
    } else if (media.length > 0 && addMore) {
      scenario = 'addingMore';
    } else {
      scenario = 'initialUpload';
    }

    send({ type: 'SET_LOADING_UPLOAD', loading: true });

    switch (scenario) {
      case 'addingMore':
        const addedFiles = files.filter((_, index) => index < filesAdded);
        await mediaHandlingService(addedFiles, onSuccess, null, null);
        cb ? cb() : '';
        break;
      case 'cropping':
        await mediaHandlingService(
          storage ? [storage] : files[currentFileIndex],
          onSuccess,
          null,
          null
        );
        cb ? cb() : '';
        break;
      case null:
        break;
      default:
        // mediaTransformation(files, onSuccess);
        await mediaHandlingService(files, onSuccess, null, null);
        cb ? cb() : '';
        break;
    }
  };

  const deleteMediaItem = () => {
    send({ type: 'FINISH_EDIT_MEDIA' });

    send({
      type: 'DELETE_MEDIA',
      index: currentFileIndex,
    });

    if (files?.length === 1) {
      send({
        type: 'ONCROPPING',
        isCropping: false,
      });
      send({ type: 'SET_COVER', coverMedia: null });
    }
  };

  // const cancelCrop = () => {
  //   if (!changed) {
  //     send({ type: 'SET_CHANGED', changed: false });
  //   }
  //   setCroppedAreaPixels(null);
  //   setZoom([1]);
  //   setCrop({ x: 0, y: 0 });
  //   send({ type: 'FINISH_EDIT_MEDIA' });
  // };

  const handleFileUpload = (addedFiles) => {
    getCleanUploadedFiles(addedFiles).then((chosenFiles) => {
      setFilesAdded(chosenFiles.length);
      chosenFiles.forEach(async ({ fileObject }) => {
        const imageDataUrl = await readFile(fileObject);
        send({
          type: 'ADD_MEDIA',
          file: {
            originalFile: fileObject,
            originalUrlBlob: imageDataUrl,
            croppedFile: fileObject,
            croppedUrlBlob: imageDataUrl,
          },
        });
      });
    });
  };

  const completeEditing = async () => {
    if (isCropping) {
      setTimeout(() => {
        waterfall([
          function (callback) {
            handleCrop(callback);
          },
          function (storage, callback) {
            handleInitialUploadMediaFiles(storage, callback);
          },
        ]);
      }, 500);
    }
    send({ type: 'VIEW_SEQUENCE', sequence: false });
    // send({ type: 'SET_CHANGED', changed: false });
    send({ type: 'FINISH_EDIT_MEDIA' });
  };

  const toggleItem = (val, asp, value) => {
    setActive(val);
    if (value === 'Original') {
      setAspect(originalAspect);
    } else {
      setAspect(asp);
    }
  };

  // const onToggleOrder = () => {
  //   if (!sequence) {
  //     send({ type: 'VIEW_SEQUENCE', sequence: true });
  //   } else if (sequence && sequenceIndex) {
  //     setTimeout(() => send({ type: 'SET_SEQUENCE', index: null }), 100);
  //   } else if (!sequenceIndex && sequence) {
  //     send({ type: 'VIEW_SEQUENCE', sequence: false });
  //   } else {
  //     // console
  //   }
  // };

  useEffect(() => {
    let id = null;
    // let upload = null;
    if (files && files?.length <= 0) {
      send({ type: 'SET_LOADING_UPLOAD', loading: false });
    }

    if (addMore) {
      id = setTimeout(() => {
        handleInitialUploadMediaFiles();
      }, 500);
    }
    return () => clearInterval(id);
  }, [files?.length]);

  // useEffect(() => {
  //   if (sequenceFileIndex !== 0) {
  //     handleCrop();
  //   }
  // }, [sequenceFileIndex]);

  useEffect(() => {
    if (editing) {
      if (media && !isCropping) {
        send({ type: 'EDITING_VIEW' });
        if (media && media.length > 0) {
          if (media[0].type === 'media' && media[0]?.asset?.type === 'video') {
            send({
              type: 'SET_COVER',
              coverMedia:
                media[currentFileIndex]?.asset?.highResThumbnailUrl ||
                media[currentFileIndex]?.asset?.lowResThumbnailUrl,
            });
          } else {
            send({
              type: 'SET_COVER',
              coverMedia:
                media[currentFileIndex]?.asset?.defaultUrl || media[currentFileIndex]?.source,
            });
          }
        }
      }
    }

    // When moving next/prev on header navigation items, we need to reset the state
    // to view cover image if we have an image
    if (!editing && media) {
      send({ type: 'SWITCH_VIEW' });
    }
  }, []);

  return (
    <div className={styles.mediaWrapper}>
      <div className={styles.addMedia}>
        <input
          type="file"
          tabIndex="-1"
          accept="image/png,image/jpg,image/jpeg,image/webp"
          id="multipleFileUpload"
          multiple
          min={2}
          hidden
          style={{ opacity: 0 }}
          ref={carouselImageUpload}
          onChange={(value) => handleFileUpload(value)}
        />
        {current.matches({ editor: 'idle' }) || files?.length === 0 ? (
          <Dropzone
            accept="image/png,image/jpg,image/jpeg,image/webp,video/mp4,video/x-m4v,video/*"
            maxFiles={10}
            multiple
            canCancel={false}
            InputComponent={CoverDropzoneInput}
            getFilesFromEvent={(e) =>
              getFilesFromEvent(e, send, setCrop, setZoom, null, acceptedFileTypes, setAddMore)
            }
            styles={{
              dropzone: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'unset',
                width: 415,
                height: 501,
                border: 'none',
                backgroundColor: '#e5e5e5',
                borderTopLeftRadius: '1.4rem',
                borderTopRightRadius: '1.4rem',
                position: 'absolute',
                left: '0',
                top: '0',
              },
              dropzoneReject: {
                border: '2px solid red',
                backgroundColor: '#DAA',
              },
              dropzoneActive: {
                border: '2px solid #50F246',
              },
            }}
          />
        ) : (
          ''
        )}
        {current.matches({ editor: 'uploading' }) ||
        current.matches({ editor: 'validatingCover' }) ? (
          <ThreeDots />
        ) : (
          ''
        )}
        {(current.matches({ editor: 'view' }) || current.matches({ editor: 'viewEdit' })) && (
          <ImageCarousel send={send} onAddImages={() => onAddImages()} current={current} />
        )}
        {/* TODO need to improve the reliability of editing image, to avoid confussion */}
        {current.matches({ editor: 'edit' }) && (
          <div>
            <div>
              <div className={styles.mediaOptions}>
                <MediaButton
                  icon="trash"
                  id="deleteMedia"
                  hasAdditionalClass="deleteButtonClass"
                  onBtnClick={() => deleteMediaItem()}
                />

                <MediaButton
                  icon="add"
                  onBtnClick={() => onAddImages()}
                  hasStyle={{ marginLeft: '1rem' }}
                />

                <MediaButton
                  icon="aspect-ratio"
                  active={onAspect}
                  onBtnClick={() => setOnAspect(!onAspect)}
                  hasStyle={{ marginRight: 'auto', marginLeft: '1rem' }}
                />
                <MediaButton id="finishEditing" done onBtnClick={() => completeEditing()} />
              </div>
              <Cropper
                image={
                  files[currentFileIndex]?.asset
                    ? files[currentFileIndex]?.asset.fullSizeUrl
                    : files[currentFileIndex].originalUrlBlob
                }
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                minZoom={1}
                maxZoom={3}
                width={375}
                height={410}
                style={{
                  cropAreaStyle: {
                    border: '0',
                  },
                }}
                showGrid={false}
                onMediaLoaded={(mediaSize) => setOriginal(mediaSize.width / mediaSize.height)}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                onInteractionEnd={justCrop}
              />
              {!onAspect ? (
                <div className={rangeWrap}>
                  <Slider
                    min={1}
                    max={3}
                    step={0.2}
                    value={zoom}
                    draggableTrack={false}
                    onChange={(val) => {
                      setZoom([val]);
                      handleCrop();
                    }}
                  />
                </div>
              ) : (
                ''
              )}
              {onAspect && (
                <div className={aspectRatiosStyle}>
                  <div className={btnWrapper}>
                    {aspectRatios.map((ratio) => (
                      <button
                        type="button"
                        key={ratio.id}
                        className={active === ratio.id ? activeItem : null}
                        onClick={() => toggleItem(ratio.id, ratio.aspect, ratio.val)}
                      >
                        {ratio.val}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

MediaCover.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
  onToggleOrder: PropTypes.func,
};

export default MediaCover;
