import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useMachine } from '@xstate/react';
import { arrayMoveImmutable } from 'array-move';
import { waterfall } from 'async';
import { motion } from 'framer-motion';
import { assign } from 'xstate';

import ClientOnly from '@components/molecules/clientOnly';
import AddLinkModal from '@components/molecules/modal/components/add-link';
import AddVideoModal from '@components/molecules/modal/components/add-video';
import BottomNavigation from '@components/molecules/navigation';
import Header from '@components/organisms/header';
import ConfirmEdit from '@components/templates/create-card/views/confirm-edit';
// Steps to Create Cards
import CreateCardEditor from '@components/templates/create-card/views/editor-types/create-card-editor';
import CurationCardEditor from '@components/templates/create-card/views/editor-types/create-curation-card';
import CreateVideoMessageCard from '@components/templates/create-card/views/editor-types/video-message-editor';
import MediaSourceInformation from '@components/templates/create-card/views/media-source';
import PreviewCard from '@components/templates/create-card/views/preview';
import Published from '@components/templates/create-card/views/published';
import Saved from '@components/templates/create-card/views/saved';
import CardFormats from '@components/templates/create-card/views/select-card-format';
import SelectChannel from '@components/templates/create-card/views/select-channel';
import { isLoggedIn } from '@helpers';

import { performAutosave } from './helpers';
// import { getTenant, getToken } from '../../../helpers';
import createCardWizardFormMachine from './shared/machine';

const CreateCardContainer = ({ subformat = null, id }) => {
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const [modalValues, setModalValues] = useState(null);
  const [videoModalValues, setVideoModalValues] = useState({});

  const [current, send] = useMachine(createCardWizardFormMachine, {
    actions: {
      setUserId: assign({
        userId: (_, event) => event.userId,
      }),
      setEditing: assign({
        editing: (_, event) => event.editing,
      }),
      setInitData: assign({
        initData: (_, event) => event.initData,
      }),
      setFormat: assign({
        format: (ctx, event) => event.format,
      }),
      setSubFormat: assign({
        subFormat: (ctx, event) => event.subformat,
      }),
      // Set States of editor
      setChanged: assign({
        changed: (_, event) => event.changed,
      }),
      setLoading: assign({
        loadingUpload: (_, event) => event.loading,
      }),
      viewSequence: assign({
        sequence: (_, event) => event.sequence,
      }),
      setSelectedEntity: assign({
        selectedEntity: (_, event) => event.selectedEntity,
      }),
      setUrlValue: assign({
        urlValue: (_, event) => event.urlValue,
      }),
      setSequence: assign({
        sequenceIndex: (_, event) => event.index,
      }),
      // Files & Media
      addBinaryFiles: assign({
        files: (ctx, event) => [event.file, ...ctx.files],
      }),
      setBinaryFiles: assign({
        files: (ctx, event) => [...ctx.files, event.file],
      }),
      setCurrentFileIndex: assign({
        currentFileIndex: (ctx, event) => event.index,
        onEdit: true,
      }),
      setSequenceIndex: assign({
        sequenceFileIndex: (ctx, event) => ctx.sequenceFileIndex + 1,
      }),
      changedMedia: assign({
        changedMedia: (_, event) => event.changedMedia,
      }),
      setDisabled: assign({
        disabled: (_, event) => event.disabled,
      }),
      setDiscarded: assign({
        discarding: (_, event) => true,
      }),
      setParsedMedia: assign({
        parsedMedia: (_, event) => event.parsed,
      }),
      // Media Cropping
      setCropping: assign({
        isCropping: (ctx, event) => event.isCropping,
      }),
      handleCrop: assign({
        files: (ctx, event) => [
          ...ctx.files.slice(0, event.index),
          {
            ...ctx.files[event.index],
            croppedFile: event.croppedFile,
            croppedUrlBlob: event.croppedUrlBlob,
          },
          ...ctx.files.slice(event.index + 1),
        ],
      }),
      cropMedia: assign({
        media: (ctx, event) => [
          ...ctx.media.slice(0, event.index),
          {
            ...ctx.media[event.index],
            ...event.croppedData,
          },
          ...ctx.media.slice(event.index + 1),
        ],
      }),
      setVideoCover: assign({
        files: (ctx, event) => [
          ...ctx.files.slice(0, event.index),
          {
            ...ctx.files[event.index],
            videoCover: event.videoCover,
          },
          ...ctx.files.slice(event.index + 1),
        ],
      }),
      setCoverMedia: assign({
        coverMedia: (ctx, event) => event.coverMedia,
      }),
      setEmbedValues: assign({
        embedValues: (ctx, event) => event.embedValues,
      }),
      deleteDuplicate: assign({
        media: (ctx, event) => [
          ...ctx.media.slice(0, event.index),
          ...ctx.media.slice(event.index + 1),
        ],
      }),
      deleteMedia: assign({
        files: (ctx, event) => [
          ...ctx.files.slice(0, event.index),
          ...ctx.files.slice(event.index + 1),
        ],
        media: (ctx, event) => [
          ...ctx.media.slice(0, event.index),
          ...ctx.media.slice(event.index + 1),
        ],
      }),
      toRemovedMedia: assign({
        removedMedia: (ctx, event) => [...ctx.removedMedia, event.media],
      }),
      setLightBox: assign({
        lightbox: (ctx, event) => event.lightbox,
      }),
      setGalleryImages: assign({
        gallery: (ctx, event) => event.gallery,
      }),
      setGalleryIndex: assign({
        galleryIndex: (_, event) => event.index,
      }),
      validateMedia: assign({
        validateMedia: (_, event) => event.validate,
      }),
      // Content Info
      setCardId: assign({
        cardId: (ctx, event) => event.cardId,
      }),
      setTitle: assign({
        title: (_, event) => event.title,
      }),
      setDescription: assign({
        description: (_, event) => event.description,
      }),
      setContent: assign({
        content: (_, event) => event.source,
      }),
      addComponentToContent: assign({
        content: (_, event) => event.newContent,
      }),
      setEditorContent: assign({
        content: (ctx, event) => event.updatedContent,
      }),
      setContentComponentData: assign({
        content: (ctx, event) => [
          ...ctx.content.slice(0, event.componentIndex),
          {
            ...event.updated,
          },
          ...ctx.content.slice(ctx.componentIndex + 1),
        ],
      }),
      setEditorIndex: assign({
        editorIndex: (_, event) => event.index,
      }),
      setCaretData: assign({
        caretData: (_, event) => event.caretData,
      }),
      setEditorBlocks: assign({
        editorBlocks: (_, event) => event.editorBlocks,
      }),
      setCursorIndex: assign({
        cursorIndex: (_, event) => event.index,
      }),
      onMediaToggle: assign({
        toggleMediaIndex: (_, event) => event.index,
      }),
      // Set initial context on card edit
      setContext: assign({
        title: (_, event) => event.context.title,
        description: (_, event) => event.context.description,
        content: (_, event) => event.context.content,
        subFormat: (_, event) => event.context.subFormat,
        format: (_, event) => event.context.format,
        userId: (_, event) => event.context.userId,
        tenantId: (_, event) => event.context.tenantId,
        cardId: (_, event) => event.context.cardId,
        media: (ctx, event) => event.context.media,
        files: (ctx, event) => event.context.files,
        streamId: (ctx, event) => event.context.streamId,
        author: (ctx, event) => event.context.author,
        piqd: (ctx, event) => event.context.piqd,
        created: (ctx, event) => event.context.created,
        cardState: (ctx, event) => event.context.cardState,
        autosaveData: (ctx, event) => event.context.autosaveData,
      }),
      setUpdatingData: assign({
        updatingData: (ctx, event) => event.updatingData,
      }),
      // Toggle Blocks and Style inside the editor
      setInlineStyle: assign({
        inlineStyle: (_, event) => event.inlineStyle,
      }),
      setBlockTypeStyle: assign({
        blockTypeStyle: (_, event) => event.blockTypeStyle,
      }),
      setToggleIndex: assign({
        toggleIndex: (_, event) => event.index,
      }),
      setCurrentEditorIndex: assign({
        currentEditorIndex: (_, event) => event.index,
      }),
      // Media changes
      setMedia: assign({
        media: (ctx, event) => [...ctx.media, event.media],
      }),
      setRemovedMedia: assign({
        removedMedia: (ctx, event) => [...ctx.removedMedia, event.media],
      }),
      setVideoMedia: assign({
        media: (ctx, event) => [
          ...ctx.media.slice(0, 0),
          {
            ...ctx.media[0],
            ...event.media,
          },
          ...ctx.media.slice(1),
        ],
      }),
      setLinkMedia: assign({
        media: (ctx, event) => [
          ...ctx.media.slice(0, 0),
          {
            ...ctx.media[0],
            ...event.media,
          },
          ...ctx.media.slice(1),
        ],
      }),
      setAspectRatio: assign({
        media: (ctx, event) => [
          ...ctx.media.slice(0, ctx.currentFileIndex),
          {
            ...ctx.media[ctx.currentFileIndex],
            intendedAspectRatio: event.aspectRatio,
          },
          ...ctx.media.slice(ctx.currentFileIndex + 1),
        ],
      }),
      onEditingLink: assign({
        selectedEntity: true,
        currentLinkData: (_, event) => event.data.currentLinkData,
      }),
      // MetaData changes
      setCoverMetaData: assign({
        media: (ctx, event) => [
          ...ctx.media.slice(0, event.index),
          {
            ...ctx.media[event.index],
            sourceInformation: event.sourceInformation,
          },
          ...ctx.media.slice(event.index + 1),
        ],
      }),
      setCarouselMetaData: assign({
        content: (ctx, event) => [
          ...ctx.content.slice(0, event.parentIndex),
          {
            ...event.updatedCarousel,
          },
          ...ctx.content.slice(event.parentIndex + 1),
        ],
      }),
      setImageMetaData: assign({
        content: (ctx, event) => [
          ...ctx.content.slice(0, event.index),
          {
            ...ctx.content[event.index],
            sourceInformation: event.sourceInformation,
          },
          ...ctx.content.slice(event.index + 1),
        ],
      }),
      // Change media order on drag & drop
      orderMedia: assign({
        media: (_, event) => arrayMoveImmutable(event.media, event.oldIndex, event.newIndex),
      }),
      orderFiles: assign({
        files: (_, event) => arrayMoveImmutable(event.files, event.oldIndex, event.newIndex),
      }),
      orderCarousel: assign({
        content: (ctx, event) => [
          ...ctx.content.slice(0, event.cId),
          {
            ...ctx.content[event.cId],
            items: arrayMoveImmutable(ctx.content[event.cId].items, event.oldIndex, event.newIndex),
          },
          ...ctx.content.slice(event.cId + 1),
        ],
      }),
      // Clear/Clean data
      cleanMedia: assign({
        media: (ctx, event) => [
          ...ctx.media.slice(0, event.index),
          ...ctx.media.slice(event.index + 1),
        ],
      }),
      clearTitle: assign({
        title: (ctx, event) => '',
      }),
      clearDescription: assign({
        title: (ctx, event) => '',
      }),
      cleanContext: assign({
        editing: () => false,
        changed: () => false,
        isExiting: () => false,
        changedMedia: () => false,
        title: () => '',
        description: () => '',
        coverMedia: () => {},
        content: () => [
          {
            type: 'text',
            source: '<p></p>',
          },
        ],
        userId: () => null,
        tenantId: () => null,
        cardId: () => null,
        media: () => [],
        files: () => [],
        streamId: () => null,
        sequenceIndex: () => null,
        created: () => '',
        author: () => null,
      }),
      cleanInitData: assign({
        initData: () => null,
      }),
      setStreamId: assign({
        streamId: (_, event) => event.streamId,
      }),
      // Determine card status for publishing
      isExiting: assign({
        isExiting: (_, event) => event.exit,
      }),
      isForSaving: assign({
        isForSaving: (_, event) => true,
        disabled: () => true,
      }),
      isForPublishing: assign({
        isForPublishing: (_, event) => true,
      }),
      setAutoSave: assign({
        autoSaveId: (_, event) => event.autosaveId,
        isAutoSaved: () => true,
      }),
      existingAutosave: assign({
        autoSaveId: (_, event) => event.data.autoSaveId,
        autosaveData: (_, event) => event.data.autosaveData,
        autoSaving: () => false,
      }),
      newAutosave: assign({
        userId: (ctx, event) => event.data.userId,
        cardId: (ctx, event) => event.data.cardId,
        autoSaveId: (_, event) => event.data.autoSaveId,
        streamId: (ctx, event) => event.data.streamId,
        cardState: (ctx, event) => event.data.cardState,
        autosaveData: (_, event) => event.data.autosaveData,
        autoSaving: () => false,
      }),
    },
  });

  const switchFormats = (cardFormat) => {
    switch (cardFormat) {
      case 'indepth':
        return send({ type: 'START_INDEPTH_EDITOR' });
      case 'curation':
        return send({ type: 'START_CURATION_EDITOR' });
      case 'video-message':
        return send({ type: 'START_VIDEO_MESSAGE_EDITOR' });
      default:
        return null;
    }
  };

  const addModalData = (values) => {
    if (values?.updatedData) {
      setModalValues(null);
      setTimeout(() => setModalValues(values), 500);
    } else {
      setModalValues(values);
    }
  };

  const updateModalValues = (val) => {
    setVideoModalValues(val);
  };

  // const { context, value, event } = current;
  // const { autoSaveId, userId, cardState, cardId, media, autosaveData, currentLinkData } = context;

  useEffect(() => {
    if (id) {
      send({ type: 'SET_EDITING', editing: true });
      send({ type: 'SET_CARD_ID', cardId: id });
      switchFormats(subformat);
    }
  }, [id]);

  return (
    <motion.div
      className="someContainer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Header isLoggedIn={isLoggedIn()} current={current} send={send} />
      {showLinkModal && (
        <AddLinkModal
          showModal={showLinkModal}
          setShowModal={setShowLinkModal}
          modalValues={current.context.media[0]}
          sendData={(values) => addModalData(values)}
        />
      )}
      {showVideoModal && (
        <AddVideoModal
          showModal={showVideoModal}
          setShowModal={setShowVideoModal}
          videoModalValues={current.context.media}
          sendData={(values) => updateModalValues(values)}
        />
      )}
      <ClientOnly className="cardWizard">
        {current.matches('formats') && <CardFormats current={current} send={send} />}
        {current.matches('editor') && (
          <CreateCardEditor current={current} send={send} cardId={id} />
        )}
        {current.matches('curationEditor') && (
          <CurationCardEditor
            current={current}
            send={send}
            showLinkModal={setShowLinkModal}
            showVideoModal={setShowVideoModal}
            videoModalData={videoModalValues}
            modalData={modalValues}
            cardId={id}
          />
        )}
        {current.matches('videoMessageEditor') && (
          <CreateVideoMessageCard current={current} send={send} cardId={id} />
        )}
        {current.matches('confirmEdit') && <ConfirmEdit current={current} />}
        {current.matches('mediaSourceInformation') && (
          <MediaSourceInformation current={current} send={send} />
        )}
        {current.matches('previewCard') && <PreviewCard current={current} send={send} />}
        {current.matches('selectChannel') && <SelectChannel current={current} send={send} />}
        {current.matches('published') && <Published />}
        {current.matches('saved') && <Saved />}
        {!current.matches('editor') && <BottomNavigation isLoggedIn={isLoggedIn()} />}
      </ClientOnly>
    </motion.div>
  );
};

CreateCardContainer.propTypes = {
  id: PropTypes.string,
  subformat: PropTypes.string,
};

export default CreateCardContainer;
