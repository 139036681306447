import React from 'react';
import PropTypes from 'prop-types';

const SpotifyEmbed = ({ source }) => {
  return (
    <iframe
      style={{ borderRadius: '12px' }}
      src={`https://open.spotify.com/embed/track/${source}?utm_source=generator`}
      width="100%"
      height="380"
      frameBorder="0"
      allowfullscreen=""
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
    ></iframe>
  );
};

SpotifyEmbed.propTypes = {
  source: PropTypes.string.isRequired,
};

export default SpotifyEmbed;
