import React, { useEffect, useState } from 'react';

import useSWR, { useSWRConfig } from 'swr';
import { waterfall } from 'async';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import SEO from '@components/seo';
import Header from '@components/organisms/header';
import ClientOnly from '@components/molecules/clientOnly';
import Modal from '@components/molecules/modal';
import Icon from '@components/atoms/icon';
import Api from '@api';
import Card from '@components/organisms/card';
import ThreeDots from '@components/atoms/loaders/three-dots';
import Button from '@components/atoms/button';
import PlaceholderImage from '@images/placeholder-image.png';

import { isLoggedIn, isCurator, hasSocials, handleAspectRatio, getTenant } from '@helpers';
import { containerWrapper, cardsWrapper, pageHeader, iconHeader } from '@styles/main.module.scss';
import { switchFormatsButtonWrap } from '@components/organisms/header/header.module.scss';
import { pageTypeSwitcher, noCards } from './autosave.module.scss';
import { incomingCards } from '@styles/main.module.scss';

const AutoSaved = () => {
  const { cache } = useSWRConfig();
  // Modal state
  const [cId, setcId] = useState(null);
  const [type, setType] = useState('');
  const [currentState, setCurrentState] = useState('');
  const [page, setPage] = useState('cards');

  // data objects
  const [cards, setCards] = useState([]);
  const [channels, setChannels] = useState([]);

  // page indexes
  const [emptyData, setEmptyData] = useState(false);
  const [prevPage, setPrevPage] = useState(1);
  const [prevChannelPage, setChannelPrevPage] = useState(1);
  const [cardPageIndex, setCardPageIndex] = useState(1);
  const [channelPageIndex, setChannelPageIndex] = useState(1);

  // fetching mechanism
  const [fetching, setFetching] = useState(false);
  const [noFetching, setNoFetching] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');

  const formatTypes = [
    {
      id: 'cards',
      name: 'Cards',
    },
    {
      id: 'channels',
      name: 'Channels',
    },
  ];

  const {
    data: cardData,
    error: errorCards,
    mutate: mutateCards,
  } = useSWR(['/listContentCreationCards', page, cardPageIndex], () =>
    Api.listContentCreationCards('', 'autosaved', 'DESC', cardPageIndex)
  );

  const {
    data: channelData,
    error: errorChannels,
    mutate: mutateChannels,
  } = useSWR(['/listContentCreationStreams', page, channelPageIndex], () =>
    Api.listContentCreationStreams('autosaved', 'DESC', channelPageIndex)
  );

  const handleCardStateAction = (id, type, cardState) => {
    setType(type);
    setcId(id);
    setCurrentState(cardState);
    switch (type) {
      case 'unpublish':
        setModalTitle(`Recover ${page === 'cards' ? 'Card' : 'Channel'}`);
        setModalDesc(
          `Are you sure you want to recover your ${page === 'cards' ? 'card' : 'channel'}?`
        );
        break;
      case 'trash-delete':
        setModalTitle(
          <>
            Are you sure you want to delete <br />
            this card?
          </>
        );
        setModalDesc(
          <>
            The card will be deleted accordingly and <br />
            disappear for your users!
          </>
        );
        break;
      default:
        break;
    }
    setTimeout(() => {
      setShowModal(true);
    }, 200);
  };

  const clearModal = (hide) => {
    if (hide) {
      setShowModal(false);
    }
    setType('');
    setModalTitle('');
    setModalDesc('');
  };

  const deleteCard = async (cb) => {
    const cardId = cId;
    try {
      const response = await Api.deleteCard({
        cardId,
        tenantId: getTenant(),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteChannel = async (cb) => {
    const streamId = cId;
    try {
      const response = await Api.deleteChannel({
        streamId,
        tenantId: getTenant(),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const checkState = (state) => {
    if (type === 'trash-delete' && currentState === 'autosaved') {
      return 'trashed_autosaved';
    }
    return state;
  };

  const updateCardState = async (cardState, cb) => {
    const cardId = cId;
    try {
      const response = await Api.changeCardState({
        cardId,
        tenantId: getTenant(),
        cardState: checkState(cardState),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const changeChannelState = async (channelState, cb) => {
    const streamId = cId;
    try {
      const response = await Api.updateChannelState(getToken(), {
        streamId,
        tenantId: getTenant(),
        streamState: checkState(channelState),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCardAction = () => {
    setShowModal(false);
    waterfall(
      [
        function (callback) {
          deleteCard(callback);
        },
      ],
      (err, res) => {
        if (!err) {
          setShowModal(true);
          if (res.code === 500 || res.code === 403 || res.code === 400) {
            setModalValues(
              'error',
              'Card can’t be deleted',
              'Something went wrong! Card can not be deleted!'
            );
          } else {
            setModalValues('successfully-deleted', 'Deleted card', 'Card was deleted successfully');
            mutateCards();
            const mutatedCards = cards.filter((card) => card.cardId !== cId);
            setCards(mutatedCards);
            cache.delete();
          }
        }
      }
    );
  };

  const deleteChannelAction = () => {
    setShowModal(false);
    waterfall(
      [
        function (callback) {
          deleteChannel(callback);
        },
      ],
      (err, res) => {
        if (!err) {
          setShowModal(true);
          if (res.code === 500 || res.code === 403 || res.code === 400) {
            setModalValues(
              'error',
              'Channel can’t be deleted',
              'Something went wrong! Channel can not be deleted!'
            );
          } else {
            setModalValues(
              'successfully-deleted',
              'Deleted channel',
              'Channel was deleted successfully'
            );
            mutateChannels();
            const mutatedChannels = channels.filter((channel) => channel.streamId !== cId);
            setChannels(mutatedChannels);
            cache.delete();
          }
        }
      }
    );
  };

  const setModalValues = (modalType, title, desc) => {
    setType(modalType);
    setModalTitle(title);
    setModalDesc(desc);
  };

  const handleErrorModal = (type) => {
    switch (type) {
      case 'unpublished':
        setModalValues(
          'error',
          'Card can’t be recovered',
          'Something went wrong! Card can not be recovered!'
        );
        break;
      case 'trashed':
        setModalValues(
          'error',
          'Card can not be deleted',
          'Something went wrong! Card can not be deleted!'
        );
        break;
      default:
        break;
    }
  };

  const handleSuccessModal = (type) => {
    switch (type) {
      case 'unpublished':
        setModalValues(
          'successfully-deleted',
          `Recovered ${page === 'cards' ? 'card' : 'channel'}`,
          `${page === 'cards' ? 'Card' : 'Channel'} was recovered successfully`
        );
        if (page === 'cards') {
          mutateCards();
        } else {
          mutateChannels();
        }
        cache.delete();
        break;
      case 'trashed':
        setModalValues(
          'successfully-deleted',
          `Deleted ${page === 'cards' ? 'card' : 'channel'}`,
          `${page === 'cards' ? 'Card' : 'Channel'} was deleted successfully`
        );
        if (page === 'cards') {
          mutateCards();
        } else {
          mutateChannels();
        }
        cache.delete();
        break;
      default:
        break;
    }
  };

  const changeCardStateAction = (state) => {
    setShowModal(false);
    waterfall(
      [
        function (cb) {
          updateCardState(state, cb);
        },
      ],
      (err, res) => {
        setShowModal(true);
        if (!res?.success) {
          handleErrorModal(state);
        } else {
          handleSuccessModal(state);
        }
      }
    );
  };

  const changeChannelStateAction = (state) => {
    setShowModal(false);
    waterfall(
      [
        function (cb) {
          changeChannelState(state, cb);
        },
      ],
      (err, res) => {
        setShowModal(true);
        if (!res?.success) {
          handleErrorModal(state);
        } else {
          handleSuccessModal(state);
        }
      }
    );
  };

  const handleModalAction = (modalType) => {
    if (page === 'cards') {
      if (modalType === 'delete') {
        deleteCardAction();
      }

      if (modalType === 'trash-delete') {
        changeCardStateAction('trashed');
      }

      if (modalType === 'unpublish') {
        changeCardStateAction('unpublished');
      }
    } else {
      if (modalType === 'delete') {
        deleteChannelAction();
      }

      if (modalType === 'unpublish') {
        changeChannelStateAction('unpublished');
      }
    }

    clearModal();
  };

  const CardProps = (card) => {
    const cardObject = {
      key: card.cardId,
      id: page === 'cards' ? card.cardId : card.streamId,
      title: page === 'cards' ? card.title : card.name,
      description: card.description,
      src: card?.coverMedia?.items?.length
        ? card?.coverMedia?.items[0]
        : { source: PlaceholderImage },
      channelId: card.streamId,
      isChannel: card.streamState && true,
      channel: card.stream,
      status: card.cardState || card.streamState,
      curator: isCurator.includes(card.subFormat),
      cardClass: card.subFormat,
      category: card.category,
      type: card.subFormat,
      date: card.created,
      author: card.author,
      isPiqd: card.contentSourceProvider && card.contentSourceProvider === 'piqd',
      isList: true,
      hasLink: true,
      socials: hasSocials.includes(card.subFormat) ? card.counters : null,
      intendedAspectRatio: handleAspectRatio(card?.media, card?.subFormat),
      recoverCard: () =>
        handleCardStateAction(card.cardId || card.streamId, 'unpublish', card.cardState),
      deleteCard: () => handleCardStateAction(card.cardId || card.streamId, 'trash-delete'),
      moveCard: () => navigate(`/app/move-to-channel/${card.cardId}`),
    };

    return cardObject;
  };

  const onScrollEnd = () => {
    if (!noFetching) {
      setFetching(true);
      if (!fetching) {
        if (page === 'cards') {
          setCardPageIndex(cardPageIndex + 1);
        } else {
          setChannelPageIndex(channelPageIndex + 1);
        }
      }
    }
  };

  useBottomScrollListener(onScrollEnd);

  useEffect(() => {
    if (page === 'cards' && cardData) {
      if (cardData.cards.length === 0) {
        setEmptyData(true);

        if (cards.length > 0) {
          setNoFetching(true);
        }
      } else {
        setNoFetching(false);
        if (cardPageIndex === prevPage) {
          setCards(cardData.cards);
          setFetching(false);
        } else {
          const newStackOfCards = [...cards, ...cardData.cards];
          setCards(newStackOfCards);
          setPrevPage(cardPageIndex);
          setFetching(false);
        }
      }
    }
  }, [cardData, errorCards]);

  useEffect(() => {
    if (page === 'channels' && channelData) {
      if (channelData.length === 0) {
        setEmptyData(true);

        if (channels.length > 0) {
          setNoFetching(true);
        }
      } else {
        setNoFetching(false);
        if (channelPageIndex === prevChannelPage) {
          setChannels(channelData);
          setFetching(false);
        } else {
          const newStackOfChannels = [...channels, ...channelData];
          setChannels(newStackOfChannels);
          setChannelPrevPage(channelPageIndex);
          setFetching(false);
        }
      }
    }
  }, [channelData, errorChannels]);

  useEffect(() => {
    if (page === 'cards') {
      setChannels([]);
    } else {
      setCards([]);
    }
  }, [page]);

  return (
    <>
      <SEO title="Autosaved" />
      <Header isLoggedIn={isLoggedIn()} />
      {showModal && (
        <Modal
          title={modalTitle}
          desc={modalDesc}
          type={type}
          showModal={showModal}
          hasCardId={cId}
          hasTenantId={getTenant()}
          onConfirm={() => handleModalAction(type)}
          onCancel={() => clearModal('hide')}
        />
      )}

      <ClientOnly>
        <div className={containerWrapper}>
          <div className={pageHeader}>
            <div className={iconHeader}>
              <Icon iconClass="autosave" />
              <h2>Autosaved</h2>
            </div>
            <p>
              Autosaved {page === 'cards' ? 'Cards' : 'Channels'} will be permanently deleted after
              90 days automatically.
            </p>
          </div>
          <div className={`${switchFormatsButtonWrap} ${pageTypeSwitcher}`}>
            {formatTypes.map((item) => (
              <Button
                btnClass={page === item.id ? 'activeBtn' : ''}
                key={item.id}
                headerBtn
                onClick={() => setPage(item.id)}
              >
                {item.name}
              </Button>
            ))}
          </div>
          <div
            className={`
              ${cardsWrapper} 
              ${
                (page === 'cards' && cards && cards?.length === 0) ||
                (page === 'channels' && channels && channels?.length === 0)
                  ? noCards
                  : ''
              }
            `}
          >
            {page === 'cards' &&
              (cards && cards?.length > 0 ? (
                cards?.map((card) => <Card {...CardProps(card)} />)
              ) : (
                <h2>No Autosaved Cards</h2>
              ))}

            {page === 'cards' && errorCards ? <h2>Error Fetchign Autosaved Cards</h2> : ''}

            {page === 'channels' &&
              (channels && channels.length > 0 ? (
                channels?.map((stream) => <Card {...CardProps(stream)} />)
              ) : (
                <>
                  <h2>No Autosaved Channels</h2>
                </>
              ))}

            {page === 'channels' && errorChannels ? <h2>Error Fetchign Autosaved Channels</h2> : ''}

            {!cardData && cards.length > 0 && (
              <div className={incomingCards}>
                <h5>Loading New Cards</h5>
                <ThreeDots />
              </div>
            )}
          </div>
        </div>
      </ClientOnly>
    </>
  );
};

export default AutoSaved;
