// extracted by mini-css-extract-plugin
export var activeBtn = "spaces-module--activeBtn--9b63f";
export var activePlayground = "spaces-module--activePlayground--5856d";
export var buttonIcon = "spaces-module--buttonIcon--f14ac";
export var buttonSlider = "spaces-module--buttonSlider--79717";
export var buttonWrapper = "spaces-module--buttonWrapper--acaf4";
export var contentWrapper = "spaces-module--contentWrapper--dab5e";
export var detailsWrapper = "spaces-module--detailsWrapper--8a8eb";
export var disabledItem = "spaces-module--disabledItem--06fb7";
export var firstItem = "spaces-module--firstItem--21865";
export var fullGridWrapper = "spaces-module--fullGridWrapper--9e2d0";
export var iconWrapper = "spaces-module--iconWrapper--184d8";
export var initial = "spaces-module--initial--86e7d";
export var justtAccount = "spaces-module--justt-account--3a315";
export var justtAdd = "spaces-module--justt-add--8e0d4";
export var justtAddVideo = "spaces-module--justt-add-video--d971c";
export var justtArrowLeft = "spaces-module--justt-arrow-left--43950";
export var justtArrowRight = "spaces-module--justt-arrow-right--c3de4";
export var justtArticleLink = "spaces-module--justt-article-link--a6bd0";
export var justtAspectRatio = "spaces-module--justt-aspect-ratio--b8776";
export var justtAutosave = "spaces-module--justt-autosave--00261";
export var justtBlur = "spaces-module--justt-blur--6017f";
export var justtCalendar = "spaces-module--justt-calendar--31f9f";
export var justtCamera = "spaces-module--justt-camera--638e5";
export var justtCards = "spaces-module--justt-cards--9a9f6";
export var justtChannel = "spaces-module--justt-channel--02223";
export var justtChannels = "spaces-module--justt-channels--5dfc8";
export var justtCharacters = "spaces-module--justt-characters--f3cf3";
export var justtCircularAdd = "spaces-module--justt-circular-add--3daca";
export var justtClose = "spaces-module--justt-close--9e784";
export var justtCloseThin = "spaces-module--justt-close-thin--fc0d8";
export var justtComments = "spaces-module--justt-comments--c1440";
export var justtCreate = "spaces-module--justt-create--7bde0";
export var justtEdit = "spaces-module--justt-edit--5dfc7";
export var justtFull = "spaces-module--justt-full--12670";
export var justtFullCardIcon = "spaces-module--justt-full-card-icon--0aa5f";
export var justtFullscreen = "spaces-module--justt-fullscreen--f76ba";
export var justtGlobe = "spaces-module--justt-globe--5a9cf";
export var justtHidePassword = "spaces-module--justt-hide-password--c19ed";
export var justtInfo = "spaces-module--justt-info--73568";
export var justtLink = "spaces-module--justt-link--5a318";
export var justtLinkArrow = "spaces-module--justt-link-arrow--58d51";
export var justtLinkImage = "spaces-module--justt-link-image--be6a7";
export var justtLists = "spaces-module--justt-lists--210c7";
export var justtLogout = "spaces-module--justt-logout--42a70";
export var justtLoop = "spaces-module--justt-loop--dde59";
export var justtMedia = "spaces-module--justt-media--80fde";
export var justtMove = "spaces-module--justt-move--fa9b7";
export var justtParagraphs = "spaces-module--justt-paragraphs--bd808";
export var justtPlay = "spaces-module--justt-play--f0468";
export var justtPreviewCard = "spaces-module--justt-preview-card--eac11";
export var justtPublished = "spaces-module--justt-published--61654";
export var justtReposition = "spaces-module--justt-reposition--e20c6";
export var justtReverse = "spaces-module--justt-reverse--d8e2f";
export var justtSaved = "spaces-module--justt-saved--cbc0e";
export var justtSeen = "spaces-module--justt-seen--1afe4";
export var justtSelected = "spaces-module--justt-selected--b1665";
export var justtSettings = "spaces-module--justt-settings--5dd3b";
export var justtShare = "spaces-module--justt-share--52cc9";
export var justtShowPassword = "spaces-module--justt-show-password--33340";
export var justtSixteenNine = "spaces-module--justt-sixteen-nine--3f6da";
export var justtSolid = "spaces-module--justt-solid--9954b";
export var justtSortColored = "spaces-module--justt-sort-colored--a32c2";
export var justtSpaces = "spaces-module--justt-spaces--7c6f9";
export var justtStacked = "spaces-module--justt-stacked--15508";
export var justtText = "spaces-module--justt-text--d0584";
export var justtTheme = "spaces-module--justt-theme--9c45a";
export var justtTrash = "spaces-module--justt-trash--2e1d1";
export var justtTrashLined = "spaces-module--justt-trash-lined--ed807";
export var justtUnpublish = "spaces-module--justt-unpublish--4f5df";
export var justtVideo = "spaces-module--justt-video--8f6ce";
export var justtVideoLink = "spaces-module--justt-video-link--4bb4e";
export var justtVideoMessage = "spaces-module--justt-video-message--5d91b";
export var path1 = "spaces-module--path1--b6b17";
export var path2 = "spaces-module--path2--4e0c2";
export var playgroundItem = "spaces-module--playgroundItem--f65c9";
export var playgroundWrapper = "spaces-module--playgroundWrapper--b1858";
export var pulledTitle = "spaces-module--pulledTitle--9b21d";
export var secondItem = "spaces-module--secondItem--3e6db";
export var sectionWrapper = "spaces-module--sectionWrapper--67aed";
export var userPlaygrounds = "spaces-module--userPlaygrounds--3ed54";