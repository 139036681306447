import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

import SEO from '@components/seo';
import Api from '@api';
import { navigate } from '@reach/router';

import MediaCover from '@components/templates/create-card/components/media-cover';
import EditorContent from '@components/templates/create-card/components/editor-content';
import PreviewCard from '@components/templates/create-card/components/preview-card';
import Button from '@components/atoms/button';
import Modal from '@components/molecules/modal';
import ThreeDots from '@components/atoms/loaders/three-dots';
import OrderMedia from '@components/templates/create-card/components/order-media';

import { getToken, getTenant, getUserId } from '@helpers';
import { statusButtons } from '@helpers/main.module.scss';
import {
  compareObjectChanges,
  allowedStates,
  performAutosave,
} from '@components/templates/create-card/helpers';
import * as styles from '@components/templates/create-card/create-new-card.module.scss';

const CreateCardContainer = ({ current, send, cardId }) => {
  const {
    editing,
    // isForPublishing,
    // isForSaving,
    // stopAutosave,
    title,
    media,
    cardState,
    toggleIndex,
    description,
    changed,
    isExiting,
    content,
    initData,
    subFormat,
    changedMedia,
    disabled,
    cardType,
    sequence,
    sequenceIndex,
  } = current.context;

  const { event } = current;

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!editing) {
      setLoading(false);
      send({ type: 'SET_USER_ID', userId: getUserId() });
    }
  }, []);

  const onCloseModal = () => {
    setShowModal(false);
    send({ type: 'IS_EXITING', exit: false });
  };

  useEffect(() => {
    if (isExiting) {
      setShowModal(true);
    }
  }, [isExiting]);

  const setContext = (obj) => {
    send({ type: 'SET_PARSED_MEDIA', parsed: true });
    send({ type: 'SET_CONTEXT', context: obj });
  };

  // const mainObj = {
  //   media,
  //   title,
  //   description,
  //   content,
  // };

  // // const objectDiff = compareObjectChanges(mainObj, autosaveData);
  const oKeys = current.value;
  const keyNames = Object.keys(oKeys);

  useEffect(() => {
    if (allowedStates.includes(keyNames[0])) {
      performAutosave(send);
    }
    () => performAutosave('', true);
  }, []);

  // FIXME move this inside the fetch state service
  const populateCard = (cardData) => {
    const fetchedData = {
      ...cardData,
      streamId: cardData?.stream?.streamId,
      content: cardData.content_v2 || cardData.content,
      media:
        cardData.coverMedia.items && cardData.coverMedia.items !== null
          ? cardData.coverMedia.items
          : [],
      files:
        cardData.coverMedia.items && cardData.coverMedia.items !== null
          ? cardData.coverMedia.items
          : [],
      autosaveData: {
        media:
          cardData.coverMedia.items && cardData.coverMedia.items !== null
            ? cardData.coverMedia.items
            : [],
        title: cardData.title,
        description: cardData.description,
        content: cardData.content_v2,
      },
    };

    if (cardData) {
      const obj = {
        title: cardData.title,
        description: cardData.description,
        content: cardData.content || cardData.content_v2,
        subFormat: cardData.subFormat,
        media: cardData.coverMedia.items ? cardData.coverMedia.items : [],
      };

      send({ type: 'SET_INIT_DATA', initData: obj });

      if (cardData?.media) {
        send({ type: 'EDITING_VIEW' });
      }
    }

    setContext(fetchedData);
  };

  const fetchCardData = async () => {
    try {
      const response = await Api.getCardData(getToken(), cardId, getTenant());
      if (cardId && !initData) {
        populateCard(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkChanges = () => {
    const updatedData = {
      title,
      description,
      content,
      subFormat,
      media,
    };

    const changedValues = compareObjectChanges(initData, updatedData);

    send({
      type: 'SET_DISABLED',
      disabled: !editing && changedValues.length === 0,
    });
    send({ type: 'SET_CHANGED', changed: changedValues.length > 0 });

    if (changedValues.length === 0) {
      send({ type: 'SET_TOGGLE_INDEX', index: 0 });
    }

    if (media && subFormat !== 'Text') {
      const mediaDiff = compareObjectChanges(initData.media, media);
      send({ type: 'CHANGED_MEDIA', changedMedia: mediaDiff.length > 0 });
    }
  };

  // TODO Consider moving this as a service to the Machine
  const validateFields = () => {
    if (editing && initData) {
      setLoading(false);
    }

    if (subFormat === 'Text') {
      if (title.length === 0 || content[0].source === '<p></p>') {
        send({ type: 'SET_DISABLED', disabled: true });
      }
      send({ type: 'SET_DISABLED', disabled: false });
    }

    if (title.length === 0 || (content.length === 1 && content[0].source === '<p></p>')) {
      send({ type: 'SET_DISABLED', disabled: true });
      return;
    }

    if (cardState !== 'unpublished' && media && media.length > 0) {
      send({ type: 'SET_DISABLED', disabled: false });
    }

    if (subFormat !== 'Text' && media.length === 0 && cardState !== 'unpublished') {
      send({ type: 'SET_DISABLED', disabled: true });
    }
  };

  const cleanEditor = () => {
    send({ type: 'CLEAN_CONTEXT' });
    setTimeout(() => send({ type: 'BACK' }), 200);
  };

  const saveChanges = () => {
    send({ type: 'IS_FOR_SAVING' });
    setTimeout(() => send({ type: 'CONFIRM' }), 100);
  };

  const discardModal = () => {
    if (editing) {
      return changed && isExiting ? (
        <Modal
          type="save-work"
          isEditing={editing}
          showModal={showModal}
          onCancel={() => navigate(-1)}
          onConfirm={() => setShowModal(false)}
          onClose={() => onCloseModal()}
        />
      ) : null;
    }

    return isExiting ? (
      <Modal
        type="save-work"
        title="Cancel Card Creation"
        isEditing={editing}
        showModal={showModal}
        onCancel={() => cleanEditor()}
        onConfirm={() => saveChanges()}
        onClose={() => onCloseModal()}
      />
    ) : null;
  };

  const goBackHandler = () => {
    if (
      title.length > 0 ||
      media.length > 0 ||
      description.length > 0 ||
      content[0].source !== '<p></p>'
    ) {
      send({ type: 'IS_EXITING', exit: true });
    } else {
      send('BACK');
    }
  };

  // Field Validations and Changes need to be
  // outside the scope of the useEffect
  // This will mean that we will have to perform the changes validate
  // outside the component but on the xState

  // FIXME This field validation logic
  // needs to be replaced entirely with something more
  // meaningful

  // const checkDisabled = () => {
  //   if (editing) {
  //   }
  // };

  useEffect(() => {
    validateFields();

    if (initData && toggleIndex !== 0) {
      checkChanges();
    }
  }, [subFormat, changedMedia]);

  useEffect(() => {
    validateFields();
    if (initData && toggleIndex !== 0) {
      checkChanges();
    }
  }, [content, title, description, media]);

  useEffect(() => {
    if (editing) {
      send({ type: 'EDITING_VIEW' });
      fetchCardData();
    }
  }, [cardId]);

  return (
    <motion.div
      className={`${styles.editorContainer} ${styles.indepthEditor}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {discardModal()}
      <SEO title={editing ? 'Edit Card' : 'Create Card'} />
      {!loading ? (
        <>
          {!sequenceIndex && (
            <PreviewCard cardType={cardType} current={current} send={send} editing hasParentStyle />
          )}
          {sequence && (
            <>
              <OrderMedia current={current} send={send} />
            </>
          )}
          <div className={styles.editorWrapper}>
            {subFormat !== 'Text' && <MediaCover current={current} send={send} />}
            <EditorContent parentClassName={styles.editorWrapper} current={current} send={send} />
          </div>
          <div className={`${statusButtons} ${styles.relativePositioning}`}>
            <Button btnClass="editorBtn" hasDisabled={disabled} onClick={() => goBackHandler()}>
              Back
            </Button>
            <Button
              btnClass="editorBtn"
              // hasDisabled={disabled}
              primaryBtn
              onClick={() => send('NEXT')}
            >
              Next
            </Button>
          </div>
        </>
      ) : (
        <ThreeDots />
      )}
    </motion.div>
  );
};

CreateCardContainer.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
  cardId: PropTypes.string,
};
export default CreateCardContainer;
