import React from 'react';
import ContentLoader from 'react-content-loader';

const Placeholder = () => (
  <ContentLoader
    speed={3}
    width={215}
    height={278}
    viewBox="0 0 215 278"
    style={{ margin: '1rem' }}
    backgroundColor="#f3f3f3"
    foregroundColor="#cbebfc"
  >
    <rect x="6" y="6" rx="6" ry="6" width="203" height="212" />
    <rect x="6" y="222" rx="6" ry="6" width="60" height="16" />
    <rect x="6" y="242" rx="6" ry="6" width="203" height="30" />
  </ContentLoader>
);

export default Placeholder;
