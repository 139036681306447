import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MediaButton from '@components/atoms/media-button';
import getCroppedImg from '@helpers/cropImage';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Cropper from 'react-easy-crop';
import Slider from 'rc-slider';
import Input from '@components/atoms/input';
import { mediaTransformation, aspectRatios } from '@services/image';
import { onLightbox } from '@components/organisms/card/shared/helpers';

import '@brainhubeu/react-carousel/lib/style.css';
import {
  mediaOptions,
  adjustAlignment,
  mediaWrap,
  imageWrap,
  fullScreenClicking,
  carouselItem,
  captionWrapper,
  aspectRatiosStyle,
  btnWrapper,
  rangeWrap,
  cropperWrapper,
  relativeParent,
} from '@helpers/main.module.scss';
import '@components/organisms/image-carousel/carouselNative.scss';
import '@helpers/style/carousel.scss';
import '@styles/utils/_rc-slider.scss';
import '@helpers/style/_rcslider.scss';

import * as styles from './carousel.module.scss';

const EditorCarousel = ({ data, onDelete, send, setData, onAddImages, onToggleOrder }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [edit, setEdit] = useState(false);
  const [onAspect, setOnAspect] = useState(4 / 5);
  const [originalAspect, setOriginal] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState([1]);

  const [active, setActive] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [croppedImage, setCroppedImage] = useState(null);

  const onCropChange = (cropValue) => {
    setCrop(cropValue);
  };

  const onCropComplete = (croppedArea, cropAreaPixles) => {
    setCroppedAreaPixels(cropAreaPixles);
  };

  const renderUrl = (url) => {
    const splitUrl = url.split('?')[0];
    return splitUrl;
  };

  const handleCrop = async () => {
    try {
      const cpI = await getCroppedImg(
        renderUrl(data[currentIndex].asset.fullSizeUrl),
        croppedAreaPixels
      );

      await mediaTransformation(cpI, setCroppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const onZoomChange = (zoomValue) => {
    setZoom([zoomValue]);
  };

  const justCrop = () => {
    handleCrop();
  };

  const handleKeyChange = (val) => {
    if (val.keyCode === 13 && val.shiftKey === false) {
      val.preventDefault();
    }
  };

  const deleteMediaItem = (val) => {
    if (val === 0 && data.length === 1) {
      send({ type: 'VIEW_SEQUENCE', sequence: false });
      setTimeout(() => onDelete(), 100);
    } else {
      let items = [...data];
      const filteredItems = items.filter((_, index) => index !== val);

      items = filteredItems;

      if (val === filteredItems.length) {
        setCurrentIndex(currentIndex - 1);
      }

      if (currentIndex > filteredItems.length) {
        setCurrentIndex(filteredItems.length);
      }

      setData(items);
    }
  };

  const onHashChange = (property) => {
    const items = [...data];
    const item = {
      ...items[currentIndex],
      ...property,
    };

    items[currentIndex] = item;
    setData(items);
    setCroppedAreaPixels(null);
  };

  const toggleItem = (val, asp, value, x, y) => {
    setActive(val);
    if (value === 'Original') {
      setOnAspect(originalAspect);
    } else {
      setOnAspect(asp);
      onHashChange({ intendedAspectRatio: { ratioX: x, ratioY: y } });
    }
  };

  // const onToggleOrder = () => {
  //   if (!sequence) {
  //     send({ type: 'VIEW_SEQUENCE', sequence: true });
  //   } else if (sequence && sequenceIndex) {
  //     setTimeout(() => send({ type: 'SET_SEQUENCE', index: null }), 100);
  //   }

  //   if (!sequenceIndex && sequence) {
  //     send({ type: 'VIEW_SEQUENCE', sequence: false });
  //   }
  // };

  const onChange = (val) => {
    setCurrentIndex(val);
  };

  const editCarouselItem = (val) => {
    setCurrentIndex(val);
    setEdit(true);
  };

  const concludeEdit = () => {
    setEdit(false);
    handleCrop();
  };

  useEffect(() => {
    if (croppedImage && croppedImage.source) {
      onHashChange({
        asset: {
          ...data[currentIndex].asset,
          defaultUrl: croppedImage.source,
        },
      });
    }
  }, [croppedImage]);

  return (
    <div className={`${styles.carouselWrapper} ${edit && styles.croppingWrap}`}>
      {!edit ? (
        <div className={styles.carouselChild}>
          <Carousel
            showArrows
            dynamicHeight
            showThumbs={false}
            selectedItem={currentIndex}
            showStatus={false}
            showIndicators={data.length === 1 ? false : true}
            onChange={(index) => onChange(index)}
            renderArrowPrev={(onClickHandler, hasPrev) =>
              hasPrev && (
                <div className={`${styles.btnWrapper} leftAligned`}>
                  <MediaButton icon="arrow-left" onBtnClick={onClickHandler} />
                </div>
              )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <div className={`${styles.btnWrapper} rightAligned`}>
                  <MediaButton icon="arrow-right" onBtnClick={onClickHandler} />
                </div>
              )
            }
          >
            {data.map((image, idx) => (
              <div className={`${mediaWrap} ${carouselItem}`} key={idx}>
                <div className={`${mediaOptions} ${!edit && adjustAlignment}`}>
                  <div style={{ marginRight: 'auto' }}>
                    <MediaButton icon="move" onBtnClick={() => onToggleOrder()} />
                  </div>
                  <MediaButton
                    icon="edit"
                    done="labeled"
                    hasLabel="Edit"
                    onBtnClick={() => editCarouselItem(idx)}
                  />
                </div>

                <div
                  className={fullScreenClicking}
                  onClick={() => onLightbox({ send, data, currentIndex })}
                ></div>

                <div className={imageWrap}>
                  <img
                    src={renderUrl(image?.asset?.defaultUrl || image?.defaultUrl)}
                    key={idx}
                    alt="Carousel Items"
                  />
                </div>
              </div>
            ))}
          </Carousel>
          <div className={captionWrapper}>
            <Input
              placeholder="Caption"
              customClass="captionInput"
              maxRows={4}
              value={data[currentIndex].caption}
              valueChange={(val) => onHashChange({ caption: val })}
              onKeyDownChange={(evt) => handleKeyChange(evt)}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={mediaOptions}>
            <div className={styles.leftAligned}>
              <MediaButton
                icon="trash"
                onBtnClick={() => deleteMediaItem(currentIndex, data[currentIndex].type)}
              />
              <div style={{ marginLeft: '1rem' }}>
                <MediaButton icon="add" onBtnClick={() => onAddImages()} />
              </div>

              <MediaButton
                icon="aspect-ratio"
                active={onAspect}
                onBtnClick={() => setOnAspect(!onAspect)}
              />
            </div>

            <MediaButton done onBtnClick={() => concludeEdit()} />
          </div>
          <div className={cropperWrapper}>
            <div className={relativeParent}>
              <Cropper
                image={renderUrl(data[currentIndex].asset.fullSizeUrl)}
                crop={crop}
                zoom={zoom}
                minZoom={1}
                maxZoom={2}
                style={{
                  cropAreaStyle: {
                    border: '0',
                  },
                }}
                aspect={onAspect}
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                onInteractionEnd={justCrop}
                onMediaLoaded={(mediaSize) => setOriginal(mediaSize.width / mediaSize.height)}
              />
            </div>
            {!onAspect ? (
              <div className={rangeWrap}>
                <Slider
                  min={1}
                  max={3}
                  step={0.2}
                  value={zoom}
                  onChange={(val) => setZoom([val])}
                />
              </div>
            ) : (
              <div className={aspectRatiosStyle}>
                <div className={btnWrapper}>
                  {aspectRatios.map((ratio) => (
                    <button
                      type="button"
                      className={active === ratio.id ? styles.activeItem : null}
                      onClick={() =>
                        toggleItem(ratio.id, ratio.aspect, ratio.val, ratio.x, ratio.y)
                      }
                    >
                      {ratio.val}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

EditorCarousel.propTypes = {
  // data: PropTypes.shape([]),
  current: PropTypes.shape(),
  setData: PropTypes.func,
  send: PropTypes.func,
  onAddImages: PropTypes.func,
  onToggleOrder: PropTypes.func,
  onDelete: PropTypes.func,
};

export default EditorCarousel;
