import React from 'react';

import PropTypes from 'prop-types';
import Icon from '@components/atoms/icon';

import * as styles from './dropzone.module.scss';

const CoverDropzoneInput = ({ accept, multiple, onFiles, getFilesFromEvent }) => (
  <div className={styles.inputWrapper}>
    <label htmlFor="upload" className={styles.filesWrapper}>
      <input
        type="file"
        id="upload"
        multiple={multiple}
        accept={accept}
        onChange={(e) => getFilesFromEvent(e).then((chosenFiles) => onFiles(chosenFiles))}
      />
      <div className={styles.IconWrapper}>
        <Icon iconClass="add" fSize={3.6} />
      </div>
      <h5>Add Media</h5>
    </label>
  </div>
);

CoverDropzoneInput.propTypes = {
  accept: PropTypes.string,
  multiple: PropTypes.string,
  onFiles: PropTypes.func,
  getFilesFromEvent: PropTypes.func,
};

export default CoverDropzoneInput;
