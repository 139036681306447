import { createMachine } from 'xstate';

const createStreamWizardFormMachine = createMachine({
  id: 'createStreamWizardFormMachine',
  initial: 'enterTitle',
  context: {
    editing: false,
    changed: false,
    disabled: false,
    isExiting: false,
    discarding: false,
    loadingUpload: false,
    parsedMedia: false,
    // Starting data
    binaryFile: null,
    channelState: null,
    streamId: null,
    autoSaveId: null,
    onAutosave: false,
    autoSaving: false,
    autoSaveData: {},
    userId: null,
    name: '',
    description: '',
    category: 'Apps',
    tempImage: false,
    initData: null,
    image: [],
    previewMedia: {},
  },
  states: {
    enterTitle: {
      initial: 'normal',
      states: {
        normal: {
          entry: 'setUserId',
        },
        invalid: {},
      },
      on: {
        ON_AUTOSAVE: {
          actions: 'setAutosave',
        },
        START_AUTOSAVE: {
          actions: 'startAutosave',
        },
        STOP_AUTOSAVE: {
          actions: 'stopAutosave',
        },
        NEW_AUTOSAVE: {
          actions: 'newAutosave',
        },
        AUTOSAVE_EXISTING: {
          actions: 'autosaveExisting',
        },
        SET_EDITING: {
          actions: 'setEditing',
        },
        ON_CHANGE: {
          actions: 'setStreamTitle',
        },
        IS_EXITING: {
          actions: 'isExiting',
        },
        SET_CHANGED: {
          actions: 'setChanged',
        },
        NEXT: [
          {
            target: 'enterDescription',
            cond: (ctx) => ctx.name.length >= 1,
          },
          { target: '.invalid' },
        ],
        SET_CONTEXT_DATA: {
          actions: 'setContext',
        },
      },
    },
    enterDescription: {
      on: {
        ON_CHANGE: {
          actions: 'setStreamDescription',
        },
        ON_AUTOSAVE: {
          actions: 'setAutosave',
        },
        NEW_AUTOSAVE: {
          actions: 'newAutosave',
        },
        AUTOSAVE_EXISTING: {
          actions: 'autosaveExisting',
        },
        START_AUTOSAVE: {
          actions: 'startAutosave',
        },
        STOP_AUTOSAVE: {
          actions: 'stopAutosave',
        },
        BACK: 'enterTitle',
        NEXT: [
          { target: 'addCover', cond: (ctx) => ctx.description.length >= 1 },
          { target: '.invalid' },
        ],
      },
      initial: 'normal',
      states: {
        normal: {},
        invalid: {},
      },
    },
    addCover: {
      initial: 'start',
      states: {
        start: {},
        autosave: {},
      },
      on: {
        ON_AUTOSAVE: {
          actions: 'setAutosave',
        },
        NEW_AUTOSAVE: {
          actions: 'newAutosave',
        },
        AUTOSAVE_EXISTING: {
          actions: 'autosaveExisting',
        },
        START_AUTOSAVE: {
          actions: 'startAutosave',
        },
        STOP_AUTOSAVE: {
          actions: 'stopAutosave',
        },
        SET_BINARY_FILE: {
          actions: 'setBinaryFile',
        },
        HANDLE_CROP: {
          actions: 'handleCrop',
        },
        SET_IMAGE_DATA: {
          actions: 'setImageData',
        },
        SET_DISABLED: {
          actions: 'setDisabled',
        },
        SET_STREAM_ID: {
          actions: 'setStreamId',
        },
        SET_PREVIEW_MEDIA: {
          actions: 'setPreviewMedia',
        },
        SET_CHANGED: {
          actions: 'setChanged',
        },
        ON_DELETE: {
          actions: 'deleteMedia',
        },
        BACK: 'enterDescription',
        NEXT: [
          {
            target: 'previewChannel',
            cond: (ctx) => ctx.editing && ctx.previewMedia,
          },
          {
            target: 'previewChannel',
            cond: (ctx) => !ctx.disabled && ctx.image,
          },
          { target: '.invalid' },
        ],
      },
      initial: 'normal',
      states: {
        normal: {},
        invalid: {},
      },
    },
    // chooseCategory: {
    //   on: {
    //     ON_CHANGE: {
    //       actions: 'setStreamCategory',
    //     },
    //     BACK: 'addCover',
    //     NEXT: [
    //       { target: 'previewChannel', cond: (ctx) => ctx.category.length > 0 },
    //       { target: '.invalid' },
    //     ],
    //   },
    //   initial: 'normal',
    //   states: {
    //     normal: {},
    //     invalid: {},
    //   },
    // },
    previewChannel: {
      on: {
        FINISH: {
          target: 'submitChannel',
        },
        BACK: {
          target: 'addCover',
        },
      },
      initial: 'normal',
      states: {
        normal: {},
        invalid: {},
      },
    },
    submitChannel: {
      type: 'final',
    },
    on: {
      ON_AUTOSAVE: {
        actions: 'setAutosave',
      },
      NEW_AUTOSAVE: {
        actions: 'newAutosave',
      },
      AUTOSAVE_EXISTING: {
        actions: 'autosaveExisting',
      },
      SET_CHANGED: {
        actions: 'setChanged',
      },
      SET_DISABLED: {
        actions: 'setDisabled',
      },
      CLEAN_CONTEXT: {
        actions: 'cleanContext',
      },
      SET_INIT_DATA: {
        actions: 'setInitData',
      },
    },
  },
});

export default createStreamWizardFormMachine;
