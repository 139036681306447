import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';
import { assign } from 'xstate';
// import useSWR from 'swr';
import Api from '@api';
import { navigate } from '@reach/router';
import { useMachine } from '@xstate/react';
import { getUserId, getToken, getTenant, isLoggedIn } from '@helpers';

import Header from '@components/organisms/header';

import ClientOnly from '@components/molecules/clientOnly';
import SEO from '@components/seo';
import { compareObjectChanges } from '@components/templates/create-card/helpers';
// import ChannelCategory from './components/channel-category';
import ChannelTitle from './components/channel-title';
import ChannelDescription from './components/channel-description';
import ChannelCover from './components/channel-cover';
import ChannelPreview from './components/channel-preview';
import FinalChannelCreationStep from './components/channel-card-create';
import Modal from '@components/molecules/modal';
// import CreateModal from "../createModal"
import createStreamWizardFormMachine from './shared/machine';
import * as styles from './create-channel.module.scss';
import { handleAutosaving, performAutosave } from './shared/helpers';

const CreateStream = ({ channelId }) => {
  const [showModal, setShowModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const fetchChannelData = async () => {
    try {
      const response = await Api.getChannelDetails(getToken(), channelId, getUserId(), getTenant());
      if (channelId) {
        dataObj(response);
      }
    } catch (error) {
      if (error) {
        setShowModal(true);
        setErrorModal(true);
      }
    }
  };

  const [current, send] = useMachine(createStreamWizardFormMachine, {
    actions: {
      setStreamId: assign({
        streamId: (ctx, event) => event.streamId,
      }),
      setEditing: assign({
        editing: (_ctx, event) => event.editing,
      }),
      setChanged: assign({
        changed: (_, event) => event.changed,
      }),
      setInitData: assign({
        initData: (_, event) => event.initData,
      }),
      setStreamTitle: assign({
        name: (ctx, event) => event.name,
      }),
      setStreamDescription: assign({
        description: (ctx, event) => event.description,
      }),
      setStreamCategory: assign({
        category: (ctx, event) => event.category,
      }),
      setBinaryFile: assign({
        binaryFile: (ctx, event) => event.binaryFile,
      }),
      handleCrop: assign({
        image: (ctx, event) => [
          {
            ...ctx.image[0],
            croppedFile: event.croppedFile,
            croppedUrlBlob: event.croppedUrlBlob,
          },
        ],
      }),
      setUserId: assign({
        userId: (ctx, event) => getUserId(),
      }),
      setPreviewMedia: assign({
        previewMedia: (ctx, event) => event.previewMedia,
      }),
      // Determine channel status for publishing
      isExiting: assign({
        isExiting: (_, event) => event.exit,
      }),
      setContext: assign({
        streamId: (_, event) => event.context.streamId,
        autoSaveId: (_, event) => event.context.autoSaveId,
        name: (_, event) => event.context.name,
        description: (_, event) => event.context.description,
        previewMedia: (ctx, event) => event.context.previewMedia,
        channelState: (_, event) => event.context.channelState,
        category: (_, event) => event.context.category,
        image: (ctx, event) => event.context.image,
        autoSaveData: (ctx, event) => event.context.autoSaveData,
      }),
      deleteMedia: assign({
        image: () => [],
        previewMedia: () => {},
        binaryFile: () => null,
      }),
      cleanContext: assign({
        name: () => '',
        description: () => '',
        category: () => 'Apps',
        image: () => [],
        previewMedia: () => {},
        binaryFile: () => null,
      }),
      setDisabled: assign({
        disabled: (_, event) => event.disabled,
      }),
      setImageData: assign({
        image: (ctx, event) => [
          {
            ...ctx.image[0],
            asset: event.asset,
          },
        ],
      }),
      setAutosave: assign({
        onAutosave: () => true,
      }),
      startAutosave: assign({
        autoSaving: () => true,
      }),
      stopAutosave: assign({
        onAutosave: () => false,
      }),
      newAutosave: assign({
        onAutosave: () => false,
        streamId: (_, event) => event.data.streamId,
        autoSaveId: (_, event) => event.data.autoSaveId,
        autoSaveData: (_, event) => event.data.autoSaveData,
        autoSaving: () => false,
      }),
      autosaveExisting: assign({
        onAutosave: () => false,
        autoSaveId: (_, event) => event.data.autoSaveId,
        autoSaveData: (_, event) => event.data.autoSaveData,
        autoSaving: () => false,
      }),
    },
  });

  const dataObj = (arr) => {
    let updateContextVal = {};
    updateContextVal = {
      streamId: arr.streamId,
      name: arr.name,
      description: arr.description,
      category: arr.category[0],
      userId: arr.user.userId,
      image: arr.coverMedia.items && arr.coverMedia.items.length > 0 ? arr.coverMedia.items : [],
      previewMedia:
        arr.coverMedia.items && arr.coverMedia.items.length > 0
          ? {
              asset: {
                source: arr.coverMedia.items[0].asset.fullSizeUrl,
                ...arr.coverMedia.items[0].asset,
              },
            }
          : [],
      channelState: arr.streamState,
      autoSaveId: arr.streamState === 'autosaved' ? arr.streamId : null,
      autoSaveData: {
        name: arr.name,
        description: arr.description,
        image: arr.coverMedia.items && arr.coverMedia.items.length > 0 ? arr.coverMedia.items : [],
      },
    };
    send({ type: 'SET_CONTEXT_DATA', context: updateContextVal });
    send({ type: 'SET_INIT_DATA', initData: updateContextVal });
  };

  const { context, event } = current;
  const {
    editing,
    name,
    description,
    image,
    // previewMedia,
    // autoSaveId,
    channelState,
    streamId,
    changed,
    isExiting,
    autoSaveData,
    autoSaving,
    onAutosave,
  } = context;

  const cleanContext = () => {
    send({ type: 'CLEAN_CONTEXT' });
    setShowModal(false);
    setTimeout(() => navigate('/app/select-create-channel'), 200);
  };

  const onCloseModal = () => {
    setShowModal(false);
    send({ type: 'IS_EXITING', exit: false });
  };

  const discardModal = () => {
    if (editing) {
      return changed && isExiting ? (
        <Modal
          type="save-work"
          isEditing={editing}
          showModal={showModal}
          onCancel={() => navigate(-1)}
          onConfirm={() => setShowModal(false)}
          onClose={() => onCloseModal()}
        />
      ) : null;
    }

    return isExiting ? (
      <Modal
        type="save-work"
        title="Cancel Channel Creation"
        showModal={showModal}
        onCancel={() => cleanContext()}
        onClose={() => onCloseModal()}
      />
    ) : null;
  };

  useEffect(() => {
    if (isExiting) {
      if (editing && changed) {
        setShowModal(true);
      }

      if (!editing) {
        setShowModal(true);
      }
    }
  }, [isExiting]);

  const conditionalAutosaving = () => {
    const mainObj = {
      name,
      description,
      image: (image && image.length > 0 && image) || [],
    };

    if (!streamId) {
      if (!autoSaving && (image.length > 0 || name.length > 0 || description.length > 0)) {
        handleAutosaving(context, send);
      } else {
        send({ type: 'STOP_AUTOSAVE' });
      }
    } else {
      const diffChanges = compareObjectChanges(mainObj, autoSaveData);
      if (!autoSaving && diffChanges.length > 0) {
        handleAutosaving(context, send);
      } else {
        send({ type: 'STOP_AUTOSAVE' });
      }
    }
  };

  useEffect(() => {
    if (onAutosave) {
      conditionalAutosaving();
    }
  }, [onAutosave]);

  useEffect(() => {
    if (channelId) {
      send({ type: 'SET_EDITING', editing: true });
      fetchChannelData();
    }

    var timerID = setInterval(function () {
      if (!autoSaving) {
        send({ type: 'ON_AUTOSAVE' });
      }
    }, 20 * 1000);

    () => clearInterval(timerID);
  }, []);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <SEO title="Create Stream" />
      <Header isLoggedIn={isLoggedIn()} current={current} send={send} />
      {discardModal()}
      {showModal && errorModal && (
        <Modal
          type="error"
          title="Error"
          desc="No data was returned for this Channel"
          showModal={showModal}
          onCancel={() => onCloseModal()}
          onConfirm={() => navigate('/app')}
          onClose={() => onCloseModal()}
        />
      )}
      <div className={styles.content}>
        <ClientOnly>
          <div>
            {current.matches('enterTitle') && <ChannelTitle current={current} send={send} />}
            {current.matches('enterDescription') && (
              <ChannelDescription current={current} send={send} />
            )}
            {current.matches('addCover') && <ChannelCover current={current} send={send} />}
            {current.matches('previewChannel') && <ChannelPreview current={current} send={send} />}
            {current.matches('submitChannel') && (
              <FinalChannelCreationStep current={current} send={send} />
            )}
          </div>
        </ClientOnly>
      </div>
    </motion.div>
  );
};

CreateStream.propTypes = {
  channelId: PropTypes.string,
};

export default CreateStream;
