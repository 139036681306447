import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { EditorState } from 'draft-js';

import Icon from '@components/atoms/icon';
import Button from '@components/atoms/button';
import MenuButton from '@components/organisms/my-editor/components/menu-button';

import {
  OPTION_TYPES,
  IMPRESSUM_OPTION_TYPES,
  PARAGRAPH_STYLES,
  CHARACTER_STYLES,
  LIST_STYLES,
  MEDIA_STYLES,
  EXTERNAL_CONTENT,
} from '@components/organisms/my-editor/helpers';

import * as styles from './inline-options.module.scss';

const InlineOptions = ({ current, isShown, onToggleBlocks, toggledClick, send, impressum }) => {
  const [selectedItem, setSelected] = useState(null);
  const [childMenu, setChildMenu] = useState();
  const [animate, setAnimate] = useState(false);

  const [menuOptions, setMenuOptions] = useState(impressum ? IMPRESSUM_OPTION_TYPES : OPTION_TYPES);

  const { toggleIndex } = current.context;

  // Consider removing this from here
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const selection = editorState.getSelection();

  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const currentStyle = editorState.getCurrentInlineStyle();

  const filterOptions = (option) => {
    const filteredOptions = OPTION_TYPES.filter((item) => item === option);
    setMenuOptions(filteredOptions);
    setChildMenu(option.child_menu);
  };

  const revertMenu = () => {
    setMenuOptions(impressum ? IMPRESSUM_OPTION_TYPES : OPTION_TYPES);
    setSelected(null);
    setChildMenu(null);
    if (!impressum) {
      onToggleBlocks(false);
    }
  };

  const toggleChildMenu = (item) => {
    if (selectedItem === null) {
      setSelected(item.label);
      setAnimate(true);
      setChildMenu(item.child_menu);
      filterOptions(item);
    } else {
      revertMenu();
    }
  };

  const variants = {
    hidden: {
      bottom: '-7rem',
      transition: { duration: 0.5 },
    },
    shown: {
      bottom: '0rem',
    },
  };

  const onToggle = (inline, type) => {
    send({ type: 'SET_TOGGLE_INDEX', index: toggleIndex + 1 });
    if (inline) {
      send({ type: 'SET_INLINE', inlineStyle: inline });
      send({ type: 'SET_BLOCKTYPE', blockTypeStyle: null });
    }

    if (type) {
      send({ type: 'SET_INLINE', inlineStyle: null });
      send({ type: 'SET_BLOCKTYPE', blockTypeStyle: type });
    }
  };

  const paragraphStyles = () =>
    PARAGRAPH_STYLES.map((item, idx) => (
      <MenuButton
        key={idx}
        style={item.style || item.blocktype_style}
        label={item.label}
        active={currentStyle.has(item.style) || item.blocktype_style === blockType}
        onToggle={() => onToggle(item.style, item.blocktype_style)}
      />
    ));

  const characterStyles = () =>
    CHARACTER_STYLES.map((item, idx) => (
      <MenuButton
        key={idx}
        style={item.style || item.blocktype_style}
        label={item.label}
        active={currentStyle.has(item.style) || item.blocktype_style === blockType}
        onToggle={() => onToggle(item.style, item.blocktype_style)}
      />
    ));

  const filteredCharacterStyles = () => {
    const excluded = ['Highlight', 'Strikethrough'];
    const duplicatedList = [...CHARACTER_STYLES];
    const filteredList = duplicatedList.filter((item) => !excluded.includes(item.label));

    return filteredList.map((item, idx) => (
      <MenuButton
        key={idx}
        style={item.style || item.blocktype_style}
        label={item.label}
        active={currentStyle.has(item.style) || item.blocktype_style === blockType}
        onToggle={() => onToggle(item.style, item.blocktype_style)}
      />
    ));
  };

  const listStyles = () =>
    LIST_STYLES.map((item, idx) => (
      <MenuButton
        key={idx}
        x
        style={item.style}
        label={item.label}
        active={currentStyle.has(item.style) || item.blocktype_style === blockType}
        onToggle={() => onToggle(item.style, item.blocktype_style)}
      />
    ));

  const mediaStyles = () =>
    MEDIA_STYLES.map((item, idx) => (
      <MenuButton
        key={item.label}
        style={item.style}
        label={item.label}
        active={currentStyle.has(item.style) || item.blocktype_style === blockType}
        onToggle={() => onToggle(item.style, item.blocktype_style)}
      />
    ));

  const externalStyles = () =>
    EXTERNAL_CONTENT.map((item) => (
      <MenuButton
        key={item.label}
        style={item.style}
        label={item.label}
        active={currentStyle.has(item.style) || item.blocktype_style === blockType}
        onToggle={() => onToggle(item.style, item.blocktype_style)}
      />
    ));

  const renderChild = (type) => {
    if (type === 'paragraphs') {
      return paragraphStyles();
    }
    if (type === 'characters') {
      if (impressum) {
        return filteredCharacterStyles();
      }
      return characterStyles();
    }
    if (type === 'lists') {
      return listStyles();
    }
    if (type === 'media') {
      onToggleBlocks(true);
      return mediaStyles();
    }
    if (type === 'external') {
      return externalStyles();
    }
    return null;
  };

  return (
    <motion.div
      variants={variants}
      animate={isShown ? 'shown' : 'hidden'}
      onClick={() => toggledClick}
      className={`${styles.inlineWrapper} ${impressum && styles.shrinkedWrapper}`}
    >
      <div className={`${styles.inlineMenu} ${animate ? styles.menuAnimating : ''}`}>
        {menuOptions.map((option) => (
          <Button
            key={option.label}
            btnClass="menuOption"
            active={selectedItem === option.label}
            onClick={() => toggleChildMenu(option)}
            hasVariance
          >
            <Icon iconClass={option.icon} fSize={option.font_size} />
            <h5>{option.label}</h5>
          </Button>
        ))}
        {childMenu && (
          <div className={styles.inlineSubMenu}>
            {selectedItem !== null && (
              <>
                {renderChild(childMenu)}
                <div
                  className={styles.closeSubMenu}
                  role="button"
                  tabIndex="0"
                  onClick={() => revertMenu()}
                  onKeyDown={() => {}}
                >
                  <Icon iconClass="close" />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

InlineOptions.propTypes = {
  send: PropTypes.func,
  isShown: PropTypes.bool,
  toggledClick: PropTypes.func,
  current: PropTypes.shape(),
};

export default InlineOptions;
