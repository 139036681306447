import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { btnWrapper } from '@components/organisms/editor-carousel/carousel.module.scss';
import MediaButton from '@components/atoms/media-button';

import './carouselNative.scss';
import * as styles from './carousel.module.scss';

const ImageCarousel = ({ send, current, onAddImages }) => {
  const { context, event } = current;
  const {
    files,
    editing,
    media,
    parsedMedia,
    loadingUpload,
    coverMedia,
    currentFileIndex,
    sequence,
    sequenceIndex,
  } = context;

  const removeItem = (idx) => {
    if (files.length === 1) {
      send({ type: 'SET_COVER', coverMedia: null });
    }

    send({ type: 'FINISH_EDIT_MEDIA' });
    send({ type: 'DELETE_MEDIA', index: idx });
  };

  const onToggleOrder = () => {
    if (!sequence) {
      send({ type: 'VIEW_SEQUENCE', sequence: true });
    } else if (sequence && sequenceIndex) {
      setTimeout(() => send({ type: 'SET_SEQUENCE', index: null }), 100);
    } else if (!sequenceIndex && sequence) {
      send({ type: 'VIEW_SEQUENCE', sequence: false });
    } else {
      // console
    }
  };

  const editItem = (idx) => {
    send({
      type: 'EDIT_MEDIA',
      index: idx,
    });

    send({ type: 'VIEW_SEQUENCE', sequence: false });

    send({
      type: 'ONCROPPING',
      isCropping: true,
    });

    if (currentFileIndex === 0) {
      if (editing) {
        send({
          type: 'SET_COVER',
          coverMedia: media[currentFileIndex]?.asset?.defaultUrl,
        });
      } else {
        send({
          type: 'SET_COVER',
          coverMedia: files[currentFileIndex].croppedUrlBlob,
        });
      }
    }
  };

  const onLightbox = () => {
    send({ type: 'SET_GALLERY_INDEX', index: currentFileIndex });
    if (editing && media[0].source) {
      send({ type: 'ON_GALLERY_IMAGES', gallery: media });
    } else {
      send({ type: 'ON_GALLERY_IMAGES', gallery: files });
    }
    setTimeout(() => send({ type: 'ON_LIGHTBOX', lightbox: true }), 100);
  };

  const renderCarouselItems = () =>
    files?.map((file, index) => {
      const type = file.type || file.originalFile.type.split('/')[0];
      if (type !== 'video') {
        return (
          <React.Fragment key={index}>
            <div className={styles.mediaWrap}>
              <div className={styles.mediaOptions}>
                <div className={styles.leftAligned}>
                  <MediaButton icon="move" onBtnClick={() => onToggleOrder()} />
                </div>
                <div className={styles.rightAligned}>
                  <MediaButton
                    icon="edit"
                    done="labeled"
                    hasLabel="Edit"
                    onBtnClick={() => editItem(index)}
                  />
                </div>
              </div>
              <div className={styles.fullScreenClick} onClick={() => onLightbox(index)}></div>
              <div className={styles.imageWrap}>
                <img
                  src={file.croppedUrlBlob || file.source || file.asset.defaultUrl}
                  alt="carousel-item"
                />
              </div>
            </div>
          </React.Fragment>
        );
      }
      return (
        <div className={styles.mediaWrap} key={index}>
          <div className={styles.mediaOptions}>
            {files.length > 0 && <MediaButton icon="trash" onBtnClick={() => removeItem(index)} />}
          </div>
          <div className={styles.videoWrap}>
            <ReactPlayer
              key={index}
              url={coverMedia || file.croppedUrlBlob}
              controls
              config={{
                file: {
                  attributes: {
                    className: 'video-player',
                    style: {
                      width: '415px',
                      height: '500px',
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      );
    });

  const renderCoverItem = () =>
    files?.length === 1 ? (
      files?.map((item, index) => {
        const type = !parsedMedia ? item?.croppedFile?.type?.split('/')[0] : item.type;
        if (type !== 'video') {
          return (
            <div className={styles.mediaWrap} key={index}>
              <div className={styles.mediaOptions}>
                <div style={{ marginLeft: 'auto' }}>
                  <MediaButton
                    icon="edit"
                    done="labeled"
                    hasLabel="Edit"
                    onBtnClick={() => editItem(0)}
                  />
                </div>
              </div>
              <div className={styles.fullScreenClick} onClick={() => onLightbox(index)}></div>
              <div className={styles.imageWrap}>
                <img src={coverMedia || item.croppedUrlBlob} alt="media-cover" />
              </div>
            </div>
          );
        }
        return (
          <div className={styles.mediaWrap} key={index}>
            <div className={styles.mediaOptions}>
              {files.length > 0 && (
                <MediaButton icon="trash" onBtnClick={() => removeItem(index)} />
              )}
            </div>
            <div className={styles.videoWrap}>
              <ReactPlayer
                key={index}
                url={item?.croppedUrlBlob || item?.asset?.defaultUrl}
                controls
                config={{
                  file: {
                    attributes: {
                      className: 'video-player',
                      style: {
                        width: '415px',
                        height: '500px',
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        );
      })
    ) : (
      <Carousel
        showArrows
        selectedItem={currentFileIndex}
        showStatus={false}
        showIndicators={files?.length > 0}
        // renderThumbs={false}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev &&
          files?.length > 1 && (
            <div className={`${btnWrapper} leftAligned`}>
              <MediaButton icon="arrow-left" onBtnClick={onClickHandler} />
            </div>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext &&
          files.length > 1 && (
            <div className={`${btnWrapper} rightAligned`}>
              <MediaButton icon="arrow-right" onBtnClick={onClickHandler} />
            </div>
          )
        }
        onChange={(index) => {
          send({ type: 'ONCHANGE_CURRENTFILEINDEX', index });
        }}
      >
        {renderCarouselItems()}
      </Carousel>
    );
  // return loadingUpload ? <ThreeDots /> : renderCoverItem();
  return renderCoverItem();
};

ImageCarousel.propTypes = {
  current: PropTypes.shape(),
  onAddImages: PropTypes.func,
  send: PropTypes.func,
};

export default ImageCarousel;
