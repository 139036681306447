import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SEO from '@components/seo';
import waterfall from 'async/waterfall';
// import useSWR from 'swr';
import Api from '@api';

import isLoadingHoc from '@components/hoc/isLoading';
import { navigate } from '@reach/router';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';

import { formatDate, getToken, getUserId, uuidv4, getTenant, getUserData } from '@helpers';

// import ReactPlayer from 'react-player';
import Dropzone from 'react-dropzone-uploader';
import VideoDropZoneInput from '@components/templates/create-card/components/video-dropzone-input';

import Modal from '@components/molecules/modal';
import LazyImage from '@hooks/lazy-image';

import {
  VideoMessageSteps,
  readFile,
  compareObjectChanges,
  allowedStates,
  performAutosave,
} from '@components/templates/create-card/helpers';

import Button from '@components/atoms/button';
import Input from '@components/atoms/input';
import Icon from '@components/atoms/icon';
import ThreeDots from '@components/atoms/loaders/three-dots';
import CurationHeader from '@components/templates/create-card/components/curation-header';
import * as styles from '../editor-type.module.scss';

const VideoMessageEditor = ({ current, send, cardId, setLoading, endAnimation }) => {
  const {
    userId,
    tenantId,
    streamId,
    title,
    content,
    disabled,
    changedMedia,
    description,
    created,
    format,
    removedMedia,
    subFormat,
    loadingUpload,
    files,
    coverMedia,
    media,
    editing,
    author,
    initData,
    changed,
    isExiting,
  } = current.context;

  const [active, setActive] = useState('1');
  const [updatingData, setUpdatingData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const createDate = new Date();

  const [loading, setIsLoading] = useState(editing);
  const currentUser = getUserData();

  const checkPreviewUrl = (url) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', getToken());

    const interval = setInterval(() => {
      if (!coverMedia) {
        fetch(url, {
          mode: 'no-cors',
          headers,
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
        })
          .then()
          .then(() => {
            setTimeout(() => {
              send({ type: 'SET_LOADING_UPLOAD', loading: false });
              send({ type: 'SET_COVER', coverMedia: url });
              if ((active === 2 && title) || active === 1) {
                send({ type: 'SET_DISABLED', disabled: false });
              }
              endAnimation(true);
            }, 300);
            clearInterval(interval);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 5000);
  };

  const populateMediaObject = () => {
    const mediaData = [];
    if (changedMedia) {
      media.map((item) =>
        mediaData.push({
          type: item.type,
          original: {
            key: item.key || item.original.key,
          },
          cropped: {
            key: item.key || item.original.key,
          },
        })
      );
    }
    return mediaData;
  };

  const handleVideoUpload = (file) => {
    send({ type: 'SET_LOADING_UPLOAD', loading: true });
    setLoading(true);
    const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
    const filename = `${uuidv4()}.${ext}`;

    const getSignedUrl = async (cb) => {
      const response = await Api.getImageUploadUrl(getToken(), [{ key: filename }]);

      cb(null, response);
    };

    const uploadMedia = async (storage, cb) => {
      try {
        await fetch(storage[0].url, {
          method: 'PUT',
          body: file,
        });
        cb(null, storage);
      } catch (err) {
        cb(err);
      }
    };

    const transformMediaContent = async (storage, cb) => {
      const type = file.type.split('/')[0];
      try {
        const result = await Api.transformMedia(getToken(), type, storage[0].key);
        cb(null, result);
      } catch (err) {
        cb(err);
      }
    };

    waterfall(
      [
        function (cb) {
          getSignedUrl(cb);
        },
        function (storage, cb) {
          uploadMedia(storage, cb);
        },
        function (storage, cb) {
          transformMediaContent(storage, cb);
        },
      ],
      (err, res) => {
        if (!err) {
          send({
            type: 'SET_VIDEO_MEDIA',
            media: {
              type: 'video',
              caption: '',
              asset: {
                defaultUrl: res.source,
                highResThumbnailUrl: res.thumbnail,
                lowResThumbnailUrl: res.previewThumbnail,
              },
            },
          });

          checkPreviewUrl(res.thumbnail);
          send({ type: 'SET_DISABLED', disabled: true });
        } else {
          setLoading(false);
          send({ type: 'SET_LOADING_UPLOAD', loading: false });
          // initialized error state modal
          setError(true);
          setShowModal(true);
          setModalTitle('Cant upload video!');
          setModalDesc(err || 'There was an error with uploading your video.');
        }
      }
    );
  };

  const getFilesFromEvent = (e) =>
    new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then(async (chosenFiles) => {
        const file = chosenFiles[0].fileObject;
        const imageDataUrl = await readFile(file);

        send({ type: 'SET_COVER', coverMedia: null });
        send({
          type: 'SELECT_MEDIA',
          file: {
            originalFile: file,
            originalUrlBlob: imageDataUrl,
            croppedFile: file,
            croppedUrlBlob: imageDataUrl,
          },
        });

        handleVideoUpload(file);

        resolve(chosenFiles.map((f) => f.fileObject));

        setTimeout(() => {
          send({ type: 'SWITCH_VIEW' });
        }, 100);
      });
    });

  const setActiveCard = (val) => {
    setActive(val);
  };

  const renderSubTitle = (val) => {
    if (val === 'VideoMessage' && active === '1') {
      return 'Default';
    }

    if (val === 'VideoMessage' && active === '2') {
      return 'Headline';
    }

    return null;
  };

  const checkChanges = () => {
    // if (initData) {
    const updatedData = {
      title,
      subFormat,
      media,
    };

    const changedValues = compareObjectChanges(initData, updatedData);

    if (changedValues.length > 0) {
      send({ type: 'SET_CHANGED', changed: true });
      send({ type: 'CHANGED_MEDIA', changedMedia: true });
    } else {
      send({ type: 'SET_CHANGED', changed: false });
    }
  };

  const validateFields = () => {
    if (!files) {
      send({ type: 'SET_DISABLED', disabled: true }); // give the creator the
    }

    if (coverMedia) {
      send({ type: 'SWITCH_VIEW' });
    }
  };

  const headlineChanged = (val) => {
    send({ type: 'ONCHANGE_TITLE', title: val });
  };

  const clearFields = (val) => {
    if (val === '1') {
      send({ type: 'CLEAR_TITLE' });
    }
  };

  const deleteMedia = () => {
    send({ type: 'SET_DISABLED', disabled: true });
    send({ type: 'CHANGED_MEDIA', changedMedia: true });

    setTimeout(() => {
      send({ type: 'DELETE_MEDIA', index: 0 });
      send({ type: 'SET_COVER', coverMedia: null });
    }, 100);
  };

  const handlePublishingCard = () => {
    clearFields(active);
    send({ type: 'SELECT_SUBFORMAT', subformat: 'VideoMessage' });
    send({ type: 'IS_FOR_PUBLISHING' });
    send('NEXT');
  };

  const handleSavingCard = () => {
    clearFields(active);
    send({ type: 'SELECT_SUBFORMAT', subformat: 'VideoMessage' });
    send({ type: 'IS_FOR_SAVING' });
    send('NEXT');
  };

  useEffect(() => {
    if (current.matches({ videoMessageEditor: 'discarding' })) {
      setShowModal(true);
    }
  }, [current]);

  const updateCard = async (cb) => {
    const cardData = {
      ...updatingData,
      coverMedia: {
        items: media,
      },
    };
    try {
      const response = await Api.updateEditedCard(getToken(), cardData);
      cb(null, response);
    } catch (err) {
      cb(err);
    }
  };

  const handleUpdatingCard = () => {
    waterfall(
      [
        function (callback) {
          updateCard(callback);
        },
      ],
      (err, res) => {
        if (!res.success) {
          setError(true);
          setShowModal(true);
          setModalTitle('Something went wrong!');
          setModalDesc('Can not update the card');
          return console.log('error');
        }

        return send({ type: 'CONFIRM_EDIT' });
      }
    );
  };

  useEffect(() => {
    validateFields();

    if (initData) {
      checkChanges();
    }

    if (subFormat === 'HeadlineVideoMessage') {
      setActive('2');
    }
  }, [title, media, subFormat]);

  const oKeys = current.value;
  const keyNames = Object.keys(oKeys);

  useEffect(() => {
    // setFormatTypes(VideoMessageSteps);
    send({ type: 'SET_USER_ID', userId: getUserId() });

    if (subFormat === 'VideoMessage' && title.length > 0) {
      setActive('2');
    }

    setUpdatingData({
      cardId,
      userId,
      title,
      content_v2: [
        {
          type: 'text',
          source: '<p></p>',
        },
      ],
      description: '',
      tenantId,
      streamId,
      format,
      subFormat,
      removedMedia,
      coverMedia,
    });

    if (allowedStates.includes(keyNames[0])) {
      performAutosave(send);
    }

    validateFields();
  }, []);

  const renderCardViews = () => (
    <div className={styles.dropzoneWrapper}>
      {current.matches({ videoMessageEditor: 'idle' }) && (
        <>
          <Dropzone
            accept="video/mp4,video/x-m4v,video/*"
            multiple={false}
            maxFiles={1}
            canCancel={false}
            InputComponent={VideoDropZoneInput}
            getFilesFromEvent={getFilesFromEvent}
            styles={{
              dropzone: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'unset',
                width: 360,
                height: 465,
                boxShadow: 'unset',
                border: 'unset',
              },
            }}
          />
        </>
      )}
      {current.matches({ videoMessageEditor: 'view' }) && (
        <>
          {loadingUpload ? (
            <div className={styles.videoLoadingWrapper}>
              <h3>Uploading video</h3>
              <ThreeDots />
            </div>
          ) : (
            <div className={styles.mediaWrapper}>
              <div className={styles.userAvatar}>
                <img
                  src={currentUser.avatar}
                  alt={currentUser.displayName}
                  width={40}
                  height={40}
                />
              </div>
              <Button btnClass="iconWrapper" onClick={() => deleteMedia()}>
                <Icon iconClass="trash" />
              </Button>
              {coverMedia && <LazyImage height={465} width={360} src={coverMedia} alt={title} />}
              <div className={styles.playIcon}>
                <Icon iconClass="play" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  useEffect(() => {
    if (active === '1') {
      send({
        type: 'SET_DISABLED',
        disabled: media.length === 0 && !coverMedia,
      });
    }

    if (active === '2') {
      send({
        type: 'SET_DISABLED',
        disabled: !coverMedia || title.length === 0,
      });
    }

    setUpdatingData({
      cardId,
      userId,
      title,
      tenantId,
      streamId,
      description: '',
      format,
      subFormat,
      content_v2: [
        {
          type: 'text',
          source: '<p></p>',
        },
      ],
      removedMedia,
    });
  }, [media, coverMedia, subFormat, active, title]);

  const populateCard = (cardData) => {
    const fetchedData = {
      ...cardData,
      content: cardData.content_v2,
      media:
        cardData.coverMedia.items && cardData.coverMedia.items !== null
          ? [
              {
                ...cardData.coverMedia.items[0],
              },
            ]
          : null,
      streamId: cardData.stream.streamId,
      files:
        cardData.coverMedia.items && cardData.coverMedia.items !== null
          ? cardData.coverMedia.items
          : null,
    };

    if (cardData) {
      const obj = {
        title: cardData.title,
        subFormat: cardData.subFormat,
        content: cardData.content_v2,
        media: [
          {
            ...cardData.coverMedia.items[0],
          },
        ],
      };

      send({ type: 'SET_INIT_DATA', initData: obj });
    }

    send({ type: 'SET_PARSED_MEDIA', parsed: true });
    send({
      type: 'SET_COVER',
      coverMedia: cardData.coverMedia.items[0].asset.highResThumbnailUrl,
    });
    send({ type: 'SET_CONTEXT', context: fetchedData });

    if (cardData.subFormat === 'VideoMessage' && cardData.title.length > 0) {
      setActive('2');
    }

    send({ type: 'SWITCH_VIEW' });

    setIsLoading(false);
  };

  const fetchCardData = async () => {
    try {
      const response = await Api.getCardData(getToken(), cardId, getTenant());
      if (cardId && !initData) {
        setIsLoading(true);
        populateCard(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cleanEditor = () => {
    send({ type: 'CLEAN_CONTEXT' });
    setTimeout(() => send({ type: 'BACK' }), 200);
  };

  const saveChanges = () => {
    send({ type: 'IS_FOR_PUBLISHING' });
    setTimeout(() => send({ type: 'NEXT' }), 250);
  };

  const onCloseModal = () => {
    setShowModal(false);
    send({ type: 'IS_EXITING', exit: false });
  };

  const discardModal = () => {
    if (editing) {
      return changed && isExiting ? (
        <Modal
          type="save-work"
          isEditing={editing}
          showModal={showModal}
          onCancel={() => navigate('-1')}
          onConfirm={() => setShowModal(false)}
          onClose={() => onCloseModal()}
        />
      ) : null;
    }
    if (error) {
      return (
        <Modal
          type="error"
          title={modalTitle}
          desc={modalDesc}
          showModal={showModal}
          onConfirm={() => handleModalAction()}
          onCancel={() => cancelModal()}
        />
      );
    }
    return isExiting ? (
      <Modal
        type="save-work"
        showModal={showModal}
        title="Cancel Card Creation"
        onCancel={() => cleanEditor()}
        onConfirm={() => saveChanges()}
        onClose={() => onCloseModal()}
      />
    ) : null;
  };

  useEffect(() => {
    if (isExiting) {
      setShowModal(true);
    }
  }, [isExiting]);

  useEffect(() => {
    fetchCardData();
  }, [cardId]);

  return (
    <div className={styles.editorContainer}>
      <SEO title={editing ? 'Edit Card' : 'Create Card'} />

      {discardModal()}

      {!loading ? (
        <>
          <div className={styles.titleWrapper}>
            <h2>Video Message Card</h2>
            <span>{renderSubTitle(subFormat)}</span>
          </div>
          <div className={styles.cardTypes}>
            {VideoMessageSteps.map((item) => (
              <Button
                btnClass={active === item.id ? 'activeBtn' : ''}
                key={item.id}
                onClick={() => setActiveCard(item.id, item.name)}
              >
                {item.name}
              </Button>
            ))}
          </div>
          <div className={`${styles.curationEditor} ${styles.videoMessagEditor}`}>
            <CurationHeader
              type={subFormat}
              noMedia={media.length === 0}
              author={editing ? author.name : currentUser.displayName}
              avatar={editing ? author.avatar : currentUser.avatar}
              date={formatDate(created || createDate, 'MMMM DD, YYYY')}
              enlarged
            />
            <div className={styles.videoWrapper}>{renderCardViews()}</div>
            {active === '2' && (
              <div className={styles.inputWrapper}>
                <Input
                  placeholder="Headline"
                  hasVideo={!!coverMedia}
                  customClass="headlineInput"
                  value={title}
                  maxLength={75}
                  valueChange={(e) => headlineChanged(e)}
                />
              </div>
            )}
          </div>
          <div
            className={`${styles.statusButtons} ${styles.relativePositioning} ${
              editing && styles.centeredButtons
            }`}
          >
            {!editing ? (
              <>
                <Button
                  btnClass="editorBtn"
                  hasDisabled={disabled}
                  onClick={() => handleSavingCard()}
                >
                  Save
                </Button>
                <Button
                  btnClass="editorBtn"
                  hasDisabled={disabled}
                  greenColor
                  onClick={() => handlePublishingCard()}
                >
                  Publish
                </Button>
              </>
            ) : (
              <>
                <Button
                  btnClass="editorBtn"
                  hasDisabled={disabled}
                  greenColor
                  onClick={() => handleUpdatingCard()}
                >
                  Update Card
                </Button>
              </>
            )}
          </div>
        </>
      ) : (
        <ThreeDots />
      )}
    </div>
  );
};

VideoMessageEditor.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default isLoadingHoc(VideoMessageEditor);
