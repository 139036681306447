import React from 'react';
import { motion } from 'framer-motion';
import SEO from '@components/seo';
import { Link } from '@reach/router';
import Header from '@components/organisms/header';
import Button from '@components/atoms/button';
import * as styles from '@components/templates/card-listings/cards.module.scss';

const NoChannels = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className={styles.contentWrapper}
  >
    <SEO title="No Channels" />
    <Header />
    <div className={`${styles.cardsWrapper} ${styles.fullHeight}`}>
      <div className={styles.noData}>
        <h3>You need to create a channel first</h3>
        <p>Try creating one and then proceed with card creation</p>
        <Link to="/app/create-channel">
          <Button btnClass="primary">Create Channel</Button>
        </Link>
      </div>
    </div>
  </motion.div>
);

export default NoChannels;
