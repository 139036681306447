import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Api from '@api';
import useSWR, { useSWRConfig } from 'swr';
import waterfall from 'async/waterfall';

import SEO from '@components/seo';
import { getToken, getUserId, placeholders, getTenant } from '@helpers';

import { Link } from '@reach/router';

import Header from '@components/organisms/header';
import Placeholder from '@components/atoms/placeholder';
import Card from '@components/organisms/card';
import Button from '@components/atoms/button';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import ThreeDots from '@components/atoms/loaders/three-dots';

import Modal from '@components/molecules/modal';
import * as styles from '@components/templates/card-listings/cards.module.scss';
// import ChannelsService from '@services/channels';

const Channels = () => {
  const { cache } = useSWRConfig();
  const userId = getUserId();
  const tenantId = getTenant();
  const [channelId, setChannelId] = useState(null);

  const [emptyData, setEmptyData] = useState(true);

  const [prevStatus, setPrevStatus] = useState('all');
  const [cardFilter, setCardFilter] = useState('all');
  const [order, setOrder] = useState('DESC');
  const [cards, setCards] = useState([]);

  // page indexes
  const [prevPage, setPrevPage] = useState(1);
  const [pageIndex, setPageIndex] = useState(1);
  const [channelState, setChannelState] = useState(0);

  // fetching mechanism
  const [fetching, setFetching] = useState(false);
  const [noFetching, setNoFetching] = useState(false);

  const [type, setType] = useState('');
  const [cId, setcId] = useState(null);
  const [currentState, setCurrentState] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const [secondaryStyle, setModalStyle] = useState(false);

  const { data, error, mutate } = useSWR(
    ['/listContentCreationStreams', cardFilter, order, pageIndex, channelState],
    () =>
      fetching || !noFetching ? Api.listContentCreationStreams(cardFilter, order, pageIndex) : ''
  );

  const publishChannel = async (cb) => {
    let streamId = null;
    streamId = channelId;
    try {
      const response = await Api.publishChannel(getToken(), {
        streamId,
        userId,
        tenantId,
      });

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const checkChannelState = (state) => {
    if (type === 'trash-delete' && currentState === 'autosaved') {
      return 'trashed_autosaved';
    }
    return state;
  };

  const updateChannelState = async (state, cb) => {
    try {
      const response = await Api.updateChannelState(getToken(), {
        streamId: channelId,
        tenantId,
        userId: getUserId(),
        streamState: checkChannelState(state),
      });

      setChannelId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const onScrollEnd = () => {
    if (!noFetching) {
      setFetching(true);
      if (!fetching) {
        setPageIndex(pageIndex + 1);
      }
    }
  };

  useBottomScrollListener(onScrollEnd);

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        if (cards.length === 0) {
          setEmptyData(true);
        }
        if (cards.length > 0) {
          setNoFetching(true);
          // setPageIndex(pageIndex - 1);
        }
        if (cardFilter !== prevStatus) {
          setCards([]);
        }

        if (prevPage === pageIndex) {
          if (cardFilter === prevStatus && data.length === 0) {
            setCards([]);
          }
        }

        // setPrevStatus(cardFilter);
      } else {
        setNoFetching(false);
        if (pageIndex === prevPage) {
          setCards(data);
          setFetching(false);
        } else if (cardFilter !== prevStatus) {
          setCards(data);
          setFetching(false);
        } else {
          const newUpdatedValues = [...cards, ...data];
          setCards(newUpdatedValues);
          setPrevPage(pageIndex);
          setFetching(false);
        }
      }
    }
  }, [data, error]);

  const changeChannelStatus = (val) => {
    setPrevStatus(cardFilter);
    setTimeout(() => {
      setCardFilter(val);
    }, 300);
    setPrevPage(1);
    setPageIndex(1);
    window.scrollTo(0, 0);
  };

  const setModalValues = (modalType, title, desc, secondary) => {
    setType(modalType);
    setModalTitle(title);
    setModalDesc(desc);
    setModalStyle(secondary);
  };

  const handleChannelStateAction = (id, type, state) => {
    setType(type);
    setChannelId(id);
    setCurrentState(state);
    switch (type) {
      case 'publish':
        setModalValues(
          'publish',
          'Are you sure you want to publish this channel?',
          <>
            <p>If you publish it all related cards will be published as well.</p>
            <p>
              If you want to publish specific cards only, go inside the channel and click on the
              icon on top right of the cards you want to publish.
            </p>
          </>,
          true
        );
        break;
      case 'unpublished':
        setModalValues(
          'unpublish',
          'Are you sure you want to unpublish this channel?',
          <>
            <p>
              This channel and all associated cards will <br /> no longer be visible to Justt users.
            </p>
            <p>You will lose your subscribers</p>
          </>
        );
        break;
      case 'trash-delete':
        setModalValues(
          'trash-delete',
          'Delete Channel',
          <>
            <p style={{ marginBottom: '1rem' }}>
              Are you sure you want to delete this channel? <br />
            </p>
            <p>
              All cards inside of the channel will be deleted accordingly and disappear for your
              users!
            </p>
          </>
        );
        break;
      default:
        break;
    }
    setTimeout(() => {
      setShowModal(true);
    }, 200);
  };

  const errorCodes = [500, 403, 400, 409, 401];

  const publishChannelAction = () => {
    waterfall(
      [
        function (callback) {
          publishChannel(callback);
        },
      ],
      (err, res) => {
        if (errorCodes.includes(res.code)) {
          setShowModal(true);
          setModalValues(
            'cant-publish',
            'Channel can’t be published',
            'Cannot publish a channel without a card in it'
          );
        } else {
          setShowModal(true);
          setModalValues(
            'successfully-deleted',
            'Published Channel',
            'Channel was published successfully'
          );
          mutate();
        }
      }
    );
  };

  const handleErrorModal = (type) => {
    switch (type) {
      case 'unpublished':
        setModalValues(
          'error',
          'Channel can’t be unpublished',
          'Something went wrong! Channel can not be unpublished!'
        );
        break;
      case 'trashed':
        setModalValues(
          'error',
          'Channel can not be deleted',
          'Something went wrong! Channel can not be deleted!'
        );
        break;
      default:
        break;
    }
  };

  const handleSuccessModal = (type) => {
    cache.delete();
    switch (type) {
      case 'unpublished':
        setModalValues(
          'successfully-deleted',
          'Unpublished channel',
          'Channel was unpublished successfully'
        );
        mutate();
        cache.delete();
        break;
      case 'trashed':
        setModalValues(
          'successfully-deleted',
          'Deleted channel',
          'Channel was deleted successfully'
        );
        mutate();
        cache.delete();
        break;
      default:
        break;
    }
  };

  const updateChannelStateAction = (state) => {
    setShowModal(false);
    waterfall(
      [
        function (cb) {
          updateChannelState(state, cb);
        },
      ],
      (err, res) => {
        setShowModal(true);
        if (!res?.success) {
          handleErrorModal(state);
        } else {
          handleSuccessModal(state);
        }
      }
    );
  };

  const clearModal = () => {
    setModalValues('', '', '');
  };

  const handleModalAction = (modalType) => {
    if (modalType === 'publish') {
      publishChannelAction();
    }

    if (modalType === 'trash-delete') {
      updateChannelStateAction('trashed');
    }

    if (modalType === 'unpublish') {
      updateChannelStateAction('unpublished');
    }

    if (modalType === 'error') {
      setShowModal(false);
    }

    clearModal();
  };

  const cancelModal = () => {
    setShowModal(false);
    clearModal();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.contentWrapper}
    >
      <SEO title="Channels" />
      {showModal && (
        <Modal
          title={modalTitle}
          desc={modalDesc}
          type={type}
          showModal={showModal}
          secondary={secondaryStyle}
          onConfirm={() => handleModalAction(type)}
          onCancel={() => cancelModal()}
        />
      )}
      <Header
        setCardFilter={(val) => changeChannelStatus(val)}
        setCardOrder={(val) => setOrder(val)}
      />
      <div
        id="channelList"
        className={`${styles.cardsWrapper} ${emptyData && cards.length === 0 && styles.fullHeight}`}
      >
        {cards &&
          cards.length > 0 &&
          cards.map((card) => (
            <Card
              isChannel
              date={card.created}
              status={card.streamState || card.published}
              key={card.streamId}
              src={card?.coverMedia?.items ? card?.coverMedia?.items[0] : null}
              id={card.streamId}
              title={card.name}
              user={card.user || null}
              deleteCard={() =>
                handleChannelStateAction(card.streamId, 'trash-delete', card.streamState)
              }
              publishCard={() => handleChannelStateAction(card.streamId, 'publish')}
              unpublishCard={() => handleChannelStateAction(card.streamId, 'unpublished')}
              hasLink
            />
          ))}
        {data && !noFetching && cards.length >= 10 ? (
          <div className={styles.loadMoreCards}>
            <Button btnClass="primary" onClick={() => setPageIndex(pageIndex + 1)}>
              View More
            </Button>
          </div>
        ) : null}

        {!data && cards.length > 0 && (
          <div className={styles.incomingCards}>
            <h5>Loading New Channels</h5>
            <ThreeDots />
          </div>
        )}

        {!data && cards.length === 0 && placeholders.map((item) => <Placeholder key={item.id} />)}

        {data && cards.length === 0 && data.length === 0 ? (
          <div className={styles.noData}>
            <h3>Currently you don't have any channels</h3>
            <p>Try creating one and they will appear here</p>
            <Link to="/app/create-channel">
              <Button btnClass="primary">Create Channel</Button>
            </Link>
          </div>
        ) : null}
      </div>
    </motion.div>
  );
};

export default Channels;
