import React, { useState, useRef, useEffect } from 'react';

import Cropper from 'react-easy-crop';
import getCroppedImg from '@helpers/cropImage';
import {
  mediaHandlingService,
  singleValidation,
  // getFilesFromEvent,
  // validateMediaUrl,
  getCleanUploadedFiles,
  readFile,
} from '@services/image';

import Icon from '@components/atoms/icon';
import Modal from '@components/molecules/modal';

// import { mediaButtons } from '@helpers/main.module.scss';
import Button from '@components/atoms/button';
import {
  editImageIcon,
  editLabel,
  imgCropper,
  imgWrapper,
  innerModalLayout,
  hiddenInput,
  statusButtons,
} from '../../profile.module.scss';

function EditProfile({ userData, files, send, showModal }) {
  // Local State
  const [aspect] = useState(1 / 1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState([1]);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const fileRef = useRef(null);

  const onFiles = (files) =>
    getCleanUploadedFiles(files).then((chosenFiles) =>
      chosenFiles.forEach(async ({ fileObject }) => {
        const imageDataUrl = await readFile(fileObject);

        const file = [
          {
            originalFile: fileObject,
            originalUrlBlob: imageDataUrl,
            croppedFile: fileObject,
            croppedUrlBlob: imageDataUrl,
          },
        ];

        mediaTransformation(file);
      })
    );

  const uploadFiles = () => fileRef.current.click();

  // Generate cropped image data
  const handleCrop = async () => {
    const { blobUrl, binaryFile } = await getCroppedImg(userData.avatar, croppedAreaPixels);
    send({
      type: 'HANDLE_CROP',
      croppedFile: binaryFile,
      croppedUrlBlob: blobUrl,
    });
  };

  const onCropChange = (cropValue) => {
    setCrop(cropValue);
  };

  const onCropComplete = (_, cropAreaPixles) => {
    setCroppedAreaPixels(cropAreaPixles);
  };

  const onZoomChange = (zoomValue) => {
    setZoom([zoomValue]);
  };

  const justCrop = () => {
    handleCrop();
  };

  const deleteImage = () => {
    send({
      type: 'DELETE_AVATAR',
      userData: {
        ...userData,
        avatar: null,
      },
    });
  };

  const updateUserImage = async ({ success, response }) => {
    if (success) {
      const validation = await singleValidation(response[0].original);
      if (validation) {
        send({
          type: 'EDIT_DISPLAY_IMAGE',
          userData: {
            ...userData,
            avatar: response[0].uncachedMediaUrl,
            avatarKey: response[0].key,
          },
        });
        send({ type: 'HIDE_MODAL' });
      }
    }
  };

  const mediaTransformation = async (uploadedFiles = files) => {
    try {
      await mediaHandlingService(uploadedFiles, updateUserImage, true, true);
    } catch (error) {
      throw error;
    }
  };

  const onDoneCropping = () => {
    setCrop({ x: 0, y: 0 });
    setZoom([1]);
    setCroppedAreaPixels(null);
    mediaTransformation();
  };

  // useEffect(() => {}, [files])

  return (
    <>
      <div className={imgWrapper}>
        {userData && (
          <img srcSet={userData?.avatar} width="100" height="100" alt={userData?.displayName} />
        )}
        <button
          className={editImageIcon}
          type="button"
          onClick={() => send({ type: 'SHOW_MODAL' })}
        >
          <Icon iconClass="edit" />
        </button>
        <span className={editLabel}>Edit Picture</span>
      </div>

      {showModal ? (
        <Modal showModal={showModal} type="default">
          <div className={innerModalLayout}>
            {userData?.avatar ? (
              <>
                <div className={imgCropper}>
                  <Cropper
                    image={userData?.avatar}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspect}
                    minZoom={1}
                    maxZoom={3}
                    width={180}
                    height={180}
                    style={{
                      cropAreaStyle: {
                        border: '0',
                        color: 'rgba(255, 255, 2555, 0.7)',
                        borderRadius: '100%',
                      },
                    }}
                    showGrid={false}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                    onInteractionEnd={justCrop}
                  />
                </div>
                <Button btnClass="nonRoundedBtn" onClick={() => deleteImage()}>
                  Delete
                </Button>
              </>
            ) : (
              ''
            )}
            <input
              type="file"
              name="uploadPicture"
              ref={fileRef}
              accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/webp"
              onChange={(e) => onFiles(e)}
              className={hiddenInput}
            />
            <div className={statusButtons}>
              <Button btnClass="category" statusBtn onClick={() => uploadFiles()}>
                Upload
              </Button>
              <Button
                btnClass="saveProfile"
                hasDisabled={!userData.avatar}
                statusBtn
                onClick={() => onDoneCropping()}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
}

export default EditProfile;
