import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@components/atoms/icon';

import * as styles from './videoDropzoneInput.module.scss';

const VideoDropZoneInput = ({ accept, onFiles, getFilesFromEvent }) => (
  <div className={styles.inputWrapper}>
    <label htmlFor="upload" className={styles.filesWrapper}>
      <input
        type="file"
        id="upload"
        accept={accept}
        onChange={(e) => getFilesFromEvent(e).then((chosenFiles) => onFiles(chosenFiles))}
      />
      <div className={styles.IconWrapper}>
        <Icon iconClass="add-video" fSize={3.6} />
      </div>
      <h4>Add Video</h4>
    </label>
  </div>
);

VideoDropZoneInput.propTypes = {
  accept: PropTypes.string,
  onFiles: PropTypes.func,
  getFilesFromEvent: PropTypes.func,
};

export default VideoDropZoneInput;
