import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './container.module.scss';

const ContainerWrapper = ({ children }) => (
  <div className={styles.containerWrapper}>{children}</div>
);

ContainerWrapper.propTypes = {
  children: PropTypes.node,
};

export default ContainerWrapper;
