import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

// import Input from '@components/atoms/input';
import Counter from '@components/atoms/character-counter';
import Button from '@components/atoms/button';
import { Textarea } from '../../styles';
import * as styles from '../../create-channel.module.scss';

const AddChannelTitle = ({ current, send }) => {
  const textAreaRef = useRef();
  const [length, setLength] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const { name } = current.context;
  const emptySpaces = name.trim().length;

  useEffect(() => {
    textAreaRef.current.focus();
  }, [current.value]);

  const toggleChanges = (val) => {
    if (emptySpaces >= 10) {
      setDisabled(false);
    }
    setLength(emptySpaces);
    send({ type: 'ON_CHANGE', name: val });
    setTimeout(() => {
      send({ type: 'SET_CHANGED', changed: val.length > 0 });
    }, 200);
  };

  const onKeyDownPreventEnter = (evt) => {
    if (evt.keyCode === 13 && evt.shiftKey === false) {
      evt.preventDefault();
    }
  };

  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.current.setSelectionRange(name.length, name.length);
    }

    if (emptySpaces > 10) {
      setLength(emptySpaces);
    }
  }, []);

  useEffect(() => {
    setLength(name.length);
    if (emptySpaces > 0) {
      setLength(name.length);
      setDisabled(false);
    }

    if (emptySpaces === 0) {
      setDisabled(true);
    }
  }, [name]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
        className={styles.motionContainer}
      >
        <div className={styles.stepperWrapper}>
          <div className={styles.titleWrapper}>
            <h2>Channel Title</h2>
          </div>
          <div className={styles.contentWrapper}>
            <Counter totalValue={40} changedValue={length} startValue={0} />
            <Textarea
              ref={textAreaRef}
              placeholder="Enter title here..."
              fontSize={['3rem', '4rem']}
              fontWeight="bold"
              padding="0"
              border="none"
              width={['100%', '61.5rem']}
              name="name"
              value={name}
              style={
                current.matches({ step1: 'invalid' })
                  ? {
                      borderRight: '2px solid red',
                    }
                  : null
              }
              maxLength={40}
              onChange={(evt) => {
                toggleChanges(evt.target.value);
              }}
              onKeyDown={onKeyDownPreventEnter}
            />
          </div>
          <div className={styles.footerWrapper}>
            <Button
              btnClass="primary"
              hasDisabled={disabled}
              onClick={() => (!disabled ? send('NEXT') : '')}
            >
              Next
            </Button>
          </div>
        </div>
        {/* <Div display="flex" alignItems="center" flexDirection="column"></Div> */}
      </motion.div>
    </>
  );
};

AddChannelTitle.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default AddChannelTitle;
