/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import Icon from '@components/atoms/icon';
import { EditorState, Modifier, SelectionState } from 'draft-js';
// import { hoveredItem, nonHoveredItem } from '../draft-editor.scss';

// FIXME Remove or add these to the other items
// { label: 'Quote', style: 'QUOTE', block_type: 'quote' },
// { label: 'Editors Note', style: 'EDITOR', block_type: 'editorsNote' },

export const PARAGRAPH_STYLES = [
  { label: 'Body', blocktype_style: 'unstyled' },
  { label: 'Subheading', blocktype_style: 'header-two' },
  { label: 'Blockquote', blocktype_style: 'blockquote' },
];

export const CHARACTER_STYLES = [
  { label: 'Body', blocktype_style: 'unstyled' },
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Highlight', style: 'HIGHLIGHT' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Strikethrough', style: 'STRIKETHROUGH' },
];

export const LIST_STYLES = [
  { label: 'Bullets', blocktype_style: 'unordered-list-item' },
  { label: 'Numbers', blocktype_style: 'ordered-list-item' },
];

export const MEDIA_STYLES = [
  {
    label: 'Image',
    blocktype_style: 'image',
  },
  {
    label: 'Image Carousel',
    blocktype_style: 'carousel',
  },
  {
    label: 'Video',
    blocktype_style: 'video',
  },
];

export const EXTERNAL_CONTENT = [
  {
    label: 'External Link',
    blocktype_style: 'externalLink',
  },
  {
    label: 'Tweet',
    blocktype_style: 'externalContent',
  },
];

export const OPTION_TYPES = [
  {
    label: 'Character',
    icon: 'characters',
    font_size: 4.6,
    child_menu: 'characters',
  },
  {
    label: 'Paragraph',
    icon: 'paragraphs',
    font_size: 3.3,
    child_menu: 'paragraphs',
  },
  {
    label: 'Lists',
    icon: 'lists',
    font_size: 3.3,
    child_menu: 'lists',
  },
  {
    label: 'Add Media',
    icon: 'media',
    font_size: 3.3,
    child_menu: 'media',
  },
  {
    label: 'Add Link',
    icon: 'article-link',
    font_size: 2.4,
    child_menu: 'external',
  },
];

export const IMPRESSUM_OPTION_TYPES = [
  {
    label: 'Character',
    icon: 'characters',
    font_size: 4.6,
    child_menu: 'characters',
  },
  {
    label: 'Paragraph',
    icon: 'paragraphs',
    font_size: 3.3,
    child_menu: 'paragraphs',
  },
  {
    label: 'Lists',
    icon: 'lists',
    font_size: 3.3,
    child_menu: 'lists',
  },
];

export const InputTypes = ['carousel', 'image'];

export const mediaTypes = ['carousel', 'image', 'video', 'externalContent'];

export const IGNORED_TYPES = [
  'image',
  'carousel',
  'video',
  'link',
  'externalContent',
  'externalLink',
];

export const uploadImages = () => {
  const images = [];
  return images;
};

export const subItemClass = (itemClass) => {
  switch (itemClass) {
    case 'header-two':
      return 'subHeadingItem';
    case 'BODY':
      return 'bodyItem';
    case 'QUOTE':
      return 'quoteItem';
    case 'EDITOR':
      return 'editorsNoteItem';
    case 'BOLD':
      return 'boldItem';
    case 'ITALIC':
      return 'italicItem';
    case 'HIGHLIGHT':
      return 'highlightItem';
    case 'LINK':
      return 'linkItem';
    case 'UNDERLINE':
      return 'underlineItem';
    case 'STRIKETHROUGH':
      return 'strikethroughItem';
    default:
      return 'randomItem';
  }
};

export const parsingData = (item) => {
  let data = {};

  if (!item) {
    return null;
  }

  if (item.type === 'image') {
    data = {
      component: item.type,
      data: {
        source: item.source,
        caption: item.caption || '',
        type: item.type,
        metadata: item.metadata,
      },
    };
  }

  if (item.type === 'carousel') {
    data = {
      component: item.type,
      data: {
        carousel: item.carousel,
        type: item.type,
        metadata: item.metadata,
      },
    };
  }

  if (item.type === 'video') {
    data = {
      component: item.type,
      data: {
        caption: item.caption,
        source: item.source,
        type: item.type,
      },
    };
  }

  if (item.type === 'text') {
    data = {
      component: item.type,
      data: {
        source: item.source,
        type: item.type,
      },
    };
  }

  if (item.type === 'articlePreview') {
    data = {
      component: item.type,
      data: {
        source: item.source,
        articlePreview: item.articlePreview,
        type: item.type,
      },
    };
  }

  return data;
};

export const inlineStyles = [
  'SUBHEADING',
  'STRIKETHROUGH',
  'ITALIC',
  'BOLD',
  'HIGHLIGHT',
  'UNDERLINE',
];

export const styleMap = {
  SUBHEADING: {
    fontWeight: 'bold',
    fontSize: '2.4rem',
    color: '#000',
  },
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
  LINKITEM: {
    color: '#000',
    textDecoration: 'underline',
    textDecorationColor: '#0a84ff',
    textDecorationOffset: '1px',
    textDecorationThickness: '1px',
  },
  ITALIC: {
    fontStyle: 'italic',
  },
  HIGHLIGHT: {
    backgroundColor: '#AFFFAA',
  },
  BODY: {
    fontWeight: '400',
    fontSize: 'inherit',
    fontStyle: 'unset',
    textDecoration: 'unset',
    color: 'inherit',
  },
};

export function myBlockRenderMap(contentBlock) {
  const type = contentBlock.getType();
  switch (type) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'unstyled':
      if (type.text === ' ' || type.text === '') {
        return <br />;
      }
      return <div />;
    default:
      return null;
  }
}

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

export const Link = (props) => {
  const { contentState, children, blockKey, start, end, entityKey, hoverIngItem, hoveringOut } =
    props;

  const linkRef = useRef(null);
  const { url } = contentState.getEntity(entityKey).getData();
  const [linkMeta, setLinkMeta] = useState(0);

  const getLinkOffset = () => {
    const { left, top, width } = linkRef.current.getBoundingClientRect();

    setLinkMeta({
      entityKey,
      blockKey,
      url,
      start,
      end,
      width,
      top,
      left,
    });
  };

  useEffect(() => {
    if (linkRef.current) {
      getLinkOffset();
    }
    window.addEventListener('scroll', getLinkOffset);

    return () => window.removeEventListener('scroll', getLinkOffset);
  }, []);

  const onHoveringItem = () => {
    const blockKey = contentState.getBlockForKey(linkMeta.start);

    hoverIngItem({
      entityKey,
      linkMeta,
    });
  };

  return (
    <>
      <a
        href={url}
        style={{ position: 'relative' }}
        onMouseEnter={() => onHoveringItem()}
        onMouseLeave={() => hoveringOut()}
        ref={linkRef}
        // onClick={() => window.open(url, '_blank')}
      >
        {children}
      </a>
    </>
  );
};

export const getSelectedBlocks = (contentState, anchorKey, focusKey) => {
  const isSameBlock = anchorKey === focusKey;
  const startingBlock = contentState.getBlockForKey(anchorKey);
  const selectedBlocks = [startingBlock];

  if (!isSameBlock) {
    let blockKey = anchorKey;

    while (blockKey !== focusKey) {
      const nextBlock = contentState.getBlockAfter(blockKey);
      selectedBlocks.push(nextBlock);
      blockKey = nextBlock.getKey();
    }
  }

  return selectedBlocks;
};

export const removeInlineStyles = (eState) => {
  const contentState = eState.getCurrentContent();
  const contentWithoutStyles = inlineStyles.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(newContentState, eState.getSelection(), style),
    contentState
  );

  const newEditorState = EditorState.push(eState, contentWithoutStyles, 'change-inline-style');
  return newEditorState;
};

export const removeEntities = (eState) => {
  const contentState = eState.getCurrentContent();
  const contentWithoutEntities = Modifier.applyEntity(contentState, eState.getSelection(), null);

  const newEditorState = EditorState.push(eState, contentWithoutEntities, 'apply-entity');

  return newEditorState;
};

export const getPlatform = (pastedLink) => {
  if (pastedLink.includes('youtube.com')) {
    return 'youtube';
  } else if (pastedLink.includes('vimeo.com')) {
    return 'vimeo';
  } else if (pastedLink.includes('twitter.com')) {
    return 'twitter';
  } else if (pastedLink.includes('soundcloud.com')) {
    return 'soundcloud';
  } else if (pastedLink.includes('facebook.com')) {
    return 'facebook';
  } else {
    return 'link';
  }
};

const removeBlockTypes = (eState) => {
  const contentState = eState.getCurrentContent();
  const currentSelection = eState.getSelection();

  const startKey = currentSelection.getStartKey();
  const endKey = currentSelection.getEndKey();

  const blocksMap = getSelectedBlocks(contentState, startKey, endKey);
  const contentWithoutBlocks = blocksMap.reduce((newContentState, block) => {
    const blockType = block.getType();
    if (blockType === 'unstyled') {
      const selectionState = SelectionState.createEmpty(block.getKey());
      const updatedSelection = selectionState.merge({
        focusOffset: 0,
        anchorOffset: block.getText().length,
      });

      return Modifier.setBlockType(newContentState, updatedSelection, 'unstyled');
    }

    return newContentState;
  }, contentState);

  const newEditorState = EditorState.push(editorState, contentWithoutBlocks, 'change-block-type');

  return newEditorState;
};

export const getCurrentLinkKey = (editorState, contentState, startKey, startOffset) => {
  if (contentState === undefined) {
    contentState = editorState.getCurrentContent();
  }

  const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
  return blockWithLinkAtBeginning.getEntityAt(startOffset);
};

// TODO Fix these helper functions, have them runable

// export const htmlParsedContent = (state) => convertToHTML({
//   styleToHTML: (style) => {
//     if (style === 'HIGHLIGHT') {
//       return <span style={{ backgroundColor: '#8cff86' }} />;
//     }
//     if (style === 'STRIKETHROUGH') {
//       return <span style={{ textDecoration: 'line-through' }} />;
//     }
//   },
// })(state.getCurrentContent());

// export const splitBlocks = ({ splittingItem, editorState }) => {
//   const entityMap = editorState.getCurrentContent().getEntityMap();
//   const contentState = ContentState.createFromBlockArray(splittingItem, entityMap);
//   const selection = editorState.getSelection();

//   const newLineBlock = Modifier.splitBlock(contentState, selection);
//   const newState = editorState.push(
//     editorState,
//     newLineBlock,
//     'split-block'
//   );

//   const splittedBlocks = newState.getCurrentContent().getBlocksAsArray();
//   return splittedBlocks;
// }

// export const getKeyValues = ({ caretData, setBlocks, editorState, id }) => {
//   const itemIndex = caretData.currentId;
//   const caretPosition = caretData.caret;

//   // Overall record
//   let leftItems = [];
//   let splittingItem = [];
//   let rightItems = [];

//   // fetch all editorState blocks
//   const blocksArray = editorState.getCurrentContent().getBlocksAsArray();
//   if (caretData.editorIndex === id) {
//     blocksArray.map((item, idx) => {
//       const characterSize = item.getCharacterList().size;

//       // Two conditions: if the index of the iterated block is lower than the index
//       // of the block where the caret is, then add it to the left side but also
//       // if indexes match and the cursor is at the very end of the line of that block
//       if (idx < itemIndex) {
//         return leftItems.push(item);
//       }

//       if (idx === itemIndex && caretPosition === characterSize) {
//         return leftItems.push(item);
//       }

//       // If we are at the start of the editor, then add all blocks to the next editor
//       // and append the media items before this editor
//       if (idx === 0 && itemIndex === 0 || caretPosition === 0) {
//         return rightItems.push(item);
//       }

//       if (idx > itemIndex) {
//         return rightItems.push(item);
//       }

//       // In the event that the conditions above aren't met that means we go with
//       // splitting a certain block. Meaning: the cursor position is between the lines of one block
//       // and that block will require splitting where one part will go left,
//       // the other part will right

//       return splittingItem.push(item);
//     });

//     if (splittingItem) {
//       const additionalBlocks = splitBlocks({ splittingItem, editorState });
//       leftItems.push(additionalBlocks[0]);
//       const newRighItems = [additionalBlocks[1], ...rightItems];
//       rightItems = newRighItems;
//     }

//     const updatedblocks = {
//       leftItems,
//       rightItems,
//     };

//     setBlocks(updatedblocks);
//   }
// };
