import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { motion } from 'framer-motion';
import SEO from '@components/seo';
import CardSuccessIcon from '@images/card-create-succes-illustration.svg';
import * as styles from '../../create-new-card.module.scss';

const Saved = () => {
  const [onDone, setDone] = useState(false);
  const animateVariants = {
    initial: {
      y: '75vh',
      opacity: 0,
      transition: {
        delay: 1,
      },
    },
    onEnter: {
      y: '15vh',
      opacity: 1,
      transition: {
        duration: 1,
        ease: [0.38, 1.34, 0.91, 1.07],
      },
    },
    exitAnimation: {
      y: '-75vh',
      opacity: 0,
      transition: {
        delay: 1,
        ease: [0.46, -0.85, 0.27, 0.88],
        duration: 1.5,
      },
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    }, 1500);

    setTimeout(() => {
      navigate('/app/cards');
    }, 4000);
  }, []);

  return (
    <>
      <motion.div
        className={`${styles.parentWrapper} ${styles.noOverflow}`}
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
      >
        <SEO title="Card Saved Successfully - Create Card" />
        <motion.div
          className={styles.cardSuccessBox}
          variants={animateVariants}
          initial="initial"
          animate={onDone ? 'exitAnimation' : 'onEnter'}
          exit="initial"
        >
          <div>
            <CardSuccessIcon />
          </div>
          <h3>
            Card Saved <br />
            Successfully
          </h3>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Saved;
