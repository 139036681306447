import React from 'react';
import PropTypes from 'prop-types';

const InstagramEmbed = ({ source }) => {
  return <>No embed for Instagram so far</>;
};

InstagramEmbed.propTypes = {
  source: PropTypes.string.isRequired,
};

export default InstagramEmbed;
