import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SEO from '@components/seo';

import isLoadingHoc from '@components/hoc/isLoading';
import Dropzone from 'react-dropzone-uploader';
import ReactPlayer from 'react-player';
import { navigate } from '@reach/router';
import waterfall from 'async/waterfall';
import { motion } from 'framer-motion';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
// import useSWR, { cache } from 'swr';

import Api from '@api';
import LazyImage from '@hooks/lazy-image';
import CardLink from '@components/atoms/card-link';
// import CuratedBy from '@components/atoms/curated-by';

import Modal from '@components/molecules/modal';
import Button from '@components/atoms/button';
import Icon from '@components/atoms/icon';
import Input from '@components/atoms/input';
import ThreeDots from '@components/atoms/loaders/three-dots';

import ArticlePreview from '@components/templates/create-card/components/article-preview';
import CurationHeader from '@components/templates/create-card/components/curation-header';
import VideoDropZoneInput from '@components/templates/create-card/components/video-dropzone-input';

import {
  ArticleLinkSteps,
  compareObjectChanges,
  hasCurationInfo,
  hasCurationLink,
  VideoRecommendationSteps,
  allowedStates,
  performAutosave,
} from '@components/templates/create-card/helpers';

import {
  // formatDateLabels,
  formatDate,
  getTenant,
  getToken,
  getUserData,
  getUserId,
  uuidv4,
} from '@helpers';

import * as styles from '../editor-type.module.scss';

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const CurationCardEditor = ({
  current,
  send,
  cardId,
  showLinkModal,
  modalData,
  videoModalData,
  setLoading,
  endAnimation,
  showVideoModal,
}) => {
  const { event, context } = current;
  const {
    subFormat,
    author,
    userId,
    tenantId,
    streamId,
    isExiting,
    format,
    created,
    title,
    description,
    content,
    media,
    coverMedia,
    removedMedia,
    files,
    editing,
    changed,
    initData,
  } = context;

  const [formatTypes, setFormatTypes] = useState([]);

  // TODO Move all this to state machines
  const [linkUrl, setLinkUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [addLink, setAddLink] = useState(true);
  const [linkAddress, setLinkAddress] = useState(null);

  const [loading, setIsLoading] = useState(false);
  const [active, setActive] = useState('1');
  const [showModal, setShowModal] = useState(false);
  // const [error, setError] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);

  const createDate = new Date();

  const [hasArticle, setHasArticle] = useState(false);
  const [articlePreview, setArticlePreview] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [messageLength, setMessageLength] = useState(100);

  const currentUser = getUserData();

  // const [noFetching, setNoFetch] = useState(false);

  // const { data, error } = useSWR(cardId && !noFetching ? '/getCard' : null, () =>
  //   Api.getCardData(getToken(), cardId, getTenant())
  // );

  const updateCard = async (cb) => {
    try {
      const response = await Api.updateEditedCard(getToken(), {
        cardId,
        userId,
        title,
        tenantId,
        streamId,
        description,
        format,
        subFormat,
        content_v2: [
          {
            type: 'text',
            source: '<p></p>',
          },
        ],
        coverMedia: {
          items: media,
        },
      });

      cb(null, response);
    } catch (err) {
      cb(err);
    }
  };

  const renderSubTitle = (val) => {
    if (val === 'ArticleLink') {
      return 'Article Link';
    }

    if (val === 'VideoLink') {
      return 'Video Link';
    }

    if (val === 'VideoRecommendation') {
      return 'Video Recommendation';
    }
    return null;
  };

  const setActiveCard = (val, fieldName) => {
    setActive(val);
    send({ type: 'SET_LINK_TYPE', linkType: fieldName });
  };

  const headlineChanged = (val) => {
    send({ type: 'ONCHANGE_TITLE', title: val });
    if (val.length > 0) {
      send('NEXT_STATE');
    }
  };

  const messageChanged = (val) => {
    send({ type: 'ONCHANGE_DESCRIPTION', description: val });
  };

  const checkPreviewUrl = (url) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', getToken());

    const interval = setInterval(() => {
      if (previewImage === null) {
        fetch(url, {
          mode: 'no-cors',
          headers,
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
        })
          .then()
          .then(() => {
            setPreviewImage(url);
            send({ type: 'SET_COVER', coverMedia: url });
            endAnimation(true);
            clearInterval(interval);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 5000);
  };

  const fetchArticlePreview = (currentUrl) => {
    Api.getArticlePreviewData(currentUrl)
      .then()
      .then((res, err) => {
        if (res.code === 500) {
          // setHasError(true);
          // setShowModal(true);

          send({
            type: 'SET_LINK_MEDIA',
            media: {
              type: 'link',
              caption: '',
              asset: {
                ...modalData?.asset,
                linkType: 'original',
                curationUrl: currentUrl,
                linkType: 'original',
                articlePreview: {},
              },
            },
          });
          setHasArticle(false);
        }

        if (res && !res.code) {
          setArticlePreview(res);
          send({
            type: 'SET_LINK_MEDIA',
            media: {
              type: 'link',
              caption: '',
              asset: {
                ...modalData?.asset,
                linkType: 'original',
                curationUrl: res.curationUrl,
                linkType: 'original',
                articlePreview: {
                  description: res.description,
                  title: res.title,
                  previewImageUrl: res.previewImageUrl,
                },
              },
            },
          });

          setHasArticle(true);
        }

        determineActiveItem(modalData?.asset?.linkType);

        setAddLink(false);
        setLinkUrl(modalData?.asset?.curationUrl);
        setLinkAddress(modalData?.asset?.displayName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVideoUpload = (file) => {
    setLoading(true);
    const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
    const filename = `${uuidv4()}.${ext}`;
    const getSignedUrl = async (cb) => {
      const response = await Api.getImageUploadUrl(getToken(), [{ key: filename }]);
      cb(null, response);
    };

    const uploadMedia = async (storage, cb) => {
      try {
        await fetch(storage[0].url, {
          method: 'PUT',
          body: file,
        });

        cb(null, storage);
      } catch (err) {
        cb(err);
      }
    };

    const transformMediaContent = async (storage, cb) => {
      const type = file.type.split('/')[0];
      try {
        const result = await Api.transformMedia(getToken(), type, storage[0].key);
        cb(null, result);
      } catch (err) {
        console.log(err);
        cb(err);
      }
    };

    waterfall(
      [
        function (callback) {
          getSignedUrl(callback);
        },
        function (storage, callback) {
          uploadMedia(storage, callback);
        },
        function (storage, callback) {
          transformMediaContent(storage, callback);
        },
      ],
      (err, res) => {
        if (!err) {
          send({
            type: 'SET_VIDEO_MEDIA',
            media: {
              type: 'link',
              caption: '',
              asset: {
                displayName:
                  media[0]?.asset && media[0].asset.displayName ? media[0].asset.displayName : null,
                curationUrl:
                  media[0]?.asset && media[0]?.asset?.curationUrl
                    ? media[0].asset.curationUrl
                    : null,
                linkType: 'original',
                videoRecommendationUrls: {
                  // type: 'video',
                  defaultUrl: res.source,
                  highResThumbnailUrl: res.thumbnail,
                  lowResThumbnailUrl: res.previewThumbnail,
                  // asset: {
                  // },
                },
              },
            },
          });

          checkPreviewUrl(res.thumbnail);
        }
      }
    );
  };

  const getFilesFromEvent = (e) =>
    new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then(async (chosenFiles) => {
        const file = chosenFiles[0].fileObject;
        const imageDataUrl = await readFile(file);
        send({
          type: 'SELECT_MEDIA',
          file: {
            originalFile: file,
            originalUrlBlob: imageDataUrl,
            croppedFile: file,
            croppedUrlBlob: imageDataUrl,
          },
        });

        handleVideoUpload(file);

        resolve(chosenFiles.map((f) => f.fileObject));

        setTimeout(() => {
          send({ type: 'SWITCH_VIEW' });
        }, 100);
      });
    });

  const checkUploadType = (val, modalData) => {
    if (val === 'VideoRecommendation') {
      if (media[0]?.asset) {
        return send({
          type: 'SET_LINK_MEDIA',
          media: {
            type: 'link',
            caption: '',
            asset: {
              displayName: modalData?.asset?.displayName,
              curationUrl: modalData?.asset?.curationUrl || modalData?.asset?.websiteUrl,
              linkType: media[0]?.asset?.linkType || 'original',
              videoRecommendationUrls: media[0]?.asset?.videoRecommendationUrls,
            },
          },
        });
      }
      return send({
        type: 'SET_LINK_MEDIA',
        media: {
          type: 'link',
          caption: '',
          asset: {
            displayName: modalData?.asset?.displayName,
            curationUrl: modalData?.asset?.curationUrl || modalData?.asset?.websiteUrl,
            linkType: 'original',
          },
        },
      });
    }
    return 'link';
  };

  useEffect(() => {
    if (subFormat === 'VideoLink') {
      setFormatTypes(null);
    }
    if (subFormat === 'ArticleLink') {
      setFormatTypes(ArticleLinkSteps);
    }
    if (subFormat === 'VideoRecommendation') {
      setFormatTypes(VideoRecommendationSteps);
    }
  }, [current?.context?.subFormat]);

  useEffect(() => {
    if (modalData && modalData?.asset?.curationUrl) {
      setAddLink(false);

      setLinkUrl(modalData?.asset?.curationUrl);
      setLinkAddress(modalData?.asset?.displayName);

      if (!media[0]?.asset?.videoRecommendationUrls) {
        fetchArticlePreview(modalData?.asset?.curationUrl);
      }

      checkUploadType(subFormat, modalData);
    } else {
      setAddLink(true);
    }
  }, [modalData]);

  useEffect(() => {
    if (videoModalData?.asset?.curationUrl) {
      setVideoUrl(videoModalData?.asset?.curationUrl);
      if (media.length === 0) {
        send({
          type: 'SET_LINK_MEDIA',
          media: {
            ...videoModalData,
          },
        });
      }
    }
  }, [videoModalData]);

  const validateFields = () => {
    if (subFormat === 'VideoRecommendation') {
      if (media.length === 0) {
        setDisabled(true);
      }

      if (
        media.length > 0 &&
        media[0].asset.curationUrl &&
        media[0]?.asset?.videoRecommendationUrls
      ) {
        setDisabled(false);
      }
    }

    if (media && media.length > 0 && media[0].articlePreview) {
      setHasArticle(true);
    }

    if (subFormat === 'ArticleLink' || subFormat === 'VideoLink') {
      if (title && media.length > 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const checkChanges = () => {
    const updatedData = {
      title,
      description,
      content,
      subFormat,
      media,
    };

    // if (media.length === 0)

    const changedValues = compareObjectChanges(initData, updatedData);
    if (changedValues.length > 0) {
      send({ type: 'SET_CHANGED', changed: true });
    } else {
      send({ type: 'SET_CHANGED', changed: false });
    }
  };

  const onKeyDownPreventEnter = (evt) => {
    if (evt.keyCode === 13 && evt.shiftKey === false) {
      evt.preventDefault();
    }

    if (evt.keyCode === 13 && evt.shiftKey === true) {
      evt.preventDefault();
    }
  };

  useEffect(() => {
    validateFields();
    if (initData) {
      checkChanges();
    }

    if (media.length === 0) {
      setLinkUrl(null);
      setLinkAddress(null);
      setHasArticle(null);
      setAddLink(true);
    }
  }, [title, description, linkUrl, videoUrl, media, subFormat]);

  const deleteMedia = (value) => {
    setDisabled(true);
    send({ type: 'DELETE_MEDIA', index: 0 });
    send({
      type: 'SET_REMOVED_MEDIA',
      media: {
        key: value,
        type: 'video',
      },
    });
    setPreviewImage(null);
  };

  const cleanEditor = () => {
    send({ type: 'CLEAN_CONTEXT' });
    setTimeout(() => send({ type: 'BACK' }), 200);
  };

  const saveChanges = () => {
    send({ type: 'IS_FOR_PUBLISHING' });
    setTimeout(() => send({ type: 'NEXT' }), 250);
  };

  const onCloseModal = () => {
    setShowModal(false);
    send({ type: 'IS_EXITING', exit: false });
  };

  const deleteVideoMedia = () => {
    send({ type: 'CLEAN_MEDIA', index: 0 });
    send({
      type: 'TO_REMOVED_MEDIA',
      media: {
        key: media[0].mediaId,
        type: media[0].type,
      },
    });
    setVideoUrl(null);
  };

  const handlePublishingCard = () => {
    send({ type: 'IS_FOR_PUBLISHING' });
    setTimeout(() => send({ type: 'NEXT' }), 500);
  };

  const handleUpdatingCard = () => {
    setIsLoading(true);
    waterfall(
      [
        function (callback) {
          updateCard(callback);
        },
      ],
      (err, res) => {
        if (!res.success) {
          setShowModal(true);
          setModalTitle('Something went wrong!');
          setModalDesc('Can not update the card');
          setHasError(true);
          return console.log(err);
        }
        performAutosave('', true);
        return send({ type: 'CONFIRM_EDIT' });
      }
    );
  };

  const discardModal = () => {
    if (editing) {
      return changed && isExiting ? (
        <Modal
          type="save-work"
          isEditing={editing}
          showModal={showModal}
          onCancel={() => navigate(-1)}
          onConfirm={() => setShowModal(false)}
          onClose={() => onCloseModal()}
        />
      ) : null;
    }

    return isExiting ? (
      <Modal
        type="save-work"
        showModal={showModal}
        title="Cancel Card Creation"
        onCancel={() => cleanEditor()}
        onConfirm={() => saveChanges()}
        onClose={() => onCloseModal()}
      />
    ) : null;
  };

  const errorModal = () => {
    if (hasError) {
      return (
        <Modal
          type="error"
          showModal={showModal}
          title={`${modalTitle}` || `Error fetching Link`}
          desc={
            `${modalDesc}` || `The link you attempted to fetch, didn't return any article preview!`
          }
          onConfirm={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
        />
      );
    }
  };

  const handleSavingCard = () => {
    send({ type: 'IS_FOR_SAVING' });
    // send
    setTimeout(() => send({ type: 'NEXT' }), 500);
  };

  const determineActiveItem = (linktype) => {
    if (linktype) {
      if (linktype === 'preview') {
        setActive('3');
      } else if (linktype === 'renamed') {
        setActive('2');
      } else {
        setActive('1');
      }
    }
  };

  const populateCard = (cardData) => {
    const fetchedData = {
      ...cardData,
      streamId: cardData.stream.streamId,
      content: cardData.content_v2,
      media:
        cardData.coverMedia.items && cardData.coverMedia.items !== null
          ? cardData.coverMedia.items
          : [],
      files:
        cardData.coverMedia.items && cardData.coverMedia.items !== null
          ? cardData.coverMedia.items
          : [],
    };

    const obj = {
      title: cardData.title,
      description: cardData.description,
      content: cardData.content_v2,
      subFormat: cardData.subFormat,
      media: cardData.coverMedia.items,
    };

    send({ type: 'SET_INIT_DATA', initData: obj });

    send({ type: 'SET_CONTEXT', context: fetchedData });

    setAddLink(false);

    const { items } = cardData?.coverMedia;

    if (cardData?.subFormat === 'VideoRecommendation') {
      setLinkUrl(items[0]?.asset?.curationUrl);
      setLinkAddress(items[0]?.asset?.displayName);
    } else {
      setLinkUrl(items[0]?.asset?.curationUrl || items[0]?.asset?.websiteUrl);
      setLinkAddress(items[0]?.asset.displayName);
    }
    determineActiveItem(items[0]?.asset.linkType);

    if (cardData?.subFormat === 'ArticleLink') {
      setHasArticle(true);
    }

    if (cardData?.subFormat === 'VideoRecommendation') {
      send({
        type: 'SET_COVER',
        coverMedia: items[0].asset.videoRecommendationUrls.highResThumbnailUrl,
      });
      setPreviewImage(items[0].asset.videoRecommendationUrls.highResThumbnailUrl);
    }

    if (cardData?.subFormat === 'VideoLink') {
      setVideoUrl(items[0]?.asset?.curationUrl || items[0]?.asset?.websiteUrl);
    }

    setIsLoading(false);
    setDisabled(false);
  };

  const fetchCardData = async () => {
    try {
      const response = await Api.getCardData(getToken(), cardId, getTenant());
      if (cardId && !initData) {
        populateCard(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isExiting) {
      setShowModal(true);
    }
  }, [isExiting]);

  useEffect(() => {
    send({ type: 'SET_USER_ID', userId: getUserId() });
    validateFields();
    if (editing) {
      setIsLoading(true);
      fetchCardData();
    }
  }, [cardId]);

  const tweakMessageLength = () => {
    if (subFormat === 'VideoLink' || subFormat === 'ArticleLink') {
      if (title.length >= 40 && title.length < 70) {
        setMessageLength(65);
      } else if (title.length >= 70) {
        setMessageLength(31);
      } else {
        setMessageLength(100);
      }
    }
  };

  const oKeys = current.value;
  const keyNames = Object.keys(oKeys);

  useEffect(() => {
    if (allowedStates.includes(keyNames[0])) {
      performAutosave(send, '');
    }
  }, []);

  useEffect(() => {
    tweakMessageLength();
  }, [title]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${styles.editorContainer} ${styles.adjustedPositioning}`}
    >
      <SEO title={editing ? 'Edit Curation Card' : 'Create Curation Card'} />
      {!loading ? (
        <>
          <div className={styles.titleWrapper}>
            <h2>Curation Card</h2>
            <span>{renderSubTitle(subFormat)}</span>
            {discardModal()}
            {errorModal()}
          </div>
          {formatTypes && (
            <div className={styles.cardTypes}>
              {(editing && media[0]) ||
              (media[0] && media[0].asset?.curationUrl) ||
              (media[0] && media[0].asset?.videoRecommendationUrls)
                ? formatTypes.map((item) => (
                    <Button
                      btnClass={active === item.id ? 'activeBtn' : ''}
                      key={item.id}
                      onClick={() => setActiveCard(item.id, item.fieldValue)}
                    >
                      {item.name}
                    </Button>
                  ))
                : ''}
            </div>
          )}
          <div className={styles.curationEditor}>
            <CurationHeader
              type={subFormat}
              author={author && author.name}
              avatar={author && author.avatar}
              noMedia={subFormat === 'VideoRecommendation' && media.length === 0}
              date={formatDate(created || createDate, 'MMMM DD, YYYY')}
              enlarged
            />
            <div
              className={`
            ${styles.contentWrapper}
            ${subFormat === 'VideoRecommendation' && styles.bottomAligned}
            ${subFormat === 'VideoLink' && styles.bottomAligned}
            ${subFormat === 'ArticleLink' && hasArticle && styles.spaceBetween}
            ${
              subFormat === 'ArticleLink' &&
              active === '3' &&
              hasArticle &&
              styles.hasArticlePreview
            }
          `}
            >
              {hasCurationInfo.includes(subFormat) && (
                <div
                  className={`
                  ${styles.inputWrapper} 
                  ${subFormat === 'VideoLink' && styles.videoLinkPull}
                  ${active === '3' && styles.articlePreviewPull}`}
                >
                  <Input
                    placeholder="Enter Headline"
                    customClass="headlineInput"
                    maxLength={65}
                    hasIndicator
                    value={title}
                    valueChange={headlineChanged}
                    onKeyDownChange={onKeyDownPreventEnter}
                  />
                  <Input
                    placeholder="Add a message if you want to"
                    customClass="messageInput"
                    maxLength={messageLength}
                    hasIndicator
                    value={description}
                    valueChange={messageChanged}
                    onKeyDownChange={onKeyDownPreventEnter}
                  />
                </div>
              )}
              {subFormat === 'VideoRecommendation' && (
                <div className={styles.dropzoneWrapper}>
                  {files.length === 0 && (
                    <Dropzone
                      accept="video/mp4,video/x-m4v,video/*"
                      multiple={false}
                      maxFiles={1}
                      canCancel={false}
                      InputComponent={VideoDropZoneInput}
                      getFilesFromEvent={getFilesFromEvent}
                      styles={{
                        dropzone: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          overflow: 'unset',
                          width: 360,
                          height: 465,
                          boxShadow: 'unset',
                          border: 'unset',
                        },
                        dropzoneActive: {
                          display: 'none',
                        },
                      }}
                    />
                  )}
                  {files.length > 0 &&
                    (!coverMedia ? (
                      <div className={styles.videoLoadingWrapper}>
                        <h3>Uploading...</h3>
                      </div>
                    ) : (
                      <div className={styles.mediaWrapper}>
                        <Button btnClass="iconWrapper" onClick={() => deleteMedia(media[0].key)}>
                          <Icon iconClass="trash" />
                        </Button>
                        <LazyImage
                          height={465}
                          width={360}
                          src={
                            previewImage ||
                            media[0]?.asset?.videoRecommendationUrls?.highResThumbnailUrl
                          }
                          alt={title}
                        />
                        <div className={styles.playIcon}>
                          <Icon iconClass="play" />
                        </div>
                      </div>
                    ))}
                </div>
              )}
              {subFormat === 'VideoLink' ? (
                <>
                  {videoUrl || media.length > 0 ? (
                    <div className={styles.playerWrapper}>
                      <ReactPlayer url={videoUrl} width={313} height={185} />
                      <Button btnClass="iconWrapper" onClick={() => deleteVideoMedia()}>
                        <Icon iconClass="close" fSize={3} />
                      </Button>
                    </div>
                  ) : (
                    <div
                      className={styles.videoLinkUploader}
                      role="button"
                      tabIndex="-1"
                      onKeyDown={() => showVideoModal(true)}
                      onClick={() => showVideoModal(true)}
                    >
                      <Icon iconClass="video-link" fSize={3} />
                      <h4>Add Video Link</h4>
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
              {active === '3' && hasArticle ? (
                <ArticlePreview
                  data={media[0].asset?.articlePreview || articlePreview}
                  onClick={() => showLinkModal(true)}
                />
              ) : (
                hasCurationLink.includes(subFormat) && (
                  <div
                    className={`${styles.linkUploader}`}
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => showLinkModal(true)}
                    onClick={() => showLinkModal(true)}
                  >
                    <CardLink
                      customClass="editorCardLink"
                      addLink={addLink}
                      state={active}
                      src={linkUrl}
                      title={linkAddress}
                    />
                  </div>
                )
              )}
            </div>
          </div>
          <div className={`${styles.statusButtons} ${editing && styles.centeredButtons}`}>
            {!editing ? (
              <>
                <Button
                  btnClass="editorBtn"
                  hasDisabled={disabled}
                  onClick={() => handleSavingCard()}
                >
                  Save Draft
                </Button>
                <Button
                  btnClass="editorBtn"
                  hasDisabled={disabled}
                  greenColor
                  onClick={() => handlePublishingCard()}
                >
                  Publish
                </Button>
              </>
            ) : (
              <>
                <Button
                  btnClass="editorBtn"
                  hasDisabled={disabled}
                  greenColor
                  onClick={() => handleUpdatingCard()}
                >
                  Update Card
                </Button>
              </>
            )}
          </div>
        </>
      ) : (
        <ThreeDots />
      )}
    </motion.div>
  );
};

CurationCardEditor.propTypes = {
  current: PropTypes.shape(),
  cardId: PropTypes.string,
  modalData: PropTypes.shape({
    type: PropTypes.string,
    externalUrl: PropTypes.string,
    renamedUrl: PropTypes.string,
    articlePreview: PropTypes.shape({}),
  }),
  videoModalData: PropTypes.shape({
    type: PropTypes.string,
    externalUrl: PropTypes.string,
  }),
  send: PropTypes.func,
  showLinkModal: PropTypes.func,
  showVideoModal: PropTypes.func,
};

export default isLoadingHoc(CurationCardEditor);
