import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { subItemClass } from '@components/organisms/my-editor/helpers';
import * as styles from './menu-button.module.scss';

const MenuButton = ({ onToggle, style, active, label }) => {
  const [activeClass, setActive] = useState(active);
  const className = `RichEditor-styleButton ${styles.menuButton} ${styles[subItemClass(style)]}`;

  const toggleClick = (e) => {
    onToggle(style);
    e.preventDefault();
  };

  useEffect(() => {
    setActive(active);
  }, [active]);

  return (
    <button
      className={`${className} ${activeClass && styles.activeBtn}`}
      type="button"
      tabIndex="0"
      onClick={(e) => toggleClick(e)}
    >
      {label}
    </button>
  );
};

MenuButton.propTypes = {
  onToggle: PropTypes.func,
  style: PropTypes.string,
  active: PropTypes.bool,
  label: PropTypes.string,
};

export default MenuButton;
