import React from 'react';

import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

export const validationSchema = yup.object().shape({
  displayName: yup
    .string()
    .min(3, 'Name must be at least 1 character')
    .max(50, 'Name can not exceed 50 characters')
    .required('Name is a required field'),
  occupation: yup.string(),
  about: yup.string().max(120, 'About can not exceed 120 characters'),
  externalLinks: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      value: yup.string().url('Please enter a valid URL'),
    })
  ),
});

export const subMenuAnimate = {
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
    display: 'block',
  },
  exit: {
    opacity: 0,
    y: -15,
    transition: {
      duration: 0.2,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

export const variants = {
  initial: {
    opacity: 1,
    transition: { duration: 0.5, delay: 0.3 },
  },
  editing: {
    opacity: 0,
    transition: { duration: 0.5 },
  },
};

const links = [
  {
    key: 'twitter',
    value: '',
  },
  {
    key: 'instagram',
    value: '',
  },
  {
    key: 'web',
    value: '',
  },
];

export const socialIcon = (type) => {
  switch (type) {
    case 'facebook':
      return (
        <FontAwesomeIcon icon={faFacebook} color="#81888f" style={{ marginRight: '0.6rem' }} />
      );
    case 'instagram':
      return (
        <FontAwesomeIcon icon={faInstagram} color="#81888f" style={{ marginRight: '0.6rem' }} />
      );
    case 'twitter':
      return <FontAwesomeIcon icon={faTwitter} color="#81888f" style={{ marginRight: '0.6rem' }} />;
    default:
      return <FontAwesomeIcon icon={faGlobe} color="#81888f" style={{ marginRight: '0.6rem' }} />;
  }
};
