// extracted by mini-css-extract-plugin
export var failedColor = "isloading-module--failedColor--257d7";
export var greenColor = "isloading-module--greenColor--1e256";
export var justtAccount = "isloading-module--justt-account--d3f28";
export var justtAdd = "isloading-module--justt-add--c3a1b";
export var justtAddVideo = "isloading-module--justt-add-video--8acb4";
export var justtArrowLeft = "isloading-module--justt-arrow-left--16f3a";
export var justtArrowRight = "isloading-module--justt-arrow-right--8bc3a";
export var justtArticleLink = "isloading-module--justt-article-link--73b72";
export var justtAspectRatio = "isloading-module--justt-aspect-ratio--bdf72";
export var justtAutosave = "isloading-module--justt-autosave--c558c";
export var justtBlur = "isloading-module--justt-blur--ab297";
export var justtCalendar = "isloading-module--justt-calendar--67e0e";
export var justtCamera = "isloading-module--justt-camera--cb87c";
export var justtCards = "isloading-module--justt-cards--a9c53";
export var justtChannel = "isloading-module--justt-channel--52b1c";
export var justtChannels = "isloading-module--justt-channels--f7e3e";
export var justtCharacters = "isloading-module--justt-characters--1949f";
export var justtCircularAdd = "isloading-module--justt-circular-add--08933";
export var justtClose = "isloading-module--justt-close--44882";
export var justtCloseThin = "isloading-module--justt-close-thin--a677b";
export var justtComments = "isloading-module--justt-comments--7a93f";
export var justtCreate = "isloading-module--justt-create--272b8";
export var justtEdit = "isloading-module--justt-edit--1a6fb";
export var justtFull = "isloading-module--justt-full--d3997";
export var justtFullCardIcon = "isloading-module--justt-full-card-icon--1b332";
export var justtFullscreen = "isloading-module--justt-fullscreen--569c4";
export var justtGlobe = "isloading-module--justt-globe--9d01c";
export var justtHidePassword = "isloading-module--justt-hide-password--6f00b";
export var justtInfo = "isloading-module--justt-info--887e1";
export var justtLink = "isloading-module--justt-link--075db";
export var justtLinkArrow = "isloading-module--justt-link-arrow--708eb";
export var justtLinkImage = "isloading-module--justt-link-image--b1ea3";
export var justtLists = "isloading-module--justt-lists--dfa39";
export var justtLogout = "isloading-module--justt-logout--5992a";
export var justtLoop = "isloading-module--justt-loop--337b8";
export var justtMedia = "isloading-module--justt-media--88211";
export var justtMove = "isloading-module--justt-move--4c317";
export var justtParagraphs = "isloading-module--justt-paragraphs--0f6e4";
export var justtPlay = "isloading-module--justt-play--9c123";
export var justtPreviewCard = "isloading-module--justt-preview-card--2e7dd";
export var justtPublished = "isloading-module--justt-published--09dfe";
export var justtReposition = "isloading-module--justt-reposition--844e5";
export var justtReverse = "isloading-module--justt-reverse--1b77a";
export var justtSaved = "isloading-module--justt-saved--42757";
export var justtSeen = "isloading-module--justt-seen--24460";
export var justtSelected = "isloading-module--justt-selected--44965";
export var justtSettings = "isloading-module--justt-settings--6ce41";
export var justtShare = "isloading-module--justt-share--63849";
export var justtShowPassword = "isloading-module--justt-show-password--f6144";
export var justtSixteenNine = "isloading-module--justt-sixteen-nine--c1705";
export var justtSolid = "isloading-module--justt-solid--de603";
export var justtSortColored = "isloading-module--justt-sort-colored--09fd9";
export var justtSpaces = "isloading-module--justt-spaces--36046";
export var justtStacked = "isloading-module--justt-stacked--3675a";
export var justtText = "isloading-module--justt-text--98609";
export var justtTheme = "isloading-module--justt-theme--f8fee";
export var justtTrash = "isloading-module--justt-trash--54b08";
export var justtTrashLined = "isloading-module--justt-trash-lined--6d498";
export var justtUnpublish = "isloading-module--justt-unpublish--d80fd";
export var justtVideo = "isloading-module--justt-video--4b8a6";
export var justtVideoLink = "isloading-module--justt-video-link--60d8b";
export var justtVideoMessage = "isloading-module--justt-video-message--7cb14";
export var loadingWrapper = "isloading-module--loadingWrapper--94a3d";
export var path1 = "isloading-module--path1--af3ae";
export var path2 = "isloading-module--path2--01341";