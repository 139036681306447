import React from 'react';

import PropTypes from 'prop-types';

import * as styles from './articlePreview.module.scss';

const ArticlePreview = ({ data, onClick }) =>
  data ? (
    <div
      className={`${styles.articlePreview} ${data.image && !data.image.url ? styles.noImage : ''}`}
      onClick={() => onClick()}
    >
      {data && data.previewImageUrl ? (
        <div className={styles.imgWrap}>
          <img src={data?.previewImageUrl} alt="Article Preview Img" />
        </div>
      ) : (
        <div className={styles.noImageWrapper}>
          <p>No Image</p>
        </div>
      )}

      <div className={styles.descWrap}>
        <h3>{data?.title}</h3>
        <span className={styles.articleUrl}>{data?.url}</span>
      </div>
    </div>
  ) : (
    <div className={`${styles.articlePreview} ${styles.noArticle}`}>
      <h4>No article Preview</h4>
    </div>
  );

ArticlePreview.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default ArticlePreview;
