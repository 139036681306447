import React, { useState } from 'react';

import useSWR, { useSWRConfig } from 'swr';
import { navigate } from '@reach/router';

import SEO from '@components/seo';
import Header from '@components/organisms/header';
import ClientOnly from '@components/molecules/clientOnly';

import Card from '@components/organisms/card';
import Modal from '@components/molecules/modal';
import Icon from '@components/atoms/icon';
import Button from '@components/atoms/button';
import waterfall from 'async/waterfall';
import PlaceholderImage from '@images/placeholder-image.png';
import CardService from '@services/cards';
import ChannelService from '@services/channels';
import Api from '@api';

import {
  isLoggedIn,
  isCurator,
  hasSocials,
  handleAspectRatio,
  getToken,
  getUserId,
  getTenant,
} from '@helpers';

import { containerWrapper, cardsWrapper, pageHeader, iconHeader } from '@styles/main.module.scss';
import { switchFormatsButtonWrap } from '@components/organisms/header/header.module.scss';
import { pageTypeSwitcher, noCards } from './trash.module.scss';

// import ChannelsService from '@services/channelsService';

const Trash = ({ pageType }) => {
  const { cache } = useSWRConfig();
  const [page, setPage] = useState(pageType);
  // Modal state
  const [cId, setcId] = useState(null);
  const [type, setType] = useState('');
  const [all, setAll] = useState(false);
  const [currentState, setCurrentState] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');

  const formatTypes = [
    {
      id: 'cards',
      name: 'Cards',
    },
    {
      id: 'channels',
      name: 'Channels',
    },
  ];

  const {
    data: cardData,
    error: errorCards,
    mutate: mutateCards,
  } = useSWR(['/listContentCreationCards', page === 'cards'], () =>
    Api.listContentCreationCards('', 'trashed', 'DESC', 0)
  );

  const {
    data: channelData,
    error: errorChannels,
    mutate: mutateChannels,
  } = useSWR(['/listContentCreationStreams', page === 'channels'], () =>
    Api.listContentCreationStreams('trashed', 'DESC', 0)
  );

  const handleCardStateAction = (id, type, cardState, title) => {
    setType(type);
    setcId(id);
    setCurrentState(cardState);

    switch (type) {
      case 'unpublish':
        setModalTitle(`Recover ${page === 'cards' ? 'Card' : 'Channel'}`);
        setModalDesc(
          `Are you sure you want to recover your ${page === 'cards' ? 'card' : 'channel'}?`
        );
        break;
      case 'delete':
        setModalTitle(
          `The selected ${page === 'cards' ? 'card' : 'channel'}(s) will be deleted permanently.`
        );
        setModalDesc('Are you sure you want to continue? This process is irreversible!');
        break;
      default:
        break;
    }
    setTimeout(() => {
      setShowModal(true);
    }, 200);
  };

  const clearModal = (hide) => {
    if (hide) {
      setShowModal(false);
    }
    setType('');
    setModalTitle('');
    setModalDesc('');
  };

  const deleteCard = async (cb) => {
    const cardId = cId;
    try {
      const response = await Api.deleteCard({
        cardId,
        tenantId: getTenant(),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteChannel = async (cb) => {
    const streamId = cId;
    try {
      const response = await Api.deleteChannel({
        streamId,
        tenantId: getTenant(),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const checkState = (state) => {
    if (type === 'trash-delete' && currentState === 'autosaved') {
      return 'trashed_autosaved';
    }
    return state;
  };

  const updateCardState = async (cardState, cb) => {
    const cardId = cId;
    try {
      const response = await Api.changeCardState({
        cardId,
        tenantId: getTenant(),
        cardState: checkState(cardState),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const changeChannelState = async (channelState, cb) => {
    const streamId = cId;
    try {
      const response = await Api.updateChannelState(getToken(), {
        streamId,
        tenantId: getTenant(),
        userId: getUserId(),
        streamState: checkState(channelState),
      });

      setcId(null);

      cb(null, response);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCardAction = () => {
    setShowModal(false);
    waterfall(
      [
        function (callback) {
          deleteCard(callback);
        },
      ],
      (err, res) => {
        if (!err) {
          setShowModal(true);
          if (res.code === 500 || res.code === 403 || res.code === 400) {
            setModalValues(
              'error',
              'Card can’t be deleted',
              'Something went wrong! Card can not be deleted!'
            );
          } else {
            setModalValues('successfully-deleted', 'Deleted card', 'Card was deleted successfully');
            mutateCards();
            cache.delete();
          }
        }
      }
    );
  };

  const deleteChannelAction = () => {
    setShowModal(false);
    waterfall(
      [
        function (callback) {
          deleteChannel(callback);
        },
      ],
      (err, res) => {
        if (!err) {
          setShowModal(true);
          if (res.code === 500 || res.code === 403 || res.code === 400) {
            setModalValues(
              'error',
              'Channel can’t be deleted',
              'Something went wrong! Channel can not be deleted!'
            );
          } else {
            setModalValues(
              'successfully-deleted',
              'Deleted channel',
              'Channel was deleted successfully'
            );
            mutateChannels();
            cache.delete();
          }
        }
      }
    );
  };

  const setModalValues = (modalType, title, desc) => {
    setType(modalType);
    setModalTitle(title);
    setModalDesc(desc);
  };

  const handleErrorModal = (type, message, errorCode) => {
    switch (type) {
      case 'unpublished':
        if (errorCode === 'StreamIsTrashed') {
          return setModalValues(
            'error',
            'Card can not be deleted',
            'Current Channel is trashed, consider moving card to another channel before recovering.'
          );
        }
        setModalValues(
          'error',
          'Card can’t be recovered',
          message || 'Something went wrong! Card can not be recovered!'
        );
        break;
      case 'trashed':
        return setModalValues(
          'error',
          'Card can not be deleted',
          message || 'Something went wrong! Card can not be deleted!'
        );

      case 'delete-all':
        setModalValues(
          'error',
          `Something went wrong!`,
          `Can not delete all ${page === 'cards' ? 'cards' : 'channels'}!`
        );
        break;
      default:
        break;
    }
  };

  const handleSuccessModal = (type) => {
    if (page === 'cards') {
      mutateCards();
    } else {
      mutateChannels();
    }
    cache.delete();

    switch (type) {
      case 'unpublished':
        setModalValues(
          'successfully-deleted',
          `Recovered ${page === 'cards' ? 'card' : 'channel'}`,
          `${page === 'cards' ? 'Card' : 'Channel'} was recovered successfully`
        );
        break;
      case 'trashed':
        setModalValues(
          'successfully-deleted',
          `Deleted ${page === 'cards' ? 'card' : 'channel'}`,
          `${page === 'cards' ? 'Card' : 'Channel'} was deleted successfully`
        );
        break;
      case 'delete-all':
        setModalValues(
          'successfully-deleted',
          `Deleted all ${page === 'cards' ? 'card' : 'channel'}`,
          `${page === 'cards' ? 'All Cards' : 'All Channels'} were deleted successfully`
        );
      default:
        break;
    }
  };

  const changeCardStateAction = (state) => {
    setShowModal(false);
    waterfall(
      [
        function (cb) {
          updateCardState(state, cb);
        },
      ],
      (err, res) => {
        setShowModal(true);
        if (!res?.success) {
          handleErrorModal(state, res?.error, res?.errorCode);
        } else {
          handleSuccessModal(state);
        }
      }
    );
  };

  const changeChannelStateAction = (state) => {
    setShowModal(false);
    waterfall(
      [
        function (cb) {
          changeChannelState(state, cb);
        },
      ],
      (err, res) => {
        setShowModal(true);
        if (!res?.success) {
          handleErrorModal(state);
        } else {
          handleSuccessModal(state);
        }
      }
    );
  };

  const handleDeleteAllCards = async () => {
    try {
      const response = await CardService.deleteTrashedCards();

      if (response.success) {
        handleSuccessModal('delete-all');
      } else {
        handleErrorModal('delete-all');
      }
      // cb(null, response);
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteAllChannels = async () => {
    try {
      const response = await ChannelService.deleteTrashedStreams();
      if (response.success) {
        handleSuccessModal('delete-all');
      } else {
        handleErrorModal('delete-all');
      }
      // cb(null, response);
    } catch (error) {
      throw error;
    }
  };

  const handleModalAction = (modalType) => {
    if (page === 'cards') {
      if (modalType === 'delete') {
        if (all) {
          handleDeleteAllCards();
        } else {
          deleteCardAction();
        }
      }

      if (modalType === 'unpublish') {
        changeCardStateAction('unpublished');
      }
    } else {
      if (modalType === 'delete') {
        if (all) {
          handleDeleteAllChannels();
        } else {
          deleteChannelAction();
        }
      }

      if (modalType === 'unpublish') {
        changeChannelStateAction('unpublished');
      }
    }

    clearModal();
  };

  const deleteAllAlert = () => {
    setAll(true);
    setType('delete');
    setModalTitle('Confirm Delete');
    setModalDesc(`Are you sure you want to delete all ${page === 'cards' ? 'cards' : 'channels'}!`);
    setTimeout(() => setShowModal(true), 200);
  };

  const CardProps = (card) => {
    const cardObject = {
      key: card.cardId,
      id: card.cardId,
      title: card.title || card.name,
      description: card.streamState ? '' : card.description,
      src: card?.coverMedia?.items?.length
        ? card?.coverMedia?.items[0]
        : { source: PlaceholderImage },
      channelId: card.streamId,
      isChannel: card.streamState && true,
      channel: card.stream,
      status: card.cardState || card.streamState,
      curator: isCurator.includes(card.subFormat),
      cardClass: card.subFormat,
      category: card.category,
      type: card.subFormat,
      date: card.created,
      author: card.author,
      isPiqd: card.contentSourceProvider,
      isList: true,
      hasLink: false,
      socials: hasSocials.includes(card.subFormat) ? card.counters : null,
      intendedAspectRatio: handleAspectRatio(card?.media, card?.subFormat),
      recoverCard: () =>
        handleCardStateAction(card.cardId || card.streamId, 'unpublish', card.cardState),
      deleteCard: () => handleCardStateAction(card.cardId || card.streamId, 'delete'),
      moveCard: () => navigate(`/app/move-to-channel/${card.cardId}`),
    };

    return cardObject;
  };

  return (
    <>
      <SEO title="Trashed" />
      <Header isLoggedIn={isLoggedIn()} />
      {showModal && (
        <Modal
          title={modalTitle}
          desc={modalDesc}
          type={type}
          showModal={showModal}
          hasCardId={cId}
          hasTenantId={getTenant()}
          onConfirm={() => handleModalAction(type)}
          onCancel={() => clearModal('hide')}
        />
      )}
      <ClientOnly>
        <div className={containerWrapper}>
          <Button iconBtn btnClass="fixedRight" onClick={() => deleteAllAlert()}>
            <Icon iconClass="trash-lined" />
            <span>Delete All</span>
          </Button>
          <div className={pageHeader}>
            <div className={iconHeader}>
              <Icon iconClass="trash-lined" />
              <h2>Recently Deleted</h2>
            </div>
            <p>
              {page === 'cards' ? 'Cards' : 'Channels'} will be permanently deleted after 90 days
              automatically.
            </p>
          </div>
          <div className={`${switchFormatsButtonWrap} ${pageTypeSwitcher}`}>
            {formatTypes.map((item) => (
              <Button
                btnClass={page === item.id ? 'activeBtn' : ''}
                key={item.id}
                headerBtn
                onClick={() => setPage(item.id)}
              >
                {item.name}
              </Button>
            ))}
          </div>
          {page === 'cards' ? (
            <div
              className={`
              ${cardsWrapper} 
              ${cardData && cardData?.cards?.length === 0 ? noCards : ''}
            `}
            >
              {cardData && cardData?.cards?.length === 0 ? (
                <h2>No Cards</h2>
              ) : (
                cardData?.cards?.map((card) => <Card {...CardProps(card)} />)
              )}
            </div>
          ) : (
            ''
          )}
          {page === 'channels' && (
            <div
              className={`
                ${cardsWrapper} 
                ${channelData && channelData?.length === 0 ? noCards : ''}
            `}
            >
              {channelData && channelData?.length === 0 ? (
                <h2>No Channels</h2>
              ) : (
                channelData?.map((card) => <Card {...CardProps(card)} />)
              )}
            </div>
          )}
        </div>
      </ClientOnly>
    </>
  );
};

export default Trash;
