import React from 'react';
import PropTypes from 'prop-types';

import Card from '@components/organisms/card';
// import Icon from '@components/atoms/icon';
import { handleAspectRatio } from '@helpers';
import PlaceholderImage from '@images/placeholder-image.png';

import * as styles from './preview-card.module.scss';

const PreviewCard = ({ current, send, hasParentStyle }) => {
  const { value } = current;
  const {
    subFormat,
    category,
    coverMedia,
    editing,
    currentFileIndex,
    description,
    title,
    loadingUpload,
    files,
    media,
    piqd,
    isCropping,
  } = current.context;

  const placeholder = {
    source: PlaceholderImage,
  };

  const renderMediaSource = () => {
    let transformedUpload = false;
    let fileMediaParsed = false;
    let imageSource = {};
    if (value && (value.editor === 'uploading' || value.editor === 'validatingCover')) {
      return placeholder;
    }

    // if (value === {editor: 'uploading'} || value === '')

    if (loadingUpload || (files && files.length === 0)) {
      return placeholder;
    }

    if (media && media.length > 0) {
      transformedUpload = 'previewSource' in media[0];

      if (isCropping && media[0].type === 'image') {
        imageSource = {
          source: (files && files[0].croppedUrlBlob) || (media && media[0].asset.defaultUrl),
        };
        return imageSource;
      }

      if (media[0].type === 'video') {
        if (editing) {
          imageSource = {
            source:
              (media && media[0].asset.highResThumbnailUrl) ||
              (files && files[0].asset.highResThumbnailUrl) ||
              coverMedia,
          };
          return imageSource;
        }

        imageSource = {
          source: media[0].asset.highResThumbnailUrl || files[0].videoCover,
        };

        return imageSource;
      }

      imageSource = {
        source:
          coverMedia ||
          (files && files[0].croppedUrlBlob) ||
          (media && media[0].source) ||
          placeholder,
      };

      return imageSource;
    }

    if (files && files.length > 0) {
      fileMediaParsed = 'previewSource' in files[0];
      if (fileMediaParsed && !isCropping) {
        imageSource = {
          source: files[0].source,
        };
        return imageSource;
      }
      if (fileMediaParsed && currentFileIndex !== 0) {
        imageSource = {
          source: files[0].source,
        };
        return imageSource;
      }

      imageSource = {
        source: files[0].croppedUrlBlob,
      };
      return imageSource;
    }
    return placeholder;
  };

  return (
    <div className={`${styles.previewWrapper} ${hasParentStyle && styles.hasParentStyle}`}>
      <Card
        type={subFormat}
        category={category}
        title={title}
        isPiqd={piqd}
        editing={editing}
        description={description}
        cardClass={subFormat}
        src={renderMediaSource()}
        intendedAspectRatio={handleAspectRatio(media, subFormat)}
        aspectRatio={media && media.length > 0 ? media[currentFileIndex]?.aspectRatio : null}
        send={send}
      />
    </div>
  );
};

PreviewCard.propTypes = {
  current: PropTypes.shape(),
  editing: PropTypes.bool,
  send: PropTypes.func,
  hasParentStyle: PropTypes.bool,
};

export default PreviewCard;
