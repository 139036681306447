import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './sortableItem';

import * as styles from './order-media.module.scss';

const SortableList = SortableContainer(({ files, hasCarousel, editing, hasMedia, focused }) => (
  <motion.div className={styles.sortableListWrapper}>
    {/* <AnimatePresence> */}
    {hasMedia &&
      files &&
      files.map((file, index) => (
        <SortableItem
          key={`media-${index}`}
          carousel={hasCarousel}
          index={index}
          identifier={focused}
          editing={editing}
          file={file}
        />
      ))}
    {/* </AnimatePresence> */}
  </motion.div>
));

SortableList.propTypes = {
  files: PropTypes.shape({}),
  hasMedia: PropTypes.bool,
  hasCarousel: PropTypes.bool,
};

export default SortableList;
