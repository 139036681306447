import { createMachine, assign } from 'xstate';
import { createCard, updateCard, compareObjectChanges } from '../helpers';
import { getTenant } from '@helpers';

const checkContentValues = (content) => {
  let otherTypes = false;
  if (content.length === 1) {
    return content[0].source !== '<p></p>';
  }

  if (content.length > 1) {
    content.map((item) => {
      if (item.type !== 'text') {
        otherTypes = true;
      }
    });

    return content.length > 1 && otherTypes;
  }
};

const mediaContentTypes = (content) => {
  let otherTypes = [];
  if (content.length > 1) {
    content.map((item) => {
      if (item.type === 'media' && !item?.items[0]?.asset?.defaultUrl.includes('youtube')) {
        otherTypes.push(item);
      }
    });
  }

  return content.length > 1 && otherTypes.length > 0;
};

const checkEmptyField = (ctx) => {
  if (ctx.format === 'Curation') {
    if (ctx.editing) {
      return ctx.userId && ctx.title.length > 1 && ctx.media.length > 0;
    }

    if (ctx.subFormat === 'VideoRecommendation') {
      return ctx.media.length > 0;
    }

    return ctx.title.length >= 1 && ctx.media.length > 0;
  }

  if (ctx.format === 'VideoMessage') {
    return ctx.userId && ctx.media.length > 0;
  }

  if (ctx.subFormat === 'Text') {
    return ctx.userId && ctx.title.length > 1 && mediaContentTypes(ctx.content);
  }

  return ctx.editing
    ? ctx.userId &&
        ctx.title.length > 1 &&
        ctx.files.length &&
        ctx.media.length > 0 &&
        checkContentValues(ctx.content)
    : ctx.userId && ctx.title.length > 1 && checkContentValues(ctx.content);
};

const contentMedia = (content, subFormat) => {
  let mediaItems = [];
  const mediaTypes = ['image', 'carousel', 'video', 'media'];
  content.map((item, idx) => {
    if (mediaTypes.includes(item.type)) {
      mediaItems.push(item);
    }
  });
  if (subFormat === 'Text') {
    return true;
  }
  return mediaItems.length !== 0 ? false : true;
};

const requestAutoSaving = async (ctx, cb) => {
  const { userId, format, subFormat, autoSaveId, media, title, editing, description, content } =
    ctx;

  // In the event that the card already exists,
  // we need to create a new card, with status autosaved. We run the create card function
  // and we save the cardId to autoSaveId.

  const createData = {
    userId,
    cardState: 'autosaved',
    tenantId: getTenant(),
    streamId: 'autosaved',
    format,
    subFormat,
    coverMedia: {
      items: media ? media : [],
    },
    description,
    title,
    content_v2: content,
  };

  const updateData = {
    cardId: autoSaveId,
    ...createData,
  };

  const response = autoSaveId ? await updateCard(updateData) : await createCard(createData);

  if (response.success) {
    const { userId, cardId, coverMedia, cardState, title, description, streamId, content_v2 } =
      response?.card;

    const parsedMedia = JSON.parse(coverMedia);

    const autosaveData = {
      media: parsedMedia.items.length > 0 ? parsedMedia.items : [],
      title,
      description,
      content: JSON.parse(content_v2),
    };

    if (editing) {
      cb({
        type: 'AUTOSAVE_EXISTING_CARD',
        data: {
          autoSaveId: cardId,
          autosaveData,
        },
      });
    } else {
      // console.log(
      //   'Autosave succedeed. You are creating an autosave version of a non-existing card!'
      // );
      cb({
        type: 'AUTOSAVE_NEW_CARD',
        data: {
          cardId,
          userId,
          cardState,
          streamId,
          autoSaveId: cardId,
          autosaveData,
        },
      });
    }
  } else {
    cb({
      type: 'AUTOSAVE_ERROR',
    });
  }
};

const conditionalAutosaving = (context) => {
  const { media, title, description, content, autosaveData, autoSaving, cardId, autoSaveId } =
    context;
  const mainObj = {
    media,
    title,
    description,
    content,
  };

  if (!cardId) {
    return (
      !autoSaving &&
      (media.length ||
        title.length > 0 ||
        description.length > 0 ||
        content[0].source !== '<p></p>')
    );
  } else {
    const objectDiff = compareObjectChanges(mainObj, autosaveData);
    return !autoSaving && objectDiff.length > 0;
  }
};

const createCardWizardFormMachine = createMachine({
  id: 'createCardWizardFormMachine',
  key: 'create',
  initial: 'formats',
  context: {
    // State data to interact
    isForSaving: false,
    isForPublishing: false,
    isExiting: false,
    isCropping: false,
    isAutoSaved: false,
    editing: false,
    onEdit: false,
    changed: false,
    cardState: null,
    disabled: false,
    discarding: false,
    changedMedia: false,
    loadingUpload: false,
    cardObject: {},
    parsedMedia: false,
    sequence: false,
    coverMedia: null, // FIXME: This will be renamed to frontCoverImage
    files: [],
    media: [], // FIXME: This will be renamed to coverMedia
    linkType: null,
    validateMedia: false,
    lightbox: false,
    gallery: null,
    galleryIndex: 0,
    // Data to be parsed / published
    userId: null,
    streamId: null,
    tenantId: null,
    cardId: null,
    autoSaveId: null,
    autoSaving: false,
    stopAutosave: false,
    autosaveData: {},
    // Card Data
    format: null,
    subFormat: null,
    currentFileIndex: 0,
    sequenceFileIndex: 0,
    editorIndex: 0,
    cursorIndex: 0,
    created: '',
    title: '',
    description: '',
    author: {},
    content: [
      {
        type: 'text',
        source: '<p></p>',
      },
    ],
    piqd: null,
    // In the event of removing exisitng media,
    // add the keys of those media here,
    initData: null,
    sequenceIndex: null,
    updatingData: {},
    embedValues: null,
    removedMedia: [],
    mediaChangesMade: [], // log changes made to the media object
    // Editor toggling of inlineStyle and blockType
    toggleIndex: 0,
    toggleMediaIndex: 0,
    // Url Appended to Text
    selectedEntity: false,
    urlValue: '',
    inlineStyle: null,
    blockTypeStyle: null,
    caretData: {},
    currentLinkData: {},
    editorBlocks: null,
    currentEditorIndex: {},
  },
  states: {
    formats: {
      initial: 'Indepth',
      id: 'cardFormats',
      states: {
        Indepth: {
          on: {
            CURATION_FORMAT: {
              target: 'Curation',
            },
            VIDEO_MESSAGE_FORMAT: {
              target: 'VideoMessage',
            },
          },
        },
        Curation: {
          on: {
            INDEPTH_FORMAT: {
              target: 'Indepth',
            },
            VIDEO_MESSAGE_FORMAT: {
              target: 'VideoMessage',
            },
          },
        },
        VideoMessage: {
          on: {
            CURATION_FORMAT: {
              target: 'Curation',
            },
            INDEPTH_FORMAT: {
              target: 'Indepth',
            },
          },
        },
      },
      on: {
        SELECT_FORMAT: {
          actions: 'setFormat',
        },
        SELECT_SUBFORMAT: {
          actions: 'setSubFormat',
        },
        START_INDEPTH_EDITOR: {
          target: 'editor',
        },
        START_CURATION_EDITOR: {
          target: 'curationEditor',
        },
        START_VIDEO_MESSAGE_EDITOR: {
          target: 'videoMessageEditor',
        },
        SET_EDITING: {
          actions: 'setEditing',
        },
      },
    },
    editor: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            SELECT_MEDIA: {
              actions: 'setBinaryFiles',
              target: 'uploading',
            },
            SWITCH_VIEW: {
              target: 'view',
              cond: (ctx) => ctx.files.length,
            },
            EDITING_VIEW: {
              target: 'viewEdit',
            },
          },
        },
        autosave: {
          invoke: {
            id: 'autosaving',
            src: (ctx, _) => async (cb) => requestAutoSaving(ctx, cb),
          },
          on: {
            AUTOSAVE_NEW_CARD: [
              {
                target: 'idle',
                actions: 'newAutosave',
                cond: (ctx) => !ctx.media.length,
              },
              {
                target: 'edit',
                actions: 'newAutosave',
                cond: (ctx) => ctx.onEdit,
              },
              {
                target: 'viewEdit',
                actions: 'newAutosave',
                cond: (ctx) => ctx.media.length && ctx.cardId && ctx.editing,
              },
              {
                target: 'view',
                actions: 'newAutosave',
                cond: (ctx) => ctx.media.length,
              },
            ],
            AUTOSAVE_EXISTING_CARD: [
              {
                target: 'idle',
                actions: 'existingAutosave',
                cond: (ctx) => !ctx.media.length,
              },
              {
                target: 'edit',
                actions: 'existingAutosave',
                cond: (ctx) => ctx.onEdit,
              },
              {
                target: 'viewEdit',
                actions: 'existingAutosave',
                cond: (ctx) => ctx.media.length && ctx.cardId && ctx.editing,
              },
              {
                target: 'view',
                actions: 'existingAutosave',
                cond: (ctx) => ctx.media.length,
              },
            ],
            AUTOSAVE_ERROR: {
              actions: assign({
                stopAutosave: (_, event) => true,
              }),
            },
          },
        },
        uploading: {
          on: {
            START_VALIDATION: {
              actions: 'validateMedia',
            },
            END_VALIDATION: {
              actions: 'validateMedia',
              target: 'validatingCover',
            },
          },
        },
        validatingCover: {
          on: {
            GO_TO_VIEW: {
              target: 'view',
              cond: (ctx) => ctx.coverMedia && !ctx.validateMedia,
            },
          },
        },
        viewEdit: {
          on: {
            always: [{ target: 'idle', cond: (ctx) => !ctx.media.length }],
            EDIT_MEDIA: {
              target: 'edit',
              actions: 'setCurrentFileIndex',
            },
          },
        },
        view: {
          on: {
            always: [{ target: 'idle', cond: (ctx) => !ctx.files.length }],
            EDIT_MEDIA: {
              target: 'edit',
              actions: 'setCurrentFileIndex',
            },
          },
        },
        edit: {
          on: {
            FINISH_EDIT_MEDIA: {
              target: 'view',
              actions: assign({ onEdit: false }),
            },
          },
        },
        exiting: {},
      },
      on: {
        ON_AUTOSAVE: [
          {
            target: 'editor.autosave',
            cond: (ctx) => conditionalAutosaving(ctx),
            actions: assign({ autoSaving: true }),
          },
        ],
        ONCHANGE_CURRENTFILEINDEX: {
          actions: 'setCurrentFileIndex',
        },
        SET_SEQUENCE_INDEX: {
          actions: 'setSequenceIndex',
        },
        // Toggle publishing status for new cards
        IS_FOR_SAVING: {
          actions: 'isForSaving',
        },
        IS_FOR_PUBLISHING: {
          actions: 'isForPublishing',
        },
        IS_EXITING: {
          actions: 'isExiting',
        },
        SET_SEQUENCE: {
          actions: 'setSequence',
        },
        VIEW_SEQUENCE: {
          actions: 'viewSequence',
        },
        SET_SELECTED_ENTITY: {
          actions: 'setSelectedEntity',
        },
        SET_URL_VALUE: {
          actions: 'setUrlValue',
        },
        SET_AUTOSAVE: {
          actions: 'setAutoSave',
        },
        // Indicate the states of created cards
        SELECT_MEDIA: {
          actions: 'setBinaryFiles',
        },
        SET_CHANGED: {
          actions: 'setChanged',
        },
        CHANGED_MEDIA: {
          actions: 'changedMedia',
        },
        SET_DISABLED: {
          actions: 'setDisabled',
        },
        DELETE_MEDIA: {
          actions: 'deleteMedia',
        },
        TO_REMOVED_MEDIA: {
          actions: 'toRemovedMedia',
        },
        // Init data in the editor for already created cards
        SET_INIT_DATA: {
          actions: 'setInitData',
        },
        SET_LOADING_UPLOAD: {
          actions: 'setLoading',
        },
        SET_PARSED_MEDIA: {
          actions: 'setParsedMedia',
        },
        SET_USER_ID: {
          actions: 'setUserId',
        },
        SET_CARD_ID: {
          actions: 'setCardId',
        },
        SET_CONTEXT: {
          actions: 'setContext',
        },
        SELECT_SUBFORMAT: {
          actions: 'setSubFormat',
        },
        // MEDIA CHANGES
        SET_MEDIA: {
          actions: 'setMedia',
        },
        ADD_MEDIA: {
          actions: 'addBinaryFiles',
        },
        CROP_MEDIA: {
          actions: 'cropMedia',
        },
        DELETE_DUPLICATES: {
          actions: 'deleteDuplicate',
        },
        SET_LINK_MEDIA: {
          actions: 'setLinkMedia',
        },
        ON_ASPECT_RATIO: {
          actions: 'setAspectRatio',
        },
        ON_LIGHTBOX: {
          actions: 'setLightBox',
        },
        ON_GALLERY_IMAGES: {
          actions: 'setGalleryImages',
        },
        SET_GALLERY_INDEX: {
          actions: 'setGalleryIndex',
        },
        // Clean context/data content
        CLEAN_CONTEXT: {
          actions: 'cleanContext',
        },
        CLEAN_INIT_DATA: {
          actions: 'cleanInitData',
        },
        SET_UPDATING_DATA: {
          actions: 'setUpdatingData',
        },
        // Media iterations
        CLEAN_MEDIA: {
          actions: 'cleanMedia',
        },
        SET_REMOVED_MEDIA: {
          actions: 'setRemovedMedia',
        },
        SET_VIDEO_COVER: {
          actions: 'setVideoCover',
        },
        SET_EMBED_VALUES: {
          actions: 'setEmbedValues',
        },
        SET_COVER: {
          actions: 'setCoverMedia',
        },
        ORDER_FILES: {
          actions: 'orderFiles',
        },
        ORDER_MEDIA: {
          actions: 'orderMedia',
        },
        ORDER_CAROUSEL: {
          actions: 'orderCarousel',
        },
        HANDLE_CROP: {
          actions: 'handleCrop',
        },
        ONCROPPING: {
          actions: 'setCropping',
        },
        // changing card details
        ONCHANGE_TITLE: {
          actions: 'setTitle',
        },
        ONCHANGE_DESCRIPTION: {
          actions: 'setDescription',
        },
        ONCHANGE_CONTENT: {
          actions: 'setContent',
        },
        ADD_COMPONENT: {
          actions: 'addComponentToContent',
        },
        ONCHANGE_EDITOR_CONTENT: {
          actions: 'setEditorContent',
        },
        ONCHANGE_EDITOR_MEDIA: {
          actions: 'setContentComponentData',
        },
        ONCHANGE_IMAGE_CAPTION: {
          actions: 'setImageCaption',
        },
        ONCHANGE_EDITORIMAGE: {
          actions: 'setEditorImage',
        },
        ONCHANGE_CAROUSELDATA: {
          actions: 'setEditorCarouselData',
        },
        ONEDIT_CONTENT: {
          actions: 'changedContent',
        },

        // changing, mutating and edting content
        SET_EDITOR_INDEX: {
          actions: 'setEditorIndex',
        },
        SET_CARET_DATA: {
          actions: 'setCaretData',
        },
        ON_LINK_EDITING: {
          actions: 'onEditingLink',
        },
        SET_EDITOR_BLOCKS: {
          actions: 'setEditorBlocks',
        },
        SET_TOGGLE_INDEX: {
          actions: 'setToggleIndex',
        },
        SET_INLINE: {
          actions: 'setInlineStyle',
        },
        SET_BLOCKTYPE: {
          actions: 'setBlockTypeStyle',
        },
        SET_EDITOR_FOCUS: {
          actions: 'setCurrentEditorIndex',
        },
        ON_MEDIA_TOGGLE: {
          actions: 'onMediaToggle',
        },
        // Navigate the card creation process
        NEXT: [
          {
            target: 'mediaSourceInformation',
            cond: (ctx) => checkEmptyField(ctx),
          },
          {
            target: 'previewCard',
            cond: (ctx) => ctx.title.length > 1 && contentMedia(ctx.content, ctx.subFormat),
          },
        ],
        BACK: [
          {
            target: 'formats',
            cond: (ctx) => !ctx.editing,
            actions: 'cleanContext',
          },
          {
            target: 'formats',
            cond: (ctx) => !checkEmptyField(ctx),
            actions: 'cleanContext',
          },
        ],
        CONFIRM: {
          target: 'selectChannel',
          cond: (ctx) => checkEmptyField(ctx),
        },
        CONFIRM_EDIT: [{ target: 'confirmEdit' }],
        // Change discarding
        DISCARD_CHANGES: {
          target: 'formats',
          actions: 'discardChanges',
        },
        // SAVE_CHANGES: {
        //   target: 'selectChannel',
        // },
        PREVIEW: [{ target: 'previewCard', cond: (ctx) => checkEmptyField(ctx) }],
        SAVE: [{ target: 'saved', cond: (ctx) => checkEmptyField(ctx) }],
      },
    },
    curationEditor: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            SELECT_MEDIA: {
              actions: 'setBinaryFiles',
            },
            SWITCH_VIEW: {
              target: 'view',
              cond: (ctx) => ctx.media.length,
            },
            CLEAN_MEDIA: {
              actions: 'cleanMedia',
            },
            NEXT_STATE: {
              target: 'view',
            },
          },
        },
        autosave: {
          invoke: {
            id: 'autosaving',
            src: (ctx, _) => async (cb) => requestAutoSaving(ctx, cb),
          },
          on: {
            AUTOSAVE_NEW_CARD: [
              {
                target: 'idle',
                actions: 'newAutosave',
                cond: (ctx) => !ctx.media.length,
              },
              {
                target: 'view',
                actions: 'newAutosave',
                cond: (ctx) => ctx.media.length,
              },
            ],
            AUTOSAVE_EXISTING_CARD: [
              {
                target: 'idle',
                actions: 'existingAutosave',
                cond: (ctx) => !ctx.media.length,
              },
              {
                target: 'view',
                actions: 'existingAutosave',
                cond: (ctx) => ctx.media.length,
              },
            ],
            AUTOSAVE_ERROR: {
              actions: assign({
                stopAutosave: (_, event) => true,
              }),
            },
          },
        },
        view: {
          on: {
            DELETE_MEDIA: {
              actions: 'deleteMedia',
              target: 'idle',
            },
            SET_MEDIA: {
              actions: 'setMedia',
            },
            SET_VIDEO_MEDIA: {
              actions: 'setVideoMedia',
            },
            CLEAN_FILES: {
              actions: 'cleanFiles',
              target: 'idle',
            },
            CLEAN_MEDIA: {
              actions: 'cleanMedia',
              target: 'idle',
            },
          },
        },
      },
      on: {
        ON_AUTOSAVE: [
          {
            target: 'curationEditor.autosave',
            cond: (ctx) => conditionalAutosaving(ctx),
            actions: assign({ autoSaving: true }),
          },
        ],
        SET_USER_ID: {
          actions: 'setUserId',
        },
        IS_EXITING: {
          actions: 'isExiting',
        },
        ONCHANGE_TITLE: {
          actions: 'setTitle',
        },
        SET_CONTEXT: {
          actions: 'setContext',
        },
        SET_CHANGED: {
          actions: 'setChanged',
        },
        SET_DISABLED: {
          actions: 'setDisabled',
        },
        SET_DISCARDED: {
          actions: 'setDiscarded',
        },
        SET_INIT_DATA: {
          actions: 'setInitData',
        },
        SET_UPDATED_DATA: {
          actions: 'setUpdatedData',
        },
        SET_LOADING_UPLOAD: {
          actions: 'setLoading',
        },
        SET_COVER: {
          actions: 'setCoverMedia',
        },
        SET_VIDEO_MEDIA: {
          actions: 'setVideoMedia',
        },
        TO_REMOVED_MEDIA: {
          actions: 'toRemovedMedia',
        },
        DELETE_MEDIA: {
          actions: 'deleteMedia',
          // target: 'idle',
        },
        CLEAN_CONTEXT: {
          actions: 'cleanContext',
        },
        SET_REMOVED_MEDIA: {
          actions: 'setRemovedMedia',
        },
        CLEAN_INIT_DATA: {
          actions: 'cleanInitData',
        },
        ONCHANGE_DESCRIPTION: {
          actions: 'setDescription',
        },
        SELECT_SUBFORMAT: {
          actions: 'setSubFormat',
        },
        SET_VIDEO_LINK_MEDIA: {
          actions: 'setVideoLinkMedia',
        },
        SET_LINK_MEDIA: {
          actions: 'setLinkMedia',
        },
        SET_LINK_TYPE: {
          actions: assign({
            media: (ctx, event) => [
              {
                ...ctx.media[0],
                asset: {
                  ...ctx.media[0].asset,
                  linkType: event.linkType,
                },
              },
            ],
          }),
        },
        IS_FOR_SAVING: {
          actions: 'isForSaving',
        },
        IS_FOR_PUBLISHING: {
          actions: 'isForPublishing',
        },
        PREVIEW: [{ target: 'previewCard', cond: (ctx) => checkEmptyField(ctx) }],
        NEXT: [{ target: 'previewCard', cond: (ctx) => checkEmptyField(ctx) }],
        BACK: [{ target: 'formats', cond: (ctx) => !checkEmptyField(ctx) }],
        CONFIRM_EDIT: [{ target: 'confirmEdit' }],
        SAVE: [{ target: 'saved' }],
        DISCARD_CHANGES: { target: 'formats' },
        CONFIRM: {
          target: 'selectChannel',
          cond: (ctx) => checkEmptyField(ctx),
        },
      },
    },
    videoMessageEditor: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            SELECT_MEDIA: {
              actions: 'setBinaryFiles',
            },
            SWITCH_VIEW: {
              target: 'view',
              cond: (ctx) => ctx.files.length,
            },
          },
        },
        view: {
          on: {
            DELETE_MEDIA: {
              actions: 'deleteMedia',
              target: 'idle',
            },
            SET_VIDEO_MEDIA: {
              actions: 'setVideoMedia',
            },
            SET_MEDIA: {
              actions: 'setMedia',
            },
            CLEAN_FILES: {
              actions: 'cleanFiles',
              target: 'idle',
            },
            CLEAN_MEDIA: {
              actions: 'cleanMedia',
              target: 'idle',
            },
          },
        },
        autosave: {
          invoke: {
            id: 'autosaving',
            src: (ctx, _) => async (cb) => requestAutoSaving(ctx, cb),
          },
          on: {
            AUTOSAVE_NEW_CARD: [
              {
                target: 'idle',
                actions: 'newAutosave',
                cond: (ctx) => !ctx.media.length,
              },
              {
                target: 'view',
                actions: 'newAutosave',
                cond: (ctx) => ctx.media.length,
              },
            ],
            AUTOSAVE_EXISTING_CARD: [
              {
                target: 'idle',
                actions: 'existingAutosave',
                cond: (ctx) => !ctx.media.length,
              },
              {
                target: 'view',
                actions: 'existingAutosave',
                cond: (ctx) => ctx.media.length,
              },
            ],
            AUTOSAVE_ERROR: {
              actions: assign({
                stopAutosave: (_, event) => true,
              }),
            },
          },
        },
      },
      on: {
        ON_AUTOSAVE: [
          {
            target: 'videoMessageEditor.autosave',
            cond: (ctx) => conditionalAutosaving(ctx),
            actions: assign({ autoSaving: true }),
          },
        ],
        NEXT: [{ target: 'previewCard', cond: (ctx) => checkEmptyField(ctx) }],
        PREVIEW: [{ target: 'previewCard', cond: (ctx) => checkEmptyField(ctx) }],
        CONFIRM_EDIT: [{ target: 'confirmEdit' }],
        SAVE: [{ target: 'saved' }],
        DISCARD_CHANGES: {
          target: 'formats',
          actions: 'discardChanges',
        },
        CONFIRM: {
          target: 'selectChannel',
          cond: (ctx) => checkEmptyField(ctx),
        }, // FIXME add a fallback alert to
        BACK: [{ target: 'formats', cond: (ctx) => !checkEmptyField(ctx) }],
        SET_USER_ID: {
          actions: 'setUserId',
        },
        IS_EXITING: {
          actions: 'isExiting',
        },
        ONCHANGE_TITLE: {
          actions: 'setTitle',
        },
        SET_CONTEXT: {
          actions: 'setContext',
        },
        SET_CHANGED: {
          actions: 'setChanged',
        },
        CHANGED_MEDIA: {
          actions: 'changedMedia',
        },
        SET_DISABLED: {
          actions: 'setDisabled',
        },
        SET_INIT_DATA: {
          actions: 'setInitData',
        },
        SET_UPDATED_DATA: {
          actions: 'setUpdatedData',
        },
        SET_COVER: {
          actions: 'setCoverMedia',
        },
        SET_LOADING_UPLOAD: {
          actions: 'setLoading',
        },
        ONCHANGE_DESCRIPTION: {
          actions: 'setDescription',
        },
        CLEAR_TITLE: {
          actions: 'clearTitle',
        },
        CLEAN_CONTEXT: {
          actions: 'cleanContext',
        },
        CLEAN_INIT_DATA: {
          actions: 'cleanInitData',
        },
        SELECT_SUBFORMAT: {
          actions: 'setSubFormat',
        },
        ADD_MEDIA: {
          actions: 'addBinaryFiles',
        },
        SELECT_MEDIA: {
          actions: 'setBinaryFiles',
        },
        SET_REMOVED_MEDIA: {
          actions: 'setRemovedMedia',
        },
        IS_FOR_SAVING: {
          actions: 'isForSaving',
        },
        IS_FOR_PUBLISHING: {
          actions: 'isForPublishing',
        },
      },
    },
    mediaSourceInformation: {
      initial: 'idle',
      states: {
        idle: {},
        discardChanges: {},
      },
      on: {
        SET_COVER_METADATA: {
          actions: 'setCoverMetaData',
        },
        SET_CAROUSEL_METADATA: {
          actions: 'setCarouselMetaData',
        },
        SET_IMAGE_METADATA: {
          actions: 'setImageMetaData',
        },
        NEXT: [{ target: 'previewCard', cond: (ctx) => checkEmptyField(ctx) }],
        BACK: [
          {
            target: 'editor',
            cond: (context) => context.format === 'Indepth',
          },
          {
            target: 'curationEditor',
            cond: (context) => context.format === 'Curation',
          },
          {
            target: 'videoMessageEditor',
            cond: (context) => context.format === 'VideoMessage',
          },
        ],
      },
    },
    previewCard: {
      on: {
        IS_FOR_SAVING: {
          actions: 'isForSaving',
        },
        IS_FOR_PUBLISHING: {
          actions: 'isForPublishing',
        },
        NEXT: [
          {
            target: 'selectChannel',
            cond: (ctx) => ctx.cardState === 'autosaved' || !ctx.editing,
          },
          {
            target: 'confirmEdit',
            cond: (ctx) => ctx.editing,
          },
        ],
        CONFIRM_EDIT: [{ target: 'confirmEdit' }],
        BACK: [
          {
            target: 'editor',
            cond: (context) => context.subFormat === 'Text' && context.media.length === 0,
          },
          {
            target: 'curationEditor',
            cond: (context) => context.format === 'Curation',
          },
          {
            target: 'videoMessageEditor',
            cond: (context) => context.format === 'VideoMessage',
          },
          {
            target: 'mediaSourceInformation',
            cond: (context) => context.format === 'Indepth',
          },
        ],
      },
    },
    selectChannel: {
      on: {
        SAVE: [{ target: 'saved', cond: (ctx) => checkEmptyField(ctx) }],
        BACK: [
          {
            target: 'previewCard',
            cond: (context) => context.format === 'Indepth',
          },
          {
            target: 'curationEditor',
            cond: (context) => context.format === 'Curation',
          },
          {
            target: 'videoMessageEditor',
            cond: (context) => context.format === 'VideoMessage',
          },
        ],
        PUBLISH: {
          target: 'published',
        },
        SET_STREAM_ID: {
          actions: 'setStreamId',
        },
      },
    },
    confirmEdit: {
      type: 'final',
    },
    published: { type: 'final' },
    saved: { type: 'final' },
  },
});

export default createCardWizardFormMachine;
