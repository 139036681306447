import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Indepth from './formats/in-depth-format';
import Curation from './formats/curation-format';
import VideoMessage from './formats/video-format';

import * as styles from './card-format.module.scss';

const cardFormats = ({ current, send }) => (
  <div className={styles.cardFormats}>
    {current.matches({ formats: 'Indepth' }) && <Indepth current={current} send={send} />}
    {current.matches({ formats: 'Curation' }) && <Curation current={current} send={send} />}
    {current.matches({ formats: 'VideoMessage' }) && <VideoMessage current={current} send={send} />}
  </div>
);

cardFormats.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default cardFormats;
