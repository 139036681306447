import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '@images/top-bar.svg';
import TabbBar from '@images/tabbar.svg';
import ChannelTabbBar from '@images/tabbar-channels.svg';
import previewPlaceholder from '@images/preview-behind-placeholder@2x.png';
import previewPlaceholder2 from '@images/bottom-placeholder@2x.png';
import * as styles from './iphoneMask.module.scss';

const IphoneMask = ({ children, preview = null }) => (
  <div className={styles.iphoneContainer}>
    {(preview !== 'card' || preview === null) && <TopBar />}
    {preview === 'cover' && (
      <img src={previewPlaceholder} className={styles.backgroundImage} alt="placeholder" />
    )}
    <div className={styles.mask} />
    <div className={styles.hinge} />
    <div className={`${styles.content} ${preview === 'card' && styles.pulledUp}`}>{children}</div>
    {preview === 'cover' && (
      <img src={previewPlaceholder2} className={styles.bottomImage} alt="placeholder" />
    )}
    {preview === 'channel' ? <ChannelTabbBar /> : preview !== 'card' && <TabbBar />}
  </div>
);

IphoneMask.propTypes = {
  children: PropTypes.node,
  preview: PropTypes.string,
};

export default IphoneMask;
