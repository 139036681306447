/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import getCroppedImg from '@helpers/cropImage';
import Cropper from 'react-easy-crop';

import Slider from 'rc-slider';

import { AnimatePresence } from 'framer-motion';

import Input from '@components/atoms/input';
import {
  mediaOptions,
  mediaItem,
  mediaItemCropping,
  fullScreenClicking,
  mediaWrapper,
  btnWrapper,
  rangeWrap,
  aspectRatiosStyle,
  cropperWrapper,
  relativeParent,
  previewImageContainer,
  adjustAlignment,
} from '@helpers/main.module.scss';
import { mediaTransformation, aspectRatios, rotation } from '@services/image';
import { onLightbox } from '@components/organisms/card/shared/helpers';

// import { preventEnterOnKeyDown } from '@helpers';
// import PlaceholderImage from '@images/placeholder-image.png';

import MediaButton from '@components/atoms/media-button';

import '@styles/utils/_rc-slider.scss';
import '@helpers/style/_rcslider.scss';
import * as styles from './editor-image.module.scss';
import { marginLeft } from 'styled-system';

const EditorImage = ({ data, setData, onDelete, tabEvent, send, id }) => {
  const [active, setActive] = useState(false);
  const [edit, setEdit] = useState(false);

  const [onAspect, setOnAspect] = useState(false);
  const [aspect, setAspect] = useState(4 / 3);
  const [originalAspect, setOriginal] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState([1]);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [isCropping, setIsCropping] = useState(false);

  const onHashChange = (property) => {
    const items = [...data];
    const item = {
      ...items[0],
      ...property,
    };

    items[0] = item;
    setData(items);
    setCroppedAreaPixels(null);
  };

  const toggleItem = (val, asp, value) => {
    setActive(val);
    if (value === 'Original') {
      setAspect(originalAspect);
    } else {
      setAspect(asp);
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const cpI = await getCroppedImg(data[0].asset.fullSizeUrl, croppedAreaPixels, rotation);
      setCroppedImage(cpI);
    } catch (e) {
      console.log(e);
    }
  }, [croppedAreaPixels, rotation]);

  const closeEdit = () => {
    setEdit(false);
    setActive(null);
    setOnAspect(false);
    showCroppedImage();
  };

  const toggleEdit = () => {
    setCroppedImage(null);
    setEdit(true);
  };

  const handleCrop = async () => {
    try {
      setIsCropping(true);
      const cpI = await getCroppedImg(data.original, croppedAreaPixels, rotation);
      await mediaTransformation(cpI, setCroppedImage);
      setIsCropping(false);
    } catch (e) {
      setIsCropping(false);
    }
  };

  // We need to discuss with Kate to potentially send a key inside
  // the content type image/carousel objects.
  // const addToRemovedMedia = () => {
  //   // onDelete();
  //   // const deletingItem = {
  //   //   key: data.key,
  //   // };
  //   // send({ type: 'TO_REMOVED_MEDIA', media: deletingItem });
  // };

  const justCrop = () => {
    handleCrop();
  };

  const onCropChange = (cropValue) => {
    setCrop(cropValue);
  };

  const onZoomChange = (zoomValue) => {
    setZoom([zoomValue]);
  };

  const onCropComplete = (croppedArea, cropAreaPixles) => {
    setCroppedAreaPixels(cropAreaPixles);
  };

  const handleKeyChange = (val) => {
    if (val.keyCode === 13 && val.shiftKey === false) {
      val.preventDefault();
    }
  };

  const handleMediaDelete = () => {
    onDelete();
    setEdit(false);
  };

  useEffect(() => {
    if (croppedImage && croppedImage.source) {
      // onImageChange(croppedImage.source);
      onHashChange({
        asset: {
          ...data[0].asset,
          defaultUrl: croppedImage.source,
        },
      });
    }
  }, [croppedImage]);

  return (
    <div className={styles.editorImageWrapper}>
      <div className={`${mediaWrapper} ${edit && mediaItemCropping}`}>
        <div className={mediaOptions}>
          {edit ? (
            <>
              <div className={styles.leftAligned}>
                <MediaButton
                  icon="trash"
                  hasAdditionalClass="trashInfo"
                  onBtnClick={() => handleMediaDelete()}
                />
                <MediaButton
                  icon="aspect-ratio"
                  active={onAspect}
                  onBtnClick={() => setOnAspect(!onAspect)}
                />
              </div>
              <MediaButton done onBtnClick={() => closeEdit()} />
            </>
          ) : (
            <>
              <div style={{ marginLeft: '29rem' }}>
                <MediaButton
                  icon="edit"
                  done="labeled"
                  hasLabel="Edit"
                  hasAdditionalClass="deleteButtonClass"
                  onBtnClick={() => toggleEdit()}
                />
              </div>
            </>
          )}
        </div>
        <div className={`${mediaItem} ${styles.imageCropper}`}>
          <AnimatePresence>
            {edit ? (
              <div className={cropperWrapper}>
                <div className={relativeParent}>
                  <Cropper
                    image={data[0].asset.fullSizeUrl}
                    crop={crop}
                    zoom={zoom}
                    minZoom={1}
                    maxZoom={2}
                    style={{
                      cropAreaStyle: {
                        border: '0',
                      },
                    }}
                    aspect={aspect}
                    showGrid={false}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                    onMediaLoaded={(mediaSize) => setOriginal(mediaSize.width / mediaSize.height)}
                    onInteractionEnd={justCrop}
                  />
                </div>
                {!onAspect && (
                  <div className={rangeWrap}>
                    <Slider min={1} max={3} step={0.2} onChange={(val) => setZoom([val])} />
                  </div>
                )}
              </div>
            ) : (
              <div className={previewImageContainer}>
                <div className={fullScreenClicking} onClick={() => onLightbox({ send, data })}>
                  <img
                    alt="editor-pic"
                    src={croppedImage?.blobUrl || data[0]?.asset?.defaultUrl || data.source}
                  />
                </div>
              </div>
            )}
          </AnimatePresence>
        </div>
        {onAspect && (
          <div className={aspectRatiosStyle}>
            <div className={btnWrapper}>
              {aspectRatios.map((ratio) => (
                <button
                  type="button"
                  key={ratio.id}
                  className={active === ratio.id ? styles.activeItem : null}
                  onClick={() => toggleItem(ratio.id, ratio.aspect, ratio.val)}
                >
                  {ratio.val}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className={styles.captionWrapper}>
        <Input
          placeholder="Caption"
          customClass="captionInput"
          maxRows={4}
          value={data[0].caption}
          valueChange={(val) => onHashChange({ caption: val })}
          onKeyDownChange={(evt) => handleKeyChange(evt)}
        />
      </div>
    </div>
  );
};

EditorImage.propTypes = {
  data: PropTypes.shape({
    original: PropTypes.string,
    source: PropTypes.string,
    type: PropTypes.string,
    previewSource: PropTypes.string,
    caption: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  setData: PropTypes.func,

  tabEvent: PropTypes.func,
};

export default EditorImage;
