import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { motion, AnimatePresence } from 'framer-motion';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import Cropper from 'react-easy-crop';

import Dropzone from 'react-dropzone-uploader';
import DropZoneInput from '@components/templates/create-channel/components/dropzone-input';
import getCroppedImg from '@helpers/cropImage';
import isLoadingHoc from '@components/hoc/isLoading';
import { getOrientation } from 'get-orientation/browser';
import { getRotatedImage } from '@helpers/rotateImage';

import { mediaHandlingService, singleValidation } from '@services/image';

import MediaButton from '@components/atoms/media-button';
import Button from '@components/atoms/button';
import Icon from '@components/atoms/icon';

import Slider from 'rc-slider';
import './cropper.scss';
import { readFile } from '@components/templates/create-channel/shared/helpers';

import * as styles from '@components/templates/create-channel/create-channel.module.scss';

const orientationAngle = {
  3: 180,
  6: 90,
  8: -90,
};

const ChannelCover = ({ current, send, setLoading, endAnimation }) => {
  const { context } = current;
  const { previewMedia, editing, image, disabled } = context;
  const acceptTypes = 'image';

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState([1]);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [currentStep, setStep] = useState('crop');
  const [hidden, setHidden] = useState(false);
  const [onDone, setOnDone] = useState(false);
  const aspect = 315 / 532;

  const onCropChange = (cropValue) => {
    setCrop(cropValue);
  };

  const onCropComplete = (croppedArea = null, cropAreaPixles) => {
    setCroppedAreaPixels(cropAreaPixles);
  };

  const onZoomChange = (zoomValue) => {
    setZoom([zoomValue]);
  };

  const handleCrop = async () => {
    try {
      const { binaryFile, blobUrl } = await getCroppedImg(
        image[0]?.asset?.fullSizeUrl,
        croppedAreaPixels
      );

      send({
        type: 'HANDLE_CROP',
        croppedFile: binaryFile,
        croppedUrlBlob: blobUrl,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const justCrop = () => {
    handleCrop();
    if (editing) {
      send({ type: 'SET_CHANGED', changed: true });
    }
  };

  const getFilesFromEvent = (e) =>
    new Promise((resolve, reject) => {
      getDroppedOrSelectedFiles(e).then(async (chosenFiles) => {
        send({
          type: 'SET_DISABLED',
          disabled: true,
        });

        const file = chosenFiles[0].fileObject;
        let imageDataUrl = await readFile(file);
        const acceptedTypes = 'image';
        const type = file.type.split('/')[0];

        if (acceptedTypes !== type) {
          return 'cant allow this type';
        }

        const orientation = await getOrientation(file);
        const rot = orientationAngle[orientation];

        if (rot) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rot);
        }

        const uploadedImage = [
          {
            croppedFile: file,
            croppedUrlBlob: imageDataUrl,
          },
        ];

        send({
          type: 'HANDLE_CROP',
          croppedFile: file,
          croppedUrlBlob: imageDataUrl,
        });

        const uploadChannelImage = async ({ success, response }) => {
          if (success) {
            const validation = await singleValidation(response[0].original);
            if (validation && response[0].type) {
              send({
                type: 'SET_IMAGE_DATA',
                asset: {
                  source: response[0]?.source,
                  fullSizeUrl: response[0]?.original,
                },
              });

              send({
                type: 'SET_DISABLED',
                disabled: false,
              });
            }
          }
        };

        const mediaTransformation = async () => {
          try {
            await mediaHandlingService(uploadedImage, uploadChannelImage, null, null);
          } catch (error) {
            throw error;
          }
        };

        await mediaTransformation();

        setCrop({ x: 0, y: 0 });
        setZoom([1]);

        setTimeout(() => {
          setHidden(true);
        }, 2500);

        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });

  function deleteImage() {
    setHidden(false);
    setStep('crop');
    send({
      type: 'ON_DELETE',
    });
  }

  const toggleEditing = (val) => {
    if (val === 'edit') {
      setStep('crop');
      // setCroppedImage(null);
    }
    if (val === 'done') {
      justCrop();
      setStep('finish');
    }
  };

  const onDoneCropping = () => {
    toggleEditing('done');
    setOnDone(true);
    send({
      type: 'SET_DISABLED',
      disabled: true,
    });
  };

  const renderMediaButtons = (step) => {
    switch (step) {
      case 'crop':
        return (
          image[0] &&
          hidden && (
            <>
              <div>
                <MediaButton icon="trash" onBtnClick={() => deleteImage()} />
              </div>
              <MediaButton done onBtnClick={() => onDoneCropping()} />
            </>
          )
        );
      case 'cover':
        return null;
      case 'finish':
        return (
          <>
            <MediaButton icon="edit" disabled={disabled} onBtnClick={() => toggleEditing('edit')} />
          </>
        );
      default:
        return null;
    }
  };

  const renderFinishButton = (val) => {
    switch (val) {
      case 'crop':
      case 'range':
        return (
          <Button
            btnClass="primary"
            hasDisabled={true || image.length === 0}
            onClick={() => (!disabled ? toggleEditing('done') : '')}
          >
            Next
          </Button>
        );
      case 'finish':
        return (
          <Button
            btnClass="primary"
            hasDisabled={disabled || image.length === 0}
            onClick={() => send('NEXT')}
          >
            {disabled ? 'Uploading' : 'Next'}
          </Button>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (image && image[0]) {
      if (image[0]?.asset?.defaultUrl) {
        setStep('finish');
        setHidden(true);
      }
    }
  }, [previewMedia]);

  const updateChannelImage = async ({ success, response }) => {
    if (success) {
      const validation = await singleValidation(response[0].original);
      if (validation && response[0].type) {
        send({
          type: 'SET_IMAGE_DATA',
          asset: {
            fullSizeUrl:
              image.length > 0 && image[0]?.asset
                ? image[0].asset.fullSizeUrl
                : response[0]?.original,
            lowResUrl: response[0]?.previewSource,
            defaultUrl: response[0]?.source,
          },
        });

        send({
          type: 'SET_DISABLED',
          disabled: false,
        });

        setTimeout(() => endAnimation(true), 300);
        setOnDone(false);
      }
    }
  };

  const mediaTransformation = async () => {
    try {
      await mediaHandlingService(image, updateChannelImage, null, null);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (onDone) {
      setLoading(true);
      mediaTransformation();
    }
  }, [onDone]);

  const inputType = 'image';
  const inputTitle = inputType === 'video' ? 'Add Video' : 'Add Image';

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
        className={styles.motionContainer}
      >
        <div className={styles.stepperWrapper}>
          <div className={styles.titleWrapper}>
            <h2>
              {image && image.length > 0 && image[0].asset
                ? 'Scale & Reposition'
                : 'Add Channel Cover'}
            </h2>
          </div>

          <div className={`${styles.contentWrapper} ${styles.centerAligned}`}>
            <div className={styles.coverWrapper}>
              <div className={styles.mediaOptions}>{renderMediaButtons(currentStep)}</div>
              {!image[0]?.asset ? (
                <Dropzone
                  accept={`${acceptTypes}/*`}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  InputComponent={(props) => <DropZoneInput title={inputTitle} {...props} />}
                  getFilesFromEvent={getFilesFromEvent}
                  styles={{
                    dropzone: {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      overflow: 'unset',
                      width: 315,
                      height: 533,
                      border: 'none',
                      backgroundColor: '#fff',
                      boxShadow: '0px 5px 20px 0 rgba(0, 0, 0, 0.08)',
                      borderRadius: '1.5rem',
                      position: 'absolute',
                      left: '0',
                      top: '0',
                    },
                    dropzoneReject: {
                      border: '2px solid red',
                      backgroundColor: '#DAA',
                    },
                    dropzoneActive: {
                      border: '2px solid #50F246',
                    },
                  }}
                />
              ) : (
                <>
                  <AnimatePresence>
                    {currentStep === 'crop' ? (
                      <div className={styles.cropperWrapper}>
                        <div className={styles.relativeParent}>
                          <Cropper
                            image={image[0].asset.fullSizeUrl}
                            crop={crop}
                            zoom={zoom}
                            minZoom={1}
                            maxZoom={3}
                            crossOrigin="Anonymous"
                            style={{
                              cropAreaStyle: {
                                border: '0',
                              },
                            }}
                            aspect={aspect}
                            showGrid={false}
                            onCropChange={onCropChange}
                            onCropComplete={onCropComplete}
                            onZoomChange={onZoomChange}
                            onInteractionEnd={justCrop}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={styles.previewImageContainer}>
                        <img
                          src={image[0]?.asset?.defaultUrl || image[0]?.croppedUrlBlob}
                          alt="Cropped Version"
                        />
                      </div>
                    )}
                  </AnimatePresence>
                  {!hidden ? (
                    <motion.div
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 0 }}
                      transition={{ delay: 1.5 }}
                      className={styles.reposition}
                    >
                      <div className={styles.iconWrapper}>
                        <Icon iconClass="reposition" fSize={3.8} />
                      </div>
                      <div className={styles.description}>
                        <h5>Reposition</h5>
                        <p>Tap and move the cursor around</p>
                      </div>
                    </motion.div>
                  ) : null}
                </>
              )}
              {previewMedia && hidden && currentStep === 'crop' ? (
                <div className={styles.rangeWrap}>
                  <Slider
                    min={1}
                    max={3}
                    step={0.2}
                    value={zoom}
                    draggableTrack={false}
                    onChange={(val) => {
                      setZoom([val]);
                      handleCrop();
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.footerWrapper}>
            {previewMedia ? (
              renderFinishButton(currentStep)
            ) : (
              <>
                <Button btnClass="primary" backBtn onClick={() => send('BACK')}>
                  Back
                </Button>
                <Button btnClass="primary" hasDisabled={disabled} onClick={() => send('NEXT')}>
                  Next
                </Button>
              </>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

ChannelCover.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default isLoadingHoc(ChannelCover);
