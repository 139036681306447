import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Api from '@api';

import waterfall from 'async/waterfall';
import { motion } from 'framer-motion';
import Modal from '@components/molecules/modal';

import Card from '@components/organisms/card';
import IphoneMask from '@components/molecules/iphone-mask';
import Button from '@components/atoms/button';

import { toggleWrapper, previewToggler } from '@components/organisms/header/header.module.scss';
import { mediaTypes } from '@components/organisms/card/shared/helpers';
import { populateMediaObject } from '@components/templates/create-card/helpers';

import { isCurator, hasSocials, getToken, getUserData, handleAspectRatio } from '@helpers';
import JusttTray from '@images/justt-tray.png';
import * as styles from '@components/templates/create-channel/create-channel.module.scss';
import CardLayout from './card-layout';
import PlaceholderImage from '@images/placeholder-image.png';

const PreviewCard = ({ current, send }) => {
  const {
    editing,
    disabled,
    cardId,
    userId,
    streamId,
    tenantId,
    format,
    cardState,
    coverMedia,
    author,
    title,
    files,
    content,
    category,
    media,
    description,
    subFormat,
  } = current.context;

  const [onDone, setOnDone] = useState(false);
  const [updatingData, setUpdatingData] = useState({});
  const [active, setActive] = useState('1');

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const [type, setType] = useState('cover');

  const currentUser = getUserData();
  // const createDate = new Date();

  const togglers = [
    {
      id: '1',
      label: 'Cover Preview',
      type: 'cover',
    },
    {
      id: '2',
      label: 'Card Preview',
      type: 'card',
    },
  ];

  const allowedTypes = ['Indepth'];

  const variants = {
    start: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.2,
      },
    },
    done: {
      y: '-50%',
      transition: {
        duration: 1.2,
      },
    },
  };

  const cardPreview = () => {
    let fileType = '';
    if (files && files.length > 0) {
      if (files[0].croppedFile) {
        // eslint-disable-next-line prefer-destructuring
        fileType = files[0].croppedFile.type.split('/')[0];
      } else {
        fileType = files[0].type;
      }
    }
    // const type = files[0].croppedFile.type.split('/')[0];
    if (subFormat !== 'Text') {
      if (!editing && mediaTypes.includes(subFormat)) {
        if (fileType === 'video') {
          return media[0];
        }
        return {
          source: coverMedia || files[0] ? files[0].originalUrlBlob : PlaceholderImage,
        };
      }
      if (format === 'Curation') {
        return media[0];
      }
      if (format === 'VideoMessage') {
        return media[0];
      }

      if (editing && fileType === 'video') {
        return media[0];
      }

      return {
        source: coverMedia || media[0]?.asset?.defaultUrl || files[0].originalUrlBlob,
      };
    }

    if (format === 'Curation') {
      return media;
    }
    return null;
  };

  const toggleViews = (id, val) => {
    setActive(id);
    setType(val);
  };

  const updateCard = async (cb) => {
    const cardData = {
      ...updatingData,
      coverMedia: {
        items: media,
      },
    };

    try {
      const response = await Api.updateEditedCard(getToken(), cardData);
      cb(null, response);
    } catch (err) {
      cb(err);
    }
  };

  const handleUpdatingCard = () => {
    if (
      (subFormat !== 'Text' && title.length > 0 && (media.length > 0 || files.length > 0)) ||
      (subFormat === 'Text' && title.length > 0 && content.length > 0)
    ) {
      waterfall(
        [
          function (callback) {
            updateCard(callback);
          },
        ],
        (err, res) => {
          if (!res.success) {
            setShowModal(true);
            setModalTitle('Something went wrong!');
            setModalDesc('Can not update the card');
            return null;
          }
          return send({ type: 'CONFIRM_EDIT' });
        }
      );
    }
    return null;
  };

  const handleSavingCard = () => {
    send({ type: 'IS_FOR_SAVING' });
    setTimeout(() => send('NEXT'), 300);
  };

  const handlePublishingCard = () => {
    send({ type: 'IS_FOR_PUBLISHING' });
    setTimeout(() => send({ type: 'NEXT' }), 500);
  };

  const cancelModal = () => {
    setShowModal(false);
    setModalTitle('');
    setModalDesc('');
  };

  const handleModalAction = () => {
    setShowModal(false);
  };

  const customButtons = () =>
    !editing || cardState === 'autosaved' ? (
      <>
        <Button btnClass="editorBtn" onClick={() => handleSavingCard()}>
          Save Draft
        </Button>
        <Button
          btnClass="editorBtn"
          hasDisabled={disabled}
          greenColor
          onClick={() => handlePublishingCard()}
        >
          Publish Card
        </Button>
      </>
    ) : (
      <Button btnClass="editorBtn" greenColor onClick={() => handleUpdatingCard()}>
        Update
      </Button>
    );

  useEffect(() => {
    setUpdatingData({
      cardId,
      userId,
      cardState,
      title,
      tenantId,
      streamId,
      description,
      format,
      subFormat,
      content_v2: content,
      // removedMedia,
    });
  }, [active]);

  return (
    <>
      <motion.div
        initial="start"
        variants={variants}
        animate={onDone ? 'done' : 'start'}
        className={styles.motionContainer}
      >
        {showModal && (
          <Modal
            type="error"
            title={modalTitle}
            desc={modalDesc}
            showModal={showModal}
            onConfirm={() => handleModalAction()}
            onCancel={() => cancelModal()}
          />
        )}
        <div className={styles.stepperWrapper}>
          <div className={`${toggleWrapper} ${previewToggler} ${styles.pulledUpPreviewTitle}`}>
            {allowedTypes.includes(format)
              ? togglers.map((item) => (
                  <Button
                    type="button"
                    headerBtn
                    previewBtn
                    active={active === item.id}
                    onClick={() => toggleViews(item.id, item.type)}
                    key={item.id}
                  >
                    {item.label}
                  </Button>
                ))
              : ''}
            {!allowedTypes.includes(format) ? (
              <Button type="button" headerBtn previewBtn active>
                Cover Preview
              </Button>
            ) : (
              ''
            )}
          </div>
          <div className={`${styles.contentWrapper} ${styles.previewChannelWrapper}`}>
            <IphoneMask preview={type}>
              {type !== 'card' && <img src={JusttTray} alt="" width={384} height={47} />}
              {type === 'card' ? (
                <CardLayout
                  media={media}
                  content={content}
                  title={title}
                  description={description}
                  subFormat={subFormat}
                  maxHeight={780}
                />
              ) : (
                <Card
                  title={title}
                  src={cardPreview()}
                  cardClass={subFormat}
                  type={subFormat}
                  preview="cardPreview"
                  author={author?.name || currentUser?.displayName}
                  curator={isCurator.includes(subFormat)}
                  description={description}
                  category={category}
                  socials={hasSocials.includes(subFormat)}
                  intendedAspectRatio={handleAspectRatio(media, subFormat)}
                  multiple={media && media.length > 1}
                />
              )}

              {type !== 'card' && <span />}
            </IphoneMask>
          </div>
          <div className={styles.footerWrapper}>
            {!onDone ? customButtons() : <h2 className={styles.doneTitle}>Done</h2>}
          </div>
        </div>
      </motion.div>
    </>
  );
};

PreviewCard.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default PreviewCard;
