import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

import SEO from '@components/seo';
import ContainerWrapper from '@components/molecules/container-wrapper';
import Card from '@components/organisms/card';

import Button from '@components/atoms/button';
// import keygen from '@helpers/keygen';

import * as styles from '@components/organisms/select-create/create.module.scss';
import CardsData from '@helpers/placeholderData';

const CurationCards = ({ current, send }) => {
  const [active, setActive] = useState('VideoLink');
  // const { format, subFormat } = current.context;
  const formatTypes = CardsData.cardTypes.curation.items;

  const setActiveCard = (val) => {
    setActive(val);
    send({ type: 'SELECT_SUBFORMAT', subformat: val });
  };

  const startCardCreation = () => {
    send({ type: 'START_CURATION_EDITOR' });
  };

  const variants = {
    initial: {
      transform: 'scale(1)',
    },
    active: {
      transform: 'scale(1.15)',
    },
  };

  useEffect(() => {
    // if ((!format, !subFormat)) {
    send({ type: 'SELECT_SUBFORMAT', subformat: active });
    send({ type: 'SELECT_FORMAT', format: 'Curation' });
    // }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ height: '100%' }}
    >
      <SEO title="Create Curation Card" />
      <ContainerWrapper>
        <div className={styles.cardContent}>
          <div className={styles.cardTypes}>
            {formatTypes.map((item) => (
              <Button
                btnClass={active === item.id ? 'activeBtn' : ''}
                key={item.id}
                onClick={() => setActiveCard(item.id)}
              >
                {item.name}
              </Button>
            ))}
          </div>
          <div className={styles.cardsFormats}>
            {formatTypes.map((item) => (
              <motion.div
                className={styles.cardHolder}
                initial="initial"
                variants={variants}
                animate={active === item.id ? 'active' : 'initial'}
                onClick={() => setActiveCard(item.id)}
                key={item.id}
                aria-hidden="true"
              >
                <Card placeholder type={item.id} curator cardClass={item.class} />
              </motion.div>
            ))}
          </div>
          <Button btnClass="primary" onClick={() => startCardCreation()}>
            Next
          </Button>
        </div>
      </ContainerWrapper>
    </motion.div>
  );
};

CurationCards.propTypes = {
  current: PropTypes.shape(),
  send: PropTypes.func,
};

export default CurationCards;
