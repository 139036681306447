import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@components/atoms/icon';
import Input from '@components/atoms/default-input';

import {
  itemWrap,
  ImageWrapper,
  headingLabels,
  contentWrapper,
  inputsWrapper,
  inputs,
} from './mediaItem.module.scss';

const MediaItem = ({ data, onMetaDataUpdate }) => {
  const [metaData, setMetaData] = useState([]);

  const editOriginatorItem = (value, index) => {
    let updatingItem = {};
    updatingItem = {
      copyrightInfo: metaData[index].copyrightInfo,
      originator: value,
    };

    const newData = metaData;
    newData[index] = updatingItem;
    setMetaData(newData);
    onMetaDataUpdate(newData);
  };

  const editCopyrightItem = (value, index) => {
    let updatingItem = {};

    updatingItem = {
      originator: metaData[index].originator,
      copyrightInfo: value,
    };

    const newData = metaData;
    newData[index] = updatingItem;

    setMetaData(newData);
    onMetaDataUpdate(newData);
  };

  useEffect(() => {
    const metachild = {
      originator: '',
      copyrightInfo: '',
    };

    if (data?.sourceInformation) {
      if (data?.sourceInformation?.length !== 3) {
        const metaItems = data?.sourceInformation?.length;
        const required = 3;
        const division = required - metaItems;
        // for whatever remaining number, add a meta child;
        const newArr = [];
        newArr.push(...data?.sourceInformation);
        for (let i = 0; i < division; i++) {
          newArr.push(metachild);
        }
        setMetaData(newArr);
      } else {
        setMetaData(data?.sourceInformation);
      }
    } else {
      setMetaData([
        {
          originator: '',
          copyrightInfo: '',
        },
        {
          originator: '',
          copyrightInfo: '',
        },
        {
          originator: '',
          copyrightInfo: '',
        },
      ]);
    }
  }, []);

  return (
    <div className={itemWrap}>
      <div className={ImageWrapper}>
        {data?.type === 'video' && data?.highResThumbnailUrl ? (
          <>
            <Icon iconClass="play" />
            <img src={data?.highResThumbnailUrl || data?.videoCover} alt={data?.caption} />
          </>
        ) : (
          <img src={data?.croppedUrlBlob || data?.asset?.defaultUrl} alt={data?.caption} />
        )}
      </div>
      <div className={contentWrapper}>
        <div className={headingLabels}>
          <h5>
            Add Creator <Icon iconClass="info" />
          </h5>
          <h5>
            Add Copyright Info
            <Icon iconClass="info" />
          </h5>
        </div>
        <div className={inputsWrapper}>
          {metaData.map((inputItem, idx) => (
            <div className={inputs} key={idx}>
              <Input
                customClass="mediaSourceInput"
                placeholder="Enter creator here"
                value={inputItem?.originator}
                changedValue={(val) => editOriginatorItem(val, idx)}
                noMargin
              />
              <Input
                customClass="mediaSourceInput"
                value={inputItem?.copyrightInfo}
                placeholder="e.g. Unsplash"
                changedValue={(val) => editCopyrightItem(val, idx)}
                noMargin
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

MediaItem.propTypes = {
  data: PropTypes.shape(),
  onMetaDataUpdate: PropTypes.func,
};

export default MediaItem;
