import React, { useEffect } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import useSWR from 'swr';
import Api from '@api';

// HOC Functions
import { cleanUpHtml, isLoggedIn, getTenant, getUserId, getToken } from '@helpers';

// App Components
import SEO from '@components/seo';
import ClientOnly from '@components/molecules/clientOnly';
import Header from '@components/organisms/header';
import Button from '@components/atoms/button';
import MediaButton from '@components/atoms/media-button';
import Icon from '@components/atoms/icon';
import Input from '@components/atoms/default-input';
// import Textarea from '@components/atoms/input';
// Profile Components
import EditProfile from './components/editProfile';
import AboutUs from './components/aboutUs';
import ViewProfile from './components/viewProfile';

import { useMachine } from '@xstate/react';
import profileEditingMachine from '@store/machines/profile-machine';

import { Formik, Field, FieldArray } from 'formik';

// Svgs and Assets
import { validationSchema } from './shared';
import LightEditorComponent from '@components/organisms/my-editor/components/light-editor';

import { profileInput, profileInputLabel } from '@components/atoms/default-input/input.module.scss';

// import * as styles from '@components/organisms/header/header.module.scss';

// Styles
import {
  editingUserDetailsWrapper,
  pulledHeader,
  contentWrapper,
  actionButtons,
  inputWrapper,
  inputLabel,
  inputLabel2,
  addNewItem,
  removeLink,
  errorMessage,
  ImpressumImprint,
  hasError,
  inputError,
} from './profile.module.scss';

const ProfilePage = () => {
  const [current, send] = useMachine(profileEditingMachine);
  const { context, event } = current;
  const { edit, showModal, files, onAbout, userData } = context;

  const links = [
    { key: 'facebook', value: '' },
    { key: 'instagram', value: '' },
    { key: 'linkedin', value: '' },
  ];

  const { data, mutate } = useSWR('/getUserProfileInfo', () =>
    Api.getUserProfileInfo(getToken(), getUserId(), getTenant())
  );

  const updateProfile = async (obj) => {
    try {
      const avatarKeyHandled = userData.avatarKey
        ? { ...obj, html: `${obj.html}`, avatarKey: userData.avatarKey }
        : { ...obj, html: `${obj.html}` };
      send({ type: 'SAVE_CHANGES' });
      const response = await Api.updateProfileInfo(getToken(), getUserId(), {
        ...avatarKeyHandled,
      });

      if (response?.success) {
        send({ type: 'GO_TO_PREVIEW' });
        setTimeout(() => {
          send({ type: 'ON_SUCCESS' });
        }, 300);
        mutate();
      } else {
        send({ type: 'HAS_ERROR' });
      }
    } catch (error) {
      send({ type: 'HAS_ERROR' });
    }
  };

  const onEnterCommand = (e) => {
    if (e.shiftKey) {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }
    return 'not-handled';
  };

  const cancelEdit = () => {
    send({ type: 'CANCEL_EDIT', edit: false });
    send({ type: 'SET_HIDE', hide: false });
  };

  useEffect(() => {
    if (data) {
      send({ type: 'ADD_USER_DATA', userData: data });
    }
  }, [data]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={contentWrapper}
    >
      <SEO title="User Account" />
      <Header isLoggedIn={isLoggedIn()} isAccount={!edit} />
      <ClientOnly>
        <div className={pulledHeader}>
          <h1>{!edit ? 'Profile' : 'Edit Profile'}</h1>
        </div>
        <AnimatePresence>{onAbout ? <AboutUs send={send} /> : ''}</AnimatePresence>

        {/* Toggle Between Views --- Either Profile Preview or Editing */}
        <AnimatePresence>
          {current.matches('preview') ? <ViewProfile send={send} context={context} /> : ''}
        </AnimatePresence>

        {current.matches('editing') && (
          <motion.div
            initial={{ opacity: 0, y: '2rem' }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, top: '-2rem', transition: { duration: 0.5 } }}
            className={editingUserDetailsWrapper}
          >
            <EditProfile showModal={showModal} send={send} userData={userData} files={files} />
            <Formik
              enableReinitialize
              initialValues={{
                displayName: userData?.displayName || '',
                occupation: userData?.profile?.occupation || '',
                html: userData?.profile?.html ? cleanUpHtml(userData?.profile?.html) : null || '',
                externalLinks:
                  userData?.profile?.externalLinks && userData?.profile?.externalLinks.length > 0
                    ? userData?.profile?.externalLinks
                    : links,
                impressum: userData?.profile?.impressum
                  ? cleanUpHtml(userData?.profile?.impressum)
                  : null || '',
              }}
              validateOnBlur={true}
              validateOnChange={false}
              validationSchema={validationSchema}
              autoComplete="off"
              onSubmit={(values) => updateProfile(values)}
            >
              {({ values, handleSubmit, handleChange, handleBlur, errors }) => (
                <form method="POST" onSubmit={handleSubmit}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      transition: { delay: 1, duration: 0.3 },
                    }}
                    className={actionButtons}
                  >
                    <Button btnClass="saveProfile" type="submit">
                      Save
                    </Button>
                    <MediaButton
                      icon="close-thin"
                      id="cancelEdit"
                      onBtnClick={() => cancelEdit()}
                      hasStyle={{ marginLeft: '2.6rem' }}
                      fSize="2.5rem"
                    />
                  </motion.div>
                  <div className={inputWrapper}>
                    <Input
                      customClass="profileInput"
                      type="text"
                      item="displayName"
                      name="displayName"
                      label="Profile Name"
                      max={50}
                      min={3}
                      placeholder="Enter your profile name..."
                      value={values.displayName}
                      changedValue={handleChange('displayName')}
                      onBlur={handleBlur}
                    />
                    {errors.displayName && <span className={hasError}>{errors.displayName}</span>}
                  </div>
                  <div className={inputWrapper}>
                    <Input
                      customClass="profileInput"
                      type="text"
                      item="occupation"
                      name="occupation"
                      placeholder="Enter your occupation..."
                      label="Occupation"
                      value={values.occupation}
                      changedValue={handleChange('occupation')}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className={inputWrapper}>
                    <span className={inputLabel}>About</span>
                    <LightEditorComponent
                      parsedValue={values.html}
                      updateValue={handleChange('html')}
                      name="html"
                    />
                  </div>
                  <FieldArray
                    name="externalLinks"
                    render={({ push, remove }) =>
                      values.externalLinks.length > 0 ? (
                        <>
                          {values.externalLinks.map((social, index) => (
                            <div
                              className={`
                                ${inputWrapper} 
                                ${
                                  errors && errors?.externalLinks && errors?.externalLinks[index]
                                    ? inputError
                                    : ''
                                }
                              `}
                              key={index}
                            >
                              <label
                                htmlFor={`externalLinks.${index}.value`}
                                className={profileInputLabel}
                              >
                                <span>{social.key || 'External' + ' Link'}</span>
                                <Field
                                  type="text"
                                  value={values.externalLinks[index].value}
                                  name={`externalLinks.${index}.value`}
                                  placeholder="Link url here..."
                                  className={profileInput}
                                />

                                {errors && errors?.externalLinks && (
                                  <span className={hasError}>
                                    {errors?.externalLinks[index]?.value}
                                  </span>
                                )}
                                {index > 2 ? (
                                  <div className={removeLink} onClick={() => remove(index)}>
                                    Remove
                                    <Icon iconClass="close-thin" />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </label>
                            </div>
                          ))}
                          <button
                            className={addNewItem}
                            type="button"
                            role="button"
                            onClick={() =>
                              push({
                                key: 'external',
                                value: '',
                              })
                            }
                          >
                            Add Link
                          </button>
                        </>
                      ) : (
                        ''
                      )
                    }
                  />
                  <br />
                  <div className={inputWrapper}>
                    <span className={ImpressumImprint}>Impressum/Imprint</span>
                    <br />
                    <span className={inputLabel2}>
                      This part should be filled out as is a legel requirement from EUX1232. Start
                      the creation from our template and adaopt the information. If you have your
                      impressum ready simply start filling your information.
                    </span>
                    <br />
                    <span className={inputLabel2}>
                      Service provider in terms of § 5 TMG (German Telemedia Act):
                    </span>
                    <br />
                    <LightEditorComponent
                      parsedValue={values.impressum}
                      updateValue={handleChange('impressum')}
                      handleReturn={onEnterCommand}
                      hasInlineOptions
                      current={current}
                      send={send}
                      name="impressum"
                    />
                  </div>
                </form>
              )}
            </Formik>
          </motion.div>
        )}

        {current.matches('error') && (
          <div className={errorMessage}>
            <h2>Udpate failed</h2>
            <p>Something went wrong. Couldn't update your profile!</p>
            <Button
              btnClass="saveProfile"
              type="submit"
              onClick={() => send({ type: 'TRY_AGAIN' })}
            >
              Go Back
            </Button>
          </div>
        )}
      </ClientOnly>
    </motion.div>
  );
};

export default ProfilePage;
